import PropTypes from 'prop-types';
import Document from '../../components/Icon/Document';

const GlassNameField = ({name, link, color}) => (
    <div style={{backgroundColor: color}} className="p-1 rounded-md text-white font-bold mt-2 w-[400px] flex flex-row justify-between">
        <h2>{name}</h2>
        <button
            type="button"
            onClick={() => window.open(link)}
        >
            <Document/>
        </button>
    </div>
);

GlassNameField.propTypes = {
    name: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
}

export default GlassNameField;