import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TermsAndConditionModal from '../../components/TermsAndConditions/TermsAndConditionModal';
import VideoModal from '../../components/VideoModal/VideoModal';
import IntroductionModal from '../../components/Introduction/Introduction';

const MainPage = () => {
    const [background, setBackground] = useState("url('/images/main_page.png')");
    const [isTermAndConditionModelOpen, setIsTermAndConditionModelOpen] = useState(false);
    const [isVideoModalOpen, setIsVideoTimeModalOpen] = useState(true);
    const [isIntroductionModalOpen, setIsIntroductionModalOpen] = useState(false);
    const [buildingType, setBuildingType] = useState('');
    const { i18n } = useTranslation();

    const onTermAndConditionModelOpenHandler = (building) => {
        setBuildingType(building);
        setIsTermAndConditionModelOpen(true);
    };

    const onIntroductionModalOpenHandler = () => {
        setIsIntroductionModalOpen(true);
    };

    function selectBackground() {
        if (i18n.language === 'en') {
            setBackground("url('/images/main_page_en.png')");
        } else {
            setBackground("url('/images/main_page_spn.png')");
        }
    }

    useEffect(() => {
        i18n.changeLanguage('en');
    }, []);

    useEffect(() => {
        selectBackground()
    }, [i18n.language]);

    return (
        <div className="bg-contain bg-no-repeat bg-center w-screen h-screen" style={{ backgroundImage: background }}>
            <button
                type='button'
                onMouseEnter={() => selectBackground()}
                onMouseLeave={() => selectBackground()}
                onClick={() => onTermAndConditionModelOpenHandler('Hospital')}
                aria-label='button'
                className="bg-transparent group absolute ml-[calc(50%)] mt-[150px] w-[calc(10%)] h-[calc(30%)]"
            />
            <button
                type='button'
                onClick={() => i18n.changeLanguage(i18n.language === 'en' ? 'spn' : 'en')}
                aria-label='button'
                className="bg-transparent group absolute ml-[calc(82%)] mt-[30px] w-[calc(15%)] h-[calc(10%)]"
            />
            <button
                type='button'
                onMouseEnter={() => selectBackground()}
                onMouseLeave={() => selectBackground()}
                onClick={() => onTermAndConditionModelOpenHandler('University')}
                aria-label='button'
                className="bg-transparent group absolute ml-[calc(80%)] mt-[150px] w-[calc(10%)] h-[calc(30%)]"
            />
            <button
                type='button'
                onMouseEnter={() => selectBackground()}
                onMouseLeave={() => selectBackground()}
                onClick={() =>  onTermAndConditionModelOpenHandler('Office')}
                aria-label='button'
                className="bg-transparent group absolute ml-[calc(62%)] mt-[350px] w-[calc(10%)] h-[calc(30%)]"
            />
            <button
                type='button'
                onMouseEnter={() => selectBackground()}
                onMouseLeave={() => selectBackground()}
                onClick={() => onTermAndConditionModelOpenHandler('Multi Family')}
                aria-label='button'
                className="bg-transparent group absolute ml-[calc(40%)] mt-[350px] w-[calc(10%)] h-[calc(30%)]"
            />
            <button
                type='button'
                onMouseEnter={() => selectBackground()}
                onMouseLeave={() => selectBackground()}
                onClick={() => onTermAndConditionModelOpenHandler('Convention Centre')}
                aria-label='button'
                className="bg-transparent group absolute ml-[calc(40%)] mt-[650px] w-[calc(19%)] h-[calc(30%)]"
            />
            <button
                type='button'
                onMouseEnter={() => selectBackground()}
                onMouseLeave={() => selectBackground()}
                onClick={onIntroductionModalOpenHandler}
                aria-label='button'
                className="bg-transparent group absolute ml-[calc(76%)] mt-[700px] w-[calc(25%)] h-[calc(25%)]"
            />
            <TermsAndConditionModal
                open={isTermAndConditionModelOpen}
                onClose={() => setIsTermAndConditionModelOpen(false)}
                redirect={buildingType}
            />
            <VideoModal
                videoLink="https://www.youtube.com/embed/3XctVN_OvBQ?rel=0"
                open={isVideoModalOpen}
                onClose={() => setIsVideoTimeModalOpen(false)}
            />
            <IntroductionModal
                open={isIntroductionModalOpen}
                onClose={() => setIsIntroductionModalOpen(false)}
                />
        </div>
    );
}

export default MainPage;