import React from 'react';
import { PDFDownloadLink, Document, Page, Image } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";

const MyDoc = ({imageSrc, imageSrc2, imageSrc3, imageSrc4}) => (
    <Document>
        <Page size="A4" wrap={false}>
            {imageSrc2 && (
                <Image
                    src={imageSrc}
                />
            )}
        </Page>
        <Page size="A4" wrap={false}>
            {imageSrc2 && (
                <Image
                    src={imageSrc2}
                />
            )}
        </Page>
        <Page size="A4" wrap={false}>
            {imageSrc2 && (
                <Image
                    src={imageSrc3}
                />
            )}
        </Page>
        <Page size="A4" wrap={false}>
            {imageSrc && (
                <Image
                    src={imageSrc4}
                />
            )}
        </Page>
    </Document>
);

const MyPDF = ({imageSrc, imageSrc2, imageSrc3, imageSrc4, submitHandler}) => {
    const { t } = useTranslation();

    return (
        <PDFDownloadLink
            document={<MyDoc imageSrc={imageSrc} imageSrc2={imageSrc2} imageSrc3={imageSrc3} imageSrc4={imageSrc4}/>}
            fileName="my-document.pdf" className="font-bold">
            {({blob, url, loading, error}) =>
                loading ?
                    <div className="fixed inset-0 flex items-center justify-center z-50">
                        <div className="flex flex-col bg-white border py-2 px-4 rounded-lg shadow-lg flex items-center">
                            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"/>
                            <p className="mt-4 ml-4 font-bold">{t('pdf.pleaseWait2')}</p>
                        </div>
                    </div>
                    :
                    <button type="submit" onClick={() => submitHandler(blob)}>{t('pdf.download')}</button>
            }
        </PDFDownloadLink>
    );
}

MyPDF.propTypes = {
    imageSrc: PropTypes.string.isRequired,
    imageSrc2: PropTypes.string.isRequired,
    imageSrc3: PropTypes.string.isRequired,
    imageSrc4: PropTypes.string.isRequired,
    submitHandler: PropTypes.func.isRequired,
};

MyDoc.propTypes = {
    imageSrc: PropTypes.string.isRequired,
    imageSrc2: PropTypes.string.isRequired,
    imageSrc3: PropTypes.string.isRequired,
    imageSrc4: PropTypes.string.isRequired,
};

export default MyPDF;