export const translation = {
    appTitle: 'Vitro Energy Calculator',
    agreement: {
        privacyPolicy: 'Política de Privacidad',
        acceptAgree: 'Acepto y estoy de acuerdo con los ',
        termsAndCondition: 'términos y condiciones',
        continue: 'Continuar',
        entry: 'Al hacer clic en Enviar, aceptas que Vitro Vidrio Arquitectónico almacene y procese la información suministrada arriba para proporcionarte el contenido solicitado.',
        title1: 'Declaración General',
        paragraph1: 'Vitro Vidrio Arquitectónico se compromete a proteger y respetar su privacidad, y solo usaremos su información personal para administrar su cuenta y brindarle los productos y servicios que nos solicitó. Vitro Vidrio Arquitectónico necesita la información de contacto que usted proporciona para contactarlo sobre nuestros productos y servicios. Podrá darse de baja de estas comunicaciones en cualquier momento. Para obtener información sobre cómo darse de baja, así como nuestras prácticas de privacidad y nuestro compromiso de proteger su privacidad, revise nuestra ',
        paragraph2: 'Este sitio web almacena cookies en su computadora. Estas cookies se utilizan para recopilar información sobre cómo interactúa con nuestro sitio web y nos permiten mejorar y personalizar su experiencia de navegación y para análisis y métricas sobre nuestros visitantes tanto en este sitio web como en otros medios. Para obtener más información sobre las cookies que utilizamos, consulte nuestra ',
        title2: 'Bases de cálculo y fuentes de entradas de datos/descargo de responsabilidad',
        paragraph3: 'Esta herramienta emplea cálculos de energía proporcionados por el Departamento de Energía de los Estados Unidos y datos del Servicio Meteorológico Nacional. Vitro no asume ninguna responsabilidad por la exactitud de la información disponible para las simulaciones en este programa.',
        paragraph4: 'Si bien Vitro ha hecho un esfuerzo de buena fe para verificar la confiabilidad de los resultados de esta herramienta informática, puede contener errores de programación desconocidos que pueden generar resultados incorrectos. Se recomienda al usuario que utilice su buen criterio e informe cualquier resultado cuestionable a Vitro para su evaluación. La aplicabilidad y los resultados posteriores de los datos simulados por esta herramienta se verán comprometidos si el usuario no ingresa la información correcta. Vitro no ofrece ninguna garantía sobre los resultados obtenidos por el usuario de esta herramienta, y sus resultados no están destinados a ser utilizados como un análisis energético formal.',
        title3: 'Marcas registradas y propiedad intelectual',
        paragraph5: 'Atlantica®, Azuria®, Graylite®, Optiblue®, Optigray®, Pacifica®, Solarban®, el logotipo de Solarban®, Solarblue®, Solarbronze®, Solarcool®, Solargray®, Solexia®, Starphire®, el logotipo de Starphire®, Sungate ®, Vistacool®, Vitro® y el logotipo de Vitro son marcas registradas propiedad de Vitro. Acuity™, Vitro Certified™ y el logotipo de Vitro Certified™ Network son marcas comerciales propiedad de Vitro.',
        paragraph6: 'Partes de la propiedad intelectual utilizada para impulsar esta herramienta que no cuentan con licencia ni son proporcionadas por otros (incluidos el Departamento de Energía de los Estados Unidos y el Servicio Meteorológico Nacional de los Estados Unidos) son propiedad de Vitro.',
        paragraph7: 'FenestraPro es una marca comercial de Phison Ltd. T/A FenestraPro.',
    },
    about: {
        title1: 'Sobre Vitro eMissions: Calculadora de Carbono & Energía',
        paragraph1: 'Vitro eMissions es una aplicación web que brinda a los usuarios una comprensión del impacto que tendrán las diferentes decisiones de diseño de acristalamiento en las emisiones operativas anuales de carbono y el uso de energía.',
        paragraph2: 'Dependiendo de los parámetros de un edificio (por ejemplo, su tipología, ubicación, orientación), la envolvente del edificio puede afectar en más de la mitad del uso operativo de energía de un edificio y las emisiones de gases de efecto invernadero relacionadas. En particular, el diseño del acristalamiento puede tener impactos importantes. Decisiones como la relación (ratio) ventana-pared; la configuración de la unidad acristalada y propiedades asociadas; ya sea que esté sombreado o no, puede afectar enormemente el uso de energía.',
        paragraph3: 'Utilizando tipologías prototípicas (hipotéticas) de edificios con parámetros predefinidos, los usuarios pueden investigar cómo las decisiones de diseño de la envolvente afectarán el rendimiento energético del edificio.',
        paragraph4: 'Vitro eMissions es una colaboración entre Vitro Vidrio Arquitectónico y FenestraPro. La herramienta emplea cálculos de energía proporcionados por el Departamento de Energía de los Estados Unidos y datos del Servicio Meteorológico Nacional de Estados Unidos. Vitro no asume ninguna responsabilidad por la exactitud de la información disponible para las simulaciones en este programa.',
        title2: 'Cómo se Calculan los Resultados de Energía Operacional:',
        paragraph5: 'Vitro eMissions utiliza el motor de software DOE-2, un programa gratuito de análisis de energía de edificios ampliamente utilizado y aceptado que puede predecir el uso y el costo de la energía para todo tipo de edificios. DOE-2 utiliza una descripción del diseño del edificio, construcciones, horarios de operación, sistemas de acondicionamiento (iluminación, HVAC, etc.) y tarifas de servicios públicos proporcionadas por el usuario, junto con datos climáticos, para realizar una simulación horaria del edificio y estimar recibos de servicios públicos.',
        title3: 'Cómo se Calculan los Resultados de Energía Operacional:',
        paragraph6: 'Utilizando los valores de energía operacional derivados del DOE-2, las emisiones de carbono se calculan con la Calculadora simplificada de emisiones de GEI (Gases Efecto Invernadero) de la EPA. Esta calculadora está diseñada como una herramienta de cálculo simplificada para ayudar a las organizaciones a estimar e inventariar sus emisiones anuales de gases de efecto invernadero (GEI) para operaciones con sede en Estados Unidos. Todas las metodologías y valores predeterminados proporcionados se basan en los documentos de orientación sobre inventarios de gases de efecto invernadero del Centro para el Liderazgo Climático Corporativo más recientes y el Emissions Factors Hub. La Calculadora cuantifica las emisiones directas e indirectas de diferentes fuentes.',
        title4: 'Los usuarios ingresan los siguientes valores:',
        buildingType: 'Tipo de Edificio',
        location: 'Ubicación',
        orientation: 'Orientación',
        windowToWallRatio: 'Relación (Ratio) Ventana - Muro',
        shading: 'Sombreado (Voladizos / Aletas)',
        curtainWallPerformance: 'Desempeño de Muro Cortina',
        glazingConfiguration: 'Configuración del Acristalamiento (dos tipos de vidrio, para comparar)',
        title5: 'Los siguientes valores están predefinidos, y como regla predefinarán:',
        subTitle1: 'La Construcción de los edificios y la resistencia térmica de loselementos opacos: valores predeterminados en eQuest:',
        externalWall: 'Pared exterior',
        roof: 'Techo',
        floor: 'Piso ',
        subTitle2: 'Horarios de ocupación',
        lighting: 'Iluminación',
        taskLighting: 'Iluminación de tareas / áreas',
        hvac: 'Equipos HVAC (incluidas calderas/boilers, enfriadores, circuitos de circulación, calentamiento de agua, etc.)',
        infiltration: 'Infiltración / Estanqueidad del caparazón',
        occupancy: 'Factores de ocupación',
        zone: 'Tipos de zona',
        period: 'Período de operación (año completo)',
        geometry: 'Geometría del edificio',
    },
    comparePage: {
        glazing: {
            selectFirstGlazing: 'Selecciona Primer Vidrio a Comparar',
            selectSecondGlazing: 'Selecciona Segundo Vidrio a Comparar',
        },
        customMarker: {
            location: 'Ubicación',
            buildingType: 'Tipo de Edificio',
            existingBuilding: 'Renovación',
            newBuilding: 'Nueva Construcción',
            orientation: 'Orientación',
            wwr: 'RVM',
            shading: 'Sombreado',
            curtainWall: 'Muro Cortina',
            notSelected: 'No seleccionada',
            none: 'Ninguno',
            low: 'Bajo',
            moderate: 'Moderado',
            high: 'Alto',
            custom: 'Personalizado'
        },
        filter: {
            filterShgc: 'Filtrar por SHGC',
            filterU: 'Filtrar por Valor',
            filterVlt: 'Filtrar por VLT',
        },
        tooltip: {
            uValue: 'Valor U',
            vlt: 'VLT',
            shgc: 'SHGC',
        }
    },
    sidebar : {
        title: {
            select: 'Selecciona:',
            SelectLanguage: 'Select Language',
            SelectBuildingType: 'Tipo de Edificio',
            selectLocation: 'Ubicación',
            selectOrientation: 'Orientación',
            selectWwr: 'Relación Ventana – Muro',
            selectShading: 'Sombreado',
            selectCurtainWall: 'Muro Cortina',
            calculate: 'Calcular',
            next: 'Próximo',
        },
    },
    header: {
        home: 'Inicio',
        vitro: 'Vitro Vidrio Arquitectónico',
        construct: 'Construct',
        about: 'Acerca de',
        help: 'Ayuda',
    },
    help: {
        firstname: 'Nombre',
        lastname: 'Apellido',
        company: 'Compañía',
        email: 'Email',
        message: 'Mensaje',
        submit: 'Enviar',
    },
    buildingSelectionSubMenu: {
        title: {
            university: 'Universidad',
            hospital: 'Hospital',
            office: 'Oficinas',
            multiFamily: 'Multifamiliar',
            conventionCenter: 'Centro de Convenciones',
            existingBuilding: 'Edificio Existente',
            newBuilding: 'Edificio Nuevo',
        }
    },
    locationSubMeu: {
        title: {
            pleaseSelectState: 'Por favor Selecciona Estado',
            pleaseSelectCity: 'Por favor Selecciona Ciudad',
        },
    },
    orientationSubMenu: {
        title: {
            selectOrientation: 'Selecciona Orientacion',
        },
    },
    windowToWallRatioSubMenu: {
        title: {
            selectWindowToWallRatio: 'Selecciona Proporción Ventana - Muro',
        },
    },
    shadingSubMenu : {
        title: {
            selectShading: 'Selecciona Sombreado',
            none: 'Ninguno',
            moderatePerformance: 'Desempeño Moderado',
            higherPerformance: 'Desempeño Alto',
            customPerformance: 'Desempeño Personalizado',
        },
        customView: {
            overhang: 'Voladizo (pies)',
            fins: 'Aletas (pies)',
            top: 'Solo',
            floor: 'Planto',
            only: ' Más Alta',
            north: 'Norte',
            south: 'Sur',
            east: 'Este',
            west: 'Oeste',
        },
    },
    curtainWallSubMenu : {
        title: {
            selectCurtainWall: 'Selecciona Muro Cortina',
            lowPerformance: 'Desempeño Bajo',
            moderatePerformance: 'Desempeño Moderado con Rotura de Puente Térmico',
            highPerformance: 'Desempeño Alto con Rotura de Puente Térmico',
        }
    },
    report : {
        region: 'Región',
        building: 'Tipo de Edificio',
        location: 'Ubicación',
        orientation: 'Orientación',
        windowToWallRatio: 'Proporción Ventana - Muro',
        shading: 'Sombreado',
        curtainWall: 'Muro Cortina',
        curtainWallProduct: 'Muro Cortina',
        hvacEfficiency: 'Hvac Efficiency 1',
        getDetailedReport: 'Obtener Reporte Detallado',
        annualCarbonEmissions: 'Emisiones Anuales de Carbono Operacional',
        annualCarbonContext: 'Toneladas CO2 equivalentes (T CO2 eq)',
        operationalCarbon25Years: 'Carbono Operacional - Ciclo de Vida 25 Años',
        embodiedCarbonOfGlazing: 'Carbono Embebido del Acristalamiento',
        offSet: 'Compensación CO2',
        energyConsumption: 'Consumo Energético',
        annualElectric: 'Consumo Eléctrico Anual (kWh x000)',
        annualGas : 'Consumo de Gas Anual (mmBtu x00)',
        monthlyElectric: 'Consumo Eléctrico Meses (kWh x000)',
        monthlyGas: 'Consumo Gas Anual (mmBtu x00)',
        moreDetails: 'Más Detalles',
        tooltip: {
            title: '¿Cómo calculamos estos resultados?',
            paragraph1: 'Acristalamientos con mejores desempeños (ie. unidades triples) contendrán un mayor carbono embebido que acristalamientos de peor desempeño (ie. vidrios monolíticos).',
            paragraph2: 'Sin embargo, utilizar estos acristalamientos de alto desempeño reduce en gran forma la energía operacional utilizada, así como el carbono asociado a ella.',
            paragraph3: 'Este valor indica que tanto tiempo tomaría el “ahorrar” ese carbono embebido extra a través de la reducción del carbono operacional (similar al concepto de retorno de inversión).',
        },
        months: {
            'jan': 'Ene ',
            'feb': 'Feb',
            'mar': 'Mar',
            'apr': 'Abr',
            'may': 'May',
            'jun': 'Jun',
            'jul': 'Jul',
            'aug': 'Ago',
            'sep': 'Sep',
            'oct': 'Oct',
            'nov': 'Nov',
            'dec': 'Dic',
            'total': 'Total',
        },
        curtainWallValues: {
            Low: 'Bajo',
            Moderate: 'Moderado',
            Medium: 'Moderado',
            High: 'Alto',
        },
        shadingValues: {
            None: 'None',
            Moderate: 'Moderado',
            High: 'Alto',
            Custom: 'Personalizado',
        },
        buildingTypes: {
            university: 'Universidad',
            hospital: 'Hospital',
            office: 'Oficinas',
            multiFamily: 'Multifamiliar',
            conventionCenter: 'Centro de Convenciones',
        }
    },
    pdf: {
        pleaseWait: ' Por favor espera mientras preparamos tu reporte...',
        pleaseWait2: 'Por favor espera mientras preparamos tu reporte...',
        name: 'Nombre',
        email: 'Correo Electrónico',
        company: 'Compañía',
        position: 'Posición',
        download: '¡Descarga ahora!',
    },
    validationErrors: {
        email: 'Email format is invalid',
        required: 'This field is required',
    },
};
