import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

export const CustomGauge = ({
    value,
    percent,
    backgroundColor,
    baseColor,
    progressColor,
    textColor,
    arrowColor,
}) => {
    const radius = 50;
    const innerRadius = radius - 5;
    const strokeWidth = 7;
    const transform = `rotate(-90 ${radius} ${radius})`;
    const dashArray = Math.PI * innerRadius * 2;
    const [strokeOffset, setStrokeOffset] = useState(dashArray);
    const [arrowRotation, setArrowRotation] = useState(0); // Initialize rotation angle

    useEffect(() => {
        const targetOffset = ((100 - percent) / 100) * dashArray;
        const targetArrowOffset = (360 * (percent / 100));

        const animationInterval = setInterval(() => {
            if (strokeOffset > targetOffset) {
                setStrokeOffset(strokeOffset - 1);
                // Update arrow rotation based on progress
                if(targetArrowOffset > arrowRotation) {
                    setArrowRotation(arrowRotation + 1.5);
                }
            } else {
                clearInterval(animationInterval);
            }
        }, 10);

        return () => clearInterval(animationInterval);
    }, [percent, strokeOffset, dashArray]);

    return (
        <svg height={radius * 2} width={radius * 2}>
            <circle
                className="gauge_background"
                cx={radius}
                cy={radius}
                r={innerRadius - 15}
                fill={backgroundColor}
            />

            <circle
                className="gauge_base"
                cx={radius}
                cy={radius}
                fill="transparent"
                r={innerRadius}
                stroke={baseColor}
                strokeWidth={strokeWidth}
            />

            <circle
                className="gauge_progress"
                cx={radius}
                cy={radius}
                fill="transparent"
                r={innerRadius}
                stroke={progressColor}
                strokeWidth={strokeWidth}
                strokeDasharray={dashArray}
                strokeDashoffset={strokeOffset}
                strokeLinecap="round"
                transform={transform}
            />

            {/* Black Arrow */}
            <polygon
                points="57.071,50 50,47.071 42.929,50 50,35"
                transform={`rotate(${arrowRotation} ${radius} ${radius}) translate(0, -25)`}
                fill={arrowColor}
            />

            <text
                x="50%"
                y="53%"
                dominantBaseline="middle"
                textAnchor="middle"
                fontSize={20}
                fontWeight={500}
                fill={textColor}
            >
                {value}
            </text>
        </svg>
    );
};

CustomGauge.propTypes = {
    value: PropTypes.string.isRequired,
    percent: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired,
    baseColor: PropTypes.string.isRequired,
    progressColor: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
    arrowColor: PropTypes.string.isRequired,
};
