export const StateCityConstant = {
    "New York": [
        "New York City",
        "Albany",
        "Binghamton",
        "Buffalo",
        "Massena",
        "Rochester",
        "Syracuse",
    ],
    "California": [
        "Arcata",
        "Bakersfield",
        "Daggett",
        "Fresno",
        "Long Beach",
        "Los Angeles",
        "Sacramento",
        "San Diego",
        "San Francisco",
        "Santa Maria",
    ],
    "Illinois": [
        "Chicago",
        "Moline",
        "Peoria",
        "Rockford",
        "Springfield",
    ],
    "Texas": [
        "Abilene",
        "Amarillo",
        "Austin",
        "Brownsville",
        "Corpus Christi",
        "El Paso",
        "Fort Worth",
        "Houston",
        "Lubbock",
        "Lufkin",
        "Midland",
        "Port Arthur",
        "San Angelo",
        "San Antonio",
        "Victoria",
        "Waco",
        "Wichita Falls",
    ],
    "Pennsylvania": [
        "Allentown",
        "Bradford",
        "Erie",
        "Harrisburg",
        "Philadelphia",
        "Pittsburgh",
        "Wilkes-Barre",
        "Williamsport",
    ],
    "Arizona": [
        "Flagstaff",
        "Phoenix",
        "Prescott",
        "Tucson",
    ],
    "Florida": [
        "Daytona Beach",
        "Jacksonville",
        "Key West",
        "Miami",
        "Tallahassee",
        "Tampa",
        "W. Palm Beach",
    ],
    "Indiana": [
        "Evansville",
        "Fort Wayne",
        "Indianapolis",
        "South Bend",
    ],
    "Ohio": [
        "Akron",
        "Cleveland",
        "Columbus",
        "Dayton",
        "Mansfield",
        "Toledo",
        "Youngstown",
    ],
    "North Carolina": [
        "Asheville",
        "Cape Hatteras",
        "Charlotte",
        "Greensboro",
        "Raleigh",
        "Wilmington",
    ],
    "Michigan": [
        "Alpena",
        "Detroit",
        "Flint",
        "Grand Rapids",
        "Houghton",
        "Lansing",
        "Muskegon",
        "Sault St Marie",
        "Traverse City",
    ],
    "Tennessee": [
        "Bristol",
        "Chattanooga",
        "Knoxville",
        "Memphis",
        "Nashville",
    ],
    "Massachusetts": [
        "Boston",
        "Worcester",
    ],
    "Washington": [
        "Olympia",
        "Quillayute",
        "Seattle",
        "Spokane",
        "Yakima",
    ],
    "Colorado": [
        "Alamosa",
        "Boulder",
        "Co. Springs",
        "Eagle",
        "Grand Junction",
        "Pueblo",
    ],
    "District of Columbia": [
        "Washington"
    ],
    "Maryland": [
        "Baltimore",
    ],
    "Kentucky": [
        "Covington",
        "Lexington",
        "Louisville",
    ],
    "Oregon": [
        "Astoria",
        "Burns",
        "Eugene",
        "Medford",
        "North Bend",
        "Portland",
        "Redmond",
        "Salem",
    ],
    "Oklahoma": [
        "Oklahoma City",
        "Tulsa",
    ],
    "Wisconsin": [
        "Eau Claire",
        "Green Bay",
        "La Crosse",
        "Madison",
        "Milwaukee",
    ],
    "Nevada": [
        "Elko",
        "Ely",
        "Las Vegas",
        "Reno",
        "Tonopah",
        "Winnemucca",
    ],
    "New Mexico": [
        "Albuquerque",
        "Tucumcari",
    ],
    "Missouri": [
        "Columbia",
        "Kansas City",
        "Springfield",
        "St. Louis",
    ],
    "Virginia": [
        "Lynchburg",
        "Norfolk",
        "Richmond",
        "Roanoke",
        "Sterling",
    ],
    "Georgia": [
        "Athens",
        "Atlanta",
        "Augusta",
        "Columbus",
        "Macon",
        "Savannah",
    ],
    "Nebraska": [
        "Grand Island",
        "Norfolk",
        "North Platte",
        "Omaha",
        "Scottsbluff",
    ],
    "Minnesota": [
        "Duluth",
        "Int. Falls",
        "Minneapolis",
        "Rochester",
        "Saint Cloud",
    ],
    "Kansas": [
        "Dodge City",
        "Goodland",
        "Topeka",
        "Wichita",
    ],
    "Louisiana": [
        "Baton Rouge",
        "Lake Charles",
        "New Orleans",
        "Shreveport",
    ],
    "Hawaii": [
        "Hilo",
        "Honolulu",
        "Kahului",
        "Lihue",
    ],
    "Alaska": [
        "Anchorage",
        "Annette",
        "Barrow",
        "Bethel",
        "Bettles",
        "Big Delta",
        "Cold Bay",
        "Fairbanks",
        "Gulkana",
        "King Salmon",
        "Kodiak",
        "Kotzebue",
        "McGrath",
        "Nome",
        "St Paul Island",
        "Talkeetna",
        "Yakutat",
    ],
    "New Jersey": [
        "Atlantic City",
        "Newark",
    ],
    "Idaho": [
        "Boise",
        "Pocatello",
    ],
    "Alabama": [
        "Birmingham",
        "Montgomery",
        "Mobile",
        "Huntsville",
    ],
    "Iowa": [
        "Des Moines",
        "Mason City",
        "Sioux City",
        "Waterloo",
    ],
    "Arkansas": [
        "Little Rock",
        "Fort Smith",
    ],
    "Utah": [
        "Cedar City",
        "Salt Lake City",
    ],
    "Rhode Island": [
        "Providence",
    ],
    "Mississippi": [
        "Jackson",
        "Meridian"
    ],
    "South Dakota": [
        "Sioux Falls",
        "Rapid City",
        "Pierre",
        "Huron",
    ],
    "Connecticut": [
        "Bridgeport",
        "Hartford",
    ],
    "South Carolina": [
        "Charleston",
        "Columbia",
        "Greenville",
    ],
    "New Hampshire": [
        "Concord"
    ],
    "North Dakota": [
        "Fargo",
        "Bismarck",
        "Minot"
    ],
    "Montana": [
        "Billings",
        "Cut Bank",
        "Glasgow",
        "Great Falls",
        "Helena",
        "Kalispell",
        "Lewistown",
        "Miles City",
        "Missoula",
    ],
    "Delaware": [
        "Wilmington",
    ],
    "Maine": [
        "Portland",
        "Caribou",
    ],
    "Wyoming": [
        "Cheyenne",
        "Casper",
        "Lander",
        "Rock Springs",
        "Sheridan",
    ],
    "West Virginia": [
        "Charleston",
        "Elkins",
        "Huntington",
    ],
    "Vermont": [
        "Burlington",
    ],
    "México": [
        "Toluca",
    ],
    "Ciudad de México": [
        "Mexico City",
    ],
    "Quintana Roo": [
        "Cancun",
    ],
    "Jalisco": [
        "Guadalajara",
    ],
    "Querétaro": [
        "Queretaro",
    ],
    "Puebla": [
        "Puebla",
    ],
    "Baja California Sur": [
        "Cabo San Lucas",
    ],
    "Guanajuato": [
        "Leon",
    ],
    "Baja California": [
        "Tijuana",
    ],
    "Sonora": [
        "Hermosillo",
    ],
    "Aguascalientes": [
        "Aguascalientes",
    ],
    "Campeche": [
        "Carmen",
    ],
    "Chiapas": [
        "Tuxla Gutierrez",
    ],
    "Chihuahua": [
        "Chihuahua",
    ],
    "Coahuila de Zaragoza": [
        "Monclova",
    ],
    "Colima": [
        "Colima",
    ],
    "Durango": [
        "Durango",
    ],
    "Guerrero": [
        "Acapulco",
    ],
    "Hidalgo": [
        "Pachuca",
    ],
    "Michoacán de Ocampo": [
        "Morelia",
    ],
    "Morelos": [
        "Cuernavaca",
    ],
    "Nayarit": [
        "Tepic",
    ],
    "Nuevo León": [
        "Monterrey",
    ],
    "Oaxaca": [
        "Oaxaca",
    ],
    "San Luis Potosí": [
        "San Luis Potosi",
    ],
    "Sinaloa": [
        "Culiacan",
    ],
    "Tabasco": [
        "Villahermosa",
    ],
    "Tamaulipas": [
        "Ciudad Victoria",
    ],
    "Tlaxcala": [
        "Tlaxcala",
    ],
    "Veracruz de Ignacio de la Llave": [
        "Xalapa",
    ],
    "Yucatán": [
        "Merida",
    ],
    "BC": [
        "Vancouver",
    ],
    "NU": [
        "Not exist",
    ],
    "NT": [
        "Not exist",
    ],
    "AB": [
        "Calgary",
    ],
    "NL": [
        "Not exist",
    ],
    "SK": [
        "Not exist",
    ],
    "MB": [
        "Winnipeg",
    ],
    "QC": [
        "Montreal",
        "Quebec City",
    ],
    "ON": [
        "Toronto",
        "Ottawa",
    ],
    "NB": [
        "Not exist",
    ],
    "NS": [
        "Halifax",
    ],
    "PE": [
        "Not exist",
    ],
    "YT": [
        "Not exist",
    ],
};