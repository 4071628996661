
export const GLAZING_CONSTANT = [
    {
        key: 1,
        active: false,
        isUsa: false,
        isMexico: false,
        isAdvance: false,
        name: "Clear + Clear",
        info: {
            vlt: 0.79,
            shgc: 0.70,
            uval: 0.47,
        },
        carbon: 3.76,
        pdf: 'https://www.vitroglazings.com/products/low-e-glass/solarban-60-glass/',
    },
    {
        key: 2,
        active: false,
        isUsa: false,
        isMexico: false,
        isAdvance: false,
        name: "Clear",
        info: {
            vlt: 0.89,
            shgc: 0.82,
            uval: 0.92,
        },
        carbon: 1.88,
        pdf: 'https://www.vitroglazings.com/products/low-e-glass/solarban-60-glass/',
    },
    {
        key: 3,
        active: true,
        isUsa: true,
        isMexico: true,
        isAdvance: false,
        name: "VacuMax™ VIG",
        info: {
            vlt: 0.62,
            shgc: 0.26,
            uval: 0.07,
        },
        carbon: 4.5,
        pdf: 'https://www.vitroglazings.com/products/special-applications/vacumax-vacuum-insulating-glass/',
    },
    {
        key: 4,
        active: true,
        isUsa: true,
        isMexico: true,
        isAdvance: false,
        name: "Solarban® 60 Clear + Clear",
        info: {
            vlt: 0.70,
            shgc: 0.39,
            uval: 0.24,
        },
        carbon: 3.26,
        pdf: 'https://www.vitroglazings.com/products/low-e-glass/solarban-60-glass/',
    },
    {
        key: 5,
        active: true,
        isUsa: true,
        isMexico: true,
        isAdvance: false,
        name: "Solarban® 60 Starphire® + Starphire® ",
        info: {
            vlt: 0.74,
            shgc: 0.41,
            uval: 0.24,
        },
        carbon: 3.26,
        pdf: 'https://www.vitroglazings.com/products/low-e-glass/solarban-60-glass/',
    },
    {
        key: 6,
        active: true,
        isUsa: true,
        isMexico: true,
        isAdvance: false,
        name: "Solarban® 60 Optiblue® + Clear",
        info: {
            vlt: 0.51,
            shgc: 0.32,
            uval: 0.24,
        },
        carbon: 3.26,
        pdf: 'https://www.vitroglazings.com/products/low-e-glass/solarban-60-glass/',
    },
    {
        key: 7,
        active: true,
        isUsa: true,
        isMexico: true,
        isAdvance: false,
        name: "Solarban® 70 Clear + Clear",
        info: {
            vlt: 0.64,
            shgc: 0.27,
            uval: 0.24,
        },
        carbon: 3.26,
        pdf: 'https://www.vitroglazings.com/products/low-e-glass/solarban-70-glass/',
    },
    {
        key: 8,
        active: true,
        isUsa: true,
        isMexico: true,
        isAdvance: false,
        name: "Solarban® 70 Solarblue® + Clear",
        info: {
            vlt: 0.41,
            shgc: 0.22,
            uval: 0.24,
        },
        carbon: 3.26,
        pdf: 'https://www.vitroglazings.com/products/low-e-glass/solarban-70-glass/',
    },
    {
        key: 9,
        active: true,
        isUsa: true,
        isMexico: true,
        isAdvance: false,
        name: "Solarban® 70 Solarbronze® + Clear",
        info: {
            vlt: 0.39,
            shgc: 0.20,
            uval: 0.24,
        },
        carbon: 3.26,
        pdf: 'https://www.vitroglazings.com/products/low-e-glass/solarban-70-glass/',
    },
    {
        key: 10,
        active: true,
        isUsa: true,
        isMexico: true,
        isAdvance: false,
        name: "Solarban® 70 Optigray® + Clear",
        info: {
            vlt: 0.46,
            shgc: 0.23,
            uval: 0.24,
        },
        carbon: 3.26,
        pdf: 'https://www.vitroglazings.com/products/low-e-glass/solarban-70-glass/',
    },
    {
        key: 11,
        active: true,
        isUsa: true,
        isMexico: true,
        isAdvance: false,
        name: "Solarban® 70 Solargray® + Clear",
        info: {
            vlt: 0.32,
            shgc: 0.19,
            uval: 0.24,
        },
        carbon: 3.26,
        pdf: 'https://www.vitroglazings.com/products/low-e-glass/solarban-70-glass/',
    },
    {
        key: 12,
        active: true,
        isUsa: true,
        isMexico: true,
        isAdvance: false,
        name: "Solarban® 70 Optiblue® + Clear",
        info: {
            vlt: 0.46,
            shgc: 0.23,
            uval: 0.24,
        },
        carbon: 3.26,
        pdf: 'https://www.vitroglazings.com/products/low-e-glass/solarban-70-glass/',
    },
    {
        key: 13,
        active: true,
        isUsa: true,
        isMexico: true,
        isAdvance: false,
        name: "Solarban® 72 Starphire® + Starphire®",
        info: {
            vlt: 0.68,
            shgc: 0.28,
            uval: 0.24,
        },
        carbon: 3.26,
        pdf: 'https://www.vitroglazings.com/products/low-e-glass/solarban-72-glass/',
    },
    {
        key: 14,
        active: true,
        isUsa: true,
        isMexico: true,
        isAdvance: false,
        name: "Solarban® 90 Clear + Clear",
        info: {
            vlt: 0.51,
            shgc: 0.23,
            uval: 0.24,
        },
        carbon: 3.26,
        pdf: 'https://www.vitroglazings.com/products/low-e-glass/solarban-90-glass/',
    },
    {
        key: 15,
        active: true,
        isUsa: true,
        isMexico: true,
        isAdvance: false,
        name: "Solarban® 90 Starphire ®  + Starphire ®",
        info: {
            vlt: 0.54,
            shgc: 0.23,
            uval: 0.24,
        },
        carbon: 3.26,
        pdf: 'https://www.vitroglazings.com/products/low-e-glass/solarban-90-glass/',
    },
    {
        key: 16,
        active: true,
        isUsa: true,
        isMexico: true,
        isAdvance: false,
        name: "Solarban® R67 Clear + Clear",
        info: {
            vlt: 0.54,
            shgc: 0.29,
            uval: 0.24,
        },
        carbon: 3.26,
        pdf: 'https://www.vitroglazings.com/products/low-e-glass/solarban-r67-glass/ ',
    },
    {
        key: 17,
        active: true,
        isUsa: true,
        isMexico: true,
        isAdvance: false,
        name: "Solarban® R77 Clear + Clear",
        info: {
            vlt: 0.47,
            shgc: 0.25,
            uval: 0.24,
        },
        carbon: 3.26,
        pdf: 'https://www.vitroglazings.com/products/low-e-glass/solarban-r77-glass/',
    },
    {
        key: 18,
        active: true,
        isUsa: true,
        isMexico: true,
        isAdvance: false,
        name: "Solarban® 77 Optiblue® + Clear",
        info: {
            vlt: 0.34,
            shgc: 0.21,
            uval: 0.24,
        },
        carbon: 3.26,
        pdf: 'https://www.vitroglazings.com/products/low-e-glass/solarban-r77-glass/',
    },
    {
        key: 19,
        active: true,
        isUsa: true,
        isMexico: true,
        isAdvance: false,
        name: "Solarban® R100 Clear® + Clear",
        info: {
            vlt: 0.42,
            shgc: 0.23,
            uval: 0.25,
        },
        carbon: 3.26,
        pdf: 'https://www.vitroglazings.com/products/low-e-glass/solarban-r100-glass/',
    },
    {
        key: 20,
        active: true,
        isUsa: true,
        isMexico: true,
        isAdvance: false,
        name: "Solarban® R100 Starphire ®  + Starphire",
        info: {
            vlt: 0.44,
            shgc: 0.23,
            uval: 0.25,
        },
        carbon: 3.26,
        pdf: 'https://www.vitroglazings.com/products/low-e-glass/solarban-r100-glass/',
    },
    {
        key: 21,
        active: true,
        isUsa: false,
        isMexico: true,
        isAdvance: false,
        name: "6mmLumax51(2) Clear + 1/2 Air+6mm Clear",
        info: {
            vlt: 0.44,
            shgc: 0.44,
            uval: 0.43,
        },
        carbon: 3.26,
        pdf: 'https://www.vitroglazings.com/products',
    },
    {
        key: 22,
        active: true,
        isUsa: false,
        isMexico: true,
        isAdvance: false,
        name: "6mmLumax68(2) Clear + 1/2 Air+6mm Clear",
        info: {
            vlt: 0.59,
            shgc: 0.57,
            uval: 0.45,
        },
        carbon: 3.26,
        pdf: 'https://www.vitroglazings.com/products',
    },
    {
        key: 23,
        active: true,
        isUsa: true,
        isMexico: true,
        isAdvance: false,
        name: "Solarban 70 Clear + Sungate ThermL Clear",
        info: {
            vlt: 0.63,
            shgc: 0.31,
            uval: 0.19,
        },
        carbon: 3.26,
        pdf: 'https://www.vitroglazings.com/products/low-e-glass/solarban-70-glass/',
    },
    {
        key: 24,
        active: true,
        isUsa: true,
        isMexico: true,
        isAdvance: false,
        name: "Solarban 72 Starphire + Sungate ThermL  Starphire",
        info: {
            vlt: 0.66,
            shgc: 0.31,
            uval: 0.19,
        },
        carbon: 3.26,
        pdf: 'https://www.vitroglazings.com/products',
    },
    {
        key: 25,
        active: true,
        isUsa: true,
        isMexico: true,
        isAdvance: false,
        name: "Solarban 70 Clear +  Clear + Clear",
        info: {
            vlt: 0.58,
            shgc: 0.25,
            uval: 0.18,
        },
        carbon: 5,
        pdf: 'https://www.vitroglazings.com/products/low-e-glass/solarban-70-glass/',
    },
    {
        key: 26,
        active: true,
        isUsa: true,
        isMexico: true,
        isAdvance: true,
        name: "Solarban 60 Pacifica + Clear",
        info: {
            vlt: 0.34,
            shgc: 0.23,
            uval: 0.24,
        },
        carbon: 3.26,
        pdf: 'https://www.vitroglazings.com/products/low-e-glass/solarban-60-glass/',
    },
    {
        key: 27,
        active: true,
        isUsa: true,
        isMexico: true,
        isAdvance: true,
        name: "Solarban 90 Optiblue + Clear",
        info: {
            vlt: 0.37,
            shgc: 0.20,
            uval: 0.24,
        },
        carbon: 3.26,
        pdf: 'https://www.vitroglazings.com/products/low-e-glass/solarban-90-glass/',
    },
    {
        key: 28,
        active: true,
        isUsa: true,
        isMexico: true,
        isAdvance: true,
        name: "Solarban 90 Solargray + Clear",
        info: {
            vlt: 0.26,
            shgc: 0.17,
            uval: 0.24,
        },
        carbon: 3.26,
        pdf: 'https://www.vitroglazings.com/products/low-e-glass/solarban-90-glass/',
    },
    {
        key: 29,
        active: true,
        isUsa: true,
        isMexico: true,
        isAdvance: true,
        name: "Solarban R67 Azuria + Clear",
        info: {
            vlt: 0.42,
            shgc: 0.23,
            uval: 0.24,
        },
        carbon: 3.26,
        pdf: 'https://www.vitroglazings.com/products/low-e-glass/solarban-r67-glass/ ',
    },
    {
        key: 30,
        active: true,
        isUsa: true,
        isMexico: true,
        isAdvance: true,
        name: "Solarban R67 Optigray + Clear",
        info: {
            vlt: 0.38,
            shgc: 0.24,
            uval: 0.24,
        },
        carbon: 3.26,
        pdf: 'https://www.vitroglazings.com/products/low-e-glass/solarban-r67-glass/ ',
    },
    {
        key: 31,
        active: true,
        isUsa: true,
        isMexico: true,
        isAdvance: true,
        name: "Solarban R77 Atlantica + CLear",
        info: {
            vlt: 0.35,
            shgc: 0.20,
            uval: 0.24,
        },
        carbon: 3.26,
        pdf: 'https://www.vitroglazings.com/products/low-e-glass/solarban-72-glass/',
    },
    {
        key: 32,
        active: true,
        isUsa: true,
        isMexico: true,
        isAdvance: true,
        name: "Solarban R77 Solarblue + CLear",
        info: {
            vlt: 0.30,
            shgc: 0.20,
            uval: 0.24,
        },
        carbon: 3.26,
        pdf: 'https://www.vitroglazings.com/products/low-e-glass/solarban-72-glass/',
    },
    {
        key: 33,
        active: true,
        isUsa: true,
        isMexico: true,
        isAdvance: true,
        name: "Solarban R77 Solargray + Clear",
        info: {
            vlt: 0.23,
            shgc: 0.18,
            uval: 0.24,
        },
        carbon: 3.26,
        pdf: 'https://www.vitroglazings.com/products/low-e-glass/solarban-72-glass/',
    },
    {
        key: 34,
        active: true,
        isUsa: true,
        isMexico: true,
        isAdvance: true,
        name: "Solarban R100 Solexia + Clear",
        info: {
            vlt: 0.36,
            shgc: 0.21,
            uval: 0.25,
        },
        carbon: 3.26,
        pdf: 'https://www.vitroglazings.com/products/low-e-glass/solarban-r100-glass/',
    },
    {
        key: 35,
        active: true,
        isUsa: true,
        isMexico: true,
        isAdvance: true,
        name: "Solarban 100 Solarblue + Clear",
        info: {
            vlt: 0.26,
            shgc: 0.19,
            uval: 0.25,
        },
        carbon: 3.26,
        pdf: 'https://www.vitroglazings.com/products/low-e-glass/solarban-r100-glass/',
    },
    {
        key: 36,
        active: true,
        isUsa: true,
        isMexico: true,
        isAdvance: true,
        name: "Solarban 100 Solarbronze + Clear",
        info: {
            vlt: 0.25,
            shgc: 0.18,
            uval: 0.25,
        },
        carbon: 3.26,
        pdf: 'https://www.vitroglazings.com/products/low-e-glass/solarban-r100-glass/',
    },
    {
        key: 37,
        active: true,
        isUsa: true,
        isMexico: true,
        isAdvance: true,
        name: "Solarban 100 Solargray + Clear",
        info: {
            vlt: 0.21,
            shgc: 0.17,
            uval: 0.25,
        },
        carbon: 3.26,
        pdf: 'https://www.vitroglazings.com/products/low-e-glass/solarban-r100-glass/',
    },
    {
        key: 38,
        active: true,
        isUsa: true,
        isMexico: true,
        isAdvance: true,
        name: "Solarban 65  Clear + Clear",
        info: {
            vlt: 0.70,
            shgc: 0.35,
            uval: 0.24,
        },
        carbon: 3.26,
        pdf: 'https://www.vitroglazings.com/products',
    },
    {
        key: 39,
        active: true,
        isUsa: true,
        isMexico: true,
        isAdvance: true,
        name: "Solarban 65  Starphire + Starphire",
        info: {
            vlt: 0.73,
            shgc: 0.36,
            uval: 0.24,
        },
        carbon: 3.26,
        pdf: 'https://www.vitroglazings.com/products',
    },
    {
        key: 40,
        active: true,
        isUsa: true,
        isMexico: true,
        isAdvance: true,
        name: "Solarban 60 Clear + Sungate ThermL Clear",
        info: {
            vlt: 0.69,
            shgc: 0.38,
            uval: 0.20,
        },
        carbon: 3.26,
        pdf: 'https://www.vitroglazings.com/products/low-e-glass/sungate-therml-glass/',
    },
    {
        key: 41,
        active: true,
        isUsa: true,
        isMexico: true,
        isAdvance: true,
        name: "Solarban 60 Starphire + Sungate ThermL  Starphire",
        info: {
            vlt: 0.73,
            shgc: 0.41,
            uval: 0.20,
        },
        carbon: 3.26,
        pdf: 'https://www.vitroglazings.com/products/low-e-glass/sungate-therml-glass/',
    },
    {
        key: 42,
        active: true,
        isUsa: true,
        isMexico: true,
        isAdvance: true,
        name: "Solarban 90 Clear + Sungate ThermL Clear",
        info: {
            vlt: 0.50,
            shgc: 0.23,
            uval: 0.20,
        },
        carbon: 3.26,
        pdf: 'https://www.vitroglazings.com/products/low-e-glass/sungate-therml-glass/',
    },
    {
        key: 43,
        active: true,
        isUsa: true,
        isMexico: true,
        isAdvance: true,
        name: "Solarban 90 Starphire + Sungate ThermL Starphire",
        info: {
            vlt: 0.52,
            shgc: 0.22,
            uval: 0.20,
        },
        carbon: 3.26,
        pdf: 'https://www.vitroglazings.com/products/low-e-glass/sungate-therml-glass/',
    },
    {
        key: 44,
        active: true,
        isUsa: true,
        isMexico: true,
        isAdvance: true,
        name: "Solarban 60  Clear + Solarban 60 (2) Clear + Clear",
        info: {
            vlt: 0.56,
            shgc: 0.31,
            uval: 0.12,
        },
        carbon: 5,
        pdf: 'https://www.vitroglazings.com/products/low-e-glass/solarban-60-glass/',
    },
    {
        key: 45,
        active: true,
        isUsa: true,
        isMexico: true,
        isAdvance: true,
        name: "Solarban 70  Clear + Solarban 60 (4) Clear + Clear",
        info: {
            vlt: 0.51,
            shgc: 0.23,
            uval: 0.12,
        },
        carbon: 5,
        pdf: 'https://www.vitroglazings.com/products/low-e-glass/solarban-60-glass/',
    },
]