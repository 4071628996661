import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { assignOrientation } from '../../redux/slices/subMenuSlice';

const SelectOrientation = ({nextFunction}) => {
    const { t } = useTranslation();
    const rotate = useSelector((state) => state.subMenu.orientation.value);
    const dispatch = useDispatch();
    const building = useSelector((state) => state.subMenu.general.building_type);

    function setCompassBackground() {
        switch (building) {
            case 'Hospital':
                return "url('/images/compass_hospital.png')";
            case 'University':
                return "url('/images/compass_university.png')";
            case 'Multi Family':
                return "url('/images/compass_family.png')";
            case 'Office':
                return "url('/images/compass_office.png')";
            case 'Convention Centre':
                return "url('/images/compass_convention.png')";
            default:
                return "url('/images/compass_hospital.png')";
        }
    }

    function rotateLeft() {
        if(rotate > 0) {
            const finalRotate = rotate - 45;
            dispatch(assignOrientation(finalRotate));
        } else if (rotate === 0) {
            const finalRotate = 360 - 45;
            dispatch(assignOrientation(finalRotate));
        }
    }

    function rotateRight() {
        const finalRotate = rotate + 45;
        if(finalRotate < 360) {
            dispatch(assignOrientation(finalRotate));
        } else {
            dispatch(assignOrientation(0));
        }
    }

    return (
        <div className="absolute ml-[265px] mt-1 bg-zinc-200 bg-opacity-90 flex flex-col w-[350px] h-[350px] p-1 rounded-lg border-white border-4">
            <div className="flex flex-row p-1 justify-between">
                <h1 className="text-[#00587e]">{t('orientationSubMenu.title.selectOrientation')}</h1>
                <button
                    className="bg-[#00587e]"
                    onClick={() => nextFunction(3)}
                    type="submit"
                >
                    <div className="flex flex-wrap">
                        <nav className="w-full p-2 flex-row justify-center text-white font-medium">
                            <h1>{t('sidebar.title.next')}</h1>
                        </nav>
                    </div>
                </button>
            </div>
            <div className="absolute ml-6 mt-6 bg-compass bg-cover w-[200px] h-[207px] ml-[65px] mt-[60px]">
                <div style={{transform: `rotate(${rotate}deg)`, backgroundImage: setCompassBackground()}} className="absolute ml-[50px] mt-[77px] bg-contain bg-no-repeat w-[105px] h-[50px]" />
                <button
                    type="button"
                    className="animate-pulse-custom absolute ml-[135px] mt-[15px] w-[50px] h-[50px]"
                    onClick={() => rotateLeft()}
                >
                    <img src="/images/rotate_left.png" alt="compass_left"/>
                </button>
                <button
                    type="button"
                    className="animate-pulse-custom absolute mt-[150px] ml-[20px] w-[50px] h-[50px]"
                    onClick={() => rotateRight()}
                >
                    <img src="/images/rotate_right.png" alt="compass_right"/>
                </button>
            </div>
            <div className="mt-[235px] ml-[130px] flex flex-col justify-center w-[80px] rounded-lg">
                <h2 className="flex flex-row justify-center p-1 cursor-default text-2xl">{rotate} °</h2>
            </div>
        </div>
    );
}

SelectOrientation.propTypes = {
    nextFunction: PropTypes.func.isRequired,
}

export default SelectOrientation;