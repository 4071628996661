import PropTypes from 'prop-types';

const MonthlyTable = ({header, data, emptyHeader}) => (
    <table className="w-full text-center">
        <tr className="text-white">
            {emptyHeader &&
                /* eslint-disable-next-line */
                <th className="p-2 border bg-slate-400 w-1/6" />
            }
            {header.map((item) => (
                <th className="p-2 border bg-slate-400">{item}</th>
            ))}
        </tr>
        {data.map((table) => (
            <tr>
                {table.map((item) => (
                    <td className="p-2 border text-sm">{item}</td>
                ))}
            </tr>
        ))}
    </table>
);

MonthlyTable.propTypes = {
    header: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    emptyHeader: PropTypes.bool,
}

MonthlyTable.defaultProps = {
    emptyHeader: true,
};

export default MonthlyTable;