import {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {assignBuildingType, assignIsNewBuilding} from '../../redux/slices/subMenuSlice';
import { COMPARE_PATH } from '../../routes';

const SelectBuildingType = ({nextFunction}) => {
    const { t } = useTranslation();
    const buildingType = useSelector((state) => state.subMenu.general.building_type)
    const navigate = useNavigate();
    const label = { inputProps: { 'aria-label': 'Checkbox' } };
    const dispatch = useDispatch();
    const isNewBuilding = useSelector((state) => state.subMenu.general.is_new_building);

    const [checkboxUniversity, setCheckboxUniversity] = useState(false);
    const [checkboxHospital, setCheckboxHospital] = useState(false);
    const [checkboxOffice, setCheckboxOffice] = useState(false);
    const [checkboxMultiFamily, setCheckboxMultiFamily] = useState(false);
    const [checkboxConventionCentre, setConventionCentre] = useState(false);

    const selectOldBuilding = () => {
        dispatch(assignIsNewBuilding(false));
    }

    const selectNewBuilding = () => {
        dispatch(assignIsNewBuilding(true));
    }

    useEffect(() => {
        switch (buildingType) {
            case 'University':
                setCheckboxUniversity(true);
                setCheckboxHospital(false);
                setCheckboxOffice(false);
                setCheckboxMultiFamily(false);
                setConventionCentre(false);
                break;
            case 'Hospital':
                setCheckboxUniversity(false);
                setCheckboxHospital(true);
                setCheckboxOffice(false);
                setCheckboxMultiFamily(false);
                setConventionCentre(false);
                break;
            case 'Office':
                setCheckboxUniversity(false);
                setCheckboxHospital(false);
                setCheckboxOffice(true);
                setCheckboxMultiFamily(false);
                setConventionCentre(false);
                break;
            case 'Multi Family':
                setCheckboxUniversity(false);
                setCheckboxHospital(false);
                setCheckboxOffice(false);
                setCheckboxMultiFamily(true);
                setConventionCentre(false);
                break;
            case 'Convention Centre':
                setCheckboxUniversity(false);
                setCheckboxHospital(false);
                setCheckboxOffice(false);
                setCheckboxMultiFamily(false);
                setConventionCentre(true);
                break;
            default:
                break;
        }
    }, [buildingType])

    const selectUniversity = () => {
        dispatch(assignBuildingType('University'));
        navigate(`${COMPARE_PATH}?building_type=University`)
    }

    const selectHospital = () => {
        dispatch(assignBuildingType('Hospital'));
        navigate(`${COMPARE_PATH}?building_type=Hospital`)
    }

    const selectOffice = () => {
        dispatch(assignBuildingType('Office'));
        navigate(`${COMPARE_PATH}?building_type=Office`)
    }

    const selectMultiFamily = () => {
        dispatch(assignBuildingType('Multi Family'));
        navigate(`${COMPARE_PATH}?building_type=Multi Family`)
    }

    const selectConventionCentre = () => {
        dispatch(assignBuildingType('Convention Centre'));
        navigate(`${COMPARE_PATH}?building_type=Convention Centre`)
    }

    return (
        <div
            className="absolute ml-[265px] mt-1 bg-zinc-200 bg-opacity-90 flex flex-col w-[350px] h-[420px] p-1 rounded-lg border-white border-4">
            <div className="flex flex-row p-1 justify-between">
                <h1 className="text-[#00587e]">{t('sidebar.title.SelectBuildingType')}</h1>
                <button
                    className="bg-[#00587e]"
                    onClick={() => nextFunction(1)}
                    type="submit"
                >
                    <div className="flex flex-wrap">
                        <nav className="w-full p-2 flex-row justify-center text-white font-medium">
                            <h1>{t('sidebar.title.next')}</h1>
                        </nav>
                    </div>
                </button>
            </div>
            <div className="pt-4">
                <div className="flex flex-row justify-around w-full">
                    <h2 className="w-[250px]">{t('buildingSelectionSubMenu.title.university')}</h2>
                    <Checkbox
                        {...label}
                        size="small"
                        checked={checkboxUniversity}
                        onClick={selectUniversity}
                    />
                </div>
                <div className="flex flex-row justify-around w-full pt-2">
                    <h2 className="w-[250px]">{t('buildingSelectionSubMenu.title.hospital')}</h2>
                    <Checkbox
                        {...label}
                        size="small"
                        checked={checkboxHospital}
                        onClick={selectHospital}
                    />
                </div>
                <div className="flex flex-row justify-around w-full pt-2">
                    <h2 className="w-[250px]">{t('buildingSelectionSubMenu.title.office')}</h2>
                    <Checkbox
                        {...label}
                        size="small"
                        checked={checkboxOffice}
                        onClick={selectOffice}
                    />
                </div>
                <div className="flex flex-row justify-around w-full pt-2">
                    <h2 className="w-[250px]">{t('buildingSelectionSubMenu.title.multiFamily')}</h2>
                    <Checkbox
                        {...label}
                        size="small"
                        checked={checkboxMultiFamily}
                        onClick={selectMultiFamily}
                    />
                </div>
                <div className="flex flex-row justify-around w-full pt-2">
                    <h2 className="w-[250px]">{t('buildingSelectionSubMenu.title.conventionCenter')}</h2>
                    <Checkbox
                        {...label}
                        size="small"
                        checked={checkboxConventionCentre}
                        onClick={selectConventionCentre}
                    />
                </div>
            </div>
            <hr className="h-px my-4 bg-gray-200 border-0 dark:bg-gray-700" />
            <div>
                <div className="flex flex-row justify-around w-full">
                    <h2 className="w-[250px]">{t('buildingSelectionSubMenu.title.existingBuilding')}</h2>
                    <Checkbox
                        {...label}
                        size="small"
                        checked={!isNewBuilding}
                        onClick={selectOldBuilding}
                    />
                </div>
                <div className="flex flex-row justify-around w-full">
                    <h2 className="w-[250px]">{t('buildingSelectionSubMenu.title.newBuilding')}</h2>
                    <Checkbox
                        {...label}
                        size="small"
                        checked={isNewBuilding}
                        onClick={selectNewBuilding}
                    />
                </div>
            </div>
        </div>
    );
}

SelectBuildingType.propTypes = {
    nextFunction: PropTypes.func.isRequired,
}

export default SelectBuildingType;