import PropTypes from 'prop-types';
import {Bar, BarChart, CartesianGrid, Cell, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';

const VitroStackBarChartPdf = ({data, dataKey , format}) => {
    const formatter = (value) => `${value}`;

    return (
        <ResponsiveContainer width="90%" height="90%">
            <BarChart
                width={200}
                height={200}
                data={data}
                margin={{ top: 15, right: 0, left: -75, bottom: 5 }}
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="name" dy="25" tick={{fontSize: 8}} />
                <YAxis width={120} tickFormatter={formatter} tick={{fontSize: 8}}/>
                <Tooltip/>
                <Bar dataKey="sc" barSize={10} stackId="a" fill="#FF4136" />
                <Bar dataKey="hr" barSize={10} stackId="a" fill="#FFA700" />
                <Bar dataKey="re" barSize={10} stackId="a" fill="#FFDC00" />
                <Bar dataKey="sh" barSize={10} stackId="a" fill="#00FF00" />
                <Bar dataKey="hp" barSize={10} stackId="a" fill="#0074D9" />
                <Bar dataKey="hw" barSize={10} stackId="a" fill="#B10DC9" />
                <Bar dataKey="vf" barSize={10} stackId="a" fill="#FF007D" />
                <Bar dataKey="pa" barSize={10} stackId="a" fill="#00BFFF" />
                <Bar dataKey="eu" barSize={10} stackId="a" fill="#FF005E" />
                <Bar dataKey="me" barSize={10} stackId="a" fill="#FF7F00" />
                <Bar dataKey="tl" barSize={10} stackId="a" fill="#008000" />
                <Bar dataKey="al" barSize={10} stackId="a" fill="#800080" />
            </BarChart>
        </ResponsiveContainer>
    );
}

VitroStackBarChartPdf.propTypes = {
    data: PropTypes.array.isRequired,
    dataKey: PropTypes.array.isRequired,
    format: PropTypes.string.isRequired,
}

export default VitroStackBarChartPdf;