import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux'
import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';
import VitroSlider from '../../components/VitroSlider/VitroSlider';
import {
    shadingAssignCustom,
    shadingAssignEastFins,
    shadingAssignEastOverhang,
    shadingAssignEastTop,
    shadingAssignHigh,
    shadingAssignModerate,
    shadingAssignNone,
    shadingAssignNorthFins,
    shadingAssignNorthOverhang,
    shadingAssignNorthTop,
    shadingAssignSouthFins,
    shadingAssignSouthOverhang,
    shadingAssignSouthTop,
    shadingAssignWestFins,
    shadingAssignWestOverhang,
    shadingAssignWestTop
} from '../../redux/slices/subMenuSlice';

const SelectShading = ({nextFunction}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const NorthOverhang = useSelector((state) => state.subMenu.shading.north_overhang);
    const SouthOverhang = useSelector((state) => state.subMenu.shading.south_overhang);
    const EastOverhang = useSelector((state) => state.subMenu.shading.east_overhang);
    const WestOverhang = useSelector((state) => state.subMenu.shading.west_overhang);

    const NorthFin = useSelector((state) => state.subMenu.shading.north_fins);
    const SouthFin = useSelector((state) => state.subMenu.shading.south_fins);
    const EastFin = useSelector((state) => state.subMenu.shading.east_fins);
    const WestFin = useSelector((state) => state.subMenu.shading.west_fins);

    const checkboxNorth = useSelector((state) => state.subMenu.shading.north_top);
    const checkboxSouth = useSelector((state) => state.subMenu.shading.south_top);
    const checkboxEast = useSelector((state) => state.subMenu.shading.east_top);
    const checkboxWest = useSelector((state) => state.subMenu.shading.west_top);

    const handleNorthOverhang = (event, newValue) => {
        dispatch(shadingAssignNorthFins(0))
        dispatch(shadingAssignNorthOverhang(newValue))
    };
    const handleSouthOverhang = (event, newValue) => {
        dispatch(shadingAssignSouthFins(0))
        dispatch(shadingAssignSouthOverhang(newValue))
    };
    const handleEastOverhang = (event, newValue) => {
        dispatch(shadingAssignEastFins(0))
        dispatch(shadingAssignEastOverhang(newValue))
    };
    const handleWestOverhang = (event, newValue) => {
        dispatch(shadingAssignWestFins(0))
        dispatch(shadingAssignWestOverhang(newValue))
    };

    const handleNorthFin = (event, newValue) => {
        dispatch(shadingAssignNorthOverhang(0))
        dispatch(shadingAssignNorthFins(newValue))
    };
    const handleSouthFin = (event, newValue) => {
        dispatch(shadingAssignSouthOverhang(0))
        dispatch(shadingAssignSouthFins(newValue))
    };
    const handleEastFin = (event, newValue) => {
        dispatch(shadingAssignEastOverhang(0))
        dispatch(shadingAssignEastFins(newValue))
    };
    const handleWestFin = (event, newValue) => {
        dispatch(shadingAssignWestOverhang(0))
        dispatch(shadingAssignWestFins(newValue))
    };

    const handleNorthTopFloorChecked = (event) => { dispatch(shadingAssignNorthTop(event.target.checked)) };
    const handleSouthTopFloorChecked = (event) => { dispatch(shadingAssignSouthTop(event.target.checked)) };
    const handleEastTopFloorChecked = (event) => { dispatch(shadingAssignEastTop(event.target.checked)) };
    const handleWestTopFloorChecked = (event) => { dispatch(shadingAssignWestTop(event.target.checked)) };

    const label = { inputProps: { 'aria-label': 'Checkbox' } };
    const checkboxNone = useSelector((state) => state.subMenu.shading.none);
    const checkboxModerate = useSelector((state) => state.subMenu.shading.moderate);
    const checkboxHigh = useSelector((state) => state.subMenu.shading.high);
    const checkboxCustom = useSelector((state) => state.subMenu.shading.custom);
    const building = useSelector((state) => state.subMenu.general.building_type);

    const selectNoneShading = () => {
        dispatch(shadingAssignNone(!checkboxNone));
        if(checkboxNone === false) {
            dispatch(shadingAssignModerate(false));
            dispatch(shadingAssignHigh(false));
            dispatch(shadingAssignCustom(false));
        }
    }

    const selectModerateShading = () => {
        dispatch(shadingAssignModerate(!checkboxModerate));
        if(checkboxModerate === false) {
            dispatch(shadingAssignNone(false));
            dispatch(shadingAssignHigh(false));
            dispatch(shadingAssignCustom(false));
        }
    }

    const selectHighShading = () => {
        dispatch(shadingAssignHigh(!checkboxHigh));
        if(checkboxHigh === false) {
            dispatch(shadingAssignNone(false));
            dispatch(shadingAssignModerate(false));
            dispatch(shadingAssignCustom(false));
        }
    }

    const selectCustomShading = () => {
        dispatch(shadingAssignCustom(!checkboxCustom));
        if(checkboxCustom === false) {
            dispatch(shadingAssignNone(false));
            dispatch(shadingAssignModerate(false));
            dispatch(shadingAssignHigh(false));
        }
    }

    function setBackground() {
        let prefix;
        let suffix;
        switch (building) {
            case 'Hospital':
                prefix = "/images/shading/hospital/hospital_";

                if(checkboxNone) {
                    suffix = "none.png"
                } else if (checkboxModerate) {
                    suffix = "moderate.png"
                } else if (checkboxHigh) {
                    suffix = "higher.png"
                } else {
                    return `url('/images/shading/empty.png')`
                }

                return `url('${prefix + suffix}')`;
            case 'University':
                prefix = "/images/shading/university/university_";

                if(checkboxNone) {
                    suffix = "none.png"
                } else if (checkboxModerate) {
                    suffix = "moderate.png"
                } else if (checkboxHigh) {
                    suffix = "higher.png"
                } else {
                    return `url('/images/shading/empty.png')`
                }

                return `url('${prefix + suffix}')`;
            case 'Multi Family':
                prefix = "/images/shading/family/family_";

                if(checkboxNone) {
                    suffix = "none.png"
                } else if (checkboxModerate) {
                    suffix = "moderate.png"
                } else if (checkboxHigh) {
                    suffix = "higher.png"
                } else {
                    return `url('/images/shading/empty.png')`
                }

                return `url('${prefix + suffix}')`;
            case 'Office':
                prefix = "/images/shading/office/office_";

                if(checkboxNone) {
                    suffix = "none.png"
                } else if (checkboxModerate) {
                    suffix = "moderate.png"
                } else if (checkboxHigh) {
                    suffix = "higher.png"
                } else {
                    return `url('/images/shading/empty.png')`
                }

                return `url('${prefix + suffix}')`;
            case 'Convention Centre':
                prefix = "/images/shading/convention/convention_";

                if(checkboxNone) {
                    suffix = "none.png"
                } else if (checkboxModerate) {
                    suffix = "moderate.png"
                } else if (checkboxHigh) {
                    suffix = "higher.png"
                } else {
                    return `url('/images/shading/empty.png')`
                }

                return `url('${prefix + suffix}')`;
            default:
                prefix = "/images/shading/hospital/hospital_";

                if(checkboxNone) {
                    suffix = "none.png"
                } else if (checkboxModerate) {
                    suffix = "moderate.png"
                } else if (checkboxHigh) {
                    suffix = "higher.png"
                } else {
                    return `url('/images/shading/empty.png')`
                }

                return `url('${prefix + suffix}')`;
        }
    }

    return (
        <div
            className="absolute ml-[265px] mt-1 bg-zinc-200 bg-opacity-90 flex flex-col w-[350px] p-1 rounded-lg border-white border-4"  style={{height: checkboxCustom ? '650px' : '450px'}}>
            <div className="flex flex-row p-1 justify-between">
                <h1 className="text-[#00587e]">{t('shadingSubMenu.title.selectShading')}</h1>
                <button
                    className="bg-[#00587e]"
                    onClick={() => nextFunction(5)}
                    type="submit"
                >
                    <div className="flex flex-wrap">
                        <nav className="w-full p-2 flex-row justify-center text-white font-medium">
                            <h1>{t('sidebar.title.next')}</h1>
                        </nav>
                    </div>
                </button>
            </div>
            {!checkboxCustom &&
                <div className="flex flex-row justify-center">
                    <div className="p-4 mt-4 w-[300px] h-[200px] bg-cover" style={{backgroundImage: setBackground()}} />
                </div>
            }
            <div>
                <div className="flex flex-row justify-around w-full pt-1">
                    <h2 className="w-[250px]">{t('shadingSubMenu.title.none')}</h2>
                    <Checkbox
                        {...label}
                        defaultChecked size="small"
                        checked={checkboxNone}
                        onClick={selectNoneShading}
                    />
                </div>
                <div className="flex flex-row justify-around w-full pt-1">
                    <h2 className="w-[250px]">{t('shadingSubMenu.title.moderatePerformance')}</h2>
                    <Checkbox
                        {...label}
                        defaultChecked size="small"
                        checked={checkboxModerate}
                        onClick={selectModerateShading}
                    />
                </div>
                <div className="flex flex-row justify-around w-full pt-1">
                    <h2 className="w-[250px]">{t('shadingSubMenu.title.higherPerformance')}</h2>
                    <Checkbox
                        {...label}
                        defaultChecked size="small"
                        checked={checkboxHigh}
                        onClick={selectHighShading}
                    />
                </div>
                <div className="flex flex-row justify-around w-full pt-1">
                    <h2 className="w-[250px]">{t('shadingSubMenu.title.customPerformance')}</h2>
                    <Checkbox
                        {...label}
                        defaultChecked size="small"
                        checked={checkboxCustom}
                        onClick={selectCustomShading}
                    />
                </div>
            </div>
            {checkboxCustom &&
                <>
                    <span className="h-0.5 w-full bg-white mt-2" />
                    <div className="flex flex-row justify-around pt-2">
                        <div />
                        <div className="flex flex-col justify-center">
                            <h2 className="font-medium pb-1 text-center">{t('shadingSubMenu.customView.overhang')}</h2>
                            <img src="/images/overhang.png" alt="compass_left" className="w-[45px] ml-6" />
                        </div>
                        <div className="flex flex-col justify-center">
                            <h2 className="font-medium pb-1 text-center">{t('shadingSubMenu.customView.fins')}</h2>
                            <img src="/images/fins.png" alt="compass_left" className="w-[45px] mr-8" />
                        </div>
                        <div className="text-sm">
                            <p>{t('shadingSubMenu.customView.top')}</p>
                            <p>{t('shadingSubMenu.customView.floor')}</p>
                            <p>{t('shadingSubMenu.customView.only')}</p>
                        </div>
                    </div>
                    <div className="flex flex-row justify-around pt-8">
                        <h2 className="font-bold w-[45px]">{t('shadingSubMenu.customView.north')}</h2>
                        <VitroSlider
                            width={70}
                            currentValue={NorthOverhang}
                            onChangeEvent={handleNorthOverhang}
                            incremental={1}
                            valueLabelDisplay="on"
                            max={6}
                        />
                        <VitroSlider
                            width={70}
                            currentValue={NorthFin}
                            onChangeEvent={handleNorthFin}
                            incremental={1}
                            valueLabelDisplay="on"
                            max={6}
                        />
                        <div className="">
                            <Checkbox
                                sx={{ svg: {position: 'absolute'} }}
                                checked={checkboxNorth}
                                onChange={handleNorthTopFloorChecked}
                            />
                        </div>
                    </div>
                    <div className="flex flex-row justify-around pt-8">
                        <h2 className="font-bold w-[45px]">{t('shadingSubMenu.customView.south')}</h2>
                        <VitroSlider
                            width={70}
                            currentValue={SouthOverhang}
                            onChangeEvent={handleSouthOverhang}
                            incremental={1}
                            valueLabelDisplay="on"
                            max={6}
                        />
                        <VitroSlider
                            width={70}
                            currentValue={SouthFin}
                            onChangeEvent={handleSouthFin}
                            incremental={1}
                            valueLabelDisplay="on"
                            max={6}
                        />
                        <div className="">
                            <Checkbox
                                sx={{ svg: {position: 'absolute'} }}
                                checked={checkboxSouth}
                                onChange={handleSouthTopFloorChecked}
                            />
                        </div>
                    </div>
                    <div className="flex flex-row justify-around pt-8">
                        <h2 className="font-bold w-[45px]">{t('shadingSubMenu.customView.east')}</h2>
                        <VitroSlider
                            width={70}
                            currentValue={EastOverhang}
                            onChangeEvent={handleEastOverhang}
                            incremental={1}
                            valueLabelDisplay="on"
                            max={6}
                        />
                        <VitroSlider
                            width={70}
                            currentValue={EastFin}
                            onChangeEvent={handleEastFin}
                            incremental={1}
                            valueLabelDisplay="on"
                            max={6}
                        />
                        <div className="">
                            <Checkbox
                                sx={{ svg: {position: 'absolute'} }}
                                checked={checkboxEast}
                                onChange={handleEastTopFloorChecked}
                            />
                        </div>
                    </div>
                    <div className="flex flex-row justify-around pt-8">
                        <h2 className="font-bold w-[45px]">{t('shadingSubMenu.customView.west')}</h2>
                        <VitroSlider
                            width={70}
                            currentValue={WestOverhang}
                            onChangeEvent={handleWestOverhang}
                            incremental={1}
                            valueLabelDisplay="on"
                            max={6}
                        />
                        <VitroSlider
                            width={70}
                            currentValue={WestFin}
                            onChangeEvent={handleWestFin}
                            incremental={1}
                            valueLabelDisplay="on"
                            max={6}
                        />
                        <div className="">
                            <Checkbox
                                sx={{ svg: {position: 'absolute'} }}
                                checked={checkboxWest}
                                onChange={handleWestTopFloorChecked}
                            />
                        </div>
                    </div>
                </>
            }
        </div>
    );
}

SelectShading.propTypes = {
    nextFunction: PropTypes.func.isRequired,
}

export default SelectShading;