import Slider from '@mui/material/Slider';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

const VitroSlider = ({width ,currentValue, onChangeEvent, incremental, marks, valueLabelDisplay, max, min}) => {

    const customTheme = createTheme({
        palette : {
            primary: {
                main: '#0090a1'
            },
        },
        components : {
            MuiSlider : {
                styleOverrides : {
                    rail: 'color: #7d7d7d',
                    track: 'color: #00587e; height: 8px',
                    thumb: 'color: #56c7da'
                }
            }
        },
    });

   return  (
       <ThemeProvider theme={customTheme}>
           <Box sx={{width: {width}}}>
               <Slider
                   aria-label="Always visible"
                   defaultValue={0}
                   value={currentValue}
                   onChange={onChangeEvent}
                   step={incremental}
                   valueLabelDisplay={valueLabelDisplay}
                   marks={marks}
                   max={max}
                   min={min}
               />
           </Box>
       </ThemeProvider>
    );
}

VitroSlider.propTypes = {
    width: PropTypes.number.isRequired,
    currentValue: PropTypes.number.isRequired,
    onChangeEvent: PropTypes.func,
    incremental: PropTypes.number.isRequired,
    marks: PropTypes.array,
    valueLabelDisplay: PropTypes.string,
    max: PropTypes.number,
    min: PropTypes.number,
}

VitroSlider.defaultProps = {
    onChangeEvent: null,
    marks: [],
    valueLabelDisplay: "off",
    max: 100,
    min: 0,
}

export default VitroSlider;