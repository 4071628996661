import { useTranslation } from 'react-i18next';

const Loading = () => {
    const { t } = useTranslation();

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="flex flex-col bg-white border py-2 px-4 rounded-lg shadow-lg flex items-center">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"/>
                <p className="mt-4 ml-4 font-bold">{t('pdf.pleaseWait')}</p>
            </div>
        </div>
    );
}

export default Loading;