export function applyTheme(theme) {
    const root = document.documentElement;
    Object.keys(theme).forEach((cssVar) => {
        root.style.setProperty(cssVar, theme[cssVar]);
    });
}

export function createTheme({
 logo,
 banner,
 sidebar,
}) {
 return {
    '--theme-logo': logo,
    '--theme-banner': banner,
    '--theme-sidebar': sidebar
 };
    }