import PropTypes from 'prop-types';

const MonthlyTablePdf = ({header, data, emptyHeader}) => (
    <table className="w-full text-center text-[8px]">
        <tr className="text-white">
            {emptyHeader &&
                /* eslint-disable-next-line */
                <th className="border bg-[#424C5C] w-1/8" />
            }
            {header.map((item) => (
                <th className="border bg-[#424C5C] pb-2 leading-[9px] align-top">{item}</th>
            ))}
        </tr>
        {data.map((table, rowIndex) => (
            <tr>
                {table.map((item) => {
                    if (rowIndex === 12) {
                        return (
                            <td className="text-white bg-[#424C5C] border pb-2 leading-[9px] align-top">
                                {item}
                            </td>
                        );
                    }

                    return (
                        <td className="border pb-2 leading-[9px] align-top">{item}</td>
                    );
                })}
            </tr>
        ))}
    </table>
);

MonthlyTablePdf.propTypes = {
    header: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    emptyHeader: PropTypes.bool,
}

MonthlyTablePdf.defaultProps = {
    emptyHeader: true,
};

export default MonthlyTablePdf;