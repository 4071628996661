import PropTypes from 'prop-types';

export const Gauge = ({
                          value, thresholds, color, radiusSize, valueSize, contextSize
                      }) => {
    const radius = radiusSize;
    const strokeWidth = radius * 0.2;
    const innerRadius = radius - strokeWidth;
    const circumference = innerRadius * 2 * Math.PI;
    const arc = circumference * 0.75;
    const dashArray = `${arc} ${circumference}`;
    const transform = `rotate(135, ${radius}, ${radius})`;

    return (
        <svg height={radius * 2} width={radius * 2}>

            <lineargradient id="grad" x1="0" y1="0" x2="1" y2="1" />
            <stop offset="15%" stopColor="#1267ff" stopOpacity="1" />
            <stop offset="85%" stopColor="#98c0ff" stopOpacity="1" />
            ‍
            ‍

            <circle
                className="gauge_base"
                cx={radius}
                cy={radius}
                fill="transparent"
                r={innerRadius}
                stroke={color}
                strokeDasharray={dashArray}
                strokeLinecap="round"
                strokeWidth={strokeWidth}
                transform={transform}
            />

            {thresholds.map((threshold) => (
                <circle
                    cx={radius}
                    cy={radius}
                    fill="transparent"
                    r={innerRadius}
                    stroke={threshold.color}
                    strokeDasharray={`${(threshold.value / 100) * 190} ${270 - (threshold.value / 100) * 190}`}
                    strokeLinecap="round"
                    strokeWidth={strokeWidth}
                    style={{
                        transition: 'stroke-dashoffset 0.3s',
                    }}
                    transform={transform}
                />
            ))}

            <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontSize={valueSize} fontWeight={600} fill={color}>
                {value}
            </text>

            <text x="50%" y="80%" dominantBaseline="middle" textAnchor="middle" fontSize={contextSize} fontWeight={600} fill={color}>
                T CO2 eq
            </text>

        </svg>

    );
};

Gauge.propTypes = {
    value: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    thresholds: PropTypes.array,
    radiusSize: PropTypes.number,
    valueSize: PropTypes.number,
    contextSize: PropTypes.number,
};

Gauge.defaultProps = {
    thresholds: [],
    radiusSize: 100,
    valueSize: 58,
    contextSize: 20,
};
