import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { Tab } from '@headlessui/react';
import Modal from '../Modal/Modal';
import FormTextInput from '../Form/FormTextInput';
import MyPDF from '../../containers/Report/DetailedReport';
import { useSendPdfReportLogMutation } from '../../redux/services/api';

const DetailedReportModal = ({
                            open, onClose, imageSrc, imageSrc2, imageSrc3, imageSrc4, logData,
                        }) => {
    const onCloseButtonClickHandler = () => {
        onClose();
    };

    const [formikInitialValues, setFormikInitialValues] = useState({
        name: '',
        email: '',
        company: '',
        position: '',
    });

    const [blobObject, setBlobObject] = useState();
    const [sendPdfReportLog] = useSendPdfReportLogMutation();

    const { t } = useTranslation();

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t('validationErrors.required')),
        email: Yup.string().email(t('validationErrors.email')).required(t('validationErrors.required')),
        company: Yup.string().required(t('validationErrors.required')),
        position: Yup.string().required(t('validationErrors.required')),
    });

    const onSubmitHandler = async (formData) => {
        // After completing the additional function, initiate the PDF download
        const url = URL.createObjectURL(blobObject);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'my-document.pdf';
        link.click();

        // Clean up the URL object
        URL.revokeObjectURL(url);

        const pdfLogObject = {
            "name": formData.name,
            "email": formData.email,
            "company": formData.company,
            "position": formData.position,
            "state": logData.state,
            "city": logData.city,
            "country": logData.country,
            "building_type": logData.building_type,
            "isNewBuilding": logData.isNewBuilding,
            "orientation_offset": logData.orientation_offset,
            "percentage_glazing": logData.percentage_glazing,
            "shading_specs": logData.shading_specs,
            "firstGlazing": logData.firstGlazing,
            "secondGlazing": logData.secondGlazing,
        }

        await sendPdfReportLog(pdfLogObject);

        onCloseButtonClickHandler();
        try {
            toast.success('Success');
        } catch {
            toast.error('Something went wrong. Please try again');
        }
    };

    const assignBlob = async (blob) => {
        await setBlobObject(blob);
    };

    return (
        <Modal open={open} setOpen={onCloseButtonClickHandler}>
            <div className="px-2">
                <Tab.Group>
                    <Tab.Panels>
                        <Formik
                            enableReinitialize
                            initialValues={formikInitialValues}
                            validationSchema={validationSchema}
                            onSubmit={onSubmitHandler}
                        >
                            {({ errors }) => (
                                <Form className="flex flex-col gap-2 py-4">
                                    <FormTextInput name="name" label={t('pdf.name')} />
                                    <FormTextInput name="email" label={t('pdf.email')} />
                                    <FormTextInput name="company" label={t('pdf.company')} />
                                    <FormTextInput name="position" label={t('pdf.position')} />
                                    <MyPDF imageSrc={imageSrc} imageSrc2={imageSrc2} imageSrc3={imageSrc3} imageSrc4={imageSrc4} submitHandler={assignBlob}/>
                                </Form>
                            )}
                        </Formik>
                    </Tab.Panels>
                </Tab.Group>
            </div>
        </Modal>
    );
};

DetailedReportModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    imageSrc: PropTypes.string.isRequired,
    imageSrc2: PropTypes.string.isRequired,
    imageSrc3: PropTypes.string.isRequired,
    imageSrc4: PropTypes.string.isRequired,
    logData: PropTypes.object.isRequired,
};

export default DetailedReportModal;
