import PropTypes from 'prop-types';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

const VitroHorizontalBarChartPdf = ({data, dataKey , format, maxData}) => {
    const formatter = (value) => `${value}`;
    // const maxDataValueFirst = Math.max(...data.map(item => item[dataKey[0].dataKey]));
    // const maxDataValueSecond = Math.max(...data.map(item => item[dataKey[1].dataKey]));
    // const maxData = maxDataValueFirst > maxDataValueSecond ? maxDataValueFirst : maxDataValueSecond;

    return (
        <ResponsiveContainer width="90%" height="90%">
            <BarChart
                width={500}
                height={500}
                data={data}
                margin={{ top: 25, right: 0, left: -25, bottom: 0 }}
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="name" tick={{fontSize: 8}}/>
                <YAxis tickFormatter={formatter} tick={{fontSize: 8}} domain={[0, maxData]}/>
                <Tooltip/>
                <Legend wrapperStyle={{fontSize: "8px", marginLeft: "25px"}}/>
                {dataKey.map((item) => (
                    <Bar barSize={6} dataKey={item.dataKey} name={item.name} fill={item.color}/>
                ))}
            </BarChart>
        </ResponsiveContainer>
    );
}

VitroHorizontalBarChartPdf.propTypes = {
    data: PropTypes.array.isRequired,
    dataKey: PropTypes.array.isRequired,
    format: PropTypes.string.isRequired,
    maxData: PropTypes.number.isRequired,
}

export default VitroHorizontalBarChartPdf;