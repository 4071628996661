import i18n from 'i18next';
import { GLAZING_CONSTANT } from '../../components/Dropdown/DropdownConstant';

export function findMinMax(a, b) {
    let min;
    let max;

    if (a < b) {
        min = Number(a);
        max = Number(b);
    } else {
        min = Number(b);
        max = Number(a);
    }

    return { min, max };
}

export function findMinMaxThree(a, b, c) {
    let min;
    let max;

    if (a <= b && a <= c) {
        min = Number(a);
    } else if (b <= a && b <= c) {
        min = Number(b);
    } else {
        min = Number(c);
    }

    if (a >= b && a >= c) {
        max = Number(a);
    } else if (b >= a && b >= c) {
        max = Number(b);
    } else {
        max = Number(c);
    }

    return { min, max };
}

export function getReportData(baseGlazing, firstGlazing, secondGlazing, buildingType, city, stateName, glazingPercentage, orientation, shadingDetail, curtainWallDetail) {
    const isThermExist = Object.prototype.hasOwnProperty.call(baseGlazing.data.energyConsumption.consumptionRecords, 'THERM');

    function getBuildingTypes() {
        switch (buildingType) {
            case 'Hospital':
                return i18n.t(`report.buildingTypes.hospital`);
            case 'University':
                return i18n.t(`report.buildingTypes.university`);
            case 'Multi Family':
                return i18n.t(`report.buildingTypes.multiFamily`);
            case 'Office':
                return i18n.t(`report.buildingTypes.office`);
            case 'Convention Centre':
                return i18n.t(`report.buildingTypes.conventionCenter`);
            default:
                break;
        }
    }

    return {
        general : {
            building_type: getBuildingTypes(),
            location: `${city}, ${stateName}`,
            wwr: `${glazingPercentage}% - ${Number(baseGlazing.data.adjTotalWindowArea.toFixed(0)).toLocaleString()} SF / ${Number(baseGlazing.data.adjTotalWallArea.toFixed(0)).toLocaleString()} SF`,
            orientation: `${orientation} °`,
            shadingDetail: `${i18n.t(`report.shadingValues.${shadingDetail}`)}`,
            curtainWallDetail: `${i18n.t(`report.curtainWallValues.${curtainWallDetail}`)}`,
        },
        glass_data : [
            {
                name: baseGlazing.data.glassSpec.name,
                link: GLAZING_CONSTANT.filter(element => element.name === baseGlazing.data.glassSpec.name).map((item) => item.pdf),
                color:'#9ca3af',
            },
            {
                name: firstGlazing.data.glassSpec.name,
                link: GLAZING_CONSTANT.filter(element => element.name === firstGlazing.data.glassSpec.name).map((item) => item.pdf),
                color: '#00587e',
            },
            {
                name: secondGlazing.data.glassSpec.name,
                link: GLAZING_CONSTANT.filter(element => element.name === secondGlazing.data.glassSpec.name).map((item) => item.pdf),
                color: '#0090a1',
            },
        ],
        annual_gauge_data : [
            {
                color:'#9ca3af',
                percent: (100).toFixed(0),
                value: (baseGlazing.data.emissions.electricity + baseGlazing.data.emissions.stationaryCombustion).toFixed(0),
                lifeValue: Number(((baseGlazing.data.emissions.electricity + baseGlazing.data.emissions.stationaryCombustion)*25).toFixed(0)).toLocaleString(),
            },
            {
                color: '#00587e',
                percent: (((100*(firstGlazing.data.emissions.electricity + firstGlazing.data.emissions.stationaryCombustion))/(baseGlazing.data.emissions.electricity + baseGlazing.data.emissions.stationaryCombustion))).toFixed(0) > 90
                    ?
                    90
                    : (((100*(firstGlazing.data.emissions.electricity + firstGlazing.data.emissions.stationaryCombustion))/(baseGlazing.data.emissions.electricity + baseGlazing.data.emissions.stationaryCombustion))).toFixed(0),
                value: (firstGlazing.data.emissions.electricity + firstGlazing.data.emissions.stationaryCombustion).toFixed(0),
                lifeValue: Number(((firstGlazing.data.emissions.electricity + firstGlazing.data.emissions.stationaryCombustion)*25).toFixed(0)).toLocaleString(),
            },
            {
                color: '#0090a1',
                percent: (((100*(secondGlazing.data.emissions.electricity + secondGlazing.data.emissions.stationaryCombustion))/(baseGlazing.data.emissions.electricity + baseGlazing.data.emissions.stationaryCombustion))).toFixed(0) > 90
                ?
                90
                :
                    (((100*(secondGlazing.data.emissions.electricity + secondGlazing.data.emissions.stationaryCombustion))/(baseGlazing.data.emissions.electricity + baseGlazing.data.emissions.stationaryCombustion))).toFixed(0),
                value: (secondGlazing.data.emissions.electricity + secondGlazing.data.emissions.stationaryCombustion).toFixed(0),
                lifeValue: Number(((secondGlazing.data.emissions.electricity + secondGlazing.data.emissions.stationaryCombustion)*25).toFixed(0)).toLocaleString(),
            },
        ],
        annual_carbon_table_data: {
            header : [
                baseGlazing.data.glassSpec.name,
                firstGlazing.data.glassSpec.name,
                secondGlazing.data.glassSpec.name,
            ],
            data: [
                [
                    'Stationary Combustion - Annual',
                    baseGlazing.data.emissions.stationaryCombustion.toFixed(0),
                    firstGlazing.data.emissions.stationaryCombustion.toFixed(0),
                    secondGlazing.data.emissions.stationaryCombustion.toFixed(0),
                ],
                [
                    'Electricity Emissions - Annual',
                    baseGlazing.data.emissions.electricity.toFixed(0),
                    firstGlazing.data.emissions.electricity.toFixed(0),
                    secondGlazing.data.emissions.electricity.toFixed(0),
                ],
            ]
        },
        annual_electric_consumption: [
            {
                value: Math.round((baseGlazing.data.energyConsumption.consumptionRecords.KWH[12]/1000)),
                name:  baseGlazing.data.glassSpec.name,
                color: "#9ca3af"
            },
            {
                value: Math.round((firstGlazing.data.energyConsumption.consumptionRecords.KWH[12]/1000)),
                name:  firstGlazing.data.glassSpec.name,
                color: "#00587e"
            },
            {
                value: Math.round((secondGlazing.data.energyConsumption.consumptionRecords.KWH[12]/1000)),
                name:  secondGlazing.data.glassSpec.name,
                color: "#0090a1"
            },
        ],
        annual_gas_consumption: [
            {
                value: isThermExist ? Math.round(baseGlazing.data.energyConsumption.consumptionRecords.THERM[12]/1000) : 0,
                name:  baseGlazing.data.glassSpec.name,
                color: "#9ca3af"
            },
            {
                value: isThermExist ? Math.round(firstGlazing.data.energyConsumption.consumptionRecords.THERM[12]/1000) : 0,
                name:  firstGlazing.data.glassSpec.name,
                color: "#00587e"
            },
            {
                value: isThermExist ? Math.round(secondGlazing.data.energyConsumption.consumptionRecords.THERM[12]/1000) : 0,
                name:  secondGlazing.data.glassSpec.name,
                color: "#0090a1"
            },
        ],
        monthly_consumption: {
            dataKey: [
                {
                    dataKey: 'base',
                    name: baseGlazing.data.glassSpec.name,
                    color: '#9ca3af',
                },
                {
                    dataKey: 'glass1',
                    name: firstGlazing.data.glassSpec.name,
                    color: '#00587e',
                },
                {
                    dataKey: 'glass2',
                    name: secondGlazing.data.glassSpec.name,
                    color: '#0090a1',
                },
            ],
            electricData: [
                {
                    name: i18n.t('report.months.jan'),
                    base: Math.round(baseGlazing.data.energyConsumption.consumptionRecords.KWH[0]/1000),
                    glass1: Math.round(firstGlazing.data.energyConsumption.consumptionRecords.KWH[0]/1000),
                    glass2: Math.round(secondGlazing.data.energyConsumption.consumptionRecords.KWH[0]/1000),
                },
                {
                    name: i18n.t('report.months.feb'),
                    base: Math.round(baseGlazing.data.energyConsumption.consumptionRecords.KWH[1]/1000),
                    glass1: Math.round(firstGlazing.data.energyConsumption.consumptionRecords.KWH[1]/1000),
                    glass2: Math.round(secondGlazing.data.energyConsumption.consumptionRecords.KWH[1]/1000),
                },
                {
                    name: i18n.t('report.months.mar'),
                    base: Math.round(baseGlazing.data.energyConsumption.consumptionRecords.KWH[2]/1000),
                    glass1: Math.round(firstGlazing.data.energyConsumption.consumptionRecords.KWH[2]/1000),
                    glass2: Math.round(secondGlazing.data.energyConsumption.consumptionRecords.KWH[2]/1000),
                },
                {
                    name: i18n.t('report.months.apr'),
                    base: (baseGlazing.data.energyConsumption.consumptionRecords.KWH[3]/1000),
                    glass1: (firstGlazing.data.energyConsumption.consumptionRecords.KWH[3]/1000),
                    glass2: (secondGlazing.data.energyConsumption.consumptionRecords.KWH[3]/1000),
                },
                {
                    name: i18n.t('report.months.may'),
                    base: Math.round(baseGlazing.data.energyConsumption.consumptionRecords.KWH[4]/1000),
                    glass1: Math.round(firstGlazing.data.energyConsumption.consumptionRecords.KWH[4]/1000),
                    glass2: Math.round(secondGlazing.data.energyConsumption.consumptionRecords.KWH[4]/1000),
                },
                {
                    name: i18n.t('report.months.jun'),
                    base: Math.round(baseGlazing.data.energyConsumption.consumptionRecords.KWH[5]/1000),
                    glass1: Math.round(firstGlazing.data.energyConsumption.consumptionRecords.KWH[5]/1000),
                    glass2: Math.round(secondGlazing.data.energyConsumption.consumptionRecords.KWH[5]/1000),
                },
                {
                    name: i18n.t('report.months.jul'),
                    base: Math.round(baseGlazing.data.energyConsumption.consumptionRecords.KWH[6]/1000),
                    glass1: Math.round(firstGlazing.data.energyConsumption.consumptionRecords.KWH[6]/1000),
                    glass2: Math.round(secondGlazing.data.energyConsumption.consumptionRecords.KWH[6]/1000),
                },
                {
                    name: i18n.t('report.months.aug'),
                    base: Math.round(baseGlazing.data.energyConsumption.consumptionRecords.KWH[7]/1000),
                    glass1: Math.round(firstGlazing.data.energyConsumption.consumptionRecords.KWH[7]/1000),
                    glass2: Math.round(secondGlazing.data.energyConsumption.consumptionRecords.KWH[7]/1000),
                },
                {
                    name: i18n.t('report.months.sep'),
                    base: Math.round(baseGlazing.data.energyConsumption.consumptionRecords.KWH[8]/1000),
                    glass1: Math.round(firstGlazing.data.energyConsumption.consumptionRecords.KWH[8]/1000),
                    glass2: Math.round(secondGlazing.data.energyConsumption.consumptionRecords.KWH[8]/1000),
                },
                {
                    name: i18n.t('report.months.oct'),
                    base: Math.round(baseGlazing.data.energyConsumption.consumptionRecords.KWH[9]/1000).toFixed(0),
                    glass1: Math.round(firstGlazing.data.energyConsumption.consumptionRecords.KWH[9]/1000).toFixed(0),
                    glass2: Math.round(secondGlazing.data.energyConsumption.consumptionRecords.KWH[9]/1000).toFixed(0),
                },
                {
                    name: i18n.t('report.months.nov'),
                    base: Math.round(baseGlazing.data.energyConsumption.consumptionRecords.KWH[10]/1000).toFixed(0),
                    glass1: Math.round(firstGlazing.data.energyConsumption.consumptionRecords.KWH[10]/1000).toFixed(0),
                    glass2: Math.round(secondGlazing.data.energyConsumption.consumptionRecords.KWH[10]/1000).toFixed(0),
                },
                {
                    name: i18n.t('report.months.dec'),
                    base: Math.round(baseGlazing.data.energyConsumption.consumptionRecords.KWH[11]/1000).toFixed(0),
                    glass1: Math.round(firstGlazing.data.energyConsumption.consumptionRecords.KWH[11]/1000).toFixed(0),
                    glass2: Math.round(secondGlazing.data.energyConsumption.consumptionRecords.KWH[11]/1000).toFixed(0),
                },
            ],
            gasData: [
                {
                    name: i18n.t('report.months.jan'),
                    base: isThermExist ? Math.round(baseGlazing.data.energyConsumption.consumptionRecords.THERM[0]/1000) : 0,
                    glass1: isThermExist ? Math.round(firstGlazing.data.energyConsumption.consumptionRecords.THERM[0]/1000) : 0,
                    glass2: isThermExist ? Math.round(secondGlazing.data.energyConsumption.consumptionRecords.THERM[0]/1000) : 0,
                },
                {
                    name: i18n.t('report.months.feb'),
                    base: isThermExist ? Math.round(baseGlazing.data.energyConsumption.consumptionRecords.THERM[1]/1000) : 0,
                    glass1: isThermExist ? Math.round(firstGlazing.data.energyConsumption.consumptionRecords.THERM[1]/1000) : 0,
                    glass2: isThermExist ? Math.round(secondGlazing.data.energyConsumption.consumptionRecords.THERM[1]/1000) : 0,
                },
                {
                    name: i18n.t('report.months.mar'),
                    base: isThermExist ? Math.round(baseGlazing.data.energyConsumption.consumptionRecords.THERM[2]/1000) : 0,
                    glass1: isThermExist ? Math.round(firstGlazing.data.energyConsumption.consumptionRecords.THERM[2]/1000) : 0,
                    glass2: isThermExist ? Math.round(secondGlazing.data.energyConsumption.consumptionRecords.THERM[2]/1000) : 0,
                },
                {
                    name: i18n.t('report.months.apr'),
                    base: isThermExist ? Math.round(baseGlazing.data.energyConsumption.consumptionRecords.THERM[3]/1000) : 0,
                    glass1: isThermExist ? Math.round(firstGlazing.data.energyConsumption.consumptionRecords.THERM[3]/1000) : 0,
                    glass2: isThermExist ? Math.round(secondGlazing.data.energyConsumption.consumptionRecords.THERM[3]/1000) : 0,
                },
                {
                    name: i18n.t('report.months.may'),
                    base: isThermExist ? Math.round(baseGlazing.data.energyConsumption.consumptionRecords.THERM[4]/1000) : 0,
                    glass1: isThermExist ? Math.round(firstGlazing.data.energyConsumption.consumptionRecords.THERM[4]/1000) : 0,
                    glass2: isThermExist ? Math.round(secondGlazing.data.energyConsumption.consumptionRecords.THERM[4]/1000) : 0,
                },
                {
                    name: i18n.t('report.months.jun'),
                    base: isThermExist ? Math.round(baseGlazing.data.energyConsumption.consumptionRecords.THERM[5]/1000) : 0,
                    glass1: isThermExist ? Math.round(firstGlazing.data.energyConsumption.consumptionRecords.THERM[5]/1000) : 0,
                    glass2: isThermExist ? Math.round(secondGlazing.data.energyConsumption.consumptionRecords.THERM[5]/1000) : 0,
                },
                {
                    name: i18n.t('report.months.jul'),
                    base: isThermExist ? Math.round(baseGlazing.data.energyConsumption.consumptionRecords.THERM[6]/1000) : 0,
                    glass1: isThermExist ? Math.round(firstGlazing.data.energyConsumption.consumptionRecords.THERM[6]/1000) : 0,
                    glass2: isThermExist ? Math.round(secondGlazing.data.energyConsumption.consumptionRecords.THERM[6]/1000) : 0,
                },
                {
                    name: i18n.t('report.months.aug'),
                    base: isThermExist ? Math.round(baseGlazing.data.energyConsumption.consumptionRecords.THERM[7]/1000) : 0,
                    glass1: isThermExist ? Math.round(firstGlazing.data.energyConsumption.consumptionRecords.THERM[7]/1000) : 0,
                    glass2: isThermExist ? Math.round(secondGlazing.data.energyConsumption.consumptionRecords.THERM[7]/1000) : 0,
                },
                {
                    name: i18n.t('report.months.sep'),
                    base: isThermExist ? Math.round(baseGlazing.data.energyConsumption.consumptionRecords.THERM[8]/1000) : 0,
                    glass1: isThermExist ? Math.round(firstGlazing.data.energyConsumption.consumptionRecords.THERM[8]/1000) : 0,
                    glass2: isThermExist ? Math.round(secondGlazing.data.energyConsumption.consumptionRecords.THERM[8]/1000) : 0,
                },
                {
                    name: i18n.t('report.months.oct'),
                    base: isThermExist ? Math.round(baseGlazing.data.energyConsumption.consumptionRecords.THERM[9]/1000) : 0,
                    glass1: isThermExist ? Math.round(firstGlazing.data.energyConsumption.consumptionRecords.THERM[9]/1000) : 0,
                    glass2: isThermExist ? Math.round(secondGlazing.data.energyConsumption.consumptionRecords.THERM[9]/1000) : 0,
                },
                {
                    name: i18n.t('report.months.nov'),
                    base: isThermExist ? Math.round(baseGlazing.data.energyConsumption.consumptionRecords.THERM[10]/1000) : 0,
                    glass1: isThermExist ? Math.round(firstGlazing.data.energyConsumption.consumptionRecords.THERM[10]/1000) : 0,
                    glass2: isThermExist ? Math.round(secondGlazing.data.energyConsumption.consumptionRecords.THERM[10]/1000) : 0,
                },
                {
                    name: i18n.t('report.months.dec'),
                    base: isThermExist ? Math.round(baseGlazing.data.energyConsumption.consumptionRecords.THERM[11]/1000) : 0,
                    glass1: isThermExist ? Math.round(firstGlazing.data.energyConsumption.consumptionRecords.THERM[11]/1000) : 0,
                    glass2: isThermExist ? Math.round(secondGlazing.data.energyConsumption.consumptionRecords.THERM[11]/1000) : 0,
                },
            ],
        },
        electricTable : {
            data: [
                [
                    baseGlazing.data.glassSpec.name,
                    (baseGlazing.data.energyConsumption.consumptionRecords.KWH[0]/1000).toFixed(0),
                    (baseGlazing.data.energyConsumption.consumptionRecords.KWH[1]/1000).toFixed(0),
                    (baseGlazing.data.energyConsumption.consumptionRecords.KWH[2]/1000).toFixed(0),
                    (baseGlazing.data.energyConsumption.consumptionRecords.KWH[3]/1000).toFixed(0),
                    (baseGlazing.data.energyConsumption.consumptionRecords.KWH[4]/1000).toFixed(0),
                    (baseGlazing.data.energyConsumption.consumptionRecords.KWH[5]/1000).toFixed(0),
                    (baseGlazing.data.energyConsumption.consumptionRecords.KWH[6]/1000).toFixed(0),
                    (baseGlazing.data.energyConsumption.consumptionRecords.KWH[7]/1000).toFixed(0),
                    (baseGlazing.data.energyConsumption.consumptionRecords.KWH[8]/1000).toFixed(0),
                    (baseGlazing.data.energyConsumption.consumptionRecords.KWH[9]/1000).toFixed(0),
                    (baseGlazing.data.energyConsumption.consumptionRecords.KWH[10]/1000).toFixed(0),
                    (baseGlazing.data.energyConsumption.consumptionRecords.KWH[11]/1000).toFixed(0),
                    (baseGlazing.data.energyConsumption.consumptionRecords.KWH[12]/1000).toFixed(0),
                ],
                [
                    firstGlazing.data.glassSpec.name,
                    (firstGlazing.data.energyConsumption.consumptionRecords.KWH[0]/1000).toFixed(0),
                    (firstGlazing.data.energyConsumption.consumptionRecords.KWH[1]/1000).toFixed(0),
                    (firstGlazing.data.energyConsumption.consumptionRecords.KWH[2]/1000).toFixed(0),
                    (firstGlazing.data.energyConsumption.consumptionRecords.KWH[3]/1000).toFixed(0),
                    (firstGlazing.data.energyConsumption.consumptionRecords.KWH[4]/1000).toFixed(0),
                    (firstGlazing.data.energyConsumption.consumptionRecords.KWH[5]/1000).toFixed(0),
                    (firstGlazing.data.energyConsumption.consumptionRecords.KWH[6]/1000).toFixed(0),
                    (firstGlazing.data.energyConsumption.consumptionRecords.KWH[7]/1000).toFixed(0),
                    (firstGlazing.data.energyConsumption.consumptionRecords.KWH[8]/1000).toFixed(0),
                    (firstGlazing.data.energyConsumption.consumptionRecords.KWH[9]/1000).toFixed(0),
                    (firstGlazing.data.energyConsumption.consumptionRecords.KWH[10]/1000).toFixed(0),
                    (firstGlazing.data.energyConsumption.consumptionRecords.KWH[11]/1000).toFixed(0),
                    (firstGlazing.data.energyConsumption.consumptionRecords.KWH[12]/1000).toFixed(0),
                ],
                [
                    secondGlazing.data.glassSpec.name,
                    (secondGlazing.data.energyConsumption.consumptionRecords.KWH[0]/1000).toFixed(0),
                    (secondGlazing.data.energyConsumption.consumptionRecords.KWH[1]/1000).toFixed(0),
                    (secondGlazing.data.energyConsumption.consumptionRecords.KWH[2]/1000).toFixed(0),
                    (secondGlazing.data.energyConsumption.consumptionRecords.KWH[3]/1000).toFixed(0),
                    (secondGlazing.data.energyConsumption.consumptionRecords.KWH[4]/1000).toFixed(0),
                    (secondGlazing.data.energyConsumption.consumptionRecords.KWH[5]/1000).toFixed(0),
                    (secondGlazing.data.energyConsumption.consumptionRecords.KWH[6]/1000).toFixed(0),
                    (secondGlazing.data.energyConsumption.consumptionRecords.KWH[7]/1000).toFixed(0),
                    (secondGlazing.data.energyConsumption.consumptionRecords.KWH[8]/1000).toFixed(0),
                    (secondGlazing.data.energyConsumption.consumptionRecords.KWH[9]/1000).toFixed(0),
                    (secondGlazing.data.energyConsumption.consumptionRecords.KWH[10]/1000).toFixed(0),
                    (secondGlazing.data.energyConsumption.consumptionRecords.KWH[11]/1000).toFixed(0),
                    (secondGlazing.data.energyConsumption.consumptionRecords.KWH[12]/1000).toFixed(0),
                ]
            ],
        },
        gasTable : {
            data: [
                [
                    baseGlazing.data.glassSpec.name,
                    isThermExist ? (baseGlazing.data.energyConsumption.consumptionRecords.THERM[0]/1000).toFixed(2) : 0,
                    isThermExist ? (baseGlazing.data.energyConsumption.consumptionRecords.THERM[1]/1000).toFixed(2) : 0,
                    isThermExist ? (baseGlazing.data.energyConsumption.consumptionRecords.THERM[2]/1000).toFixed(2) : 0,
                    isThermExist ? (baseGlazing.data.energyConsumption.consumptionRecords.THERM[3]/1000).toFixed(2) : 0,
                    isThermExist ? (baseGlazing.data.energyConsumption.consumptionRecords.THERM[4]/1000).toFixed(2) : 0,
                    isThermExist ? (baseGlazing.data.energyConsumption.consumptionRecords.THERM[5]/1000).toFixed(2) : 0,
                    isThermExist ? (baseGlazing.data.energyConsumption.consumptionRecords.THERM[6]/1000).toFixed(2) : 0,
                    isThermExist ? (baseGlazing.data.energyConsumption.consumptionRecords.THERM[7]/1000).toFixed(2) : 0,
                    isThermExist ? (baseGlazing.data.energyConsumption.consumptionRecords.THERM[8]/1000).toFixed(2) : 0,
                    isThermExist ? (baseGlazing.data.energyConsumption.consumptionRecords.THERM[9]/1000).toFixed(2) : 0,
                    isThermExist ? (baseGlazing.data.energyConsumption.consumptionRecords.THERM[10]/1000).toFixed(2) : 0,
                    isThermExist ? (baseGlazing.data.energyConsumption.consumptionRecords.THERM[11]/1000).toFixed(2) : 0,
                    isThermExist ? (baseGlazing.data.energyConsumption.consumptionRecords.THERM[12]/1000).toFixed(2) : 0,
                ],
                [
                    firstGlazing.data.glassSpec.name,
                    isThermExist ? (firstGlazing.data.energyConsumption.consumptionRecords.THERM[0]/1000).toFixed(2) : 0,
                    isThermExist ? (firstGlazing.data.energyConsumption.consumptionRecords.THERM[1]/1000).toFixed(2) : 0,
                    isThermExist ? (firstGlazing.data.energyConsumption.consumptionRecords.THERM[2]/1000).toFixed(2) : 0,
                    isThermExist ? (firstGlazing.data.energyConsumption.consumptionRecords.THERM[3]/1000).toFixed(2) : 0,
                    isThermExist ? (firstGlazing.data.energyConsumption.consumptionRecords.THERM[4]/1000).toFixed(2) : 0,
                    isThermExist ? (firstGlazing.data.energyConsumption.consumptionRecords.THERM[5]/1000).toFixed(2) : 0,
                    isThermExist ? (firstGlazing.data.energyConsumption.consumptionRecords.THERM[6]/1000).toFixed(2) : 0,
                    isThermExist ? (firstGlazing.data.energyConsumption.consumptionRecords.THERM[7]/1000).toFixed(2) : 0,
                    isThermExist ? (firstGlazing.data.energyConsumption.consumptionRecords.THERM[8]/1000).toFixed(2) : 0,
                    isThermExist ? (firstGlazing.data.energyConsumption.consumptionRecords.THERM[9]/1000).toFixed(2) : 0,
                    isThermExist ? (firstGlazing.data.energyConsumption.consumptionRecords.THERM[10]/1000).toFixed(2) : 0,
                    isThermExist ? (firstGlazing.data.energyConsumption.consumptionRecords.THERM[11]/1000).toFixed(2) : 0,
                    isThermExist ? (firstGlazing.data.energyConsumption.consumptionRecords.THERM[12]/1000).toFixed(2) : 0,
                ],
                [
                    secondGlazing.data.glassSpec.name,
                    isThermExist ? (secondGlazing.data.energyConsumption.consumptionRecords.THERM[0]/1000).toFixed(2) : 0,
                    isThermExist ? (secondGlazing.data.energyConsumption.consumptionRecords.THERM[1]/1000).toFixed(2) : 0,
                    isThermExist ? (secondGlazing.data.energyConsumption.consumptionRecords.THERM[2]/1000).toFixed(2) : 0,
                    isThermExist ? (secondGlazing.data.energyConsumption.consumptionRecords.THERM[3]/1000).toFixed(2) : 0,
                    isThermExist ? (secondGlazing.data.energyConsumption.consumptionRecords.THERM[4]/1000).toFixed(2) : 0,
                    isThermExist ? (secondGlazing.data.energyConsumption.consumptionRecords.THERM[5]/1000).toFixed(2) : 0,
                    isThermExist ? (secondGlazing.data.energyConsumption.consumptionRecords.THERM[6]/1000).toFixed(2) : 0,
                    isThermExist ? (secondGlazing.data.energyConsumption.consumptionRecords.THERM[7]/1000).toFixed(2) : 0,
                    isThermExist ? (secondGlazing.data.energyConsumption.consumptionRecords.THERM[8]/1000).toFixed(2) : 0,
                    isThermExist ? (secondGlazing.data.energyConsumption.consumptionRecords.THERM[9]/1000).toFixed(2) : 0,
                    isThermExist ? (secondGlazing.data.energyConsumption.consumptionRecords.THERM[10]/1000).toFixed(2) : 0,
                    isThermExist ? (secondGlazing.data.energyConsumption.consumptionRecords.THERM[11]/1000).toFixed(2) : 0,
                    isThermExist ? (secondGlazing.data.energyConsumption.consumptionRecords.THERM[12]/1000).toFixed(2) : 0,
                ]
            ],
        }
    };
}

export function getReportPdfData (baseGlazing, firstGlazing, secondGlazing, buildingType, city, stateName, glazingPercentage, firstGlazingId, secondGlazingId, isNewBuilding) {
    const isThermExist = Object.prototype.hasOwnProperty.call(baseGlazing.data.energyConsumption.consumptionRecords, 'THERM');
    return {
        general : {
            building_type: buildingType,
            location: `${city}, ${stateName}`,
            wwr: `${glazingPercentage}% - ${Number(baseGlazing.data.adjTotalWindowArea.toFixed(0)).toLocaleString()} SF / ${Number(baseGlazing.data.adjTotalWallArea.toFixed(0)).toLocaleString()} SF`,
        },
        first_glazing_annual_gauge_data: {
            stationaryCombustion: `${firstGlazing.data.emissions.stationaryCombustion.toFixed(0)} Tons`,
            electricEmissions: `${firstGlazing.data.emissions.electricity.toFixed(0)} Tons`,
            total: `${(firstGlazing.data.emissions.electricity + firstGlazing.data.emissions.stationaryCombustion).toFixed(0)} Tons`,
        },
        second_glazing_annual_gauge_data: {
            stationaryCombustion: `${secondGlazing.data.emissions.stationaryCombustion.toFixed(0)} Tons`,
            electricEmissions: `${secondGlazing.data.emissions.electricity.toFixed(0)} Tons`,
            total: `${(secondGlazing.data.emissions.electricity + secondGlazing.data.emissions.stationaryCombustion).toFixed(0)} Tons`,
        },
        annual_gauge_data : [
            {
                color: '#00587e',
                percent: (((100*(firstGlazing.data.emissions.electricity + firstGlazing.data.emissions.stationaryCombustion))/(baseGlazing.data.emissions.electricity + baseGlazing.data.emissions.stationaryCombustion))).toFixed(0) > 90
                    ?
                    90
                    : (((100*(firstGlazing.data.emissions.electricity + firstGlazing.data.emissions.stationaryCombustion))/(baseGlazing.data.emissions.electricity + baseGlazing.data.emissions.stationaryCombustion))).toFixed(0),
                value: (firstGlazing.data.emissions.electricity + firstGlazing.data.emissions.stationaryCombustion).toFixed(0),
                lifeValue: ((firstGlazing.data.emissions.electricity + firstGlazing.data.emissions.stationaryCombustion)*25).toFixed(0),
            },
            {
                percent: (((100*(secondGlazing.data.emissions.electricity + secondGlazing.data.emissions.stationaryCombustion))/(baseGlazing.data.emissions.electricity + baseGlazing.data.emissions.stationaryCombustion))).toFixed(0) > 90
                    ?
                    90
                    :
                    (((100*(secondGlazing.data.emissions.electricity + secondGlazing.data.emissions.stationaryCombustion))/(baseGlazing.data.emissions.electricity + baseGlazing.data.emissions.stationaryCombustion))).toFixed(0),
                value: (secondGlazing.data.emissions.electricity + secondGlazing.data.emissions.stationaryCombustion).toFixed(0),
                lifeValue: ((secondGlazing.data.emissions.electricity + secondGlazing.data.emissions.stationaryCombustion)*25).toFixed(0),
            },
        ],
        first_annual_carbon_table_data: {
            header : [
                firstGlazing.data.glassSpec.name,
            ],
            data: [
                [
                    'Operational Carbon - 25 Year Life Cycle',
                    `${((firstGlazing.data.emissions.electricity + firstGlazing.data.emissions.stationaryCombustion)*25).toFixed(0)} TCO2e`,
                ],
                [
                    'Embodied Carbon of Glazing',
                    `${((firstGlazing.data.totalWindowArea * GLAZING_CONSTANT.at(firstGlazingId - 1).carbon)/1000).toFixed(0)} TCO2e`,
                ],
                [
                    'CO2 Offset',
                    `${
                        ((((((firstGlazing.data.totalWindowArea * GLAZING_CONSTANT.at(firstGlazingId - 1).carbon)/1000).toFixed(0))
                                    -
                                    (isNewBuilding ? ((baseGlazing.data.totalWindowArea * GLAZING_CONSTANT.at(0).carbon)/1000).toFixed(0) : ((baseGlazing.data.totalWindowArea * GLAZING_CONSTANT.at(1).carbon)/1000).toFixed(0)))
                                /
                                Math.abs(((baseGlazing.data.emissions.electricity + baseGlazing.data.emissions.stationaryCombustion) - (firstGlazing.data.emissions.electricity + firstGlazing.data.emissions.stationaryCombustion))))
                            * 12).toFixed(0)
                    } Months`,
                ],
            ]
        },
        second_annual_carbon_table_data: {
            header : [
                secondGlazing.data.glassSpec.name,
            ],
            data: [
                [
                    'Operational Carbon - 25 Year Life Cycle',
                    `${((secondGlazing.data.emissions.electricity + secondGlazing.data.emissions.stationaryCombustion)*25).toFixed(0)} TCO2e`,
                ],
                [
                    'Embodied Carbon of Glazing',
                    `${((secondGlazing.data.totalWindowArea * GLAZING_CONSTANT.at(secondGlazingId - 1).carbon)/1000).toFixed(0)} TCO2e`,
                ],
                [
                    'CO2 Offset',
                    `${
                        ((((((secondGlazing.data.totalWindowArea * GLAZING_CONSTANT.at(secondGlazingId - 1).carbon)/1000).toFixed(0))
                                    -
                                    (isNewBuilding ? ((baseGlazing.data.totalWindowArea * GLAZING_CONSTANT.at(0).carbon)/1000).toFixed(0) : ((baseGlazing.data.totalWindowArea * GLAZING_CONSTANT.at(1).carbon)/1000).toFixed(0)))
                                /
                                Math.abs(((baseGlazing.data.emissions.electricity + baseGlazing.data.emissions.stationaryCombustion) - (secondGlazing.data.emissions.electricity + secondGlazing.data.emissions.stationaryCombustion))))
                            * 12).toFixed(0)
                    } Months`,
                ],
            ]
        },
        annual_electric_consumption: [
            {
                value: Math.round(firstGlazing.data.energyConsumption.consumptionRecords.KWH[12]/1000),
                name:  firstGlazing.data.glassSpec.name,
                color: "#00587e"
            },
            {
                value: Math.round(secondGlazing.data.energyConsumption.consumptionRecords.KWH[12]/1000),
                name:  secondGlazing.data.glassSpec.name,
                color: "#0090a1"
            },
        ],
        annual_gas_consumption: [
            {
                value: isThermExist ? Math.round(firstGlazing.data.energyConsumption.consumptionRecords.THERM[12]/1000) : 0,
                name:  firstGlazing.data.glassSpec.name,
                color: "#00587e"
            },
            {
                value: isThermExist ? Math.round(secondGlazing.data.energyConsumption.consumptionRecords.THERM[12]/1000) : 0,
                name:  secondGlazing.data.glassSpec.name,
                color: "#0090a1"
            },
        ],
        monthly_consumption: {
            dataKey: [
                {
                    dataKey: 'glass1',
                    name: firstGlazing.data.glassSpec.name,
                    color: '#00587e',
                },
                {
                    dataKey: 'glass2',
                    name: secondGlazing.data.glassSpec.name,
                    color: '#0090a1',
                },
            ],
            electricData: [
                {
                    name: 'Jan',
                    glass1: Math.round(firstGlazing.data.energyConsumption.consumptionRecords.KWH[0]/1000),
                    glass2: Math.round(secondGlazing.data.energyConsumption.consumptionRecords.KWH[0]/1000),
                },
                {
                    name: 'Feb',
                    glass1: Math.round(firstGlazing.data.energyConsumption.consumptionRecords.KWH[1]/1000),
                    glass2: Math.round(secondGlazing.data.energyConsumption.consumptionRecords.KWH[1]/1000),
                },
                {
                    name: 'Mar',
                    glass1: Math.round(firstGlazing.data.energyConsumption.consumptionRecords.KWH[2]/1000),
                    glass2: Math.round(secondGlazing.data.energyConsumption.consumptionRecords.KWH[2]/1000),
                },
                {
                    name: 'Apr',
                    glass1: Math.round(firstGlazing.data.energyConsumption.consumptionRecords.KWH[3]/1000),
                    glass2: Math.round(secondGlazing.data.energyConsumption.consumptionRecords.KWH[3]/1000),
                },
                {
                    name: 'May',
                    glass1: (firstGlazing.data.energyConsumption.consumptionRecords.KWH[4]/1000).toFixed(0),
                    glass2: (secondGlazing.data.energyConsumption.consumptionRecords.KWH[4]/1000).toFixed(0),
                },
                {
                    name: 'Jun',
                    glass1: Math.round(firstGlazing.data.energyConsumption.consumptionRecords.KWH[5]/1000),
                    glass2: Math.round(secondGlazing.data.energyConsumption.consumptionRecords.KWH[5]/1000),
                },
                {
                    name: 'July',
                    glass1: Math.round(firstGlazing.data.energyConsumption.consumptionRecords.KWH[6]/1000),
                    glass2: Math.round(secondGlazing.data.energyConsumption.consumptionRecords.KWH[6]/1000),
                },
                {
                    name: 'Aug',
                    glass1: Math.round(firstGlazing.data.energyConsumption.consumptionRecords.KWH[7]/1000),
                    glass2: Math.round(secondGlazing.data.energyConsumption.consumptionRecords.KWH[7]/1000),
                },
                {
                    name: 'Sept',
                    glass1: Math.round(firstGlazing.data.energyConsumption.consumptionRecords.KWH[8]/1000),
                    glass2: Math.round(secondGlazing.data.energyConsumption.consumptionRecords.KWH[8]/1000),
                },
                {
                    name: 'Oct',
                    glass1: Math.round(firstGlazing.data.energyConsumption.consumptionRecords.KWH[9]/1000),
                    glass2: Math.round(secondGlazing.data.energyConsumption.consumptionRecords.KWH[9]/1000),
                },
                {
                    name: 'Nov',
                    glass1: Math.round(firstGlazing.data.energyConsumption.consumptionRecords.KWH[10]/1000),
                    glass2: Math.round(secondGlazing.data.energyConsumption.consumptionRecords.KWH[10]/1000),
                },
                {
                    name: 'Dec',
                    glass1: Math.round(firstGlazing.data.energyConsumption.consumptionRecords.KWH[11]/1000),
                    glass2: Math.round(secondGlazing.data.energyConsumption.consumptionRecords.KWH[11]/1000),
                },
            ],
            gasData: [
                {
                    name: 'Jan',
                    glass1: isThermExist ? Math.round(firstGlazing.data.energyConsumption.consumptionRecords.THERM[0]/1000) : 0,
                    glass2: isThermExist ? Math.round(secondGlazing.data.energyConsumption.consumptionRecords.THERM[0]/1000) : 0,
                },
                {
                    name: 'Feb',
                    glass1: isThermExist ? Math.round(firstGlazing.data.energyConsumption.consumptionRecords.THERM[1]/1000) : 0,
                    glass2: isThermExist ? Math.round(secondGlazing.data.energyConsumption.consumptionRecords.THERM[1]/1000) : 0,
                },
                {
                    name: 'Mar',
                    glass1: isThermExist ? Math.round(firstGlazing.data.energyConsumption.consumptionRecords.THERM[2]/1000) : 0,
                    glass2: isThermExist ? Math.round(secondGlazing.data.energyConsumption.consumptionRecords.THERM[2]/1000) : 0,
                },
                {
                    name: 'Apr',
                    glass1: isThermExist ? Math.round(firstGlazing.data.energyConsumption.consumptionRecords.THERM[3]/1000) : 0,
                    glass2: isThermExist ? Math.round(secondGlazing.data.energyConsumption.consumptionRecords.THERM[3]/1000) : 0,
                },
                {
                    name: 'May',
                    glass1: isThermExist ? Math.round(firstGlazing.data.energyConsumption.consumptionRecords.THERM[4]/1000) : 0,
                    glass2: isThermExist ? Math.round(secondGlazing.data.energyConsumption.consumptionRecords.THERM[4]/1000) : 0,
                },
                {
                    name: 'Jun',
                    glass1: isThermExist ? Math.round(firstGlazing.data.energyConsumption.consumptionRecords.THERM[5]/1000) : 0,
                    glass2: isThermExist ? Math.round(secondGlazing.data.energyConsumption.consumptionRecords.THERM[5]/1000) : 0,
                },
                {
                    name: 'July',
                    glass1: isThermExist ? Math.round(firstGlazing.data.energyConsumption.consumptionRecords.THERM[6]/1000) : 0,
                    glass2: isThermExist ? Math.round(secondGlazing.data.energyConsumption.consumptionRecords.THERM[6]/1000) : 0,
                },
                {
                    name: 'Aug',
                    glass1: isThermExist ? Math.round(firstGlazing.data.energyConsumption.consumptionRecords.THERM[7]/1000) : 0,
                    glass2: isThermExist ? Math.round(secondGlazing.data.energyConsumption.consumptionRecords.THERM[7]/1000) : 0,
                },
                {
                    name: 'Sept',
                    glass1: isThermExist ? Math.round(firstGlazing.data.energyConsumption.consumptionRecords.THERM[8]/1000) : 0,
                    glass2: isThermExist ? Math.round(secondGlazing.data.energyConsumption.consumptionRecords.THERM[8]/1000) : 0,
                },
                {
                    name: 'Oct',
                    glass1: isThermExist ? Math.round(firstGlazing.data.energyConsumption.consumptionRecords.THERM[9]/1000) : 0,
                    glass2: isThermExist ? Math.round(secondGlazing.data.energyConsumption.consumptionRecords.THERM[9]/1000) : 0,
                },
                {
                    name: 'Nov',
                    glass1: isThermExist ? Math.round(firstGlazing.data.energyConsumption.consumptionRecords.THERM[10]/1000) : 0,
                    glass2: isThermExist ? Math.round(secondGlazing.data.energyConsumption.consumptionRecords.THERM[10]/1000) : 0,
                },
                {
                    name: 'Dec',
                    glass1: isThermExist ? Math.round(firstGlazing.data.energyConsumption.consumptionRecords.THERM[11]/1000) : 0,
                    glass2: isThermExist ? Math.round(secondGlazing.data.energyConsumption.consumptionRecords.THERM[11]/1000) : 0,
                },
            ],
        },
        electricTable : {
            data: [
                [
                    firstGlazing.data.glassSpec.name,
                    (firstGlazing.data.energyConsumption.consumptionRecords.KWH[0]/1000).toFixed(0),
                    (firstGlazing.data.energyConsumption.consumptionRecords.KWH[1]/1000).toFixed(0),
                    (firstGlazing.data.energyConsumption.consumptionRecords.KWH[2]/1000).toFixed(0),
                    (firstGlazing.data.energyConsumption.consumptionRecords.KWH[3]/1000).toFixed(0),
                    (firstGlazing.data.energyConsumption.consumptionRecords.KWH[4]/1000).toFixed(0),
                    (firstGlazing.data.energyConsumption.consumptionRecords.KWH[5]/1000).toFixed(0),
                    (firstGlazing.data.energyConsumption.consumptionRecords.KWH[6]/1000).toFixed(0),
                    (firstGlazing.data.energyConsumption.consumptionRecords.KWH[7]/1000).toFixed(0),
                    (firstGlazing.data.energyConsumption.consumptionRecords.KWH[8]/1000).toFixed(0),
                    (firstGlazing.data.energyConsumption.consumptionRecords.KWH[9]/1000).toFixed(0),
                    (firstGlazing.data.energyConsumption.consumptionRecords.KWH[10]/1000).toFixed(0),
                    (firstGlazing.data.energyConsumption.consumptionRecords.KWH[11]/1000).toFixed(0),
                    (firstGlazing.data.energyConsumption.consumptionRecords.KWH[12]/1000).toFixed(0),
                ],
                [
                    secondGlazing.data.glassSpec.name,
                    (secondGlazing.data.energyConsumption.consumptionRecords.KWH[0]/1000).toFixed(0),
                    (secondGlazing.data.energyConsumption.consumptionRecords.KWH[1]/1000).toFixed(0),
                    (secondGlazing.data.energyConsumption.consumptionRecords.KWH[2]/1000).toFixed(0),
                    (secondGlazing.data.energyConsumption.consumptionRecords.KWH[3]/1000).toFixed(0),
                    (secondGlazing.data.energyConsumption.consumptionRecords.KWH[4]/1000).toFixed(0),
                    (secondGlazing.data.energyConsumption.consumptionRecords.KWH[5]/1000).toFixed(0),
                    (secondGlazing.data.energyConsumption.consumptionRecords.KWH[6]/1000).toFixed(0),
                    (secondGlazing.data.energyConsumption.consumptionRecords.KWH[7]/1000).toFixed(0),
                    (secondGlazing.data.energyConsumption.consumptionRecords.KWH[8]/1000).toFixed(0),
                    (secondGlazing.data.energyConsumption.consumptionRecords.KWH[9]/1000).toFixed(0),
                    (secondGlazing.data.energyConsumption.consumptionRecords.KWH[10]/1000).toFixed(0),
                    (secondGlazing.data.energyConsumption.consumptionRecords.KWH[11]/1000).toFixed(0),
                    (secondGlazing.data.energyConsumption.consumptionRecords.KWH[12]/1000).toFixed(0),
                ]
            ],
        },
        gasTable : {
            data: [
                [
                    firstGlazing.data.glassSpec.name,
                    isThermExist ? (firstGlazing.data.energyConsumption.consumptionRecords.THERM[0]/1000).toFixed(2) : 0,
                    isThermExist ? (firstGlazing.data.energyConsumption.consumptionRecords.THERM[1]/1000).toFixed(2) : 0,
                    isThermExist ? (firstGlazing.data.energyConsumption.consumptionRecords.THERM[2]/1000).toFixed(2) : 0,
                    isThermExist ? (firstGlazing.data.energyConsumption.consumptionRecords.THERM[3]/1000).toFixed(2) : 0,
                    isThermExist ? (firstGlazing.data.energyConsumption.consumptionRecords.THERM[4]/1000).toFixed(2) : 0,
                    isThermExist ? (firstGlazing.data.energyConsumption.consumptionRecords.THERM[5]/1000).toFixed(2) : 0,
                    isThermExist ? (firstGlazing.data.energyConsumption.consumptionRecords.THERM[6]/1000).toFixed(2) : 0,
                    isThermExist ? (firstGlazing.data.energyConsumption.consumptionRecords.THERM[7]/1000).toFixed(2) : 0,
                    isThermExist ? (firstGlazing.data.energyConsumption.consumptionRecords.THERM[8]/1000).toFixed(2) : 0,
                    isThermExist ? (firstGlazing.data.energyConsumption.consumptionRecords.THERM[9]/1000).toFixed(2) : 0,
                    isThermExist ? (firstGlazing.data.energyConsumption.consumptionRecords.THERM[10]/1000).toFixed(2) : 0,
                    isThermExist ? (firstGlazing.data.energyConsumption.consumptionRecords.THERM[11]/1000).toFixed(2) : 0,
                    isThermExist ? (firstGlazing.data.energyConsumption.consumptionRecords.THERM[12]/1000).toFixed(2) : 0,
                ],
                [
                    secondGlazing.data.glassSpec.name,
                    isThermExist ? (secondGlazing.data.energyConsumption.consumptionRecords.THERM[0]/1000).toFixed(2) : 0,
                    isThermExist ? (secondGlazing.data.energyConsumption.consumptionRecords.THERM[1]/1000).toFixed(2) : 0,
                    isThermExist ? (secondGlazing.data.energyConsumption.consumptionRecords.THERM[2]/1000).toFixed(2) : 0,
                    isThermExist ? (secondGlazing.data.energyConsumption.consumptionRecords.THERM[3]/1000).toFixed(2) : 0,
                    isThermExist ? (secondGlazing.data.energyConsumption.consumptionRecords.THERM[4]/1000).toFixed(2) : 0,
                    isThermExist ? (secondGlazing.data.energyConsumption.consumptionRecords.THERM[5]/1000).toFixed(2) : 0,
                    isThermExist ? (secondGlazing.data.energyConsumption.consumptionRecords.THERM[6]/1000).toFixed(2) : 0,
                    isThermExist ? (secondGlazing.data.energyConsumption.consumptionRecords.THERM[7]/1000).toFixed(2) : 0,
                    isThermExist ? (secondGlazing.data.energyConsumption.consumptionRecords.THERM[8]/1000).toFixed(2) : 0,
                    isThermExist ? (secondGlazing.data.energyConsumption.consumptionRecords.THERM[9]/1000).toFixed(2) : 0,
                    isThermExist ? (secondGlazing.data.energyConsumption.consumptionRecords.THERM[10]/1000).toFixed(2) : 0,
                    isThermExist ? (secondGlazing.data.energyConsumption.consumptionRecords.THERM[11]/1000).toFixed(2) : 0,
                    isThermExist ? (secondGlazing.data.energyConsumption.consumptionRecords.THERM[12]/1000).toFixed(2) : 0,
                ]
            ],
        },
        firstElectricTableSummary : {
            data: [
                [
                    "Space Cooling",
                    (firstGlazing.data.electricityEndUseSummary.JAN[4].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.FEB[4].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.MAR[4].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.APR[4].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.MAY[4].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.JUN[4].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.JUL[4].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.AUG[4].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.SEP[4].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.OCT[4].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.NOV[4].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.DEC[4].kwh/1000).toFixed(0),
                    ((firstGlazing.data.electricityEndUseSummary.JAN[4].kwh +
                    firstGlazing.data.electricityEndUseSummary.FEB[4].kwh +
                    firstGlazing.data.electricityEndUseSummary.MAR[4].kwh +
                    firstGlazing.data.electricityEndUseSummary.APR[4].kwh +
                    firstGlazing.data.electricityEndUseSummary.MAY[4].kwh +
                    firstGlazing.data.electricityEndUseSummary.JUN[4].kwh +
                    firstGlazing.data.electricityEndUseSummary.JUL[4].kwh +
                    firstGlazing.data.electricityEndUseSummary.AUG[4].kwh +
                    firstGlazing.data.electricityEndUseSummary.SEP[4].kwh +
                    firstGlazing.data.electricityEndUseSummary.OCT[4].kwh +
                    firstGlazing.data.electricityEndUseSummary.NOV[4].kwh +
                    firstGlazing.data.electricityEndUseSummary.DEC[4].kwh)/1000).toFixed(0),
                ],
                [
                    "Heat Rejection",
                    (firstGlazing.data.electricityEndUseSummary.JAN[5].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.FEB[5].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.MAR[5].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.APR[5].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.MAY[5].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.JUN[5].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.JUL[5].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.AUG[5].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.SEP[5].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.OCT[5].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.NOV[5].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.DEC[5].kwh/1000).toFixed(0),
                    ((firstGlazing.data.electricityEndUseSummary.JAN[5].kwh +
                    firstGlazing.data.electricityEndUseSummary.FEB[5].kwh +
                    firstGlazing.data.electricityEndUseSummary.MAR[5].kwh +
                    firstGlazing.data.electricityEndUseSummary.APR[5].kwh +
                    firstGlazing.data.electricityEndUseSummary.MAY[5].kwh +
                    firstGlazing.data.electricityEndUseSummary.JUN[5].kwh +
                    firstGlazing.data.electricityEndUseSummary.JUL[5].kwh +
                    firstGlazing.data.electricityEndUseSummary.AUG[5].kwh +
                    firstGlazing.data.electricityEndUseSummary.SEP[5].kwh +
                    firstGlazing.data.electricityEndUseSummary.OCT[5].kwh +
                    firstGlazing.data.electricityEndUseSummary.NOV[5].kwh +
                    firstGlazing.data.electricityEndUseSummary.DEC[5].kwh)/1000).toFixed(0),
                ],
                [
                    "Refridgeration",
                    (firstGlazing.data.electricityEndUseSummary.JAN[8].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.FEB[8].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.MAR[8].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.APR[8].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.MAY[8].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.JUN[8].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.JUL[8].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.AUG[8].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.SEP[8].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.OCT[8].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.NOV[8].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.DEC[8].kwh/1000).toFixed(0),
                    ((firstGlazing.data.electricityEndUseSummary.JAN[8].kwh +
                    firstGlazing.data.electricityEndUseSummary.FEB[8].kwh +
                    firstGlazing.data.electricityEndUseSummary.MAR[8].kwh +
                    firstGlazing.data.electricityEndUseSummary.APR[8].kwh +
                    firstGlazing.data.electricityEndUseSummary.MAY[8].kwh +
                    firstGlazing.data.electricityEndUseSummary.JUN[8].kwh +
                    firstGlazing.data.electricityEndUseSummary.JUL[8].kwh +
                    firstGlazing.data.electricityEndUseSummary.AUG[8].kwh +
                    firstGlazing.data.electricityEndUseSummary.SEP[8].kwh +
                    firstGlazing.data.electricityEndUseSummary.OCT[8].kwh +
                    firstGlazing.data.electricityEndUseSummary.NOV[8].kwh +
                    firstGlazing.data.electricityEndUseSummary.DEC[8].kwh)/1000).toFixed(0),
                ],
                [
                    "Space Heat",
                    (firstGlazing.data.electricityEndUseSummary.JAN[3].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.FEB[3].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.MAR[3].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.APR[3].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.MAY[3].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.JUN[3].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.JUL[3].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.AUG[3].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.SEP[3].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.OCT[3].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.NOV[3].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.DEC[3].kwh/1000).toFixed(0),
                    ((firstGlazing.data.electricityEndUseSummary.JAN[3].kwh +
                    firstGlazing.data.electricityEndUseSummary.FEB[3].kwh +
                    firstGlazing.data.electricityEndUseSummary.MAR[3].kwh +
                    firstGlazing.data.electricityEndUseSummary.APR[3].kwh +
                    firstGlazing.data.electricityEndUseSummary.MAY[3].kwh +
                    firstGlazing.data.electricityEndUseSummary.JUN[3].kwh +
                    firstGlazing.data.electricityEndUseSummary.JUL[3].kwh +
                    firstGlazing.data.electricityEndUseSummary.AUG[3].kwh +
                    firstGlazing.data.electricityEndUseSummary.SEP[3].kwh +
                    firstGlazing.data.electricityEndUseSummary.OCT[3].kwh +
                    firstGlazing.data.electricityEndUseSummary.NOV[3].kwh +
                    firstGlazing.data.electricityEndUseSummary.DEC[3].kwh)/1000).toFixed(0),
                ],
                [
                    "Hp Supp.",
                    (firstGlazing.data.electricityEndUseSummary.JAN[9].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.FEB[9].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.MAR[9].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.APR[9].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.MAY[9].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.JUN[9].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.JUL[9].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.AUG[9].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.SEP[9].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.OCT[9].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.NOV[9].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.DEC[9].kwh/1000).toFixed(0),
                    ((firstGlazing.data.electricityEndUseSummary.JAN[9].kwh +
                    firstGlazing.data.electricityEndUseSummary.FEB[9].kwh +
                    firstGlazing.data.electricityEndUseSummary.MAR[9].kwh +
                    firstGlazing.data.electricityEndUseSummary.APR[9].kwh +
                    firstGlazing.data.electricityEndUseSummary.MAY[9].kwh +
                    firstGlazing.data.electricityEndUseSummary.JUN[9].kwh +
                    firstGlazing.data.electricityEndUseSummary.JUL[9].kwh +
                    firstGlazing.data.electricityEndUseSummary.AUG[9].kwh +
                    firstGlazing.data.electricityEndUseSummary.SEP[9].kwh +
                    firstGlazing.data.electricityEndUseSummary.OCT[9].kwh +
                    firstGlazing.data.electricityEndUseSummary.NOV[9].kwh +
                    firstGlazing.data.electricityEndUseSummary.DEC[9].kwh)/1000).toFixed(0),
                ],
                [
                    "Hot Water",
                    (firstGlazing.data.electricityEndUseSummary.JAN[10].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.FEB[10].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.MAR[10].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.APR[10].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.MAY[10].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.JUN[10].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.JUL[10].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.AUG[10].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.SEP[10].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.OCT[10].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.NOV[10].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.DEC[10].kwh/1000).toFixed(0),
                    ((firstGlazing.data.electricityEndUseSummary.JAN[10].kwh +
                    firstGlazing.data.electricityEndUseSummary.FEB[10].kwh +
                    firstGlazing.data.electricityEndUseSummary.MAR[10].kwh +
                    firstGlazing.data.electricityEndUseSummary.APR[10].kwh +
                    firstGlazing.data.electricityEndUseSummary.MAY[10].kwh +
                    firstGlazing.data.electricityEndUseSummary.JUN[10].kwh +
                    firstGlazing.data.electricityEndUseSummary.JUL[10].kwh +
                    firstGlazing.data.electricityEndUseSummary.AUG[10].kwh +
                    firstGlazing.data.electricityEndUseSummary.SEP[10].kwh +
                    firstGlazing.data.electricityEndUseSummary.OCT[10].kwh +
                    firstGlazing.data.electricityEndUseSummary.NOV[10].kwh +
                    firstGlazing.data.electricityEndUseSummary.DEC[10].kwh)/1000).toFixed(0),
                ],
                [
                    "Vent. Fans",
                    (firstGlazing.data.electricityEndUseSummary.JAN[7].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.FEB[7].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.MAR[7].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.APR[7].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.MAY[7].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.JUN[7].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.JUL[7].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.AUG[7].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.SEP[7].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.OCT[7].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.NOV[7].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.DEC[7].kwh/1000).toFixed(0),
                    ((firstGlazing.data.electricityEndUseSummary.JAN[7].kwh +
                    firstGlazing.data.electricityEndUseSummary.FEB[7].kwh +
                    firstGlazing.data.electricityEndUseSummary.MAR[7].kwh +
                    firstGlazing.data.electricityEndUseSummary.APR[7].kwh +
                    firstGlazing.data.electricityEndUseSummary.MAY[7].kwh +
                    firstGlazing.data.electricityEndUseSummary.JUN[7].kwh +
                    firstGlazing.data.electricityEndUseSummary.JUL[7].kwh +
                    firstGlazing.data.electricityEndUseSummary.AUG[7].kwh +
                    firstGlazing.data.electricityEndUseSummary.SEP[7].kwh +
                    firstGlazing.data.electricityEndUseSummary.OCT[7].kwh +
                    firstGlazing.data.electricityEndUseSummary.NOV[7].kwh +
                    firstGlazing.data.electricityEndUseSummary.DEC[7].kwh)/1000).toFixed(0),
                ],
                [
                    "Pumps and Aux.",
                    (firstGlazing.data.electricityEndUseSummary.JAN[6].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.FEB[6].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.MAR[6].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.APR[6].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.MAY[6].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.JUN[6].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.JUL[6].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.AUG[6].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.SEP[6].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.OCT[6].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.NOV[6].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.DEC[6].kwh/1000).toFixed(0),
                    ((firstGlazing.data.electricityEndUseSummary.JAN[6].kwh +
                    firstGlazing.data.electricityEndUseSummary.FEB[6].kwh +
                    firstGlazing.data.electricityEndUseSummary.MAR[6].kwh +
                    firstGlazing.data.electricityEndUseSummary.APR[6].kwh +
                    firstGlazing.data.electricityEndUseSummary.MAY[6].kwh +
                    firstGlazing.data.electricityEndUseSummary.JUN[6].kwh +
                    firstGlazing.data.electricityEndUseSummary.JUL[6].kwh +
                    firstGlazing.data.electricityEndUseSummary.AUG[6].kwh +
                    firstGlazing.data.electricityEndUseSummary.SEP[6].kwh +
                    firstGlazing.data.electricityEndUseSummary.OCT[6].kwh +
                    firstGlazing.data.electricityEndUseSummary.NOV[6].kwh +
                    firstGlazing.data.electricityEndUseSummary.DEC[6].kwh)/1000).toFixed(0),
                ],
                [
                    "Ext. Usage",
                    (firstGlazing.data.electricityEndUseSummary.JAN[11].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.FEB[11].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.MAR[11].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.APR[11].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.MAY[11].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.JUN[11].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.JUL[11].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.AUG[11].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.SEP[11].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.OCT[11].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.NOV[11].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.DEC[11].kwh/1000).toFixed(0),
                    ((firstGlazing.data.electricityEndUseSummary.JAN[11].kwh +
                    firstGlazing.data.electricityEndUseSummary.FEB[11].kwh +
                    firstGlazing.data.electricityEndUseSummary.MAR[11].kwh +
                    firstGlazing.data.electricityEndUseSummary.APR[11].kwh +
                    firstGlazing.data.electricityEndUseSummary.MAY[11].kwh +
                    firstGlazing.data.electricityEndUseSummary.JUN[11].kwh +
                    firstGlazing.data.electricityEndUseSummary.JUL[11].kwh +
                    firstGlazing.data.electricityEndUseSummary.AUG[11].kwh +
                    firstGlazing.data.electricityEndUseSummary.SEP[11].kwh +
                    firstGlazing.data.electricityEndUseSummary.OCT[11].kwh +
                    firstGlazing.data.electricityEndUseSummary.NOV[11].kwh +
                    firstGlazing.data.electricityEndUseSummary.DEC[11].kwh)/1000).toFixed(0),
                ],
                [
                    "Misc. Equip.",
                    (firstGlazing.data.electricityEndUseSummary.JAN[2].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.FEB[2].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.MAR[2].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.APR[2].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.MAY[2].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.JUN[2].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.JUL[2].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.AUG[2].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.SEP[2].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.OCT[2].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.NOV[2].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.DEC[2].kwh/1000).toFixed(0),
                    ((firstGlazing.data.electricityEndUseSummary.JAN[2].kwh +
                    firstGlazing.data.electricityEndUseSummary.FEB[2].kwh +
                    firstGlazing.data.electricityEndUseSummary.MAR[2].kwh +
                    firstGlazing.data.electricityEndUseSummary.APR[2].kwh +
                    firstGlazing.data.electricityEndUseSummary.MAY[2].kwh +
                    firstGlazing.data.electricityEndUseSummary.JUN[2].kwh +
                    firstGlazing.data.electricityEndUseSummary.JUL[2].kwh +
                    firstGlazing.data.electricityEndUseSummary.AUG[2].kwh +
                    firstGlazing.data.electricityEndUseSummary.SEP[2].kwh +
                    firstGlazing.data.electricityEndUseSummary.OCT[2].kwh +
                    firstGlazing.data.electricityEndUseSummary.NOV[2].kwh +
                    firstGlazing.data.electricityEndUseSummary.DEC[2].kwh)/1000).toFixed(0),
                ],
                [
                    "Task Lights",
                    (firstGlazing.data.electricityEndUseSummary.JAN[1].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.FEB[1].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.MAR[1].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.APR[1].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.MAY[1].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.JUN[1].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.JUL[1].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.AUG[1].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.SEP[1].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.OCT[1].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.NOV[1].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.DEC[1].kwh/1000).toFixed(0),
                    ((firstGlazing.data.electricityEndUseSummary.JAN[1].kwh +
                    firstGlazing.data.electricityEndUseSummary.FEB[1].kwh +
                    firstGlazing.data.electricityEndUseSummary.MAR[1].kwh +
                    firstGlazing.data.electricityEndUseSummary.APR[1].kwh +
                    firstGlazing.data.electricityEndUseSummary.MAY[1].kwh +
                    firstGlazing.data.electricityEndUseSummary.JUN[1].kwh +
                    firstGlazing.data.electricityEndUseSummary.JUL[1].kwh +
                    firstGlazing.data.electricityEndUseSummary.AUG[1].kwh +
                    firstGlazing.data.electricityEndUseSummary.SEP[1].kwh +
                    firstGlazing.data.electricityEndUseSummary.OCT[1].kwh +
                    firstGlazing.data.electricityEndUseSummary.NOV[1].kwh +
                    firstGlazing.data.electricityEndUseSummary.DEC[1].kwh)/1000).toFixed(0),
                ],
                [
                    "Area Lights",
                    (firstGlazing.data.electricityEndUseSummary.JAN[0].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.FEB[0].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.MAR[0].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.APR[0].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.MAY[0].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.JUN[0].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.JUL[0].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.AUG[0].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.SEP[0].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.OCT[0].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.NOV[0].kwh/1000).toFixed(0),
                    (firstGlazing.data.electricityEndUseSummary.DEC[0].kwh/1000).toFixed(0),
                    ((firstGlazing.data.electricityEndUseSummary.JAN[0].kwh +
                    firstGlazing.data.electricityEndUseSummary.FEB[0].kwh +
                    firstGlazing.data.electricityEndUseSummary.MAR[0].kwh +
                    firstGlazing.data.electricityEndUseSummary.APR[0].kwh +
                    firstGlazing.data.electricityEndUseSummary.MAY[0].kwh +
                    firstGlazing.data.electricityEndUseSummary.JUN[0].kwh +
                    firstGlazing.data.electricityEndUseSummary.JUL[0].kwh +
                    firstGlazing.data.electricityEndUseSummary.AUG[0].kwh +
                    firstGlazing.data.electricityEndUseSummary.SEP[0].kwh +
                    firstGlazing.data.electricityEndUseSummary.OCT[0].kwh +
                    firstGlazing.data.electricityEndUseSummary.NOV[0].kwh +
                    firstGlazing.data.electricityEndUseSummary.DEC[0].kwh)/1000).toFixed(0),
                ],
                [
                    "Total",
                    ((firstGlazing.data.electricityEndUseSummary.JAN[4].kwh +
                    firstGlazing.data.electricityEndUseSummary.JAN[5].kwh +
                    firstGlazing.data.electricityEndUseSummary.JAN[8].kwh +
                    firstGlazing.data.electricityEndUseSummary.JAN[3].kwh +
                    firstGlazing.data.electricityEndUseSummary.JAN[9].kwh +
                    firstGlazing.data.electricityEndUseSummary.JAN[10].kwh +
                    firstGlazing.data.electricityEndUseSummary.JAN[7].kwh +
                    firstGlazing.data.electricityEndUseSummary.JAN[6].kwh +
                    firstGlazing.data.electricityEndUseSummary.JAN[11].kwh +
                    firstGlazing.data.electricityEndUseSummary.JAN[2].kwh +
                    firstGlazing.data.electricityEndUseSummary.JAN[1].kwh +
                    firstGlazing.data.electricityEndUseSummary.JAN[0].kwh)/1000).toFixed(0),
                    ((firstGlazing.data.electricityEndUseSummary.FEB[4].kwh +
                    firstGlazing.data.electricityEndUseSummary.FEB[5].kwh +
                    firstGlazing.data.electricityEndUseSummary.FEB[8].kwh +
                    firstGlazing.data.electricityEndUseSummary.FEB[3].kwh +
                    firstGlazing.data.electricityEndUseSummary.FEB[9].kwh +
                    firstGlazing.data.electricityEndUseSummary.FEB[10].kwh +
                    firstGlazing.data.electricityEndUseSummary.FEB[7].kwh +
                    firstGlazing.data.electricityEndUseSummary.FEB[6].kwh +
                    firstGlazing.data.electricityEndUseSummary.FEB[11].kwh +
                    firstGlazing.data.electricityEndUseSummary.FEB[2].kwh +
                    firstGlazing.data.electricityEndUseSummary.FEB[1].kwh +
                    firstGlazing.data.electricityEndUseSummary.FEB[0].kwh)/1000).toFixed(0),
                    ((firstGlazing.data.electricityEndUseSummary.MAR[4].kwh +
                    firstGlazing.data.electricityEndUseSummary.MAR[5].kwh +
                    firstGlazing.data.electricityEndUseSummary.MAR[8].kwh +
                    firstGlazing.data.electricityEndUseSummary.MAR[3].kwh +
                    firstGlazing.data.electricityEndUseSummary.MAR[9].kwh +
                    firstGlazing.data.electricityEndUseSummary.MAR[10].kwh +
                    firstGlazing.data.electricityEndUseSummary.MAR[7].kwh +
                    firstGlazing.data.electricityEndUseSummary.MAR[6].kwh +
                    firstGlazing.data.electricityEndUseSummary.MAR[11].kwh +
                    firstGlazing.data.electricityEndUseSummary.MAR[2].kwh +
                    firstGlazing.data.electricityEndUseSummary.MAR[1].kwh +
                    firstGlazing.data.electricityEndUseSummary.MAR[0].kwh)/1000).toFixed(0),
                    ((firstGlazing.data.electricityEndUseSummary.APR[4].kwh +
                    firstGlazing.data.electricityEndUseSummary.APR[5].kwh +
                    firstGlazing.data.electricityEndUseSummary.APR[8].kwh +
                    firstGlazing.data.electricityEndUseSummary.APR[3].kwh +
                    firstGlazing.data.electricityEndUseSummary.APR[9].kwh +
                    firstGlazing.data.electricityEndUseSummary.APR[10].kwh +
                    firstGlazing.data.electricityEndUseSummary.APR[7].kwh +
                    firstGlazing.data.electricityEndUseSummary.APR[6].kwh +
                    firstGlazing.data.electricityEndUseSummary.APR[11].kwh +
                    firstGlazing.data.electricityEndUseSummary.APR[2].kwh +
                    firstGlazing.data.electricityEndUseSummary.APR[1].kwh +
                    firstGlazing.data.electricityEndUseSummary.APR[0].kwh)/1000).toFixed(0),
                    ((firstGlazing.data.electricityEndUseSummary.MAY[4].kwh +
                    firstGlazing.data.electricityEndUseSummary.MAY[5].kwh +
                    firstGlazing.data.electricityEndUseSummary.MAY[8].kwh +
                    firstGlazing.data.electricityEndUseSummary.MAY[3].kwh +
                    firstGlazing.data.electricityEndUseSummary.MAY[9].kwh +
                    firstGlazing.data.electricityEndUseSummary.MAY[10].kwh +
                    firstGlazing.data.electricityEndUseSummary.MAY[7].kwh +
                    firstGlazing.data.electricityEndUseSummary.MAY[6].kwh +
                    firstGlazing.data.electricityEndUseSummary.MAY[11].kwh +
                    firstGlazing.data.electricityEndUseSummary.MAY[2].kwh +
                    firstGlazing.data.electricityEndUseSummary.MAY[1].kwh +
                    firstGlazing.data.electricityEndUseSummary.MAY[0].kwh)/1000).toFixed(0),
                    ((firstGlazing.data.electricityEndUseSummary.JUN[4].kwh +
                    firstGlazing.data.electricityEndUseSummary.JUN[5].kwh +
                    firstGlazing.data.electricityEndUseSummary.JUN[8].kwh +
                    firstGlazing.data.electricityEndUseSummary.JUN[3].kwh +
                    firstGlazing.data.electricityEndUseSummary.JUN[9].kwh +
                    firstGlazing.data.electricityEndUseSummary.JUN[10].kwh +
                    firstGlazing.data.electricityEndUseSummary.JUN[7].kwh +
                    firstGlazing.data.electricityEndUseSummary.JUN[6].kwh +
                    firstGlazing.data.electricityEndUseSummary.JUN[11].kwh +
                    firstGlazing.data.electricityEndUseSummary.JUN[2].kwh +
                    firstGlazing.data.electricityEndUseSummary.JUN[1].kwh +
                    firstGlazing.data.electricityEndUseSummary.JUN[0].kwh)/1000).toFixed(0),
                    ((firstGlazing.data.electricityEndUseSummary.JUL[4].kwh +
                    firstGlazing.data.electricityEndUseSummary.JUL[5].kwh +
                    firstGlazing.data.electricityEndUseSummary.JUL[8].kwh +
                    firstGlazing.data.electricityEndUseSummary.JUL[3].kwh +
                    firstGlazing.data.electricityEndUseSummary.JUL[9].kwh +
                    firstGlazing.data.electricityEndUseSummary.JUL[10].kwh +
                    firstGlazing.data.electricityEndUseSummary.JUL[7].kwh +
                    firstGlazing.data.electricityEndUseSummary.JUL[6].kwh +
                    firstGlazing.data.electricityEndUseSummary.JUL[11].kwh +
                    firstGlazing.data.electricityEndUseSummary.JUL[2].kwh +
                    firstGlazing.data.electricityEndUseSummary.JUL[1].kwh +
                    firstGlazing.data.electricityEndUseSummary.JUL[0].kwh)/1000).toFixed(0),
                    ((firstGlazing.data.electricityEndUseSummary.AUG[4].kwh +
                    firstGlazing.data.electricityEndUseSummary.AUG[5].kwh +
                    firstGlazing.data.electricityEndUseSummary.AUG[8].kwh +
                    firstGlazing.data.electricityEndUseSummary.AUG[3].kwh +
                    firstGlazing.data.electricityEndUseSummary.AUG[9].kwh +
                    firstGlazing.data.electricityEndUseSummary.AUG[10].kwh +
                    firstGlazing.data.electricityEndUseSummary.AUG[7].kwh +
                    firstGlazing.data.electricityEndUseSummary.AUG[6].kwh +
                    firstGlazing.data.electricityEndUseSummary.AUG[11].kwh +
                    firstGlazing.data.electricityEndUseSummary.AUG[2].kwh +
                    firstGlazing.data.electricityEndUseSummary.AUG[1].kwh +
                    firstGlazing.data.electricityEndUseSummary.AUG[0].kwh)/1000).toFixed(0),
                    ((firstGlazing.data.electricityEndUseSummary.SEP[4].kwh +
                    firstGlazing.data.electricityEndUseSummary.SEP[5].kwh +
                    firstGlazing.data.electricityEndUseSummary.SEP[8].kwh +
                    firstGlazing.data.electricityEndUseSummary.SEP[3].kwh +
                    firstGlazing.data.electricityEndUseSummary.SEP[9].kwh +
                    firstGlazing.data.electricityEndUseSummary.SEP[10].kwh +
                    firstGlazing.data.electricityEndUseSummary.SEP[7].kwh +
                    firstGlazing.data.electricityEndUseSummary.SEP[6].kwh +
                    firstGlazing.data.electricityEndUseSummary.SEP[11].kwh +
                    firstGlazing.data.electricityEndUseSummary.SEP[2].kwh +
                    firstGlazing.data.electricityEndUseSummary.SEP[1].kwh +
                    firstGlazing.data.electricityEndUseSummary.SEP[0].kwh)/1000).toFixed(0),
                    ((firstGlazing.data.electricityEndUseSummary.OCT[4].kwh +
                    firstGlazing.data.electricityEndUseSummary.OCT[5].kwh +
                    firstGlazing.data.electricityEndUseSummary.OCT[8].kwh +
                    firstGlazing.data.electricityEndUseSummary.OCT[3].kwh +
                    firstGlazing.data.electricityEndUseSummary.OCT[9].kwh +
                    firstGlazing.data.electricityEndUseSummary.OCT[10].kwh +
                    firstGlazing.data.electricityEndUseSummary.OCT[7].kwh +
                    firstGlazing.data.electricityEndUseSummary.OCT[6].kwh +
                    firstGlazing.data.electricityEndUseSummary.OCT[11].kwh +
                    firstGlazing.data.electricityEndUseSummary.OCT[2].kwh +
                    firstGlazing.data.electricityEndUseSummary.OCT[1].kwh +
                    firstGlazing.data.electricityEndUseSummary.OCT[0].kwh)/1000).toFixed(0),
                    ((firstGlazing.data.electricityEndUseSummary.NOV[4].kwh +
                    firstGlazing.data.electricityEndUseSummary.NOV[5].kwh +
                    firstGlazing.data.electricityEndUseSummary.NOV[8].kwh +
                    firstGlazing.data.electricityEndUseSummary.NOV[3].kwh +
                    firstGlazing.data.electricityEndUseSummary.NOV[9].kwh +
                    firstGlazing.data.electricityEndUseSummary.NOV[10].kwh +
                    firstGlazing.data.electricityEndUseSummary.NOV[7].kwh +
                    firstGlazing.data.electricityEndUseSummary.NOV[6].kwh +
                    firstGlazing.data.electricityEndUseSummary.NOV[11].kwh +
                    firstGlazing.data.electricityEndUseSummary.NOV[2].kwh +
                    firstGlazing.data.electricityEndUseSummary.NOV[1].kwh +
                    firstGlazing.data.electricityEndUseSummary.NOV[0].kwh)/1000).toFixed(0),
                    ((firstGlazing.data.electricityEndUseSummary.DEC[4].kwh +
                    firstGlazing.data.electricityEndUseSummary.DEC[5].kwh +
                    firstGlazing.data.electricityEndUseSummary.DEC[8].kwh +
                    firstGlazing.data.electricityEndUseSummary.DEC[3].kwh +
                    firstGlazing.data.electricityEndUseSummary.DEC[9].kwh +
                    firstGlazing.data.electricityEndUseSummary.DEC[10].kwh +
                    firstGlazing.data.electricityEndUseSummary.DEC[7].kwh +
                    firstGlazing.data.electricityEndUseSummary.DEC[6].kwh +
                    firstGlazing.data.electricityEndUseSummary.DEC[11].kwh +
                    firstGlazing.data.electricityEndUseSummary.DEC[2].kwh +
                    firstGlazing.data.electricityEndUseSummary.DEC[1].kwh +
                    firstGlazing.data.electricityEndUseSummary.DEC[0].kwh)/1000).toFixed(0),
                    0,
                ],
            ],
        },
        secondElectricTableSummary : {
            data: [
                [
                    "Space Cooling",
                    (secondGlazing.data.electricityEndUseSummary.JAN[4].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.FEB[4].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.MAR[4].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.APR[4].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.MAY[4].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.JUN[4].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.JUL[4].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.AUG[4].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.SEP[4].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.OCT[4].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.NOV[4].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.DEC[4].kwh/1000).toFixed(0),
                    ((secondGlazing.data.electricityEndUseSummary.JAN[4].kwh +
                        secondGlazing.data.electricityEndUseSummary.FEB[4].kwh +
                        secondGlazing.data.electricityEndUseSummary.MAR[4].kwh +
                        secondGlazing.data.electricityEndUseSummary.APR[4].kwh +
                        secondGlazing.data.electricityEndUseSummary.MAY[4].kwh +
                        secondGlazing.data.electricityEndUseSummary.JUN[4].kwh +
                        secondGlazing.data.electricityEndUseSummary.JUL[4].kwh +
                        secondGlazing.data.electricityEndUseSummary.AUG[4].kwh +
                        secondGlazing.data.electricityEndUseSummary.SEP[4].kwh +
                        secondGlazing.data.electricityEndUseSummary.OCT[4].kwh +
                        secondGlazing.data.electricityEndUseSummary.NOV[4].kwh +
                        secondGlazing.data.electricityEndUseSummary.DEC[4].kwh)/1000).toFixed(0),
                ],
                [
                    "Heat Rejection",
                    (secondGlazing.data.electricityEndUseSummary.JAN[5].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.FEB[5].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.MAR[5].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.APR[5].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.MAY[5].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.JUN[5].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.JUL[5].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.AUG[5].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.SEP[5].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.OCT[5].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.NOV[5].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.DEC[5].kwh/1000).toFixed(0),
                    ((secondGlazing.data.electricityEndUseSummary.JAN[5].kwh +
                        secondGlazing.data.electricityEndUseSummary.FEB[5].kwh +
                        secondGlazing.data.electricityEndUseSummary.MAR[5].kwh +
                        secondGlazing.data.electricityEndUseSummary.APR[5].kwh +
                        secondGlazing.data.electricityEndUseSummary.MAY[5].kwh +
                        secondGlazing.data.electricityEndUseSummary.JUN[5].kwh +
                        secondGlazing.data.electricityEndUseSummary.JUL[5].kwh +
                        secondGlazing.data.electricityEndUseSummary.AUG[5].kwh +
                        secondGlazing.data.electricityEndUseSummary.SEP[5].kwh +
                        secondGlazing.data.electricityEndUseSummary.OCT[5].kwh +
                        secondGlazing.data.electricityEndUseSummary.NOV[5].kwh +
                        secondGlazing.data.electricityEndUseSummary.DEC[5].kwh)/1000).toFixed(0),
                ],
                [
                    "Refridgeration",
                    (secondGlazing.data.electricityEndUseSummary.JAN[8].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.FEB[8].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.MAR[8].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.APR[8].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.MAY[8].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.JUN[8].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.JUL[8].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.AUG[8].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.SEP[8].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.OCT[8].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.NOV[8].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.DEC[8].kwh/1000).toFixed(0),
                    ((secondGlazing.data.electricityEndUseSummary.JAN[8].kwh +
                        secondGlazing.data.electricityEndUseSummary.FEB[8].kwh +
                        secondGlazing.data.electricityEndUseSummary.MAR[8].kwh +
                        secondGlazing.data.electricityEndUseSummary.APR[8].kwh +
                        secondGlazing.data.electricityEndUseSummary.MAY[8].kwh +
                        secondGlazing.data.electricityEndUseSummary.JUN[8].kwh +
                        secondGlazing.data.electricityEndUseSummary.JUL[8].kwh +
                        secondGlazing.data.electricityEndUseSummary.AUG[8].kwh +
                        secondGlazing.data.electricityEndUseSummary.SEP[8].kwh +
                        secondGlazing.data.electricityEndUseSummary.OCT[8].kwh +
                        secondGlazing.data.electricityEndUseSummary.NOV[8].kwh +
                        secondGlazing.data.electricityEndUseSummary.DEC[8].kwh)/1000).toFixed(0),
                ],
                [
                    "Space Heat",
                    (secondGlazing.data.electricityEndUseSummary.JAN[3].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.FEB[3].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.MAR[3].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.APR[3].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.MAY[3].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.JUN[3].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.JUL[3].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.AUG[3].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.SEP[3].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.OCT[3].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.NOV[3].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.DEC[3].kwh/1000).toFixed(0),
                    ((secondGlazing.data.electricityEndUseSummary.JAN[3].kwh +
                        secondGlazing.data.electricityEndUseSummary.FEB[3].kwh +
                        secondGlazing.data.electricityEndUseSummary.MAR[3].kwh +
                        secondGlazing.data.electricityEndUseSummary.APR[3].kwh +
                        secondGlazing.data.electricityEndUseSummary.MAY[3].kwh +
                        secondGlazing.data.electricityEndUseSummary.JUN[3].kwh +
                        secondGlazing.data.electricityEndUseSummary.JUL[3].kwh +
                        secondGlazing.data.electricityEndUseSummary.AUG[3].kwh +
                        secondGlazing.data.electricityEndUseSummary.SEP[3].kwh +
                        secondGlazing.data.electricityEndUseSummary.OCT[3].kwh +
                        secondGlazing.data.electricityEndUseSummary.NOV[3].kwh +
                        secondGlazing.data.electricityEndUseSummary.DEC[3].kwh)/1000).toFixed(0),
                ],
                [
                    "Hp Supp.",
                    (secondGlazing.data.electricityEndUseSummary.JAN[9].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.FEB[9].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.MAR[9].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.APR[9].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.MAY[9].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.JUN[9].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.JUL[9].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.AUG[9].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.SEP[9].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.OCT[9].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.NOV[9].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.DEC[9].kwh/1000).toFixed(0),
                    ((secondGlazing.data.electricityEndUseSummary.JAN[9].kwh +
                        secondGlazing.data.electricityEndUseSummary.FEB[9].kwh +
                        secondGlazing.data.electricityEndUseSummary.MAR[9].kwh +
                        secondGlazing.data.electricityEndUseSummary.APR[9].kwh +
                        secondGlazing.data.electricityEndUseSummary.MAY[9].kwh +
                        secondGlazing.data.electricityEndUseSummary.JUN[9].kwh +
                        secondGlazing.data.electricityEndUseSummary.JUL[9].kwh +
                        secondGlazing.data.electricityEndUseSummary.AUG[9].kwh +
                        secondGlazing.data.electricityEndUseSummary.SEP[9].kwh +
                        secondGlazing.data.electricityEndUseSummary.OCT[9].kwh +
                        secondGlazing.data.electricityEndUseSummary.NOV[9].kwh +
                        secondGlazing.data.electricityEndUseSummary.DEC[9].kwh)/1000).toFixed(0),
                ],
                [
                    "Hot Water",
                    (secondGlazing.data.electricityEndUseSummary.JAN[10].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.FEB[10].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.MAR[10].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.APR[10].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.MAY[10].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.JUN[10].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.JUL[10].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.AUG[10].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.SEP[10].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.OCT[10].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.NOV[10].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.DEC[10].kwh/1000).toFixed(0),
                    ((secondGlazing.data.electricityEndUseSummary.JAN[10].kwh +
                        secondGlazing.data.electricityEndUseSummary.FEB[10].kwh +
                        secondGlazing.data.electricityEndUseSummary.MAR[10].kwh +
                        secondGlazing.data.electricityEndUseSummary.APR[10].kwh +
                        secondGlazing.data.electricityEndUseSummary.MAY[10].kwh +
                        secondGlazing.data.electricityEndUseSummary.JUN[10].kwh +
                        secondGlazing.data.electricityEndUseSummary.JUL[10].kwh +
                        secondGlazing.data.electricityEndUseSummary.AUG[10].kwh +
                        secondGlazing.data.electricityEndUseSummary.SEP[10].kwh +
                        secondGlazing.data.electricityEndUseSummary.OCT[10].kwh +
                        secondGlazing.data.electricityEndUseSummary.NOV[10].kwh +
                        secondGlazing.data.electricityEndUseSummary.DEC[10].kwh)/1000).toFixed(0),
                ],
                [
                    "Vent. Fans",
                    (secondGlazing.data.electricityEndUseSummary.JAN[7].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.FEB[7].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.MAR[7].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.APR[7].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.MAY[7].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.JUN[7].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.JUL[7].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.AUG[7].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.SEP[7].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.OCT[7].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.NOV[7].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.DEC[7].kwh/1000).toFixed(0),
                    ((secondGlazing.data.electricityEndUseSummary.JAN[7].kwh +
                        secondGlazing.data.electricityEndUseSummary.FEB[7].kwh +
                        secondGlazing.data.electricityEndUseSummary.MAR[7].kwh +
                        secondGlazing.data.electricityEndUseSummary.APR[7].kwh +
                        secondGlazing.data.electricityEndUseSummary.MAY[7].kwh +
                        secondGlazing.data.electricityEndUseSummary.JUN[7].kwh +
                        secondGlazing.data.electricityEndUseSummary.JUL[7].kwh +
                        secondGlazing.data.electricityEndUseSummary.AUG[7].kwh +
                        secondGlazing.data.electricityEndUseSummary.SEP[7].kwh +
                        secondGlazing.data.electricityEndUseSummary.OCT[7].kwh +
                        secondGlazing.data.electricityEndUseSummary.NOV[7].kwh +
                        secondGlazing.data.electricityEndUseSummary.DEC[7].kwh)/1000).toFixed(0),
                ],
                [
                    "Pumps and Aux.",
                    (secondGlazing.data.electricityEndUseSummary.JAN[6].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.FEB[6].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.MAR[6].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.APR[6].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.MAY[6].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.JUN[6].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.JUL[6].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.AUG[6].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.SEP[6].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.OCT[6].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.NOV[6].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.DEC[6].kwh/1000).toFixed(0),
                    ((secondGlazing.data.electricityEndUseSummary.JAN[6].kwh +
                        secondGlazing.data.electricityEndUseSummary.FEB[6].kwh +
                        secondGlazing.data.electricityEndUseSummary.MAR[6].kwh +
                        secondGlazing.data.electricityEndUseSummary.APR[6].kwh +
                        secondGlazing.data.electricityEndUseSummary.MAY[6].kwh +
                        secondGlazing.data.electricityEndUseSummary.JUN[6].kwh +
                        secondGlazing.data.electricityEndUseSummary.JUL[6].kwh +
                        secondGlazing.data.electricityEndUseSummary.AUG[6].kwh +
                        secondGlazing.data.electricityEndUseSummary.SEP[6].kwh +
                        secondGlazing.data.electricityEndUseSummary.OCT[6].kwh +
                        secondGlazing.data.electricityEndUseSummary.NOV[6].kwh +
                        secondGlazing.data.electricityEndUseSummary.DEC[6].kwh)/1000).toFixed(0),
                ],
                [
                    "Ext. Usage",
                    (secondGlazing.data.electricityEndUseSummary.JAN[11].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.FEB[11].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.MAR[11].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.APR[11].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.MAY[11].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.JUN[11].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.JUL[11].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.AUG[11].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.SEP[11].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.OCT[11].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.NOV[11].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.DEC[11].kwh/1000).toFixed(0),
                    ((secondGlazing.data.electricityEndUseSummary.JAN[11].kwh +
                        secondGlazing.data.electricityEndUseSummary.FEB[11].kwh +
                        secondGlazing.data.electricityEndUseSummary.MAR[11].kwh +
                        secondGlazing.data.electricityEndUseSummary.APR[11].kwh +
                        secondGlazing.data.electricityEndUseSummary.MAY[11].kwh +
                        secondGlazing.data.electricityEndUseSummary.JUN[11].kwh +
                        secondGlazing.data.electricityEndUseSummary.JUL[11].kwh +
                        secondGlazing.data.electricityEndUseSummary.AUG[11].kwh +
                        secondGlazing.data.electricityEndUseSummary.SEP[11].kwh +
                        secondGlazing.data.electricityEndUseSummary.OCT[11].kwh +
                        secondGlazing.data.electricityEndUseSummary.NOV[11].kwh +
                        secondGlazing.data.electricityEndUseSummary.DEC[11].kwh)/1000).toFixed(0),
                ],
                [
                    "Misc. Equip.",
                    (secondGlazing.data.electricityEndUseSummary.JAN[2].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.FEB[2].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.MAR[2].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.APR[2].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.MAY[2].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.JUN[2].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.JUL[2].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.AUG[2].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.SEP[2].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.OCT[2].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.NOV[2].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.DEC[2].kwh/1000).toFixed(0),
                    ((secondGlazing.data.electricityEndUseSummary.JAN[2].kwh +
                        secondGlazing.data.electricityEndUseSummary.FEB[2].kwh +
                        secondGlazing.data.electricityEndUseSummary.MAR[2].kwh +
                        secondGlazing.data.electricityEndUseSummary.APR[2].kwh +
                        secondGlazing.data.electricityEndUseSummary.MAY[2].kwh +
                        secondGlazing.data.electricityEndUseSummary.JUN[2].kwh +
                        secondGlazing.data.electricityEndUseSummary.JUL[2].kwh +
                        secondGlazing.data.electricityEndUseSummary.AUG[2].kwh +
                        secondGlazing.data.electricityEndUseSummary.SEP[2].kwh +
                        secondGlazing.data.electricityEndUseSummary.OCT[2].kwh +
                        secondGlazing.data.electricityEndUseSummary.NOV[2].kwh +
                        secondGlazing.data.electricityEndUseSummary.DEC[2].kwh)/1000).toFixed(0),
                ],
                [
                    "Task Lights",
                    (secondGlazing.data.electricityEndUseSummary.JAN[1].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.FEB[1].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.MAR[1].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.APR[1].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.MAY[1].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.JUN[1].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.JUL[1].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.AUG[1].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.SEP[1].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.OCT[1].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.NOV[1].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.DEC[1].kwh/1000).toFixed(0),
                    ((secondGlazing.data.electricityEndUseSummary.JAN[1].kwh +
                        secondGlazing.data.electricityEndUseSummary.FEB[1].kwh +
                        secondGlazing.data.electricityEndUseSummary.MAR[1].kwh +
                        secondGlazing.data.electricityEndUseSummary.APR[1].kwh +
                        secondGlazing.data.electricityEndUseSummary.MAY[1].kwh +
                        secondGlazing.data.electricityEndUseSummary.JUN[1].kwh +
                        secondGlazing.data.electricityEndUseSummary.JUL[1].kwh +
                        secondGlazing.data.electricityEndUseSummary.AUG[1].kwh +
                        secondGlazing.data.electricityEndUseSummary.SEP[1].kwh +
                        secondGlazing.data.electricityEndUseSummary.OCT[1].kwh +
                        secondGlazing.data.electricityEndUseSummary.NOV[1].kwh +
                        secondGlazing.data.electricityEndUseSummary.DEC[1].kwh)/1000).toFixed(0),
                ],
                [
                    "Area Lights",
                    (secondGlazing.data.electricityEndUseSummary.JAN[0].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.FEB[0].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.MAR[0].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.APR[0].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.MAY[0].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.JUN[0].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.JUL[0].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.AUG[0].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.SEP[0].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.OCT[0].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.NOV[0].kwh/1000).toFixed(0),
                    (secondGlazing.data.electricityEndUseSummary.DEC[0].kwh/1000).toFixed(0),
                    ((secondGlazing.data.electricityEndUseSummary.JAN[0].kwh +
                        secondGlazing.data.electricityEndUseSummary.FEB[0].kwh +
                        secondGlazing.data.electricityEndUseSummary.MAR[0].kwh +
                        secondGlazing.data.electricityEndUseSummary.APR[0].kwh +
                        secondGlazing.data.electricityEndUseSummary.MAY[0].kwh +
                        secondGlazing.data.electricityEndUseSummary.JUN[0].kwh +
                        secondGlazing.data.electricityEndUseSummary.JUL[0].kwh +
                        secondGlazing.data.electricityEndUseSummary.AUG[0].kwh +
                        secondGlazing.data.electricityEndUseSummary.SEP[0].kwh +
                        secondGlazing.data.electricityEndUseSummary.OCT[0].kwh +
                        secondGlazing.data.electricityEndUseSummary.NOV[0].kwh +
                        secondGlazing.data.electricityEndUseSummary.DEC[0].kwh)/1000).toFixed(0),
                ],
                [
                    "Total",
                    ((secondGlazing.data.electricityEndUseSummary.JAN[4].kwh +
                        secondGlazing.data.electricityEndUseSummary.JAN[5].kwh +
                        secondGlazing.data.electricityEndUseSummary.JAN[8].kwh +
                        secondGlazing.data.electricityEndUseSummary.JAN[3].kwh +
                        secondGlazing.data.electricityEndUseSummary.JAN[9].kwh +
                        secondGlazing.data.electricityEndUseSummary.JAN[10].kwh +
                        secondGlazing.data.electricityEndUseSummary.JAN[7].kwh +
                        secondGlazing.data.electricityEndUseSummary.JAN[6].kwh +
                        secondGlazing.data.electricityEndUseSummary.JAN[11].kwh +
                        secondGlazing.data.electricityEndUseSummary.JAN[2].kwh +
                        secondGlazing.data.electricityEndUseSummary.JAN[1].kwh +
                        secondGlazing.data.electricityEndUseSummary.JAN[0].kwh)/1000).toFixed(0),
                    ((secondGlazing.data.electricityEndUseSummary.FEB[4].kwh +
                        secondGlazing.data.electricityEndUseSummary.FEB[5].kwh +
                        secondGlazing.data.electricityEndUseSummary.FEB[8].kwh +
                        secondGlazing.data.electricityEndUseSummary.FEB[3].kwh +
                        secondGlazing.data.electricityEndUseSummary.FEB[9].kwh +
                        secondGlazing.data.electricityEndUseSummary.FEB[10].kwh +
                        secondGlazing.data.electricityEndUseSummary.FEB[7].kwh +
                        secondGlazing.data.electricityEndUseSummary.FEB[6].kwh +
                        secondGlazing.data.electricityEndUseSummary.FEB[11].kwh +
                        secondGlazing.data.electricityEndUseSummary.FEB[2].kwh +
                        secondGlazing.data.electricityEndUseSummary.FEB[1].kwh +
                        secondGlazing.data.electricityEndUseSummary.FEB[0].kwh)/1000).toFixed(0),
                    ((secondGlazing.data.electricityEndUseSummary.MAR[4].kwh +
                        secondGlazing.data.electricityEndUseSummary.MAR[5].kwh +
                        secondGlazing.data.electricityEndUseSummary.MAR[8].kwh +
                        secondGlazing.data.electricityEndUseSummary.MAR[3].kwh +
                        secondGlazing.data.electricityEndUseSummary.MAR[9].kwh +
                        secondGlazing.data.electricityEndUseSummary.MAR[10].kwh +
                        secondGlazing.data.electricityEndUseSummary.MAR[7].kwh +
                        secondGlazing.data.electricityEndUseSummary.MAR[6].kwh +
                        secondGlazing.data.electricityEndUseSummary.MAR[11].kwh +
                        secondGlazing.data.electricityEndUseSummary.MAR[2].kwh +
                        secondGlazing.data.electricityEndUseSummary.MAR[1].kwh +
                        secondGlazing.data.electricityEndUseSummary.MAR[0].kwh)/1000).toFixed(0),
                    ((secondGlazing.data.electricityEndUseSummary.APR[4].kwh +
                        secondGlazing.data.electricityEndUseSummary.APR[5].kwh +
                        secondGlazing.data.electricityEndUseSummary.APR[8].kwh +
                        secondGlazing.data.electricityEndUseSummary.APR[3].kwh +
                        secondGlazing.data.electricityEndUseSummary.APR[9].kwh +
                        secondGlazing.data.electricityEndUseSummary.APR[10].kwh +
                        secondGlazing.data.electricityEndUseSummary.APR[7].kwh +
                        secondGlazing.data.electricityEndUseSummary.APR[6].kwh +
                        secondGlazing.data.electricityEndUseSummary.APR[11].kwh +
                        secondGlazing.data.electricityEndUseSummary.APR[2].kwh +
                        secondGlazing.data.electricityEndUseSummary.APR[1].kwh +
                        secondGlazing.data.electricityEndUseSummary.APR[0].kwh)/1000).toFixed(0),
                    ((secondGlazing.data.electricityEndUseSummary.MAY[4].kwh +
                        secondGlazing.data.electricityEndUseSummary.MAY[5].kwh +
                        secondGlazing.data.electricityEndUseSummary.MAY[8].kwh +
                        secondGlazing.data.electricityEndUseSummary.MAY[3].kwh +
                        secondGlazing.data.electricityEndUseSummary.MAY[9].kwh +
                        secondGlazing.data.electricityEndUseSummary.MAY[10].kwh +
                        secondGlazing.data.electricityEndUseSummary.MAY[7].kwh +
                        secondGlazing.data.electricityEndUseSummary.MAY[6].kwh +
                        secondGlazing.data.electricityEndUseSummary.MAY[11].kwh +
                        secondGlazing.data.electricityEndUseSummary.MAY[2].kwh +
                        secondGlazing.data.electricityEndUseSummary.MAY[1].kwh +
                        secondGlazing.data.electricityEndUseSummary.MAY[0].kwh)/1000).toFixed(0),
                    ((secondGlazing.data.electricityEndUseSummary.JUN[4].kwh +
                        secondGlazing.data.electricityEndUseSummary.JUN[5].kwh +
                        secondGlazing.data.electricityEndUseSummary.JUN[8].kwh +
                        secondGlazing.data.electricityEndUseSummary.JUN[3].kwh +
                        secondGlazing.data.electricityEndUseSummary.JUN[9].kwh +
                        secondGlazing.data.electricityEndUseSummary.JUN[10].kwh +
                        secondGlazing.data.electricityEndUseSummary.JUN[7].kwh +
                        secondGlazing.data.electricityEndUseSummary.JUN[6].kwh +
                        secondGlazing.data.electricityEndUseSummary.JUN[11].kwh +
                        secondGlazing.data.electricityEndUseSummary.JUN[2].kwh +
                        secondGlazing.data.electricityEndUseSummary.JUN[1].kwh +
                        secondGlazing.data.electricityEndUseSummary.JUN[0].kwh)/1000).toFixed(0),
                    ((secondGlazing.data.electricityEndUseSummary.JUL[4].kwh +
                        secondGlazing.data.electricityEndUseSummary.JUL[5].kwh +
                        secondGlazing.data.electricityEndUseSummary.JUL[8].kwh +
                        secondGlazing.data.electricityEndUseSummary.JUL[3].kwh +
                        secondGlazing.data.electricityEndUseSummary.JUL[9].kwh +
                        secondGlazing.data.electricityEndUseSummary.JUL[10].kwh +
                        secondGlazing.data.electricityEndUseSummary.JUL[7].kwh +
                        secondGlazing.data.electricityEndUseSummary.JUL[6].kwh +
                        secondGlazing.data.electricityEndUseSummary.JUL[11].kwh +
                        secondGlazing.data.electricityEndUseSummary.JUL[2].kwh +
                        secondGlazing.data.electricityEndUseSummary.JUL[1].kwh +
                        secondGlazing.data.electricityEndUseSummary.JUL[0].kwh)/1000).toFixed(0),
                    ((secondGlazing.data.electricityEndUseSummary.AUG[4].kwh +
                        secondGlazing.data.electricityEndUseSummary.AUG[5].kwh +
                        secondGlazing.data.electricityEndUseSummary.AUG[8].kwh +
                        secondGlazing.data.electricityEndUseSummary.AUG[3].kwh +
                        secondGlazing.data.electricityEndUseSummary.AUG[9].kwh +
                        secondGlazing.data.electricityEndUseSummary.AUG[10].kwh +
                        secondGlazing.data.electricityEndUseSummary.AUG[7].kwh +
                        secondGlazing.data.electricityEndUseSummary.AUG[6].kwh +
                        secondGlazing.data.electricityEndUseSummary.AUG[11].kwh +
                        secondGlazing.data.electricityEndUseSummary.AUG[2].kwh +
                        secondGlazing.data.electricityEndUseSummary.AUG[1].kwh +
                        secondGlazing.data.electricityEndUseSummary.AUG[0].kwh)/1000).toFixed(0),
                    ((secondGlazing.data.electricityEndUseSummary.SEP[4].kwh +
                        secondGlazing.data.electricityEndUseSummary.SEP[5].kwh +
                        secondGlazing.data.electricityEndUseSummary.SEP[8].kwh +
                        secondGlazing.data.electricityEndUseSummary.SEP[3].kwh +
                        secondGlazing.data.electricityEndUseSummary.SEP[9].kwh +
                        secondGlazing.data.electricityEndUseSummary.SEP[10].kwh +
                        secondGlazing.data.electricityEndUseSummary.SEP[7].kwh +
                        secondGlazing.data.electricityEndUseSummary.SEP[6].kwh +
                        secondGlazing.data.electricityEndUseSummary.SEP[11].kwh +
                        secondGlazing.data.electricityEndUseSummary.SEP[2].kwh +
                        secondGlazing.data.electricityEndUseSummary.SEP[1].kwh +
                        secondGlazing.data.electricityEndUseSummary.SEP[0].kwh)/1000).toFixed(0),
                    ((secondGlazing.data.electricityEndUseSummary.OCT[4].kwh +
                        secondGlazing.data.electricityEndUseSummary.OCT[5].kwh +
                        secondGlazing.data.electricityEndUseSummary.OCT[8].kwh +
                        secondGlazing.data.electricityEndUseSummary.OCT[3].kwh +
                        secondGlazing.data.electricityEndUseSummary.OCT[9].kwh +
                        secondGlazing.data.electricityEndUseSummary.OCT[10].kwh +
                        secondGlazing.data.electricityEndUseSummary.OCT[7].kwh +
                        secondGlazing.data.electricityEndUseSummary.OCT[6].kwh +
                        secondGlazing.data.electricityEndUseSummary.OCT[11].kwh +
                        secondGlazing.data.electricityEndUseSummary.OCT[2].kwh +
                        secondGlazing.data.electricityEndUseSummary.OCT[1].kwh +
                        secondGlazing.data.electricityEndUseSummary.OCT[0].kwh)/1000).toFixed(0),
                    ((secondGlazing.data.electricityEndUseSummary.NOV[4].kwh +
                        secondGlazing.data.electricityEndUseSummary.NOV[5].kwh +
                        secondGlazing.data.electricityEndUseSummary.NOV[8].kwh +
                        secondGlazing.data.electricityEndUseSummary.NOV[3].kwh +
                        secondGlazing.data.electricityEndUseSummary.NOV[9].kwh +
                        secondGlazing.data.electricityEndUseSummary.NOV[10].kwh +
                        secondGlazing.data.electricityEndUseSummary.NOV[7].kwh +
                        secondGlazing.data.electricityEndUseSummary.NOV[6].kwh +
                        secondGlazing.data.electricityEndUseSummary.NOV[11].kwh +
                        secondGlazing.data.electricityEndUseSummary.NOV[2].kwh +
                        secondGlazing.data.electricityEndUseSummary.NOV[1].kwh +
                        secondGlazing.data.electricityEndUseSummary.NOV[0].kwh)/1000).toFixed(0),
                    ((secondGlazing.data.electricityEndUseSummary.DEC[4].kwh +
                        secondGlazing.data.electricityEndUseSummary.DEC[5].kwh +
                        secondGlazing.data.electricityEndUseSummary.DEC[8].kwh +
                        secondGlazing.data.electricityEndUseSummary.DEC[3].kwh +
                        secondGlazing.data.electricityEndUseSummary.DEC[9].kwh +
                        secondGlazing.data.electricityEndUseSummary.DEC[10].kwh +
                        secondGlazing.data.electricityEndUseSummary.DEC[7].kwh +
                        secondGlazing.data.electricityEndUseSummary.DEC[6].kwh +
                        secondGlazing.data.electricityEndUseSummary.DEC[11].kwh +
                        secondGlazing.data.electricityEndUseSummary.DEC[2].kwh +
                        secondGlazing.data.electricityEndUseSummary.DEC[1].kwh +
                        secondGlazing.data.electricityEndUseSummary.DEC[0].kwh)/1000).toFixed(0),
                    0,
                ],
            ],
        },
        firstGasTableSummary : {
            data: [
                [
                    "Space Cooling",
                    (firstGlazing.data.gasEndUseSummary.JAN[4].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.FEB[4].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.MAR[4].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.APR[4].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.MAY[4].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.JUN[4].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.JUL[4].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.AUG[4].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.SEP[4].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.OCT[4].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.NOV[4].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.DEC[4].mmBtu*1000).toFixed(0),
                    ((firstGlazing.data.gasEndUseSummary.JAN[4].mmBtu +
                        firstGlazing.data.gasEndUseSummary.FEB[4].mmBtu +
                        firstGlazing.data.gasEndUseSummary.MAR[4].mmBtu +
                        firstGlazing.data.gasEndUseSummary.APR[4].mmBtu +
                        firstGlazing.data.gasEndUseSummary.MAY[4].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JUN[4].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JUL[4].mmBtu +
                        firstGlazing.data.gasEndUseSummary.AUG[4].mmBtu +
                        firstGlazing.data.gasEndUseSummary.SEP[4].mmBtu +
                        firstGlazing.data.gasEndUseSummary.OCT[4].mmBtu +
                        firstGlazing.data.gasEndUseSummary.NOV[4].mmBtu +
                        firstGlazing.data.gasEndUseSummary.DEC[4].mmBtu)*1000).toFixed(0),
                ],
                [
                    "Heat Rejection",
                    (firstGlazing.data.gasEndUseSummary.JAN[5].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.FEB[5].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.MAR[5].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.APR[5].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.MAY[5].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.JUN[5].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.JUL[5].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.AUG[5].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.SEP[5].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.OCT[5].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.NOV[5].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.DEC[5].mmBtu*1000).toFixed(0),
                    ((firstGlazing.data.gasEndUseSummary.JAN[5].mmBtu +
                        firstGlazing.data.gasEndUseSummary.FEB[5].mmBtu +
                        firstGlazing.data.gasEndUseSummary.MAR[5].mmBtu +
                        firstGlazing.data.gasEndUseSummary.APR[5].mmBtu +
                        firstGlazing.data.gasEndUseSummary.MAY[5].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JUN[5].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JUL[5].mmBtu +
                        firstGlazing.data.gasEndUseSummary.AUG[5].mmBtu +
                        firstGlazing.data.gasEndUseSummary.SEP[5].mmBtu +
                        firstGlazing.data.gasEndUseSummary.OCT[5].mmBtu +
                        firstGlazing.data.gasEndUseSummary.NOV[5].mmBtu +
                        firstGlazing.data.gasEndUseSummary.DEC[5].mmBtu)*1000).toFixed(0),
                ],
                [
                    "Refridgeration",
                    (firstGlazing.data.gasEndUseSummary.JAN[8].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.FEB[8].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.MAR[8].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.APR[8].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.MAY[8].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.JUN[8].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.JUL[8].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.AUG[8].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.SEP[8].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.OCT[8].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.NOV[8].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.DEC[8].mmBtu*1000).toFixed(0),
                    ((firstGlazing.data.gasEndUseSummary.JAN[8].mmBtu +
                        firstGlazing.data.gasEndUseSummary.FEB[8].mmBtu +
                        firstGlazing.data.gasEndUseSummary.MAR[8].mmBtu +
                        firstGlazing.data.gasEndUseSummary.APR[8].mmBtu +
                        firstGlazing.data.gasEndUseSummary.MAY[8].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JUN[8].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JUL[8].mmBtu +
                        firstGlazing.data.gasEndUseSummary.AUG[8].mmBtu +
                        firstGlazing.data.gasEndUseSummary.SEP[8].mmBtu +
                        firstGlazing.data.gasEndUseSummary.OCT[8].mmBtu +
                        firstGlazing.data.gasEndUseSummary.NOV[8].mmBtu +
                        firstGlazing.data.gasEndUseSummary.DEC[8].mmBtu)*1000).toFixed(0),
                ],
                [
                    "Space Heat",
                    (firstGlazing.data.gasEndUseSummary.JAN[3].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.FEB[3].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.MAR[3].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.APR[3].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.MAY[3].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.JUN[3].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.JUL[3].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.AUG[3].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.SEP[3].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.OCT[3].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.NOV[3].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.DEC[3].mmBtu*1000).toFixed(0),
                    ((firstGlazing.data.gasEndUseSummary.JAN[3].mmBtu +
                        firstGlazing.data.gasEndUseSummary.FEB[3].mmBtu +
                        firstGlazing.data.gasEndUseSummary.MAR[3].mmBtu +
                        firstGlazing.data.gasEndUseSummary.APR[3].mmBtu +
                        firstGlazing.data.gasEndUseSummary.MAY[3].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JUN[3].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JUL[3].mmBtu +
                        firstGlazing.data.gasEndUseSummary.AUG[3].mmBtu +
                        firstGlazing.data.gasEndUseSummary.SEP[3].mmBtu +
                        firstGlazing.data.gasEndUseSummary.OCT[3].mmBtu +
                        firstGlazing.data.gasEndUseSummary.NOV[3].mmBtu +
                        firstGlazing.data.gasEndUseSummary.DEC[3].mmBtu)*1000).toFixed(0),
                ],
                [
                    "Hp Supp.",
                    (firstGlazing.data.gasEndUseSummary.JAN[9].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.FEB[9].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.MAR[9].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.APR[9].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.MAY[9].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.JUN[9].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.JUL[9].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.AUG[9].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.SEP[9].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.OCT[9].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.NOV[9].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.DEC[9].mmBtu*1000).toFixed(0),
                    ((firstGlazing.data.gasEndUseSummary.JAN[9].mmBtu +
                        firstGlazing.data.gasEndUseSummary.FEB[9].mmBtu +
                        firstGlazing.data.gasEndUseSummary.MAR[9].mmBtu +
                        firstGlazing.data.gasEndUseSummary.APR[9].mmBtu +
                        firstGlazing.data.gasEndUseSummary.MAY[9].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JUN[9].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JUL[9].mmBtu +
                        firstGlazing.data.gasEndUseSummary.AUG[9].mmBtu +
                        firstGlazing.data.gasEndUseSummary.SEP[9].mmBtu +
                        firstGlazing.data.gasEndUseSummary.OCT[9].mmBtu +
                        firstGlazing.data.gasEndUseSummary.NOV[9].mmBtu +
                        firstGlazing.data.gasEndUseSummary.DEC[9].mmBtu)*1000).toFixed(0),
                ],
                [
                    "Hot Water",
                    (firstGlazing.data.gasEndUseSummary.JAN[10].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.FEB[10].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.MAR[10].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.APR[10].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.MAY[10].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.JUN[10].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.JUL[10].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.AUG[10].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.SEP[10].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.OCT[10].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.NOV[10].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.DEC[10].mmBtu*1000).toFixed(0),
                    ((firstGlazing.data.gasEndUseSummary.JAN[10].mmBtu +
                        firstGlazing.data.gasEndUseSummary.FEB[10].mmBtu +
                        firstGlazing.data.gasEndUseSummary.MAR[10].mmBtu +
                        firstGlazing.data.gasEndUseSummary.APR[10].mmBtu +
                        firstGlazing.data.gasEndUseSummary.MAY[10].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JUN[10].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JUL[10].mmBtu +
                        firstGlazing.data.gasEndUseSummary.AUG[10].mmBtu +
                        firstGlazing.data.gasEndUseSummary.SEP[10].mmBtu +
                        firstGlazing.data.gasEndUseSummary.OCT[10].mmBtu +
                        firstGlazing.data.gasEndUseSummary.NOV[10].mmBtu +
                        firstGlazing.data.gasEndUseSummary.DEC[10].mmBtu)*1000).toFixed(0),
                ],
                [
                    "Vent. Fans",
                    (firstGlazing.data.gasEndUseSummary.JAN[7].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.FEB[7].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.MAR[7].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.APR[7].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.MAY[7].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.JUN[7].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.JUL[7].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.AUG[7].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.SEP[7].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.OCT[7].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.NOV[7].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.DEC[7].mmBtu*1000).toFixed(0),
                    ((firstGlazing.data.gasEndUseSummary.JAN[7].mmBtu +
                        firstGlazing.data.gasEndUseSummary.FEB[7].mmBtu +
                        firstGlazing.data.gasEndUseSummary.MAR[7].mmBtu +
                        firstGlazing.data.gasEndUseSummary.APR[7].mmBtu +
                        firstGlazing.data.gasEndUseSummary.MAY[7].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JUN[7].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JUL[7].mmBtu +
                        firstGlazing.data.gasEndUseSummary.AUG[7].mmBtu +
                        firstGlazing.data.gasEndUseSummary.SEP[7].mmBtu +
                        firstGlazing.data.gasEndUseSummary.OCT[7].mmBtu +
                        firstGlazing.data.gasEndUseSummary.NOV[7].mmBtu +
                        firstGlazing.data.gasEndUseSummary.DEC[7].mmBtu)*1000).toFixed(0),
                ],
                [
                    "Pumps and Aux.",
                    (firstGlazing.data.gasEndUseSummary.JAN[6].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.FEB[6].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.MAR[6].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.APR[6].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.MAY[6].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.JUN[6].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.JUL[6].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.AUG[6].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.SEP[6].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.OCT[6].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.NOV[6].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.DEC[6].mmBtu*1000).toFixed(0),
                    ((firstGlazing.data.gasEndUseSummary.JAN[6].mmBtu +
                        firstGlazing.data.gasEndUseSummary.FEB[6].mmBtu +
                        firstGlazing.data.gasEndUseSummary.MAR[6].mmBtu +
                        firstGlazing.data.gasEndUseSummary.APR[6].mmBtu +
                        firstGlazing.data.gasEndUseSummary.MAY[6].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JUN[6].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JUL[6].mmBtu +
                        firstGlazing.data.gasEndUseSummary.AUG[6].mmBtu +
                        firstGlazing.data.gasEndUseSummary.SEP[6].mmBtu +
                        firstGlazing.data.gasEndUseSummary.OCT[6].mmBtu +
                        firstGlazing.data.gasEndUseSummary.NOV[6].mmBtu +
                        firstGlazing.data.gasEndUseSummary.DEC[6].mmBtu)*1000).toFixed(0),
                ],
                [
                    "Ext. Usage",
                    (firstGlazing.data.gasEndUseSummary.JAN[11].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.FEB[11].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.MAR[11].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.APR[11].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.MAY[11].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.JUN[11].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.JUL[11].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.AUG[11].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.SEP[11].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.OCT[11].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.NOV[11].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.DEC[11].mmBtu*1000).toFixed(0),
                    ((firstGlazing.data.gasEndUseSummary.JAN[11].mmBtu +
                        firstGlazing.data.gasEndUseSummary.FEB[11].mmBtu +
                        firstGlazing.data.gasEndUseSummary.MAR[11].mmBtu +
                        firstGlazing.data.gasEndUseSummary.APR[11].mmBtu +
                        firstGlazing.data.gasEndUseSummary.MAY[11].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JUN[11].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JUL[11].mmBtu +
                        firstGlazing.data.gasEndUseSummary.AUG[11].mmBtu +
                        firstGlazing.data.gasEndUseSummary.SEP[11].mmBtu +
                        firstGlazing.data.gasEndUseSummary.OCT[11].mmBtu +
                        firstGlazing.data.gasEndUseSummary.NOV[11].mmBtu +
                        firstGlazing.data.gasEndUseSummary.DEC[11].mmBtu)*1000).toFixed(0),
                ],
                [
                    "Misc. Equip.",
                    (firstGlazing.data.gasEndUseSummary.JAN[2].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.FEB[2].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.MAR[2].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.APR[2].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.MAY[2].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.JUN[2].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.JUL[2].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.AUG[2].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.SEP[2].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.OCT[2].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.NOV[2].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.DEC[2].mmBtu*1000).toFixed(0),
                    ((firstGlazing.data.gasEndUseSummary.JAN[2].mmBtu +
                        firstGlazing.data.gasEndUseSummary.FEB[2].mmBtu +
                        firstGlazing.data.gasEndUseSummary.MAR[2].mmBtu +
                        firstGlazing.data.gasEndUseSummary.APR[2].mmBtu +
                        firstGlazing.data.gasEndUseSummary.MAY[2].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JUN[2].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JUL[2].mmBtu +
                        firstGlazing.data.gasEndUseSummary.AUG[2].mmBtu +
                        firstGlazing.data.gasEndUseSummary.SEP[2].mmBtu +
                        firstGlazing.data.gasEndUseSummary.OCT[2].mmBtu +
                        firstGlazing.data.gasEndUseSummary.NOV[2].mmBtu +
                        firstGlazing.data.gasEndUseSummary.DEC[2].mmBtu)*1000).toFixed(0),
                ],
                [
                    "Task Lights",
                    (firstGlazing.data.gasEndUseSummary.JAN[1].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.FEB[1].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.MAR[1].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.APR[1].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.MAY[1].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.JUN[1].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.JUL[1].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.AUG[1].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.SEP[1].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.OCT[1].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.NOV[1].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.DEC[1].mmBtu*1000).toFixed(0),
                    ((firstGlazing.data.gasEndUseSummary.JAN[1].mmBtu +
                        firstGlazing.data.gasEndUseSummary.FEB[1].mmBtu +
                        firstGlazing.data.gasEndUseSummary.MAR[1].mmBtu +
                        firstGlazing.data.gasEndUseSummary.APR[1].mmBtu +
                        firstGlazing.data.gasEndUseSummary.MAY[1].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JUN[1].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JUL[1].mmBtu +
                        firstGlazing.data.gasEndUseSummary.AUG[1].mmBtu +
                        firstGlazing.data.gasEndUseSummary.SEP[1].mmBtu +
                        firstGlazing.data.gasEndUseSummary.OCT[1].mmBtu +
                        firstGlazing.data.gasEndUseSummary.NOV[1].mmBtu +
                        firstGlazing.data.gasEndUseSummary.DEC[1].mmBtu)*1000).toFixed(0),
                ],
                [
                    "Area Lights",
                    (firstGlazing.data.gasEndUseSummary.JAN[0].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.FEB[0].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.MAR[0].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.APR[0].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.MAY[0].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.JUN[0].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.JUL[0].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.AUG[0].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.SEP[0].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.OCT[0].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.NOV[0].mmBtu*1000).toFixed(0),
                    (firstGlazing.data.gasEndUseSummary.DEC[0].mmBtu*1000).toFixed(0),
                    ((firstGlazing.data.gasEndUseSummary.JAN[0].mmBtu +
                        firstGlazing.data.gasEndUseSummary.FEB[0].mmBtu +
                        firstGlazing.data.gasEndUseSummary.MAR[0].mmBtu +
                        firstGlazing.data.gasEndUseSummary.APR[0].mmBtu +
                        firstGlazing.data.gasEndUseSummary.MAY[0].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JUN[0].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JUL[0].mmBtu +
                        firstGlazing.data.gasEndUseSummary.AUG[0].mmBtu +
                        firstGlazing.data.gasEndUseSummary.SEP[0].mmBtu +
                        firstGlazing.data.gasEndUseSummary.OCT[0].mmBtu +
                        firstGlazing.data.gasEndUseSummary.NOV[0].mmBtu +
                        firstGlazing.data.gasEndUseSummary.DEC[0].mmBtu)*1000).toFixed(0),
                ],
                [
                    "Total",
                    ((firstGlazing.data.gasEndUseSummary.JAN[4].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JAN[5].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JAN[8].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JAN[3].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JAN[9].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JAN[10].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JAN[7].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JAN[6].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JAN[11].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JAN[2].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JAN[1].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JAN[0].mmBtu)*1000).toFixed(0),
                    ((firstGlazing.data.gasEndUseSummary.FEB[4].mmBtu +
                        firstGlazing.data.gasEndUseSummary.FEB[5].mmBtu +
                        firstGlazing.data.gasEndUseSummary.FEB[8].mmBtu +
                        firstGlazing.data.gasEndUseSummary.FEB[3].mmBtu +
                        firstGlazing.data.gasEndUseSummary.FEB[9].mmBtu +
                        firstGlazing.data.gasEndUseSummary.FEB[10].mmBtu +
                        firstGlazing.data.gasEndUseSummary.FEB[7].mmBtu +
                        firstGlazing.data.gasEndUseSummary.FEB[6].mmBtu +
                        firstGlazing.data.gasEndUseSummary.FEB[11].mmBtu +
                        firstGlazing.data.gasEndUseSummary.FEB[2].mmBtu +
                        firstGlazing.data.gasEndUseSummary.FEB[1].mmBtu +
                        firstGlazing.data.gasEndUseSummary.FEB[0].mmBtu)*1000).toFixed(0),
                    ((firstGlazing.data.gasEndUseSummary.MAR[4].mmBtu +
                        firstGlazing.data.gasEndUseSummary.MAR[5].mmBtu +
                        firstGlazing.data.gasEndUseSummary.MAR[8].mmBtu +
                        firstGlazing.data.gasEndUseSummary.MAR[3].mmBtu +
                        firstGlazing.data.gasEndUseSummary.MAR[9].mmBtu +
                        firstGlazing.data.gasEndUseSummary.MAR[10].mmBtu +
                        firstGlazing.data.gasEndUseSummary.MAR[7].mmBtu +
                        firstGlazing.data.gasEndUseSummary.MAR[6].mmBtu +
                        firstGlazing.data.gasEndUseSummary.MAR[11].mmBtu +
                        firstGlazing.data.gasEndUseSummary.MAR[2].mmBtu +
                        firstGlazing.data.gasEndUseSummary.MAR[1].mmBtu +
                        firstGlazing.data.gasEndUseSummary.MAR[0].mmBtu)*1000).toFixed(0),
                    ((firstGlazing.data.gasEndUseSummary.APR[4].mmBtu +
                        firstGlazing.data.gasEndUseSummary.APR[5].mmBtu +
                        firstGlazing.data.gasEndUseSummary.APR[8].mmBtu +
                        firstGlazing.data.gasEndUseSummary.APR[3].mmBtu +
                        firstGlazing.data.gasEndUseSummary.APR[9].mmBtu +
                        firstGlazing.data.gasEndUseSummary.APR[10].mmBtu +
                        firstGlazing.data.gasEndUseSummary.APR[7].mmBtu +
                        firstGlazing.data.gasEndUseSummary.APR[6].mmBtu +
                        firstGlazing.data.gasEndUseSummary.APR[11].mmBtu +
                        firstGlazing.data.gasEndUseSummary.APR[2].mmBtu +
                        firstGlazing.data.gasEndUseSummary.APR[1].mmBtu +
                        firstGlazing.data.gasEndUseSummary.APR[0].mmBtu)*1000).toFixed(0),
                    ((firstGlazing.data.gasEndUseSummary.MAY[4].mmBtu +
                        firstGlazing.data.gasEndUseSummary.MAY[5].mmBtu +
                        firstGlazing.data.gasEndUseSummary.MAY[8].mmBtu +
                        firstGlazing.data.gasEndUseSummary.MAY[3].mmBtu +
                        firstGlazing.data.gasEndUseSummary.MAY[9].mmBtu +
                        firstGlazing.data.gasEndUseSummary.MAY[10].mmBtu +
                        firstGlazing.data.gasEndUseSummary.MAY[7].mmBtu +
                        firstGlazing.data.gasEndUseSummary.MAY[6].mmBtu +
                        firstGlazing.data.gasEndUseSummary.MAY[11].mmBtu +
                        firstGlazing.data.gasEndUseSummary.MAY[2].mmBtu +
                        firstGlazing.data.gasEndUseSummary.MAY[1].mmBtu +
                        firstGlazing.data.gasEndUseSummary.MAY[0].mmBtu)*1000).toFixed(0),
                    ((firstGlazing.data.gasEndUseSummary.JUN[4].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JUN[5].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JUN[8].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JUN[3].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JUN[9].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JUN[10].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JUN[7].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JUN[6].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JUN[11].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JUN[2].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JUN[1].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JUN[0].mmBtu)*1000).toFixed(0),
                    ((firstGlazing.data.gasEndUseSummary.JUL[4].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JUL[5].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JUL[8].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JUL[3].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JUL[9].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JUL[10].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JUL[7].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JUL[6].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JUL[11].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JUL[2].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JUL[1].mmBtu +
                        firstGlazing.data.gasEndUseSummary.JUL[0].mmBtu)*1000).toFixed(0),
                    ((firstGlazing.data.gasEndUseSummary.AUG[4].mmBtu +
                        firstGlazing.data.gasEndUseSummary.AUG[5].mmBtu +
                        firstGlazing.data.gasEndUseSummary.AUG[8].mmBtu +
                        firstGlazing.data.gasEndUseSummary.AUG[3].mmBtu +
                        firstGlazing.data.gasEndUseSummary.AUG[9].mmBtu +
                        firstGlazing.data.gasEndUseSummary.AUG[10].mmBtu +
                        firstGlazing.data.gasEndUseSummary.AUG[7].mmBtu +
                        firstGlazing.data.gasEndUseSummary.AUG[6].mmBtu +
                        firstGlazing.data.gasEndUseSummary.AUG[11].mmBtu +
                        firstGlazing.data.gasEndUseSummary.AUG[2].mmBtu +
                        firstGlazing.data.gasEndUseSummary.AUG[1].mmBtu +
                        firstGlazing.data.gasEndUseSummary.AUG[0].mmBtu)*1000).toFixed(0),
                    ((firstGlazing.data.gasEndUseSummary.SEP[4].mmBtu +
                        firstGlazing.data.gasEndUseSummary.SEP[5].mmBtu +
                        firstGlazing.data.gasEndUseSummary.SEP[8].mmBtu +
                        firstGlazing.data.gasEndUseSummary.SEP[3].mmBtu +
                        firstGlazing.data.gasEndUseSummary.SEP[9].mmBtu +
                        firstGlazing.data.gasEndUseSummary.SEP[10].mmBtu +
                        firstGlazing.data.gasEndUseSummary.SEP[7].mmBtu +
                        firstGlazing.data.gasEndUseSummary.SEP[6].mmBtu +
                        firstGlazing.data.gasEndUseSummary.SEP[11].mmBtu +
                        firstGlazing.data.gasEndUseSummary.SEP[2].mmBtu +
                        firstGlazing.data.gasEndUseSummary.SEP[1].mmBtu +
                        firstGlazing.data.gasEndUseSummary.SEP[0].mmBtu)*1000).toFixed(0),
                    ((firstGlazing.data.gasEndUseSummary.OCT[4].mmBtu +
                        firstGlazing.data.gasEndUseSummary.OCT[5].mmBtu +
                        firstGlazing.data.gasEndUseSummary.OCT[8].mmBtu +
                        firstGlazing.data.gasEndUseSummary.OCT[3].mmBtu +
                        firstGlazing.data.gasEndUseSummary.OCT[9].mmBtu +
                        firstGlazing.data.gasEndUseSummary.OCT[10].mmBtu +
                        firstGlazing.data.gasEndUseSummary.OCT[7].mmBtu +
                        firstGlazing.data.gasEndUseSummary.OCT[6].mmBtu +
                        firstGlazing.data.gasEndUseSummary.OCT[11].mmBtu +
                        firstGlazing.data.gasEndUseSummary.OCT[2].mmBtu +
                        firstGlazing.data.gasEndUseSummary.OCT[1].mmBtu +
                        firstGlazing.data.gasEndUseSummary.OCT[0].mmBtu)*1000).toFixed(0),
                    ((firstGlazing.data.gasEndUseSummary.NOV[4].mmBtu +
                        firstGlazing.data.gasEndUseSummary.NOV[5].mmBtu +
                        firstGlazing.data.gasEndUseSummary.NOV[8].mmBtu +
                        firstGlazing.data.gasEndUseSummary.NOV[3].mmBtu +
                        firstGlazing.data.gasEndUseSummary.NOV[9].mmBtu +
                        firstGlazing.data.gasEndUseSummary.NOV[10].mmBtu +
                        firstGlazing.data.gasEndUseSummary.NOV[7].mmBtu +
                        firstGlazing.data.gasEndUseSummary.NOV[6].mmBtu +
                        firstGlazing.data.gasEndUseSummary.NOV[11].mmBtu +
                        firstGlazing.data.gasEndUseSummary.NOV[2].mmBtu +
                        firstGlazing.data.gasEndUseSummary.NOV[1].mmBtu +
                        firstGlazing.data.gasEndUseSummary.NOV[0].mmBtu)*1000).toFixed(0),
                    ((firstGlazing.data.gasEndUseSummary.DEC[4].mmBtu +
                        firstGlazing.data.gasEndUseSummary.DEC[5].mmBtu +
                        firstGlazing.data.gasEndUseSummary.DEC[8].mmBtu +
                        firstGlazing.data.gasEndUseSummary.DEC[3].mmBtu +
                        firstGlazing.data.gasEndUseSummary.DEC[9].mmBtu +
                        firstGlazing.data.gasEndUseSummary.DEC[10].mmBtu +
                        firstGlazing.data.gasEndUseSummary.DEC[7].mmBtu +
                        firstGlazing.data.gasEndUseSummary.DEC[6].mmBtu +
                        firstGlazing.data.gasEndUseSummary.DEC[11].mmBtu +
                        firstGlazing.data.gasEndUseSummary.DEC[2].mmBtu +
                        firstGlazing.data.gasEndUseSummary.DEC[1].mmBtu +
                        firstGlazing.data.gasEndUseSummary.DEC[0].mmBtu)*1000).toFixed(0),
                    0,
                ],
            ],
        },
        secondGasTableSummary : {
            data: [
                [
                    "Space Cooling",
                    (secondGlazing.data.gasEndUseSummary.JAN[4].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.FEB[4].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.MAR[4].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.APR[4].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.MAY[4].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.JUN[4].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.JUL[4].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.AUG[4].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.SEP[4].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.OCT[4].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.NOV[4].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.DEC[4].mmBtu*1000).toFixed(0),
                    ((secondGlazing.data.gasEndUseSummary.JAN[4].mmBtu +
                        secondGlazing.data.gasEndUseSummary.FEB[4].mmBtu +
                        secondGlazing.data.gasEndUseSummary.MAR[4].mmBtu +
                        secondGlazing.data.gasEndUseSummary.APR[4].mmBtu +
                        secondGlazing.data.gasEndUseSummary.MAY[4].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JUN[4].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JUL[4].mmBtu +
                        secondGlazing.data.gasEndUseSummary.AUG[4].mmBtu +
                        secondGlazing.data.gasEndUseSummary.SEP[4].mmBtu +
                        secondGlazing.data.gasEndUseSummary.OCT[4].mmBtu +
                        secondGlazing.data.gasEndUseSummary.NOV[4].mmBtu +
                        secondGlazing.data.gasEndUseSummary.DEC[4].mmBtu)*1000).toFixed(0),
                ],
                [
                    "Heat Rejection",
                    (secondGlazing.data.gasEndUseSummary.JAN[5].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.FEB[5].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.MAR[5].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.APR[5].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.MAY[5].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.JUN[5].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.JUL[5].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.AUG[5].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.SEP[5].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.OCT[5].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.NOV[5].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.DEC[5].mmBtu*1000).toFixed(0),
                    ((secondGlazing.data.gasEndUseSummary.JAN[5].mmBtu +
                        secondGlazing.data.gasEndUseSummary.FEB[5].mmBtu +
                        secondGlazing.data.gasEndUseSummary.MAR[5].mmBtu +
                        secondGlazing.data.gasEndUseSummary.APR[5].mmBtu +
                        secondGlazing.data.gasEndUseSummary.MAY[5].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JUN[5].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JUL[5].mmBtu +
                        secondGlazing.data.gasEndUseSummary.AUG[5].mmBtu +
                        secondGlazing.data.gasEndUseSummary.SEP[5].mmBtu +
                        secondGlazing.data.gasEndUseSummary.OCT[5].mmBtu +
                        secondGlazing.data.gasEndUseSummary.NOV[5].mmBtu +
                        secondGlazing.data.gasEndUseSummary.DEC[5].mmBtu)*1000).toFixed(0),
                ],
                [
                    "Refridgeration",
                    (secondGlazing.data.gasEndUseSummary.JAN[8].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.FEB[8].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.MAR[8].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.APR[8].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.MAY[8].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.JUN[8].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.JUL[8].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.AUG[8].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.SEP[8].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.OCT[8].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.NOV[8].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.DEC[8].mmBtu*1000).toFixed(0),
                    ((secondGlazing.data.gasEndUseSummary.JAN[8].mmBtu +
                        secondGlazing.data.gasEndUseSummary.FEB[8].mmBtu +
                        secondGlazing.data.gasEndUseSummary.MAR[8].mmBtu +
                        secondGlazing.data.gasEndUseSummary.APR[8].mmBtu +
                        secondGlazing.data.gasEndUseSummary.MAY[8].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JUN[8].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JUL[8].mmBtu +
                        secondGlazing.data.gasEndUseSummary.AUG[8].mmBtu +
                        secondGlazing.data.gasEndUseSummary.SEP[8].mmBtu +
                        secondGlazing.data.gasEndUseSummary.OCT[8].mmBtu +
                        secondGlazing.data.gasEndUseSummary.NOV[8].mmBtu +
                        secondGlazing.data.gasEndUseSummary.DEC[8].mmBtu)*1000).toFixed(0),
                ],
                [
                    "Space Heat",
                    (secondGlazing.data.gasEndUseSummary.JAN[3].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.FEB[3].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.MAR[3].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.APR[3].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.MAY[3].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.JUN[3].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.JUL[3].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.AUG[3].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.SEP[3].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.OCT[3].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.NOV[3].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.DEC[3].mmBtu*1000).toFixed(0),
                    ((secondGlazing.data.gasEndUseSummary.JAN[3].mmBtu +
                        secondGlazing.data.gasEndUseSummary.FEB[3].mmBtu +
                        secondGlazing.data.gasEndUseSummary.MAR[3].mmBtu +
                        secondGlazing.data.gasEndUseSummary.APR[3].mmBtu +
                        secondGlazing.data.gasEndUseSummary.MAY[3].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JUN[3].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JUL[3].mmBtu +
                        secondGlazing.data.gasEndUseSummary.AUG[3].mmBtu +
                        secondGlazing.data.gasEndUseSummary.SEP[3].mmBtu +
                        secondGlazing.data.gasEndUseSummary.OCT[3].mmBtu +
                        secondGlazing.data.gasEndUseSummary.NOV[3].mmBtu +
                        secondGlazing.data.gasEndUseSummary.DEC[3].mmBtu)*1000).toFixed(0),
                ],
                [
                    "Hp Supp.",
                    (secondGlazing.data.gasEndUseSummary.JAN[9].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.FEB[9].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.MAR[9].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.APR[9].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.MAY[9].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.JUN[9].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.JUL[9].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.AUG[9].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.SEP[9].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.OCT[9].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.NOV[9].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.DEC[9].mmBtu*1000).toFixed(0),
                    ((secondGlazing.data.gasEndUseSummary.JAN[9].mmBtu +
                        secondGlazing.data.gasEndUseSummary.FEB[9].mmBtu +
                        secondGlazing.data.gasEndUseSummary.MAR[9].mmBtu +
                        secondGlazing.data.gasEndUseSummary.APR[9].mmBtu +
                        secondGlazing.data.gasEndUseSummary.MAY[9].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JUN[9].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JUL[9].mmBtu +
                        secondGlazing.data.gasEndUseSummary.AUG[9].mmBtu +
                        secondGlazing.data.gasEndUseSummary.SEP[9].mmBtu +
                        secondGlazing.data.gasEndUseSummary.OCT[9].mmBtu +
                        secondGlazing.data.gasEndUseSummary.NOV[9].mmBtu +
                        secondGlazing.data.gasEndUseSummary.DEC[9].mmBtu)*1000).toFixed(0),
                ],
                [
                    "Hot Water",
                    (secondGlazing.data.gasEndUseSummary.JAN[10].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.FEB[10].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.MAR[10].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.APR[10].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.MAY[10].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.JUN[10].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.JUL[10].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.AUG[10].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.SEP[10].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.OCT[10].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.NOV[10].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.DEC[10].mmBtu*1000).toFixed(0),
                    ((secondGlazing.data.gasEndUseSummary.JAN[10].mmBtu +
                        secondGlazing.data.gasEndUseSummary.FEB[10].mmBtu +
                        secondGlazing.data.gasEndUseSummary.MAR[10].mmBtu +
                        secondGlazing.data.gasEndUseSummary.APR[10].mmBtu +
                        secondGlazing.data.gasEndUseSummary.MAY[10].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JUN[10].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JUL[10].mmBtu +
                        secondGlazing.data.gasEndUseSummary.AUG[10].mmBtu +
                        secondGlazing.data.gasEndUseSummary.SEP[10].mmBtu +
                        secondGlazing.data.gasEndUseSummary.OCT[10].mmBtu +
                        secondGlazing.data.gasEndUseSummary.NOV[10].mmBtu +
                        secondGlazing.data.gasEndUseSummary.DEC[10].mmBtu)*1000).toFixed(0),
                ],
                [
                    "Vent. Fans",
                    (secondGlazing.data.gasEndUseSummary.JAN[7].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.FEB[7].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.MAR[7].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.APR[7].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.MAY[7].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.JUN[7].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.JUL[7].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.AUG[7].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.SEP[7].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.OCT[7].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.NOV[7].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.DEC[7].mmBtu*1000).toFixed(0),
                    ((secondGlazing.data.gasEndUseSummary.JAN[7].mmBtu +
                        secondGlazing.data.gasEndUseSummary.FEB[7].mmBtu +
                        secondGlazing.data.gasEndUseSummary.MAR[7].mmBtu +
                        secondGlazing.data.gasEndUseSummary.APR[7].mmBtu +
                        secondGlazing.data.gasEndUseSummary.MAY[7].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JUN[7].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JUL[7].mmBtu +
                        secondGlazing.data.gasEndUseSummary.AUG[7].mmBtu +
                        secondGlazing.data.gasEndUseSummary.SEP[7].mmBtu +
                        secondGlazing.data.gasEndUseSummary.OCT[7].mmBtu +
                        secondGlazing.data.gasEndUseSummary.NOV[7].mmBtu +
                        secondGlazing.data.gasEndUseSummary.DEC[7].mmBtu)*1000).toFixed(0),
                ],
                [
                    "Pumps and Aux.",
                    (secondGlazing.data.gasEndUseSummary.JAN[6].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.FEB[6].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.MAR[6].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.APR[6].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.MAY[6].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.JUN[6].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.JUL[6].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.AUG[6].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.SEP[6].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.OCT[6].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.NOV[6].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.DEC[6].mmBtu*1000).toFixed(0),
                    ((secondGlazing.data.gasEndUseSummary.JAN[6].mmBtu +
                        secondGlazing.data.gasEndUseSummary.FEB[6].mmBtu +
                        secondGlazing.data.gasEndUseSummary.MAR[6].mmBtu +
                        secondGlazing.data.gasEndUseSummary.APR[6].mmBtu +
                        secondGlazing.data.gasEndUseSummary.MAY[6].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JUN[6].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JUL[6].mmBtu +
                        secondGlazing.data.gasEndUseSummary.AUG[6].mmBtu +
                        secondGlazing.data.gasEndUseSummary.SEP[6].mmBtu +
                        secondGlazing.data.gasEndUseSummary.OCT[6].mmBtu +
                        secondGlazing.data.gasEndUseSummary.NOV[6].mmBtu +
                        secondGlazing.data.gasEndUseSummary.DEC[6].mmBtu)*1000).toFixed(0),
                ],
                [
                    "Ext. Usage",
                    (secondGlazing.data.gasEndUseSummary.JAN[11].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.FEB[11].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.MAR[11].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.APR[11].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.MAY[11].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.JUN[11].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.JUL[11].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.AUG[11].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.SEP[11].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.OCT[11].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.NOV[11].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.DEC[11].mmBtu*1000).toFixed(0),
                    ((secondGlazing.data.gasEndUseSummary.JAN[11].mmBtu +
                        secondGlazing.data.gasEndUseSummary.FEB[11].mmBtu +
                        secondGlazing.data.gasEndUseSummary.MAR[11].mmBtu +
                        secondGlazing.data.gasEndUseSummary.APR[11].mmBtu +
                        secondGlazing.data.gasEndUseSummary.MAY[11].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JUN[11].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JUL[11].mmBtu +
                        secondGlazing.data.gasEndUseSummary.AUG[11].mmBtu +
                        secondGlazing.data.gasEndUseSummary.SEP[11].mmBtu +
                        secondGlazing.data.gasEndUseSummary.OCT[11].mmBtu +
                        secondGlazing.data.gasEndUseSummary.NOV[11].mmBtu +
                        secondGlazing.data.gasEndUseSummary.DEC[11].mmBtu)*1000).toFixed(0),
                ],
                [
                    "Misc. Equip.",
                    (secondGlazing.data.gasEndUseSummary.JAN[2].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.FEB[2].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.MAR[2].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.APR[2].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.MAY[2].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.JUN[2].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.JUL[2].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.AUG[2].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.SEP[2].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.OCT[2].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.NOV[2].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.DEC[2].mmBtu*1000).toFixed(0),
                    ((secondGlazing.data.gasEndUseSummary.JAN[2].mmBtu +
                        secondGlazing.data.gasEndUseSummary.FEB[2].mmBtu +
                        secondGlazing.data.gasEndUseSummary.MAR[2].mmBtu +
                        secondGlazing.data.gasEndUseSummary.APR[2].mmBtu +
                        secondGlazing.data.gasEndUseSummary.MAY[2].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JUN[2].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JUL[2].mmBtu +
                        secondGlazing.data.gasEndUseSummary.AUG[2].mmBtu +
                        secondGlazing.data.gasEndUseSummary.SEP[2].mmBtu +
                        secondGlazing.data.gasEndUseSummary.OCT[2].mmBtu +
                        secondGlazing.data.gasEndUseSummary.NOV[2].mmBtu +
                        secondGlazing.data.gasEndUseSummary.DEC[2].mmBtu)*1000).toFixed(0),
                ],
                [
                    "Task Lights",
                    (secondGlazing.data.gasEndUseSummary.JAN[1].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.FEB[1].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.MAR[1].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.APR[1].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.MAY[1].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.JUN[1].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.JUL[1].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.AUG[1].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.SEP[1].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.OCT[1].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.NOV[1].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.DEC[1].mmBtu*1000).toFixed(0),
                    ((secondGlazing.data.gasEndUseSummary.JAN[1].mmBtu +
                        secondGlazing.data.gasEndUseSummary.FEB[1].mmBtu +
                        secondGlazing.data.gasEndUseSummary.MAR[1].mmBtu +
                        secondGlazing.data.gasEndUseSummary.APR[1].mmBtu +
                        secondGlazing.data.gasEndUseSummary.MAY[1].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JUN[1].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JUL[1].mmBtu +
                        secondGlazing.data.gasEndUseSummary.AUG[1].mmBtu +
                        secondGlazing.data.gasEndUseSummary.SEP[1].mmBtu +
                        secondGlazing.data.gasEndUseSummary.OCT[1].mmBtu +
                        secondGlazing.data.gasEndUseSummary.NOV[1].mmBtu +
                        secondGlazing.data.gasEndUseSummary.DEC[1].mmBtu)*1000).toFixed(0),
                ],
                [
                    "Area Lights",
                    (secondGlazing.data.gasEndUseSummary.JAN[0].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.FEB[0].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.MAR[0].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.APR[0].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.MAY[0].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.JUN[0].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.JUL[0].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.AUG[0].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.SEP[0].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.OCT[0].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.NOV[0].mmBtu*1000).toFixed(0),
                    (secondGlazing.data.gasEndUseSummary.DEC[0].mmBtu*1000).toFixed(0),
                    ((secondGlazing.data.gasEndUseSummary.JAN[0].mmBtu +
                        secondGlazing.data.gasEndUseSummary.FEB[0].mmBtu +
                        secondGlazing.data.gasEndUseSummary.MAR[0].mmBtu +
                        secondGlazing.data.gasEndUseSummary.APR[0].mmBtu +
                        secondGlazing.data.gasEndUseSummary.MAY[0].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JUN[0].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JUL[0].mmBtu +
                        secondGlazing.data.gasEndUseSummary.AUG[0].mmBtu +
                        secondGlazing.data.gasEndUseSummary.SEP[0].mmBtu +
                        secondGlazing.data.gasEndUseSummary.OCT[0].mmBtu +
                        secondGlazing.data.gasEndUseSummary.NOV[0].mmBtu +
                        secondGlazing.data.gasEndUseSummary.DEC[0].mmBtu)*1000).toFixed(0),
                ],
                [
                    "Total",
                    ((secondGlazing.data.gasEndUseSummary.JAN[4].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JAN[5].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JAN[8].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JAN[3].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JAN[9].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JAN[10].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JAN[7].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JAN[6].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JAN[11].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JAN[2].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JAN[1].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JAN[0].mmBtu)*1000).toFixed(0),
                    ((secondGlazing.data.gasEndUseSummary.FEB[4].mmBtu +
                        secondGlazing.data.gasEndUseSummary.FEB[5].mmBtu +
                        secondGlazing.data.gasEndUseSummary.FEB[8].mmBtu +
                        secondGlazing.data.gasEndUseSummary.FEB[3].mmBtu +
                        secondGlazing.data.gasEndUseSummary.FEB[9].mmBtu +
                        secondGlazing.data.gasEndUseSummary.FEB[10].mmBtu +
                        secondGlazing.data.gasEndUseSummary.FEB[7].mmBtu +
                        secondGlazing.data.gasEndUseSummary.FEB[6].mmBtu +
                        secondGlazing.data.gasEndUseSummary.FEB[11].mmBtu +
                        secondGlazing.data.gasEndUseSummary.FEB[2].mmBtu +
                        secondGlazing.data.gasEndUseSummary.FEB[1].mmBtu +
                        secondGlazing.data.gasEndUseSummary.FEB[0].mmBtu)*1000).toFixed(0),
                    ((secondGlazing.data.gasEndUseSummary.MAR[4].mmBtu +
                        secondGlazing.data.gasEndUseSummary.MAR[5].mmBtu +
                        secondGlazing.data.gasEndUseSummary.MAR[8].mmBtu +
                        secondGlazing.data.gasEndUseSummary.MAR[3].mmBtu +
                        secondGlazing.data.gasEndUseSummary.MAR[9].mmBtu +
                        secondGlazing.data.gasEndUseSummary.MAR[10].mmBtu +
                        secondGlazing.data.gasEndUseSummary.MAR[7].mmBtu +
                        secondGlazing.data.gasEndUseSummary.MAR[6].mmBtu +
                        secondGlazing.data.gasEndUseSummary.MAR[11].mmBtu +
                        secondGlazing.data.gasEndUseSummary.MAR[2].mmBtu +
                        secondGlazing.data.gasEndUseSummary.MAR[1].mmBtu +
                        secondGlazing.data.gasEndUseSummary.MAR[0].mmBtu)*1000).toFixed(0),
                    ((secondGlazing.data.gasEndUseSummary.APR[4].mmBtu +
                        secondGlazing.data.gasEndUseSummary.APR[5].mmBtu +
                        secondGlazing.data.gasEndUseSummary.APR[8].mmBtu +
                        secondGlazing.data.gasEndUseSummary.APR[3].mmBtu +
                        secondGlazing.data.gasEndUseSummary.APR[9].mmBtu +
                        secondGlazing.data.gasEndUseSummary.APR[10].mmBtu +
                        secondGlazing.data.gasEndUseSummary.APR[7].mmBtu +
                        secondGlazing.data.gasEndUseSummary.APR[6].mmBtu +
                        secondGlazing.data.gasEndUseSummary.APR[11].mmBtu +
                        secondGlazing.data.gasEndUseSummary.APR[2].mmBtu +
                        secondGlazing.data.gasEndUseSummary.APR[1].mmBtu +
                        secondGlazing.data.gasEndUseSummary.APR[0].mmBtu)*1000).toFixed(0),
                    ((secondGlazing.data.gasEndUseSummary.MAY[4].mmBtu +
                        secondGlazing.data.gasEndUseSummary.MAY[5].mmBtu +
                        secondGlazing.data.gasEndUseSummary.MAY[8].mmBtu +
                        secondGlazing.data.gasEndUseSummary.MAY[3].mmBtu +
                        secondGlazing.data.gasEndUseSummary.MAY[9].mmBtu +
                        secondGlazing.data.gasEndUseSummary.MAY[10].mmBtu +
                        secondGlazing.data.gasEndUseSummary.MAY[7].mmBtu +
                        secondGlazing.data.gasEndUseSummary.MAY[6].mmBtu +
                        secondGlazing.data.gasEndUseSummary.MAY[11].mmBtu +
                        secondGlazing.data.gasEndUseSummary.MAY[2].mmBtu +
                        secondGlazing.data.gasEndUseSummary.MAY[1].mmBtu +
                        secondGlazing.data.gasEndUseSummary.MAY[0].mmBtu)*1000).toFixed(0),
                    ((secondGlazing.data.gasEndUseSummary.JUN[4].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JUN[5].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JUN[8].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JUN[3].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JUN[9].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JUN[10].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JUN[7].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JUN[6].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JUN[11].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JUN[2].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JUN[1].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JUN[0].mmBtu)*1000).toFixed(0),
                    ((secondGlazing.data.gasEndUseSummary.JUL[4].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JUL[5].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JUL[8].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JUL[3].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JUL[9].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JUL[10].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JUL[7].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JUL[6].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JUL[11].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JUL[2].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JUL[1].mmBtu +
                        secondGlazing.data.gasEndUseSummary.JUL[0].mmBtu)*1000).toFixed(0),
                    ((secondGlazing.data.gasEndUseSummary.AUG[4].mmBtu +
                        secondGlazing.data.gasEndUseSummary.AUG[5].mmBtu +
                        secondGlazing.data.gasEndUseSummary.AUG[8].mmBtu +
                        secondGlazing.data.gasEndUseSummary.AUG[3].mmBtu +
                        secondGlazing.data.gasEndUseSummary.AUG[9].mmBtu +
                        secondGlazing.data.gasEndUseSummary.AUG[10].mmBtu +
                        secondGlazing.data.gasEndUseSummary.AUG[7].mmBtu +
                        secondGlazing.data.gasEndUseSummary.AUG[6].mmBtu +
                        secondGlazing.data.gasEndUseSummary.AUG[11].mmBtu +
                        secondGlazing.data.gasEndUseSummary.AUG[2].mmBtu +
                        secondGlazing.data.gasEndUseSummary.AUG[1].mmBtu +
                        secondGlazing.data.gasEndUseSummary.AUG[0].mmBtu)*1000).toFixed(0),
                    ((secondGlazing.data.gasEndUseSummary.SEP[4].mmBtu +
                        secondGlazing.data.gasEndUseSummary.SEP[5].mmBtu +
                        secondGlazing.data.gasEndUseSummary.SEP[8].mmBtu +
                        secondGlazing.data.gasEndUseSummary.SEP[3].mmBtu +
                        secondGlazing.data.gasEndUseSummary.SEP[9].mmBtu +
                        secondGlazing.data.gasEndUseSummary.SEP[10].mmBtu +
                        secondGlazing.data.gasEndUseSummary.SEP[7].mmBtu +
                        secondGlazing.data.gasEndUseSummary.SEP[6].mmBtu +
                        secondGlazing.data.gasEndUseSummary.SEP[11].mmBtu +
                        secondGlazing.data.gasEndUseSummary.SEP[2].mmBtu +
                        secondGlazing.data.gasEndUseSummary.SEP[1].mmBtu +
                        secondGlazing.data.gasEndUseSummary.SEP[0].mmBtu)*1000).toFixed(0),
                    ((secondGlazing.data.gasEndUseSummary.OCT[4].mmBtu +
                        secondGlazing.data.gasEndUseSummary.OCT[5].mmBtu +
                        secondGlazing.data.gasEndUseSummary.OCT[8].mmBtu +
                        secondGlazing.data.gasEndUseSummary.OCT[3].mmBtu +
                        secondGlazing.data.gasEndUseSummary.OCT[9].mmBtu +
                        secondGlazing.data.gasEndUseSummary.OCT[10].mmBtu +
                        secondGlazing.data.gasEndUseSummary.OCT[7].mmBtu +
                        secondGlazing.data.gasEndUseSummary.OCT[6].mmBtu +
                        secondGlazing.data.gasEndUseSummary.OCT[11].mmBtu +
                        secondGlazing.data.gasEndUseSummary.OCT[2].mmBtu +
                        secondGlazing.data.gasEndUseSummary.OCT[1].mmBtu +
                        secondGlazing.data.gasEndUseSummary.OCT[0].mmBtu)*1000).toFixed(0),
                    ((secondGlazing.data.gasEndUseSummary.NOV[4].mmBtu +
                        secondGlazing.data.gasEndUseSummary.NOV[5].mmBtu +
                        secondGlazing.data.gasEndUseSummary.NOV[8].mmBtu +
                        secondGlazing.data.gasEndUseSummary.NOV[3].mmBtu +
                        secondGlazing.data.gasEndUseSummary.NOV[9].mmBtu +
                        secondGlazing.data.gasEndUseSummary.NOV[10].mmBtu +
                        secondGlazing.data.gasEndUseSummary.NOV[7].mmBtu +
                        secondGlazing.data.gasEndUseSummary.NOV[6].mmBtu +
                        secondGlazing.data.gasEndUseSummary.NOV[11].mmBtu +
                        secondGlazing.data.gasEndUseSummary.NOV[2].mmBtu +
                        secondGlazing.data.gasEndUseSummary.NOV[1].mmBtu +
                        secondGlazing.data.gasEndUseSummary.NOV[0].mmBtu)*1000).toFixed(0),
                    ((secondGlazing.data.gasEndUseSummary.DEC[4].mmBtu +
                        secondGlazing.data.gasEndUseSummary.DEC[5].mmBtu +
                        secondGlazing.data.gasEndUseSummary.DEC[8].mmBtu +
                        secondGlazing.data.gasEndUseSummary.DEC[3].mmBtu +
                        secondGlazing.data.gasEndUseSummary.DEC[9].mmBtu +
                        secondGlazing.data.gasEndUseSummary.DEC[10].mmBtu +
                        secondGlazing.data.gasEndUseSummary.DEC[7].mmBtu +
                        secondGlazing.data.gasEndUseSummary.DEC[6].mmBtu +
                        secondGlazing.data.gasEndUseSummary.DEC[11].mmBtu +
                        secondGlazing.data.gasEndUseSummary.DEC[2].mmBtu +
                        secondGlazing.data.gasEndUseSummary.DEC[1].mmBtu +
                        secondGlazing.data.gasEndUseSummary.DEC[0].mmBtu)*1000).toFixed(0),
                    0,
                ],
            ],
        },
        first_chart_monthly_electric_consumption : [
            {
                name: 'Jan',
                sc: (firstGlazing.data.electricityEndUseSummary.JAN[4].kwh/1000).toFixed(0),
                hr: (firstGlazing.data.electricityEndUseSummary.JAN[5].kwh/1000).toFixed(0),
                re: (firstGlazing.data.electricityEndUseSummary.JAN[8].kwh/1000).toFixed(0),
                sh: (firstGlazing.data.electricityEndUseSummary.JAN[3].kwh/1000).toFixed(0),
                hp: (firstGlazing.data.electricityEndUseSummary.JAN[9].kwh/1000).toFixed(0),
                hw: (firstGlazing.data.electricityEndUseSummary.JAN[10].kwh/1000).toFixed(0),
                vf: (firstGlazing.data.electricityEndUseSummary.JAN[7].kwh/1000).toFixed(0),
                pa: (firstGlazing.data.electricityEndUseSummary.JAN[6].kwh/1000).toFixed(0),
                eu: (firstGlazing.data.electricityEndUseSummary.JAN[11].kwh/1000).toFixed(0),
                me: (firstGlazing.data.electricityEndUseSummary.JAN[2].kwh/1000).toFixed(0),
                tl: (firstGlazing.data.electricityEndUseSummary.JAN[1].kwh/1000).toFixed(0),
                al: (firstGlazing.data.electricityEndUseSummary.JAN[0].kwh/1000).toFixed(0),
            },
            {
                name: 'Feb',
                sc: (firstGlazing.data.electricityEndUseSummary.FEB[4].kwh/1000).toFixed(0),
                hr: (firstGlazing.data.electricityEndUseSummary.FEB[5].kwh/1000).toFixed(0),
                re: (firstGlazing.data.electricityEndUseSummary.FEB[8].kwh/1000).toFixed(0),
                sh: (firstGlazing.data.electricityEndUseSummary.FEB[3].kwh/1000).toFixed(0),
                hp: (firstGlazing.data.electricityEndUseSummary.FEB[9].kwh/1000).toFixed(0),
                hw: (firstGlazing.data.electricityEndUseSummary.FEB[10].kwh/1000).toFixed(0),
                vf: (firstGlazing.data.electricityEndUseSummary.FEB[7].kwh/1000).toFixed(0),
                pa: (firstGlazing.data.electricityEndUseSummary.FEB[6].kwh/1000).toFixed(0),
                eu: (firstGlazing.data.electricityEndUseSummary.FEB[11].kwh/1000).toFixed(0),
                me: (firstGlazing.data.electricityEndUseSummary.FEB[2].kwh/1000).toFixed(0),
                tl: (firstGlazing.data.electricityEndUseSummary.FEB[1].kwh/1000).toFixed(0),
                al: (firstGlazing.data.electricityEndUseSummary.FEB[0].kwh/1000).toFixed(0),
            },
            {
                name: 'Mar',
                sc: (firstGlazing.data.electricityEndUseSummary.MAR[4].kwh/1000).toFixed(0),
                hr: (firstGlazing.data.electricityEndUseSummary.MAR[5].kwh/1000).toFixed(0),
                re: (firstGlazing.data.electricityEndUseSummary.MAR[8].kwh/1000).toFixed(0),
                sh: (firstGlazing.data.electricityEndUseSummary.MAR[3].kwh/1000).toFixed(0),
                hp: (firstGlazing.data.electricityEndUseSummary.MAR[9].kwh/1000).toFixed(0),
                hw: (firstGlazing.data.electricityEndUseSummary.MAR[10].kwh/1000).toFixed(0),
                vf: (firstGlazing.data.electricityEndUseSummary.MAR[7].kwh/1000).toFixed(0),
                pa: (firstGlazing.data.electricityEndUseSummary.MAR[6].kwh/1000).toFixed(0),
                eu: (firstGlazing.data.electricityEndUseSummary.MAR[11].kwh/1000).toFixed(0),
                me: (firstGlazing.data.electricityEndUseSummary.MAR[2].kwh/1000).toFixed(0),
                tl: (firstGlazing.data.electricityEndUseSummary.MAR[1].kwh/1000).toFixed(0),
                al: (firstGlazing.data.electricityEndUseSummary.MAR[0].kwh/1000).toFixed(0),
            },
            {
                name: 'Apr',
                sc: (firstGlazing.data.electricityEndUseSummary.APR[4].kwh/1000).toFixed(0),
                hr: (firstGlazing.data.electricityEndUseSummary.APR[5].kwh/1000).toFixed(0),
                re: (firstGlazing.data.electricityEndUseSummary.APR[8].kwh/1000).toFixed(0),
                sh: (firstGlazing.data.electricityEndUseSummary.APR[3].kwh/1000).toFixed(0),
                hp: (firstGlazing.data.electricityEndUseSummary.APR[9].kwh/1000).toFixed(0),
                hw: (firstGlazing.data.electricityEndUseSummary.APR[10].kwh/1000).toFixed(0),
                vf: (firstGlazing.data.electricityEndUseSummary.APR[7].kwh/1000).toFixed(0),
                pa: (firstGlazing.data.electricityEndUseSummary.APR[6].kwh/1000).toFixed(0),
                eu: (firstGlazing.data.electricityEndUseSummary.APR[11].kwh/1000).toFixed(0),
                me: (firstGlazing.data.electricityEndUseSummary.APR[2].kwh/1000).toFixed(0),
                tl: (firstGlazing.data.electricityEndUseSummary.APR[1].kwh/1000).toFixed(0),
                al: (firstGlazing.data.electricityEndUseSummary.APR[0].kwh/1000).toFixed(0),
            },
            {
                name: 'May',
                sc: (firstGlazing.data.electricityEndUseSummary.MAY[4].kwh/1000).toFixed(0),
                hr: (firstGlazing.data.electricityEndUseSummary.MAY[5].kwh/1000).toFixed(0),
                re: (firstGlazing.data.electricityEndUseSummary.MAY[8].kwh/1000).toFixed(0),
                sh: (firstGlazing.data.electricityEndUseSummary.MAY[3].kwh/1000).toFixed(0),
                hp: (firstGlazing.data.electricityEndUseSummary.MAY[9].kwh/1000).toFixed(0),
                hw: (firstGlazing.data.electricityEndUseSummary.MAY[10].kwh/1000).toFixed(0),
                vf: (firstGlazing.data.electricityEndUseSummary.MAY[7].kwh/1000).toFixed(0),
                pa: (firstGlazing.data.electricityEndUseSummary.MAY[6].kwh/1000).toFixed(0),
                eu: (firstGlazing.data.electricityEndUseSummary.MAY[11].kwh/1000).toFixed(0),
                me: (firstGlazing.data.electricityEndUseSummary.MAY[2].kwh/1000).toFixed(0),
                tl: (firstGlazing.data.electricityEndUseSummary.MAY[1].kwh/1000).toFixed(0),
                al: (firstGlazing.data.electricityEndUseSummary.MAY[0].kwh/1000).toFixed(0),
            },
            {
                name: 'Jun',
                sc: (firstGlazing.data.electricityEndUseSummary.JUN[4].kwh/1000).toFixed(0),
                hr: (firstGlazing.data.electricityEndUseSummary.JUN[5].kwh/1000).toFixed(0),
                re: (firstGlazing.data.electricityEndUseSummary.JUN[8].kwh/1000).toFixed(0),
                sh: (firstGlazing.data.electricityEndUseSummary.JUN[3].kwh/1000).toFixed(0),
                hp: (firstGlazing.data.electricityEndUseSummary.JUN[9].kwh/1000).toFixed(0),
                hw: (firstGlazing.data.electricityEndUseSummary.JUN[10].kwh/1000).toFixed(0),
                vf: (firstGlazing.data.electricityEndUseSummary.JUN[7].kwh/1000).toFixed(0),
                pa: (firstGlazing.data.electricityEndUseSummary.JUN[6].kwh/1000).toFixed(0),
                eu: (firstGlazing.data.electricityEndUseSummary.JUN[11].kwh/1000).toFixed(0),
                me: (firstGlazing.data.electricityEndUseSummary.JUN[2].kwh/1000).toFixed(0),
                tl: (firstGlazing.data.electricityEndUseSummary.JUN[1].kwh/1000).toFixed(0),
                al: (firstGlazing.data.electricityEndUseSummary.JUN[0].kwh/1000).toFixed(0),
            },
            {
                name: 'Jul',
                sc: (firstGlazing.data.electricityEndUseSummary.JUL[4].kwh/1000).toFixed(0),
                hr: (firstGlazing.data.electricityEndUseSummary.JUL[5].kwh/1000).toFixed(0),
                re: (firstGlazing.data.electricityEndUseSummary.JUL[8].kwh/1000).toFixed(0),
                sh: (firstGlazing.data.electricityEndUseSummary.JUL[3].kwh/1000).toFixed(0),
                hp: (firstGlazing.data.electricityEndUseSummary.JUL[9].kwh/1000).toFixed(0),
                hw: (firstGlazing.data.electricityEndUseSummary.JUL[10].kwh/1000).toFixed(0),
                vf: (firstGlazing.data.electricityEndUseSummary.JUL[7].kwh/1000).toFixed(0),
                pa: (firstGlazing.data.electricityEndUseSummary.JUL[6].kwh/1000).toFixed(0),
                eu: (firstGlazing.data.electricityEndUseSummary.JUL[11].kwh/1000).toFixed(0),
                me: (firstGlazing.data.electricityEndUseSummary.JUL[2].kwh/1000).toFixed(0),
                tl: (firstGlazing.data.electricityEndUseSummary.JUL[1].kwh/1000).toFixed(0),
                al: (firstGlazing.data.electricityEndUseSummary.JUL[0].kwh/1000).toFixed(0),
            },
            {
                name: 'Aug',
                sc: (firstGlazing.data.electricityEndUseSummary.AUG[4].kwh/1000).toFixed(0),
                hr: (firstGlazing.data.electricityEndUseSummary.AUG[5].kwh/1000).toFixed(0),
                re: (firstGlazing.data.electricityEndUseSummary.AUG[8].kwh/1000).toFixed(0),
                sh: (firstGlazing.data.electricityEndUseSummary.AUG[3].kwh/1000).toFixed(0),
                hp: (firstGlazing.data.electricityEndUseSummary.AUG[9].kwh/1000).toFixed(0),
                hw: (firstGlazing.data.electricityEndUseSummary.AUG[10].kwh/1000).toFixed(0),
                vf: (firstGlazing.data.electricityEndUseSummary.AUG[7].kwh/1000).toFixed(0),
                pa: (firstGlazing.data.electricityEndUseSummary.AUG[6].kwh/1000).toFixed(0),
                eu: (firstGlazing.data.electricityEndUseSummary.AUG[11].kwh/1000).toFixed(0),
                me: (firstGlazing.data.electricityEndUseSummary.AUG[2].kwh/1000).toFixed(0),
                tl: (firstGlazing.data.electricityEndUseSummary.AUG[1].kwh/1000).toFixed(0),
                al: (firstGlazing.data.electricityEndUseSummary.AUG[0].kwh/1000).toFixed(0),
            },
            {
                name: 'Sep',
                sc: (firstGlazing.data.electricityEndUseSummary.SEP[4].kwh/1000).toFixed(0),
                hr: (firstGlazing.data.electricityEndUseSummary.SEP[5].kwh/1000).toFixed(0),
                re: (firstGlazing.data.electricityEndUseSummary.SEP[8].kwh/1000).toFixed(0),
                sh: (firstGlazing.data.electricityEndUseSummary.SEP[3].kwh/1000).toFixed(0),
                hp: (firstGlazing.data.electricityEndUseSummary.SEP[9].kwh/1000).toFixed(0),
                hw: (firstGlazing.data.electricityEndUseSummary.SEP[10].kwh/1000).toFixed(0),
                vf: (firstGlazing.data.electricityEndUseSummary.SEP[7].kwh/1000).toFixed(0),
                pa: (firstGlazing.data.electricityEndUseSummary.SEP[6].kwh/1000).toFixed(0),
                eu: (firstGlazing.data.electricityEndUseSummary.SEP[11].kwh/1000).toFixed(0),
                me: (firstGlazing.data.electricityEndUseSummary.SEP[2].kwh/1000).toFixed(0),
                tl: (firstGlazing.data.electricityEndUseSummary.SEP[1].kwh/1000).toFixed(0),
                al: (firstGlazing.data.electricityEndUseSummary.SEP[0].kwh/1000).toFixed(0),
            },
            {
                name: 'Oct',
                sc: (firstGlazing.data.electricityEndUseSummary.OCT[4].kwh/1000).toFixed(0),
                hr: (firstGlazing.data.electricityEndUseSummary.OCT[5].kwh/1000).toFixed(0),
                re: (firstGlazing.data.electricityEndUseSummary.OCT[8].kwh/1000).toFixed(0),
                sh: (firstGlazing.data.electricityEndUseSummary.OCT[3].kwh/1000).toFixed(0),
                hp: (firstGlazing.data.electricityEndUseSummary.OCT[9].kwh/1000).toFixed(0),
                hw: (firstGlazing.data.electricityEndUseSummary.OCT[10].kwh/1000).toFixed(0),
                vf: (firstGlazing.data.electricityEndUseSummary.OCT[7].kwh/1000).toFixed(0),
                pa: (firstGlazing.data.electricityEndUseSummary.OCT[6].kwh/1000).toFixed(0),
                eu: (firstGlazing.data.electricityEndUseSummary.OCT[11].kwh/1000).toFixed(0),
                me: (firstGlazing.data.electricityEndUseSummary.OCT[2].kwh/1000).toFixed(0),
                tl: (firstGlazing.data.electricityEndUseSummary.OCT[1].kwh/1000).toFixed(0),
                al: (firstGlazing.data.electricityEndUseSummary.OCT[0].kwh/1000).toFixed(0),
            },
            {
                name: 'Nov',
                sc: (firstGlazing.data.electricityEndUseSummary.NOV[4].kwh/1000).toFixed(0),
                hr: (firstGlazing.data.electricityEndUseSummary.NOV[5].kwh/1000).toFixed(0),
                re: (firstGlazing.data.electricityEndUseSummary.NOV[8].kwh/1000).toFixed(0),
                sh: (firstGlazing.data.electricityEndUseSummary.NOV[3].kwh/1000).toFixed(0),
                hp: (firstGlazing.data.electricityEndUseSummary.NOV[9].kwh/1000).toFixed(0),
                hw: (firstGlazing.data.electricityEndUseSummary.NOV[10].kwh/1000).toFixed(0),
                vf: (firstGlazing.data.electricityEndUseSummary.NOV[7].kwh/1000).toFixed(0),
                pa: (firstGlazing.data.electricityEndUseSummary.NOV[6].kwh/1000).toFixed(0),
                eu: (firstGlazing.data.electricityEndUseSummary.NOV[11].kwh/1000).toFixed(0),
                me: (firstGlazing.data.electricityEndUseSummary.NOV[2].kwh/1000).toFixed(0),
                tl: (firstGlazing.data.electricityEndUseSummary.NOV[1].kwh/1000).toFixed(0),
                al: (firstGlazing.data.electricityEndUseSummary.NOV[0].kwh/1000).toFixed(0),
            },
            {
                name: 'Dec',
                sc: (firstGlazing.data.electricityEndUseSummary.DEC[4].kwh/1000).toFixed(0),
                hr: (firstGlazing.data.electricityEndUseSummary.DEC[5].kwh/1000).toFixed(0),
                re: (firstGlazing.data.electricityEndUseSummary.DEC[8].kwh/1000).toFixed(0),
                sh: (firstGlazing.data.electricityEndUseSummary.DEC[3].kwh/1000).toFixed(0),
                hp: (firstGlazing.data.electricityEndUseSummary.DEC[9].kwh/1000).toFixed(0),
                hw: (firstGlazing.data.electricityEndUseSummary.DEC[10].kwh/1000).toFixed(0),
                vf: (firstGlazing.data.electricityEndUseSummary.DEC[7].kwh/1000).toFixed(0),
                pa: (firstGlazing.data.electricityEndUseSummary.DEC[6].kwh/1000).toFixed(0),
                eu: (firstGlazing.data.electricityEndUseSummary.DEC[11].kwh/1000).toFixed(0),
                me: (firstGlazing.data.electricityEndUseSummary.DEC[2].kwh/1000).toFixed(0),
                tl: (firstGlazing.data.electricityEndUseSummary.DEC[1].kwh/1000).toFixed(0),
                al: (firstGlazing.data.electricityEndUseSummary.DEC[0].kwh/1000).toFixed(0),
            },
        ],
        second_chart_monthly_electric_consumption : [
            {
                name: 'Jan',
                sc: (secondGlazing.data.electricityEndUseSummary.JAN[4].kwh/1000).toFixed(0),
                hr: (secondGlazing.data.electricityEndUseSummary.JAN[5].kwh/1000).toFixed(0),
                re: (secondGlazing.data.electricityEndUseSummary.JAN[8].kwh/1000).toFixed(0),
                sh: (secondGlazing.data.electricityEndUseSummary.JAN[3].kwh/1000).toFixed(0),
                hp: (secondGlazing.data.electricityEndUseSummary.JAN[9].kwh/1000).toFixed(0),
                hw: (secondGlazing.data.electricityEndUseSummary.JAN[10].kwh/1000).toFixed(0),
                vf: (secondGlazing.data.electricityEndUseSummary.JAN[7].kwh/1000).toFixed(0),
                pa: (secondGlazing.data.electricityEndUseSummary.JAN[6].kwh/1000).toFixed(0),
                eu: (secondGlazing.data.electricityEndUseSummary.JAN[11].kwh/1000).toFixed(0),
                me: (secondGlazing.data.electricityEndUseSummary.JAN[2].kwh/1000).toFixed(0),
                tl: (secondGlazing.data.electricityEndUseSummary.JAN[1].kwh/1000).toFixed(0),
                al: (secondGlazing.data.electricityEndUseSummary.JAN[0].kwh/1000).toFixed(0),
            },
            {
                name: 'Feb',
                sc: (secondGlazing.data.electricityEndUseSummary.FEB[4].kwh/1000).toFixed(0),
                hr: (secondGlazing.data.electricityEndUseSummary.FEB[5].kwh/1000).toFixed(0),
                re: (secondGlazing.data.electricityEndUseSummary.FEB[8].kwh/1000).toFixed(0),
                sh: (secondGlazing.data.electricityEndUseSummary.FEB[3].kwh/1000).toFixed(0),
                hp: (secondGlazing.data.electricityEndUseSummary.FEB[9].kwh/1000).toFixed(0),
                hw: (secondGlazing.data.electricityEndUseSummary.FEB[10].kwh/1000).toFixed(0),
                vf: (secondGlazing.data.electricityEndUseSummary.FEB[7].kwh/1000).toFixed(0),
                pa: (secondGlazing.data.electricityEndUseSummary.FEB[6].kwh/1000).toFixed(0),
                eu: (secondGlazing.data.electricityEndUseSummary.FEB[11].kwh/1000).toFixed(0),
                me: (secondGlazing.data.electricityEndUseSummary.FEB[2].kwh/1000).toFixed(0),
                tl: (secondGlazing.data.electricityEndUseSummary.FEB[1].kwh/1000).toFixed(0),
                al: (secondGlazing.data.electricityEndUseSummary.FEB[0].kwh/1000).toFixed(0),
            },
            {
                name: 'Mar',
                sc: (secondGlazing.data.electricityEndUseSummary.MAR[4].kwh/1000).toFixed(0),
                hr: (secondGlazing.data.electricityEndUseSummary.MAR[5].kwh/1000).toFixed(0),
                re: (secondGlazing.data.electricityEndUseSummary.MAR[8].kwh/1000).toFixed(0),
                sh: (secondGlazing.data.electricityEndUseSummary.MAR[3].kwh/1000).toFixed(0),
                hp: (secondGlazing.data.electricityEndUseSummary.MAR[9].kwh/1000).toFixed(0),
                hw: (secondGlazing.data.electricityEndUseSummary.MAR[10].kwh/1000).toFixed(0),
                vf: (secondGlazing.data.electricityEndUseSummary.MAR[7].kwh/1000).toFixed(0),
                pa: (secondGlazing.data.electricityEndUseSummary.MAR[6].kwh/1000).toFixed(0),
                eu: (secondGlazing.data.electricityEndUseSummary.MAR[11].kwh/1000).toFixed(0),
                me: (secondGlazing.data.electricityEndUseSummary.MAR[2].kwh/1000).toFixed(0),
                tl: (secondGlazing.data.electricityEndUseSummary.MAR[1].kwh/1000).toFixed(0),
                al: (secondGlazing.data.electricityEndUseSummary.MAR[0].kwh/1000).toFixed(0),
            },
            {
                name: 'Apr',
                sc: (secondGlazing.data.electricityEndUseSummary.APR[4].kwh/1000).toFixed(0),
                hr: (secondGlazing.data.electricityEndUseSummary.APR[5].kwh/1000).toFixed(0),
                re: (secondGlazing.data.electricityEndUseSummary.APR[8].kwh/1000).toFixed(0),
                sh: (secondGlazing.data.electricityEndUseSummary.APR[3].kwh/1000).toFixed(0),
                hp: (secondGlazing.data.electricityEndUseSummary.APR[9].kwh/1000).toFixed(0),
                hw: (secondGlazing.data.electricityEndUseSummary.APR[10].kwh/1000).toFixed(0),
                vf: (secondGlazing.data.electricityEndUseSummary.APR[7].kwh/1000).toFixed(0),
                pa: (secondGlazing.data.electricityEndUseSummary.APR[6].kwh/1000).toFixed(0),
                eu: (secondGlazing.data.electricityEndUseSummary.APR[11].kwh/1000).toFixed(0),
                me: (secondGlazing.data.electricityEndUseSummary.APR[2].kwh/1000).toFixed(0),
                tl: (secondGlazing.data.electricityEndUseSummary.APR[1].kwh/1000).toFixed(0),
                al: (secondGlazing.data.electricityEndUseSummary.APR[0].kwh/1000).toFixed(0),
            },
            {
                name: 'May',
                sc: (secondGlazing.data.electricityEndUseSummary.MAY[4].kwh/1000).toFixed(0),
                hr: (secondGlazing.data.electricityEndUseSummary.MAY[5].kwh/1000).toFixed(0),
                re: (secondGlazing.data.electricityEndUseSummary.MAY[8].kwh/1000).toFixed(0),
                sh: (secondGlazing.data.electricityEndUseSummary.MAY[3].kwh/1000).toFixed(0),
                hp: (secondGlazing.data.electricityEndUseSummary.MAY[9].kwh/1000).toFixed(0),
                hw: (secondGlazing.data.electricityEndUseSummary.MAY[10].kwh/1000).toFixed(0),
                vf: (secondGlazing.data.electricityEndUseSummary.MAY[7].kwh/1000).toFixed(0),
                pa: (secondGlazing.data.electricityEndUseSummary.MAY[6].kwh/1000).toFixed(0),
                eu: (secondGlazing.data.electricityEndUseSummary.MAY[11].kwh/1000).toFixed(0),
                me: (secondGlazing.data.electricityEndUseSummary.MAY[2].kwh/1000).toFixed(0),
                tl: (secondGlazing.data.electricityEndUseSummary.MAY[1].kwh/1000).toFixed(0),
                al: (secondGlazing.data.electricityEndUseSummary.MAY[0].kwh/1000).toFixed(0),
            },
            {
                name: 'Jun',
                sc: (secondGlazing.data.electricityEndUseSummary.JUN[4].kwh/1000).toFixed(0),
                hr: (secondGlazing.data.electricityEndUseSummary.JUN[5].kwh/1000).toFixed(0),
                re: (secondGlazing.data.electricityEndUseSummary.JUN[8].kwh/1000).toFixed(0),
                sh: (secondGlazing.data.electricityEndUseSummary.JUN[3].kwh/1000).toFixed(0),
                hp: (secondGlazing.data.electricityEndUseSummary.JUN[9].kwh/1000).toFixed(0),
                hw: (secondGlazing.data.electricityEndUseSummary.JUN[10].kwh/1000).toFixed(0),
                vf: (secondGlazing.data.electricityEndUseSummary.JUN[7].kwh/1000).toFixed(0),
                pa: (secondGlazing.data.electricityEndUseSummary.JUN[6].kwh/1000).toFixed(0),
                eu: (secondGlazing.data.electricityEndUseSummary.JUN[11].kwh/1000).toFixed(0),
                me: (secondGlazing.data.electricityEndUseSummary.JUN[2].kwh/1000).toFixed(0),
                tl: (secondGlazing.data.electricityEndUseSummary.JUN[1].kwh/1000).toFixed(0),
                al: (secondGlazing.data.electricityEndUseSummary.JUN[0].kwh/1000).toFixed(0),
            },
            {
                name: 'Jul',
                sc: (secondGlazing.data.electricityEndUseSummary.JUL[4].kwh/1000).toFixed(0),
                hr: (secondGlazing.data.electricityEndUseSummary.JUL[5].kwh/1000).toFixed(0),
                re: (secondGlazing.data.electricityEndUseSummary.JUL[8].kwh/1000).toFixed(0),
                sh: (secondGlazing.data.electricityEndUseSummary.JUL[3].kwh/1000).toFixed(0),
                hp: (secondGlazing.data.electricityEndUseSummary.JUL[9].kwh/1000).toFixed(0),
                hw: (secondGlazing.data.electricityEndUseSummary.JUL[10].kwh/1000).toFixed(0),
                vf: (secondGlazing.data.electricityEndUseSummary.JUL[7].kwh/1000).toFixed(0),
                pa: (secondGlazing.data.electricityEndUseSummary.JUL[6].kwh/1000).toFixed(0),
                eu: (secondGlazing.data.electricityEndUseSummary.JUL[11].kwh/1000).toFixed(0),
                me: (secondGlazing.data.electricityEndUseSummary.JUL[2].kwh/1000).toFixed(0),
                tl: (secondGlazing.data.electricityEndUseSummary.JUL[1].kwh/1000).toFixed(0),
                al: (secondGlazing.data.electricityEndUseSummary.JUL[0].kwh/1000).toFixed(0),
            },
            {
                name: 'Aug',
                sc: (secondGlazing.data.electricityEndUseSummary.AUG[4].kwh/1000).toFixed(0),
                hr: (secondGlazing.data.electricityEndUseSummary.AUG[5].kwh/1000).toFixed(0),
                re: (secondGlazing.data.electricityEndUseSummary.AUG[8].kwh/1000).toFixed(0),
                sh: (secondGlazing.data.electricityEndUseSummary.AUG[3].kwh/1000).toFixed(0),
                hp: (secondGlazing.data.electricityEndUseSummary.AUG[9].kwh/1000).toFixed(0),
                hw: (secondGlazing.data.electricityEndUseSummary.AUG[10].kwh/1000).toFixed(0),
                vf: (secondGlazing.data.electricityEndUseSummary.AUG[7].kwh/1000).toFixed(0),
                pa: (secondGlazing.data.electricityEndUseSummary.AUG[6].kwh/1000).toFixed(0),
                eu: (secondGlazing.data.electricityEndUseSummary.AUG[11].kwh/1000).toFixed(0),
                me: (secondGlazing.data.electricityEndUseSummary.AUG[2].kwh/1000).toFixed(0),
                tl: (secondGlazing.data.electricityEndUseSummary.AUG[1].kwh/1000).toFixed(0),
                al: (secondGlazing.data.electricityEndUseSummary.AUG[0].kwh/1000).toFixed(0),
            },
            {
                name: 'Sep',
                sc: (secondGlazing.data.electricityEndUseSummary.SEP[4].kwh/1000).toFixed(0),
                hr: (secondGlazing.data.electricityEndUseSummary.SEP[5].kwh/1000).toFixed(0),
                re: (secondGlazing.data.electricityEndUseSummary.SEP[8].kwh/1000).toFixed(0),
                sh: (secondGlazing.data.electricityEndUseSummary.SEP[3].kwh/1000).toFixed(0),
                hp: (secondGlazing.data.electricityEndUseSummary.SEP[9].kwh/1000).toFixed(0),
                hw: (secondGlazing.data.electricityEndUseSummary.SEP[10].kwh/1000).toFixed(0),
                vf: (secondGlazing.data.electricityEndUseSummary.SEP[7].kwh/1000).toFixed(0),
                pa: (secondGlazing.data.electricityEndUseSummary.SEP[6].kwh/1000).toFixed(0),
                eu: (secondGlazing.data.electricityEndUseSummary.SEP[11].kwh/1000).toFixed(0),
                me: (secondGlazing.data.electricityEndUseSummary.SEP[2].kwh/1000).toFixed(0),
                tl: (secondGlazing.data.electricityEndUseSummary.SEP[1].kwh/1000).toFixed(0),
                al: (secondGlazing.data.electricityEndUseSummary.SEP[0].kwh/1000).toFixed(0),
            },
            {
                name: 'Oct',
                sc: (secondGlazing.data.electricityEndUseSummary.OCT[4].kwh/1000).toFixed(0),
                hr: (secondGlazing.data.electricityEndUseSummary.OCT[5].kwh/1000).toFixed(0),
                re: (secondGlazing.data.electricityEndUseSummary.OCT[8].kwh/1000).toFixed(0),
                sh: (secondGlazing.data.electricityEndUseSummary.OCT[3].kwh/1000).toFixed(0),
                hp: (secondGlazing.data.electricityEndUseSummary.OCT[9].kwh/1000).toFixed(0),
                hw: (secondGlazing.data.electricityEndUseSummary.OCT[10].kwh/1000).toFixed(0),
                vf: (secondGlazing.data.electricityEndUseSummary.OCT[7].kwh/1000).toFixed(0),
                pa: (secondGlazing.data.electricityEndUseSummary.OCT[6].kwh/1000).toFixed(0),
                eu: (secondGlazing.data.electricityEndUseSummary.OCT[11].kwh/1000).toFixed(0),
                me: (secondGlazing.data.electricityEndUseSummary.OCT[2].kwh/1000).toFixed(0),
                tl: (secondGlazing.data.electricityEndUseSummary.OCT[1].kwh/1000).toFixed(0),
                al: (secondGlazing.data.electricityEndUseSummary.OCT[0].kwh/1000).toFixed(0),
            },
            {
                name: 'Nov',
                sc: (secondGlazing.data.electricityEndUseSummary.NOV[4].kwh/1000).toFixed(0),
                hr: (secondGlazing.data.electricityEndUseSummary.NOV[5].kwh/1000).toFixed(0),
                re: (secondGlazing.data.electricityEndUseSummary.NOV[8].kwh/1000).toFixed(0),
                sh: (secondGlazing.data.electricityEndUseSummary.NOV[3].kwh/1000).toFixed(0),
                hp: (secondGlazing.data.electricityEndUseSummary.NOV[9].kwh/1000).toFixed(0),
                hw: (secondGlazing.data.electricityEndUseSummary.NOV[10].kwh/1000).toFixed(0),
                vf: (secondGlazing.data.electricityEndUseSummary.NOV[7].kwh/1000).toFixed(0),
                pa: (secondGlazing.data.electricityEndUseSummary.NOV[6].kwh/1000).toFixed(0),
                eu: (secondGlazing.data.electricityEndUseSummary.NOV[11].kwh/1000).toFixed(0),
                me: (secondGlazing.data.electricityEndUseSummary.NOV[2].kwh/1000).toFixed(0),
                tl: (secondGlazing.data.electricityEndUseSummary.NOV[1].kwh/1000).toFixed(0),
                al: (secondGlazing.data.electricityEndUseSummary.NOV[0].kwh/1000).toFixed(0),
            },
            {
                name: 'Dec',
                sc: (secondGlazing.data.electricityEndUseSummary.DEC[4].kwh/1000).toFixed(0),
                hr: (secondGlazing.data.electricityEndUseSummary.DEC[5].kwh/1000).toFixed(0),
                re: (secondGlazing.data.electricityEndUseSummary.DEC[8].kwh/1000).toFixed(0),
                sh: (secondGlazing.data.electricityEndUseSummary.DEC[3].kwh/1000).toFixed(0),
                hp: (secondGlazing.data.electricityEndUseSummary.DEC[9].kwh/1000).toFixed(0),
                hw: (secondGlazing.data.electricityEndUseSummary.DEC[10].kwh/1000).toFixed(0),
                vf: (secondGlazing.data.electricityEndUseSummary.DEC[7].kwh/1000).toFixed(0),
                pa: (secondGlazing.data.electricityEndUseSummary.DEC[6].kwh/1000).toFixed(0),
                eu: (secondGlazing.data.electricityEndUseSummary.DEC[11].kwh/1000).toFixed(0),
                me: (secondGlazing.data.electricityEndUseSummary.DEC[2].kwh/1000).toFixed(0),
                tl: (secondGlazing.data.electricityEndUseSummary.DEC[1].kwh/1000).toFixed(0),
                al: (secondGlazing.data.electricityEndUseSummary.DEC[0].kwh/1000).toFixed(0),
            },
        ],
        first_chart_monthly_gas_consumption : [
            {
                name: 'Jan',
                sc: isThermExist ? (firstGlazing.data.gasEndUseSummary.JAN[4].mmBtu*1000).toFixed(0) : 0,
                hr: isThermExist ? (firstGlazing.data.gasEndUseSummary.JAN[5].mmBtu*1000).toFixed(0) : 0,
                re: isThermExist ? (firstGlazing.data.gasEndUseSummary.JAN[8].mmBtu*1000).toFixed(0) : 0,
                sh: isThermExist ? (firstGlazing.data.gasEndUseSummary.JAN[3].mmBtu*1000).toFixed(0) : 0,
                hp: isThermExist ? (firstGlazing.data.gasEndUseSummary.JAN[9].mmBtu*1000).toFixed(0) : 0,
                hw: isThermExist ? (firstGlazing.data.gasEndUseSummary.JAN[10].mmBtu*1000).toFixed(0) : 0,
                vf: isThermExist ? (firstGlazing.data.gasEndUseSummary.JAN[7].mmBtu*1000).toFixed(0) : 0,
                pa: isThermExist ? (firstGlazing.data.gasEndUseSummary.JAN[6].mmBtu*1000).toFixed(0) : 0,
                eu: isThermExist ? (firstGlazing.data.gasEndUseSummary.JAN[11].mmBtu*1000).toFixed(0) : 0,
                me: isThermExist ? (firstGlazing.data.gasEndUseSummary.JAN[2].mmBtu*1000).toFixed(0) : 0,
                tl: isThermExist ? (firstGlazing.data.gasEndUseSummary.JAN[1].mmBtu*1000).toFixed(0) : 0,
                al: isThermExist ? (firstGlazing.data.gasEndUseSummary.JAN[0].mmBtu*1000).toFixed(0) : 0,
            },
            {
                name: 'Feb',
                sc: isThermExist ? (firstGlazing.data.gasEndUseSummary.FEB[4].mmBtu*1000).toFixed(0) : 0,
                hr: isThermExist ? (firstGlazing.data.gasEndUseSummary.FEB[5].mmBtu*1000).toFixed(0) : 0,
                re: isThermExist ? (firstGlazing.data.gasEndUseSummary.FEB[8].mmBtu*1000).toFixed(0) : 0,
                sh: isThermExist ? (firstGlazing.data.gasEndUseSummary.FEB[3].mmBtu*1000).toFixed(0) : 0,
                hp: isThermExist ? (firstGlazing.data.gasEndUseSummary.FEB[9].mmBtu*1000).toFixed(0) : 0,
                hw: isThermExist ? (firstGlazing.data.gasEndUseSummary.FEB[10].mmBtu*1000).toFixed(0) : 0,
                vf: isThermExist ? (firstGlazing.data.gasEndUseSummary.FEB[7].mmBtu*1000).toFixed(0) : 0,
                pa: isThermExist ? (firstGlazing.data.gasEndUseSummary.FEB[6].mmBtu*1000).toFixed(0) : 0,
                eu: isThermExist ? (firstGlazing.data.gasEndUseSummary.FEB[11].mmBtu*1000).toFixed(0) : 0,
                me: isThermExist ? (firstGlazing.data.gasEndUseSummary.FEB[2].mmBtu*1000).toFixed(0) : 0,
                tl: isThermExist ? (firstGlazing.data.gasEndUseSummary.FEB[1].mmBtu*1000).toFixed(0) : 0,
                al: isThermExist ? (firstGlazing.data.gasEndUseSummary.FEB[0].mmBtu*1000).toFixed(0) : 0,
            },
            {
                name: 'Mar',
                sc: isThermExist ? (firstGlazing.data.gasEndUseSummary.MAR[4].mmBtu*1000).toFixed(0) : 0,
                hr: isThermExist ? (firstGlazing.data.gasEndUseSummary.MAR[5].mmBtu*1000).toFixed(0) : 0,
                re: isThermExist ? (firstGlazing.data.gasEndUseSummary.MAR[8].mmBtu*1000).toFixed(0) : 0,
                sh: isThermExist ? (firstGlazing.data.gasEndUseSummary.MAR[3].mmBtu*1000).toFixed(0) : 0,
                hp: isThermExist ? (firstGlazing.data.gasEndUseSummary.MAR[9].mmBtu*1000).toFixed(0) : 0,
                hw: isThermExist ? (firstGlazing.data.gasEndUseSummary.MAR[10].mmBtu*1000).toFixed(0) : 0,
                vf: isThermExist ? (firstGlazing.data.gasEndUseSummary.MAR[7].mmBtu*1000).toFixed(0) : 0,
                pa: isThermExist ? (firstGlazing.data.gasEndUseSummary.MAR[6].mmBtu*1000).toFixed(0) : 0,
                eu: isThermExist ? (firstGlazing.data.gasEndUseSummary.MAR[11].mmBtu*1000).toFixed(0) : 0,
                me: isThermExist ? (firstGlazing.data.gasEndUseSummary.MAR[2].mmBtu*1000).toFixed(0) : 0,
                tl: isThermExist ? (firstGlazing.data.gasEndUseSummary.MAR[1].mmBtu*1000).toFixed(0) : 0,
                al: isThermExist ? (firstGlazing.data.gasEndUseSummary.MAR[0].mmBtu*1000).toFixed(0) : 0,
            },
            {
                name: 'Apr',
                sc: isThermExist ? (firstGlazing.data.gasEndUseSummary.APR[4].mmBtu*1000).toFixed(0) : 0,
                hr: isThermExist ? (firstGlazing.data.gasEndUseSummary.APR[5].mmBtu*1000).toFixed(0) : 0,
                re: isThermExist ? (firstGlazing.data.gasEndUseSummary.APR[8].mmBtu*1000).toFixed(0) : 0,
                sh: isThermExist ? (firstGlazing.data.gasEndUseSummary.APR[3].mmBtu*1000).toFixed(0) : 0,
                hp: isThermExist ? (firstGlazing.data.gasEndUseSummary.APR[9].mmBtu*1000).toFixed(0) : 0,
                hw: isThermExist ? (firstGlazing.data.gasEndUseSummary.APR[10].mmBtu*1000).toFixed(0) : 0,
                vf: isThermExist ? (firstGlazing.data.gasEndUseSummary.APR[7].mmBtu*1000).toFixed(0) : 0,
                pa: isThermExist ? (firstGlazing.data.gasEndUseSummary.APR[6].mmBtu*1000).toFixed(0) : 0,
                eu: isThermExist ? (firstGlazing.data.gasEndUseSummary.APR[11].mmBtu*1000).toFixed(0) : 0,
                me: isThermExist ? (firstGlazing.data.gasEndUseSummary.APR[2].mmBtu*1000).toFixed(0) : 0,
                tl: isThermExist ? (firstGlazing.data.gasEndUseSummary.APR[1].mmBtu*1000).toFixed(0) : 0,
                al: isThermExist ? (firstGlazing.data.gasEndUseSummary.APR[0].mmBtu*1000).toFixed(0) : 0,
            },
            {
                name: 'May',
                sc: isThermExist ? (firstGlazing.data.gasEndUseSummary.MAY[4].mmBtu*1000).toFixed(0) : 0,
                hr: isThermExist ? (firstGlazing.data.gasEndUseSummary.MAY[5].mmBtu*1000).toFixed(0) : 0,
                re: isThermExist ? (firstGlazing.data.gasEndUseSummary.MAY[8].mmBtu*1000).toFixed(0) : 0,
                sh: isThermExist ? (firstGlazing.data.gasEndUseSummary.MAY[3].mmBtu*1000).toFixed(0) : 0,
                hp: isThermExist ? (firstGlazing.data.gasEndUseSummary.MAY[9].mmBtu*1000).toFixed(0) : 0,
                hw: isThermExist ? (firstGlazing.data.gasEndUseSummary.MAY[10].mmBtu*1000).toFixed(0) : 0,
                vf: isThermExist ? (firstGlazing.data.gasEndUseSummary.MAY[7].mmBtu*1000).toFixed(0) : 0,
                pa: isThermExist ? (firstGlazing.data.gasEndUseSummary.MAY[6].mmBtu*1000).toFixed(0) : 0,
                eu: isThermExist ? (firstGlazing.data.gasEndUseSummary.MAY[11].mmBtu*1000).toFixed(0) : 0,
                me: isThermExist ? (firstGlazing.data.gasEndUseSummary.MAY[2].mmBtu*1000).toFixed(0) : 0,
                tl: isThermExist ? (firstGlazing.data.gasEndUseSummary.MAY[1].mmBtu*1000).toFixed(0) : 0,
                al: isThermExist ? (firstGlazing.data.gasEndUseSummary.MAY[0].mmBtu*1000).toFixed(0) : 0,
            },
            {
                name: 'Jun',
                sc: isThermExist ? (firstGlazing.data.gasEndUseSummary.JUN[4].mmBtu*1000).toFixed(0) : 0,
                hr: isThermExist ? (firstGlazing.data.gasEndUseSummary.JUN[5].mmBtu*1000).toFixed(0) : 0,
                re: isThermExist ? (firstGlazing.data.gasEndUseSummary.JUN[8].mmBtu*1000).toFixed(0) : 0,
                sh: isThermExist ? (firstGlazing.data.gasEndUseSummary.JUN[3].mmBtu*1000).toFixed(0) : 0,
                hp: isThermExist ? (firstGlazing.data.gasEndUseSummary.JUN[9].mmBtu*1000).toFixed(0) : 0,
                hw: isThermExist ? (firstGlazing.data.gasEndUseSummary.JUN[10].mmBtu*1000).toFixed(0) : 0,
                vf: isThermExist ? (firstGlazing.data.gasEndUseSummary.JUN[7].mmBtu*1000).toFixed(0) : 0,
                pa: isThermExist ? (firstGlazing.data.gasEndUseSummary.JUN[6].mmBtu*1000).toFixed(0) : 0,
                eu: isThermExist ? (firstGlazing.data.gasEndUseSummary.JUN[11].mmBtu*1000).toFixed(0) : 0,
                me: isThermExist ? (firstGlazing.data.gasEndUseSummary.JUN[2].mmBtu*1000).toFixed(0) : 0,
                tl: isThermExist ? (firstGlazing.data.gasEndUseSummary.JUN[1].mmBtu*1000).toFixed(0) : 0,
                al: isThermExist ? (firstGlazing.data.gasEndUseSummary.JUN[0].mmBtu*1000).toFixed(0) : 0,
            },
            {
                name: 'Jul',
                sc: isThermExist ? (firstGlazing.data.gasEndUseSummary.JUL[4].mmBtu*1000).toFixed(0) : 0,
                hr: isThermExist ? (firstGlazing.data.gasEndUseSummary.JUL[5].mmBtu*1000).toFixed(0) : 0,
                re: isThermExist ? (firstGlazing.data.gasEndUseSummary.JUL[8].mmBtu*1000).toFixed(0) : 0,
                sh: isThermExist ? (firstGlazing.data.gasEndUseSummary.JUL[3].mmBtu*1000).toFixed(0) : 0,
                hp: isThermExist ? (firstGlazing.data.gasEndUseSummary.JUL[9].mmBtu*1000).toFixed(0) : 0,
                hw: isThermExist ? (firstGlazing.data.gasEndUseSummary.JUL[10].mmBtu*1000).toFixed(0) : 0,
                vf: isThermExist ? (firstGlazing.data.gasEndUseSummary.JUL[7].mmBtu*1000).toFixed(0) : 0,
                pa: isThermExist ? (firstGlazing.data.gasEndUseSummary.JUL[6].mmBtu*1000).toFixed(0) : 0,
                eu: isThermExist ? (firstGlazing.data.gasEndUseSummary.JUL[11].mmBtu*1000).toFixed(0) : 0,
                me: isThermExist ? (firstGlazing.data.gasEndUseSummary.JUL[2].mmBtu*1000).toFixed(0) : 0,
                tl: isThermExist ? (firstGlazing.data.gasEndUseSummary.JUL[1].mmBtu*1000).toFixed(0) : 0,
                al: isThermExist ? (firstGlazing.data.gasEndUseSummary.JUL[0].mmBtu*1000).toFixed(0) : 0,
            },
            {
                name: 'Aug',
                sc: isThermExist ? (firstGlazing.data.gasEndUseSummary.AUG[4].mmBtu*1000).toFixed(0) : 0,
                hr: isThermExist ? (firstGlazing.data.gasEndUseSummary.AUG[5].mmBtu*1000).toFixed(0) : 0,
                re: isThermExist ? (firstGlazing.data.gasEndUseSummary.AUG[8].mmBtu*1000).toFixed(0) : 0,
                sh: isThermExist ? (firstGlazing.data.gasEndUseSummary.AUG[3].mmBtu*1000).toFixed(0) : 0,
                hp: isThermExist ? (firstGlazing.data.gasEndUseSummary.AUG[9].mmBtu*1000).toFixed(0) : 0,
                hw: isThermExist ? (firstGlazing.data.gasEndUseSummary.AUG[10].mmBtu*1000).toFixed(0) : 0,
                vf: isThermExist ? (firstGlazing.data.gasEndUseSummary.AUG[7].mmBtu*1000).toFixed(0) : 0,
                pa: isThermExist ? (firstGlazing.data.gasEndUseSummary.AUG[6].mmBtu*1000).toFixed(0) : 0,
                eu: isThermExist ? (firstGlazing.data.gasEndUseSummary.AUG[11].mmBtu*1000).toFixed(0) : 0,
                me: isThermExist ? (firstGlazing.data.gasEndUseSummary.AUG[2].mmBtu*1000).toFixed(0) : 0,
                tl: isThermExist ? (firstGlazing.data.gasEndUseSummary.AUG[1].mmBtu*1000).toFixed(0) : 0,
                al: isThermExist ? (firstGlazing.data.gasEndUseSummary.AUG[0].mmBtu*1000).toFixed(0) : 0,
            },
            {
                name: 'Sep',
                sc: isThermExist ? (firstGlazing.data.gasEndUseSummary.SEP[4].mmBtu*1000).toFixed(0) : 0,
                hr: isThermExist ? (firstGlazing.data.gasEndUseSummary.SEP[5].mmBtu*1000).toFixed(0) : 0,
                re: isThermExist ? (firstGlazing.data.gasEndUseSummary.SEP[8].mmBtu*1000).toFixed(0) : 0,
                sh: isThermExist ? (firstGlazing.data.gasEndUseSummary.SEP[3].mmBtu*1000).toFixed(0) : 0,
                hp: isThermExist ? (firstGlazing.data.gasEndUseSummary.SEP[9].mmBtu*1000).toFixed(0) : 0,
                hw: isThermExist ? (firstGlazing.data.gasEndUseSummary.SEP[10].mmBtu*1000).toFixed(0) : 0,
                vf: isThermExist ? (firstGlazing.data.gasEndUseSummary.SEP[7].mmBtu*1000).toFixed(0) : 0,
                pa: isThermExist ? (firstGlazing.data.gasEndUseSummary.SEP[6].mmBtu*1000).toFixed(0) : 0,
                eu: isThermExist ? (firstGlazing.data.gasEndUseSummary.SEP[11].mmBtu*1000).toFixed(0) : 0,
                me: isThermExist ? (firstGlazing.data.gasEndUseSummary.SEP[2].mmBtu*1000).toFixed(0) : 0,
                tl: isThermExist ? (firstGlazing.data.gasEndUseSummary.SEP[1].mmBtu*1000).toFixed(0) : 0,
                al: isThermExist ? (firstGlazing.data.gasEndUseSummary.SEP[0].mmBtu*1000).toFixed(0) : 0,
            },
            {
                name: 'Oct',
                sc: isThermExist ? (firstGlazing.data.gasEndUseSummary.OCT[4].mmBtu*1000).toFixed(0) : 0,
                hr: isThermExist ? (firstGlazing.data.gasEndUseSummary.OCT[5].mmBtu*1000).toFixed(0) : 0,
                re: isThermExist ? (firstGlazing.data.gasEndUseSummary.OCT[8].mmBtu*1000).toFixed(0) : 0,
                sh: isThermExist ? (firstGlazing.data.gasEndUseSummary.OCT[3].mmBtu*1000).toFixed(0) : 0,
                hp: isThermExist ? (firstGlazing.data.gasEndUseSummary.OCT[9].mmBtu*1000).toFixed(0) : 0,
                hw: isThermExist ? (firstGlazing.data.gasEndUseSummary.OCT[10].mmBtu*1000).toFixed(0) : 0,
                vf: isThermExist ? (firstGlazing.data.gasEndUseSummary.OCT[7].mmBtu*1000).toFixed(0) : 0,
                pa: isThermExist ? (firstGlazing.data.gasEndUseSummary.OCT[6].mmBtu*1000).toFixed(0) : 0,
                eu: isThermExist ? (firstGlazing.data.gasEndUseSummary.OCT[11].mmBtu*1000).toFixed(0) : 0,
                me: isThermExist ? (firstGlazing.data.gasEndUseSummary.OCT[2].mmBtu*1000).toFixed(0) : 0,
                tl: isThermExist ? (firstGlazing.data.gasEndUseSummary.OCT[1].mmBtu*1000).toFixed(0) : 0,
                al: isThermExist ? (firstGlazing.data.gasEndUseSummary.OCT[0].mmBtu*1000).toFixed(0) : 0,
            },
            {
                name: 'Nov',
                sc: isThermExist ? (firstGlazing.data.gasEndUseSummary.NOV[4].mmBtu*1000).toFixed(0) : 0,
                hr: isThermExist ? (firstGlazing.data.gasEndUseSummary.NOV[5].mmBtu*1000).toFixed(0) : 0,
                re: isThermExist ? (firstGlazing.data.gasEndUseSummary.NOV[8].mmBtu*1000).toFixed(0) : 0,
                sh: isThermExist ? (firstGlazing.data.gasEndUseSummary.NOV[3].mmBtu*1000).toFixed(0) : 0,
                hp: isThermExist ? (firstGlazing.data.gasEndUseSummary.NOV[9].mmBtu*1000).toFixed(0) : 0,
                hw: isThermExist ? (firstGlazing.data.gasEndUseSummary.NOV[10].mmBtu*1000).toFixed(0) : 0,
                vf: isThermExist ? (firstGlazing.data.gasEndUseSummary.NOV[7].mmBtu*1000).toFixed(0) : 0,
                pa: isThermExist ? (firstGlazing.data.gasEndUseSummary.NOV[6].mmBtu*1000).toFixed(0) : 0,
                eu: isThermExist ? (firstGlazing.data.gasEndUseSummary.NOV[11].mmBtu*1000).toFixed(0) : 0,
                me: isThermExist ? (firstGlazing.data.gasEndUseSummary.NOV[2].mmBtu*1000).toFixed(0) : 0,
                tl: isThermExist ? (firstGlazing.data.gasEndUseSummary.NOV[1].mmBtu*1000).toFixed(0) : 0,
                al: isThermExist ? (firstGlazing.data.gasEndUseSummary.NOV[0].mmBtu*1000).toFixed(0) : 0,
            },
            {
                name: 'Dec',
                sc: isThermExist ? (firstGlazing.data.gasEndUseSummary.DEC[4].mmBtu*1000).toFixed(0) : 0,
                hr: isThermExist ? (firstGlazing.data.gasEndUseSummary.DEC[5].mmBtu*1000).toFixed(0) : 0,
                re: isThermExist ? (firstGlazing.data.gasEndUseSummary.DEC[8].mmBtu*1000).toFixed(0) : 0,
                sh: isThermExist ? (firstGlazing.data.gasEndUseSummary.DEC[3].mmBtu*1000).toFixed(0) : 0,
                hp: isThermExist ? (firstGlazing.data.gasEndUseSummary.DEC[9].mmBtu*1000).toFixed(0) : 0,
                hw: isThermExist ? (firstGlazing.data.gasEndUseSummary.DEC[10].mmBtu*1000).toFixed(0) : 0,
                vf: isThermExist ? (firstGlazing.data.gasEndUseSummary.DEC[7].mmBtu*1000).toFixed(0) : 0,
                pa: isThermExist ? (firstGlazing.data.gasEndUseSummary.DEC[6].mmBtu*1000).toFixed(0) : 0,
                eu: isThermExist ? (firstGlazing.data.gasEndUseSummary.DEC[11].mmBtu*1000).toFixed(0) : 0,
                me: isThermExist ? (firstGlazing.data.gasEndUseSummary.DEC[2].mmBtu*1000).toFixed(0) : 0,
                tl: isThermExist ? (firstGlazing.data.gasEndUseSummary.DEC[1].mmBtu*1000).toFixed(0) : 0,
                al: isThermExist ? (firstGlazing.data.gasEndUseSummary.DEC[0].mmBtu*1000).toFixed(0) : 0,
            },
        ],
        second_chart_monthly_gas_consumption : [
            {
                name: 'Jan',
                sc: isThermExist ? (secondGlazing.data.gasEndUseSummary.JAN[4].mmBtu*1000).toFixed(0) : 0,
                hr: isThermExist ? (secondGlazing.data.gasEndUseSummary.JAN[5].mmBtu*1000).toFixed(0) : 0,
                re: isThermExist ? (secondGlazing.data.gasEndUseSummary.JAN[8].mmBtu*1000).toFixed(0) : 0,
                sh: isThermExist ? (secondGlazing.data.gasEndUseSummary.JAN[3].mmBtu*1000).toFixed(0) : 0,
                hp: isThermExist ? (secondGlazing.data.gasEndUseSummary.JAN[9].mmBtu*1000).toFixed(0) : 0,
                hw: isThermExist ? (secondGlazing.data.gasEndUseSummary.JAN[10].mmBtu*1000).toFixed(0) : 0,
                vf: isThermExist ? (secondGlazing.data.gasEndUseSummary.JAN[7].mmBtu*1000).toFixed(0) : 0,
                pa: isThermExist ? (secondGlazing.data.gasEndUseSummary.JAN[6].mmBtu*1000).toFixed(0) : 0,
                eu: isThermExist ? (secondGlazing.data.gasEndUseSummary.JAN[11].mmBtu*1000).toFixed(0) : 0,
                me: isThermExist ? (secondGlazing.data.gasEndUseSummary.JAN[2].mmBtu*1000).toFixed(0) : 0,
                tl: isThermExist ? (secondGlazing.data.gasEndUseSummary.JAN[1].mmBtu*1000).toFixed(0) : 0,
                al: isThermExist ? (secondGlazing.data.gasEndUseSummary.JAN[0].mmBtu*1000).toFixed(0) : 0,
            },
            {
                name: 'Feb',
                sc: isThermExist ? (secondGlazing.data.gasEndUseSummary.FEB[4].mmBtu*1000).toFixed(0) : 0,
                hr: isThermExist ? (secondGlazing.data.gasEndUseSummary.FEB[5].mmBtu*1000).toFixed(0) : 0,
                re: isThermExist ? (secondGlazing.data.gasEndUseSummary.FEB[8].mmBtu*1000).toFixed(0) : 0,
                sh: isThermExist ? (secondGlazing.data.gasEndUseSummary.FEB[3].mmBtu*1000).toFixed(0) : 0,
                hp: isThermExist ? (secondGlazing.data.gasEndUseSummary.FEB[9].mmBtu*1000).toFixed(0) : 0,
                hw: isThermExist ? (secondGlazing.data.gasEndUseSummary.FEB[10].mmBtu*1000).toFixed(0) : 0,
                vf: isThermExist ? (secondGlazing.data.gasEndUseSummary.FEB[7].mmBtu*1000).toFixed(0) : 0,
                pa: isThermExist ? (secondGlazing.data.gasEndUseSummary.FEB[6].mmBtu*1000).toFixed(0) : 0,
                eu: isThermExist ? (secondGlazing.data.gasEndUseSummary.FEB[11].mmBtu*1000).toFixed(0) : 0,
                me: isThermExist ? (secondGlazing.data.gasEndUseSummary.FEB[2].mmBtu*1000).toFixed(0) : 0,
                tl: isThermExist ? (secondGlazing.data.gasEndUseSummary.FEB[1].mmBtu*1000).toFixed(0) : 0,
                al: isThermExist ? (secondGlazing.data.gasEndUseSummary.FEB[0].mmBtu*1000).toFixed(0) : 0,
            },
            {
                name: 'Mar',
                sc: isThermExist ? (secondGlazing.data.gasEndUseSummary.MAR[4].mmBtu*1000).toFixed(0) : 0,
                hr: isThermExist ? (secondGlazing.data.gasEndUseSummary.MAR[5].mmBtu*1000).toFixed(0) : 0,
                re: isThermExist ? (secondGlazing.data.gasEndUseSummary.MAR[8].mmBtu*1000).toFixed(0) : 0,
                sh: isThermExist ? (secondGlazing.data.gasEndUseSummary.MAR[3].mmBtu*1000).toFixed(0) : 0,
                hp: isThermExist ? (secondGlazing.data.gasEndUseSummary.MAR[9].mmBtu*1000).toFixed(0) : 0,
                hw: isThermExist ? (secondGlazing.data.gasEndUseSummary.MAR[10].mmBtu*1000).toFixed(0) : 0,
                vf: isThermExist ? (secondGlazing.data.gasEndUseSummary.MAR[7].mmBtu*1000).toFixed(0) : 0,
                pa: isThermExist ? (secondGlazing.data.gasEndUseSummary.MAR[6].mmBtu*1000).toFixed(0) : 0,
                eu: isThermExist ? (secondGlazing.data.gasEndUseSummary.MAR[11].mmBtu*1000).toFixed(0) : 0,
                me: isThermExist ? (secondGlazing.data.gasEndUseSummary.MAR[2].mmBtu*1000).toFixed(0) : 0,
                tl: isThermExist ? (secondGlazing.data.gasEndUseSummary.MAR[1].mmBtu*1000).toFixed(0) : 0,
                al: isThermExist ? (secondGlazing.data.gasEndUseSummary.MAR[0].mmBtu*1000).toFixed(0) : 0,
            },
            {
                name: 'Apr',
                sc: isThermExist ? (secondGlazing.data.gasEndUseSummary.APR[4].mmBtu*1000).toFixed(0) : 0,
                hr: isThermExist ? (secondGlazing.data.gasEndUseSummary.APR[5].mmBtu*1000).toFixed(0) : 0,
                re: isThermExist ? (secondGlazing.data.gasEndUseSummary.APR[8].mmBtu*1000).toFixed(0) : 0,
                sh: isThermExist ? (secondGlazing.data.gasEndUseSummary.APR[3].mmBtu*1000).toFixed(0) : 0,
                hp: isThermExist ? (secondGlazing.data.gasEndUseSummary.APR[9].mmBtu*1000).toFixed(0) : 0,
                hw: isThermExist ? (secondGlazing.data.gasEndUseSummary.APR[10].mmBtu*1000).toFixed(0) : 0,
                vf: isThermExist ? (secondGlazing.data.gasEndUseSummary.APR[7].mmBtu*1000).toFixed(0) : 0,
                pa: isThermExist ? (secondGlazing.data.gasEndUseSummary.APR[6].mmBtu*1000).toFixed(0) : 0,
                eu: isThermExist ? (secondGlazing.data.gasEndUseSummary.APR[11].mmBtu*1000).toFixed(0) : 0,
                me: isThermExist ? (secondGlazing.data.gasEndUseSummary.APR[2].mmBtu*1000).toFixed(0) : 0,
                tl: isThermExist ? (secondGlazing.data.gasEndUseSummary.APR[1].mmBtu*1000).toFixed(0) : 0,
                al: isThermExist ? (secondGlazing.data.gasEndUseSummary.APR[0].mmBtu*1000).toFixed(0) : 0,
            },
            {
                name: 'May',
                sc: isThermExist ? (secondGlazing.data.gasEndUseSummary.MAY[4].mmBtu*1000).toFixed(0) : 0,
                hr: isThermExist ? (secondGlazing.data.gasEndUseSummary.MAY[5].mmBtu*1000).toFixed(0) : 0,
                re: isThermExist ? (secondGlazing.data.gasEndUseSummary.MAY[8].mmBtu*1000).toFixed(0) : 0,
                sh: isThermExist ? (secondGlazing.data.gasEndUseSummary.MAY[3].mmBtu*1000).toFixed(0) : 0,
                hp: isThermExist ? (secondGlazing.data.gasEndUseSummary.MAY[9].mmBtu*1000).toFixed(0) : 0,
                hw: isThermExist ? (secondGlazing.data.gasEndUseSummary.MAY[10].mmBtu*1000).toFixed(0) : 0,
                vf: isThermExist ? (secondGlazing.data.gasEndUseSummary.MAY[7].mmBtu*1000).toFixed(0) : 0,
                pa: isThermExist ? (secondGlazing.data.gasEndUseSummary.MAY[6].mmBtu*1000).toFixed(0) : 0,
                eu: isThermExist ? (secondGlazing.data.gasEndUseSummary.MAY[11].mmBtu*1000).toFixed(0) : 0,
                me: isThermExist ? (secondGlazing.data.gasEndUseSummary.MAY[2].mmBtu*1000).toFixed(0) : 0,
                tl: isThermExist ? (secondGlazing.data.gasEndUseSummary.MAY[1].mmBtu*1000).toFixed(0) : 0,
                al: isThermExist ? (secondGlazing.data.gasEndUseSummary.MAY[0].mmBtu*1000).toFixed(0) : 0,
            },
            {
                name: 'Jun',
                sc: isThermExist ? (secondGlazing.data.gasEndUseSummary.JUN[4].mmBtu*1000).toFixed(0) : 0,
                hr: isThermExist ? (secondGlazing.data.gasEndUseSummary.JUN[5].mmBtu*1000).toFixed(0) : 0,
                re: isThermExist ? (secondGlazing.data.gasEndUseSummary.JUN[8].mmBtu*1000).toFixed(0) : 0,
                sh: isThermExist ? (secondGlazing.data.gasEndUseSummary.JUN[3].mmBtu*1000).toFixed(0) : 0,
                hp: isThermExist ? (secondGlazing.data.gasEndUseSummary.JUN[9].mmBtu*1000).toFixed(0) : 0,
                hw: isThermExist ? (secondGlazing.data.gasEndUseSummary.JUN[10].mmBtu*1000).toFixed(0) : 0,
                vf: isThermExist ? (secondGlazing.data.gasEndUseSummary.JUN[7].mmBtu*1000).toFixed(0) : 0,
                pa: isThermExist ? (secondGlazing.data.gasEndUseSummary.JUN[6].mmBtu*1000).toFixed(0) : 0,
                eu: isThermExist ? (secondGlazing.data.gasEndUseSummary.JUN[11].mmBtu*1000).toFixed(0) : 0,
                me: isThermExist ? (secondGlazing.data.gasEndUseSummary.JUN[2].mmBtu*1000).toFixed(0) : 0,
                tl: isThermExist ? (secondGlazing.data.gasEndUseSummary.JUN[1].mmBtu*1000).toFixed(0) : 0,
                al: isThermExist ? (secondGlazing.data.gasEndUseSummary.JUN[0].mmBtu*1000).toFixed(0) : 0,
            },
            {
                name: 'Jul',
                sc: isThermExist ? (secondGlazing.data.gasEndUseSummary.JUL[4].mmBtu*1000).toFixed(0) : 0,
                hr: isThermExist ? (secondGlazing.data.gasEndUseSummary.JUL[5].mmBtu*1000).toFixed(0) : 0,
                re: isThermExist ? (secondGlazing.data.gasEndUseSummary.JUL[8].mmBtu*1000).toFixed(0) : 0,
                sh: isThermExist ? (secondGlazing.data.gasEndUseSummary.JUL[3].mmBtu*1000).toFixed(0) : 0,
                hp: isThermExist ? (secondGlazing.data.gasEndUseSummary.JUL[9].mmBtu*1000).toFixed(0) : 0,
                hw: isThermExist ? (secondGlazing.data.gasEndUseSummary.JUL[10].mmBtu*1000).toFixed(0) : 0,
                vf: isThermExist ? (secondGlazing.data.gasEndUseSummary.JUL[7].mmBtu*1000).toFixed(0) : 0,
                pa: isThermExist ? (secondGlazing.data.gasEndUseSummary.JUL[6].mmBtu*1000).toFixed(0) : 0,
                eu: isThermExist ? (secondGlazing.data.gasEndUseSummary.JUL[11].mmBtu*1000).toFixed(0) : 0,
                me: isThermExist ? (secondGlazing.data.gasEndUseSummary.JUL[2].mmBtu*1000).toFixed(0) : 0,
                tl: isThermExist ? (secondGlazing.data.gasEndUseSummary.JUL[1].mmBtu*1000).toFixed(0) : 0,
                al: isThermExist ? (secondGlazing.data.gasEndUseSummary.JUL[0].mmBtu*1000).toFixed(0) : 0,
            },
            {
                name: 'Aug',
                sc: isThermExist ? (secondGlazing.data.gasEndUseSummary.AUG[4].mmBtu*1000).toFixed(0) : 0,
                hr: isThermExist ? (secondGlazing.data.gasEndUseSummary.AUG[5].mmBtu*1000).toFixed(0) : 0,
                re: isThermExist ? (secondGlazing.data.gasEndUseSummary.AUG[8].mmBtu*1000).toFixed(0) : 0,
                sh: isThermExist ? (secondGlazing.data.gasEndUseSummary.AUG[3].mmBtu*1000).toFixed(0) : 0,
                hp: isThermExist ? (secondGlazing.data.gasEndUseSummary.AUG[9].mmBtu*1000).toFixed(0) : 0,
                hw: isThermExist ? (secondGlazing.data.gasEndUseSummary.AUG[10].mmBtu*1000).toFixed(0) : 0,
                vf: isThermExist ? (secondGlazing.data.gasEndUseSummary.AUG[7].mmBtu*1000).toFixed(0) : 0,
                pa: isThermExist ? (secondGlazing.data.gasEndUseSummary.AUG[6].mmBtu*1000).toFixed(0) : 0,
                eu: isThermExist ? (secondGlazing.data.gasEndUseSummary.AUG[11].mmBtu*1000).toFixed(0) : 0,
                me: isThermExist ? (secondGlazing.data.gasEndUseSummary.AUG[2].mmBtu*1000).toFixed(0) : 0,
                tl: isThermExist ? (secondGlazing.data.gasEndUseSummary.AUG[1].mmBtu*1000).toFixed(0) : 0,
                al: isThermExist ? (secondGlazing.data.gasEndUseSummary.AUG[0].mmBtu*1000).toFixed(0) : 0,
            },
            {
                name: 'Sep',
                sc: isThermExist ? (secondGlazing.data.gasEndUseSummary.SEP[4].mmBtu*1000).toFixed(0) : 0,
                hr: isThermExist ? (secondGlazing.data.gasEndUseSummary.SEP[5].mmBtu*1000).toFixed(0) : 0,
                re: isThermExist ? (secondGlazing.data.gasEndUseSummary.SEP[8].mmBtu*1000).toFixed(0) : 0,
                sh: isThermExist ? (secondGlazing.data.gasEndUseSummary.SEP[3].mmBtu*1000).toFixed(0) : 0,
                hp: isThermExist ? (secondGlazing.data.gasEndUseSummary.SEP[9].mmBtu*1000).toFixed(0) : 0,
                hw: isThermExist ? (secondGlazing.data.gasEndUseSummary.SEP[10].mmBtu*1000).toFixed(0) : 0,
                vf: isThermExist ? (secondGlazing.data.gasEndUseSummary.SEP[7].mmBtu*1000).toFixed(0) : 0,
                pa: isThermExist ? (secondGlazing.data.gasEndUseSummary.SEP[6].mmBtu*1000).toFixed(0) : 0,
                eu: isThermExist ? (secondGlazing.data.gasEndUseSummary.SEP[11].mmBtu*1000).toFixed(0) : 0,
                me: isThermExist ? (secondGlazing.data.gasEndUseSummary.SEP[2].mmBtu*1000).toFixed(0) : 0,
                tl: isThermExist ? (secondGlazing.data.gasEndUseSummary.SEP[1].mmBtu*1000).toFixed(0) : 0,
                al: isThermExist ? (secondGlazing.data.gasEndUseSummary.SEP[0].mmBtu*1000).toFixed(0) : 0,
            },
            {
                name: 'Oct',
                sc: isThermExist ? (secondGlazing.data.gasEndUseSummary.OCT[4].mmBtu*1000).toFixed(0) : 0,
                hr: isThermExist ? (secondGlazing.data.gasEndUseSummary.OCT[5].mmBtu*1000).toFixed(0) : 0,
                re: isThermExist ? (secondGlazing.data.gasEndUseSummary.OCT[8].mmBtu*1000).toFixed(0) : 0,
                sh: isThermExist ? (secondGlazing.data.gasEndUseSummary.OCT[3].mmBtu*1000).toFixed(0) : 0,
                hp: isThermExist ? (secondGlazing.data.gasEndUseSummary.OCT[9].mmBtu*1000).toFixed(0) : 0,
                hw: isThermExist ? (secondGlazing.data.gasEndUseSummary.OCT[10].mmBtu*1000).toFixed(0) : 0,
                vf: isThermExist ? (secondGlazing.data.gasEndUseSummary.OCT[7].mmBtu*1000).toFixed(0) : 0,
                pa: isThermExist ? (secondGlazing.data.gasEndUseSummary.OCT[6].mmBtu*1000).toFixed(0) : 0,
                eu: isThermExist ? (secondGlazing.data.gasEndUseSummary.OCT[11].mmBtu*1000).toFixed(0) : 0,
                me: isThermExist ? (secondGlazing.data.gasEndUseSummary.OCT[2].mmBtu*1000).toFixed(0) : 0,
                tl: isThermExist ? (secondGlazing.data.gasEndUseSummary.OCT[1].mmBtu*1000).toFixed(0) : 0,
                al: isThermExist ? (secondGlazing.data.gasEndUseSummary.OCT[0].mmBtu*1000).toFixed(0) : 0,
            },
            {
                name: 'Nov',
                sc: isThermExist ? (secondGlazing.data.gasEndUseSummary.NOV[4].mmBtu*1000).toFixed(0) : 0,
                hr: isThermExist ? (secondGlazing.data.gasEndUseSummary.NOV[5].mmBtu*1000).toFixed(0) : 0,
                re: isThermExist ? (secondGlazing.data.gasEndUseSummary.NOV[8].mmBtu*1000).toFixed(0) : 0,
                sh: isThermExist ? (secondGlazing.data.gasEndUseSummary.NOV[3].mmBtu*1000).toFixed(0) : 0,
                hp: isThermExist ? (secondGlazing.data.gasEndUseSummary.NOV[9].mmBtu*1000).toFixed(0) : 0,
                hw: isThermExist ? (secondGlazing.data.gasEndUseSummary.NOV[10].mmBtu*1000).toFixed(0) : 0,
                vf: isThermExist ? (secondGlazing.data.gasEndUseSummary.NOV[7].mmBtu*1000).toFixed(0) : 0,
                pa: isThermExist ? (secondGlazing.data.gasEndUseSummary.NOV[6].mmBtu*1000).toFixed(0) : 0,
                eu: isThermExist ? (secondGlazing.data.gasEndUseSummary.NOV[11].mmBtu*1000).toFixed(0) : 0,
                me: isThermExist ? (secondGlazing.data.gasEndUseSummary.NOV[2].mmBtu*1000).toFixed(0) : 0,
                tl: isThermExist ? (secondGlazing.data.gasEndUseSummary.NOV[1].mmBtu*1000).toFixed(0) : 0,
                al: isThermExist ? (secondGlazing.data.gasEndUseSummary.NOV[0].mmBtu*1000).toFixed(0) : 0,
            },
            {
                name: 'Dec',
                sc: isThermExist ? (secondGlazing.data.gasEndUseSummary.DEC[4].mmBtu*1000).toFixed(0) : 0,
                hr: isThermExist ? (secondGlazing.data.gasEndUseSummary.DEC[5].mmBtu*1000).toFixed(0) : 0,
                re: isThermExist ? (secondGlazing.data.gasEndUseSummary.DEC[8].mmBtu*1000).toFixed(0) : 0,
                sh: isThermExist ? (secondGlazing.data.gasEndUseSummary.DEC[3].mmBtu*1000).toFixed(0) : 0,
                hp: isThermExist ? (secondGlazing.data.gasEndUseSummary.DEC[9].mmBtu*1000).toFixed(0) : 0,
                hw: isThermExist ? (secondGlazing.data.gasEndUseSummary.DEC[10].mmBtu*1000).toFixed(0) : 0,
                vf: isThermExist ? (secondGlazing.data.gasEndUseSummary.DEC[7].mmBtu*1000).toFixed(0) : 0,
                pa: isThermExist ? (secondGlazing.data.gasEndUseSummary.DEC[6].mmBtu*1000).toFixed(0) : 0,
                eu: isThermExist ? (secondGlazing.data.gasEndUseSummary.DEC[11].mmBtu*1000).toFixed(0) : 0,
                me: isThermExist ? (secondGlazing.data.gasEndUseSummary.DEC[2].mmBtu*1000).toFixed(0) : 0,
                tl: isThermExist ? (secondGlazing.data.gasEndUseSummary.DEC[1].mmBtu*1000).toFixed(0) : 0,
                al: isThermExist ? (secondGlazing.data.gasEndUseSummary.DEC[0].mmBtu*1000).toFixed(0) : 0,
            },
        ]
    };
}