import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Modal from '../Modal/Modal';

const IntroductionModal = ({
                            open, onClose
                        }) => {
    const { t } = useTranslation();
    const { i18n } = useTranslation();

    const onCloseButtonClickHandler = () => {
        onClose();
        // alert('Please select an action from the pop-up');
    };

    const triggerFirstTime = async () => {
        onClose();
    };

    return (
        <Modal open={open} setOpen={onCloseButtonClickHandler}>
            <div className="px-2">
                <div className="flex justify-center">
                    {i18n.language === 'en' &&
                        <img src="/logos/vitro_logo_color_en.svg" alt="Vitro" />
                    }
                    {i18n.language === 'spn' &&
                        <img src="/logos/vitro_logo_color_spn.svg" alt="Vitro" />
                    }
                </div>
            </div>
            <div className="container mx-auto my-8 p-6 bg-white shadow-md rounded-lg">

                <h1 className="text-2xl font-bold mb-4">Optimizing Your Experience</h1>

                <p className="mb-4">Thank you for using Vitro emissions™ Carbon & Energy Calculator! To ensure the best
                    user experience, we recommend accessing the application on a desktop or laptop rather than a mobile
                    device. The calculator is not optimized for mobile screens, and you may encounter display
                    issues.</p>

                <p className="mb-4">Additionally, for an optimal viewing experience, we recommend using a screen with a
                    16:9 aspect ratio. This will enhance the clarity of the interface and help you make the most of
                    Vitro emissions™ Carbon & Energy Calculator features.</p>

                <h2 className="text-lg font-bold mb-2">Tips for an Enhanced Experience:</h2>

                <ul className="list-disc ml-6 mb-4">
                    <li>Desktop over Mobile: For the best experience, use a desktop or laptop computer for access. This
                        will provide a larger and more user-friendly interface since this web application is not mobile compatible.
                    </li>
                    <li>Aspect Ratio Matters: A screen with a 16:9 aspect ratio and at least 13inch is ideal. This ensures that the content
                        is displayed as intended, allowing you to navigate and interact seamlessly.
                    </li>
                    <li>Browser Compatibility: Make sure you are using a supported web browser for optimal performance.
                        We recommend the latest versions of popular browsers such as Chrome or Safari.
                    </li>
                </ul>

                <p className="mb-4">We appreciate your understanding and cooperation. If you have any questions or
                    encounter any issues, feel free to reach out to <a href="https://www.emissionscalc.com/help" className="text-blue-500">Contact
                        Us</a>.</p>

            </div>
        </Modal>
    );
};

IntroductionModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default IntroductionModal;
