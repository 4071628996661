import PropTypes from 'prop-types';

const ProjectInfoField = ({title, value}) => (
    <div className="flex flex-row justify-center text-[8px] ">
        <div className="w-[100px] font-bold">
            <h1 className="align-top">{title}:</h1>
        </div>
        <div className="text-[#00587E] font-bold w-[220px]">
            <h1 className="flex flex-row justify-start mb-1 align-top">{value}</h1>
        </div>
    </div>
);

ProjectInfoField.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default ProjectInfoField;