import { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Modal from '../Modal/Modal';
import { COMPARE_PATH } from '../../routes';

const FirstTimeModal = ({
                            open, onClose, redirect
                        }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const onCloseButtonClickHandler = () => {
        onClose();
        // alert('Please select an action from the pop-up');
    };

    const triggerFirstTime = async () => {
        onClose();
        navigate(`${COMPARE_PATH}?building_type=${redirect}`)
    };

    const [agree, setAgree] = useState(false);

    const checkboxHandler = () => {
        setAgree(!agree);
    }

    return (
        <Modal open={open} setOpen={onCloseButtonClickHandler}>
            <div className="px-2">
                <div className="flex justify-center">
                    {i18n.language === 'en' &&
                        <img src="/logos/vitro_logo_color_en.svg" alt="Vitro" />
                    }
                    {i18n.language === 'spn' &&
                        <img src="/logos/vitro_logo_color_spn.svg" alt="Vitro" />
                    }
                </div>
            </div>

            <div className="mt-4 max-h-[300px] overflow-y-scroll">
                <p>&nbsp;</p>
                <p>{t('agreement.entry')}</p>
                <p>&nbsp;</p>
                <p><strong>{t('agreement.title1')}</strong></p>
                <p>&nbsp;</p>
                <p>{t('agreement.paragraph1')}<span><a className="underline text-[#2563eb]" target="noreferrer" href="http://www.vitroglazings.com/getmedia/45ebd858-c261-4608-8c48-8c5b9996d57d/Privacy-Policy.aspx">{t('agreement.privacyPolicy')}</a></span>.</p>
                <p>&nbsp;</p>
                <p>{t('agreement.paragraph2')}<span><a className="underline text-[#2563eb]" target="noreferrer" href="http://www.vitroglazings.com/getmedia/45ebd858-c261-4608-8c48-8c5b9996d57d/Privacy-Policy.aspx">{t('agreement.privacyPolicy')}</a></span>.</p>
                <p>&nbsp;</p>
                <ol start="2">
                    <li><strong>{t('agreement.title2')}</strong></li>
                </ol>
                <p>&nbsp;</p>
                <p>{t('agreement.paragraph3')}</p>
                <p>&nbsp;</p>
                <p>{t('agreement.paragraph4')}</p>
                <p>&nbsp;</p>
                <ol start="3">
                    <li><strong>{t('agreement.title3')}</strong></li>
                </ol>
                <p>&nbsp;</p>
                <p>{t('agreement.paragraph5')}</p>
                <p>&nbsp;</p>
                <p>{t('agreement.paragraph6')}</p>
                <p>&nbsp;</p>
                <p>{t('agreement.paragraph7')}</p>
                <p><span>&nbsp;</span></p>
            </div>

            <div className="flex justify-center mt-4">
                <input type="checkbox" onChange={checkboxHandler} checked={agree} className="w-5 h-5 border-gray-400 rounded-md checked:bg-blue-500 checked:border-blue-500"/>
                <label htmlFor="agree" className="pl-2"> {t('agreement.acceptAgree')} <b>{t('agreement.termsAndCondition')}</b></label>
            </div>
            <div className="flex justify-center mt-4">
                <button
                    type="button"
                    disabled={!agree}
                    className="bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded disabled:opacity-25"
                    onClick={() => triggerFirstTime()}
                >
                    {t('agreement.continue')}
                </button>
            </div>
        </Modal>
    );
};

FirstTimeModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    redirect: PropTypes.string.isRequired,
};

export default FirstTimeModal;
