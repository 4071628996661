import PropTypes from 'prop-types';
import ModalVideo from '../Modal/ModalVideo';

const VideoModal = ({
                        open, onClose, videoLink,
                    }) => {
    const onCloseButtonClickHandler = () => {
        // onClose();
    };

    return (
        <ModalVideo open={open} setOpen={onCloseButtonClickHandler}>
            <div>
                <iframe width="100%" height="500" src={videoLink} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
            </div>
            <div className="flex justify-center pt-2">
                <button
                    type="submit"
                    onClick={() => onClose()}
                    className="bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded disabled:opacity-25"
                >
                    Skip Video
                </button>
            </div>
        </ModalVideo>
    );
};

VideoModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    videoLink: PropTypes.string.isRequired,
};

export default VideoModal;
