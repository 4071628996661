import { useState,forwardRef, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Slider from '@mui/material/Slider';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { GLAZING_CONSTANT } from './DropdownConstant';
import IconButton from '../IconButton/IconButton';
import ArrowUp from '../Icon/ArrowUp';
import ArrowDown from '../Icon/ArrowDown';
import {
    assignFirstGlazing,
    assignFirstGlazingId,
    assignIsBuildingTypeOpen,
    assignIsCurtainWallOpen,
    assignIsFirstGlazingOpen,
    assignIsLocationOpen,
    assignIsOrientationOpen,
    assignIsSecondGlazingOpen,
    assignIsShadingOpen,
    assignIsWindowToWallRatioOpen,
    assignSecondGlazing,
    assignSecondGlazingId,
} from '../../redux/slices/subMenuSlice';
import IconLink from '../Icon/IconLink';
import ToolTip from '../Tooltip/Tooltip';
import Document from '../Icon/Document';
import Info from '../Icon/Info';

const Dropdown = forwardRef(
    (
        {
            defaultValue, type, isDropdownOpen, advancedFilterRight
        }
    ) => {
        const dispatch = useDispatch();
        const { t } = useTranslation();
        const isOpen = isDropdownOpen;
        const advancedFilterRightValue = advancedFilterRight;
        const [isAdvanced, setIsAdvanced] = useState(false);
        const [selected, setSelected] = useState(defaultValue);
        const [selectedShgcValueFilter, setSelectedShgcValueFilter] = useState([0, 1]);
        const [selectedUValueFilter, setSelectedUValueFilter] = useState([0, 1]);
        const [selectedVltValueFilter, setSelectedVltValueFilter] = useState([0, 1]);
        const [searchValue, setSearchValue] = useState('');
        const minDistance = 0.1;
        const isUsaSelected = useSelector((state) => state.subMenu.location.isAmerica);

        useEffect(() => {
            setSelected(defaultValue);
        }, [defaultValue]);

        useEffect(() => {
            if(isOpen) {
                setIsAdvanced(false);
            }
        }, [isOpen]);

        useEffect(() => {
            if (isOpen) {
                dispatch(assignIsBuildingTypeOpen(false));
                dispatch(assignIsLocationOpen(false));
                dispatch(assignIsOrientationOpen(false));
                dispatch(assignIsWindowToWallRatioOpen(false));
                dispatch(assignIsShadingOpen(false));
                dispatch(assignIsCurtainWallOpen(false));
            }
        }, [isOpen]);

        const handleSearchChange = (event) => {
            const newValue = event.target.value;
            setSearchValue(newValue);
            console.log(searchValue);
        };

        const customTheme = createTheme({
            palette : {
                primary: {
                    main: '#0090a1'
                },
            },
            components : {
                MuiSlider : {
                    styleOverrides : {
                        rail: 'color: #7d7d7d',
                        track: 'color: #00587e; height: 8px',
                        thumb: 'color: #56c7da'
                    }
                }
            },
        });

        function selectAndClose(name, key) {
            if (type === "first") {
                dispatch(assignFirstGlazing(name));
                dispatch(assignFirstGlazingId(key));
                dispatch(assignIsFirstGlazingOpen(false));
                setIsAdvanced(false);
            } else {
                dispatch(assignSecondGlazing(name));
                dispatch(assignSecondGlazingId(key));
                dispatch(assignIsSecondGlazingOpen(false));
                setIsAdvanced(false);
            }
            setSelected(name);
        }

        function setIsOpen() {
            if (type === "first") {
                dispatch(assignIsFirstGlazingOpen(!isOpen));
            } else {
                dispatch(assignIsSecondGlazingOpen(!isOpen));
            }
        }

        function openAdvancedFilter() {
            if (type === "first") {
                dispatch(assignIsFirstGlazingOpen(!isOpen));
                setIsAdvanced(true);
            } else {
                dispatch(assignIsSecondGlazingOpen(!isOpen));
                setIsAdvanced(true);
            }
        }

        const handleShgcFilter = (event, newValue, activeThumb) => {
            if (!Array.isArray(newValue)) {
                return;
            }

            if (activeThumb === 0) {
                setSelectedShgcValueFilter([Math.min(newValue[0], selectedShgcValueFilter[1] - minDistance), selectedShgcValueFilter[1]]);
            } else {
                setSelectedShgcValueFilter([selectedShgcValueFilter[0], Math.max(newValue[1], selectedShgcValueFilter[0] + minDistance)]);
            }
        }

        const handleUValueFilter = (event, newValue, activeThumb) => {
            if (!Array.isArray(newValue)) {
                return;
            }

            if (activeThumb === 0) {
                setSelectedUValueFilter([Math.min(newValue[0], selectedUValueFilter[1] - minDistance), selectedUValueFilter[1]]);
            } else {
                setSelectedUValueFilter([selectedUValueFilter[0], Math.max(newValue[1], selectedUValueFilter[0] + minDistance)]);
            }
        }

        const handleVltFilter = (event, newValue, activeThumb) => {
            if (!Array.isArray(newValue)) {
                return;
            }

            if (activeThumb === 0) {
                setSelectedVltValueFilter([Math.min(newValue[0], selectedVltValueFilter[1] - minDistance), selectedVltValueFilter[1]]);
            } else {
                setSelectedVltValueFilter([selectedVltValueFilter[0], Math.max(newValue[1], selectedVltValueFilter[0] + minDistance)]);
            }
        }

        return (
            <div className="relative flex flex-col items-center rounded-lg">
                <button
                    onClick={() => setIsOpen()}
                    className="bg-[#00587e] p-2 w-[500px] flex items items-center justify-between font-normal text-base rounded-lg border-4 border-transparent active:border-white duration-300 text-white"
                        type="submit"
                >
                    {selected}
                    {!isOpen ? (
                        <ArrowUp />
                    ) : (
                        <ArrowDown />
                    )
                    }
                </button>
                {isOpen && (
                    <>
                        <div className="bg-[#0090a1] absolute top-[-180px] flex flex-col items-start rounded-lg p-2 w-full text-white overflow-y-scroll overflow-x-hidden h-44">
                            {GLAZING_CONSTANT.filter(element => element.info.shgc >= selectedShgcValueFilter[0] && element.info.shgc <= selectedShgcValueFilter[1] && element.info.shgc >= selectedUValueFilter[0] && element.info.shgc <= selectedUValueFilter[1]).length > 0  ?
                                GLAZING_CONSTANT.filter(element =>
                                    element.info.shgc >= selectedShgcValueFilter[0] &&
                                    element.info.shgc <= selectedShgcValueFilter[1] &&
                                    element.info.vlt >= selectedVltValueFilter[0] &&
                                    element.info.vlt <= selectedVltValueFilter[1] &&
                                    element.active === true &&
                                    (isUsaSelected ? element.isUsa === true : element.isMexico === true) &&
                                    element.isAdvance === false
                                ).map((item) => (
                                    <div key={item.key} className="flex w-full justify-between p-1 hover:bg-[#00587e] cursor-pointer rounded-r-lg border-l-transparent hover:border-l-white border-l-4">
                                        <button
                                            onClick={() => selectAndClose(item.name, item.key)}
                                            className="font-normal text-base w-full justify-start flex"
                                            type="submit"
                                        >
                                            {item.name}
                                        </button>
                                        <div className="flex w-[100px] justify-end">
                                            <ToolTip tooltip={item.info}>
                                                <IconButton
                                                    icon={<Info/>}
                                                    onClick={() => null}
                                                />
                                            </ToolTip>
                                            <IconButton
                                                icon={<Document/>}
                                                onClick={() => window.open(item.pdf)}
                                            />
                                        </div>
                                    </div>
                                )) :
                                <div className="font-medium w-full justify-start flex">
                                    <h2>There is no such glazing!</h2>
                                </div>}
                            <hr className="h-px my-2 mx-1 bg-white border-1 w-full"/>
                            <button
                                type="submit"
                                className="w-full p-2 font-bold hover:bg-[#00587e]"
                                onClick={openAdvancedFilter}
                            >
                                Advanced Products & Filters
                            </button>
                        </div>
                        <div className="bg-white absolute top-[-250px] flex flex-col items-start rounded-lg p-1 w-full">
                            <div className="flex flex-row justify-around w-full">
                                <div>
                                    <h2>{t('comparePage.filter.filterShgc')}</h2>
                                    <ThemeProvider theme={customTheme}>
                                        <Box sx={{ width: 200 }}>
                                            <Slider
                                                getAriaLabel={() => 'Minimum distance'}
                                                value={selectedShgcValueFilter}
                                                onChange={handleShgcFilter}
                                                valueLabelDisplay="auto"
                                                disableSwap
                                                max={1}
                                                step={0.1}
                                            />
                                        </Box>
                                    </ThemeProvider>
                                </div>
                                <div>
                                    <h2>{t('comparePage.filter.filterU')}</h2>
                                    <ThemeProvider theme={customTheme}>
                                        <Box sx={{ width: 200 }}>
                                            <Slider
                                                getAriaLabel={() => 'Minimum distance'}
                                                value={selectedUValueFilter}
                                                onChange={handleUValueFilter}
                                                valueLabelDisplay="auto"
                                                disableSwap
                                                max={1}
                                                step={0.1}
                                            />
                                        </Box>
                                    </ThemeProvider>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {isAdvanced && (
                    <>
                        <div className="bg-[#0090a1] absolute top-[-41vh] w-[60vw] flex flex-col items-start rounded-lg p-2 text-white overflow-y-scroll overflow-x-hidden h-[38vh]" style={{right: `calc(${advancedFilterRightValue})`}}>
                            <div className="flex flex-row w-full font-bold">
                                <div className="basis-8/12 flex flex-col">
                                    <h2 className="pl-1">Product Name</h2>
                                    <hr className="h-px my-2 mx-1 bg-white border-1 m-0.5"/>
                                </div>
                                <div className="basis-1/12 flex flex-col text-center bg-[#66bcc7]">
                                    <h2 className="pl-1">U-Value</h2>
                                    <hr className="h-px my-2 mx-1 bg-white border-1"/>
                                </div>
                                <div className="basis-1/12 flex flex-col text-center bg-[#99d3da]">
                                    <h2 className="pl-1">SHGC</h2>
                                    <hr className="h-px my-2 mx-1 bg-white border-1"/>
                                </div>
                                <div className="basis-1/12 flex flex-col text-center bg-[#66bcc7]">
                                    <h2 className="pl-1">VLT</h2>
                                    <hr className="h-px my-2 mx-1 bg-white border-1"/>
                                </div>
                                <div className="basis-1/12 flex flex-col text-center bg-[#99d3da]">
                                    <h2 className="pl-1">Link</h2>
                                    <hr className="h-px my-2 mx-1 bg-white border-1"/>
                                </div>
                            </div>
                            {GLAZING_CONSTANT.filter(element => element.info.shgc >= selectedShgcValueFilter[0] && element.info.shgc <= selectedShgcValueFilter[1] && element.info.shgc >= selectedUValueFilter[0] && element.info.shgc <= selectedUValueFilter[1]).length > 0 ?
                                GLAZING_CONSTANT.filter(element =>
                                    element.info.shgc >= selectedShgcValueFilter[0] &&
                                    element.info.shgc <= selectedShgcValueFilter[1] &&
                                    element.info.uval >= selectedUValueFilter[0] &&
                                    element.info.uval <= selectedUValueFilter[1] &&
                                    element.info.vlt >= selectedVltValueFilter[0] &&
                                    element.info.vlt <= selectedVltValueFilter[1] &&
                                    element.active === true &&
                                    (isUsaSelected ? element.isUsa === true : element.isMexico === true) &&
                                    element.isAdvance === true &&
                                    (searchValue === '' || element.name.toLowerCase().includes(searchValue.toLowerCase()))
                                ).map((item) => (
                                <div key={item.key}
                                     className="flex w-full justify-between hover:bg-[#00587e] cursor-pointer rounded-r-lg border-l-transparent hover:border-l-white hover:border-l-4"
                                >
                                    <button
                                        onClick={() => selectAndClose(item.name, item.key)}
                                        className="font-normal text-base w-full justify-start flex flex-row"
                                        type="submit"
                                    >
                                        <div className="basis-8/12 flex flex-col h-full justify-center text-left">
                                            <h2 className="pl-1 ">{item.name}</h2>
                                        </div>
                                        <div className="basis-1/12 flex flex-col h-full justify-center text-center bg-[#66bcc7] w-full">
                                            <h2 className="pl-1">{(item.info.uval).toFixed(2)}</h2>
                                        </div>
                                        <div className="basis-1/12 flex flex-col h-full justify-center text-center bg-[#99d3da] w-full">
                                            <h2 className="pl-1">{item.info.shgc}</h2>
                                        </div>
                                        <div className="basis-1/12 flex flex-col h-full justify-center text-center bg-[#66bcc7] w-full">
                                            <h2 className="pl-1">{(item.info.vlt * 100).toFixed(0)}%</h2>
                                        </div>
                                        <div className="basis-1/12 flex flex-col items-center justify-center pl-1 bg-[#99d3da] w-full h-full">
                                            <IconButton
                                                icon={<IconLink/>}
                                                onClick={() => window.open(item.pdf)}
                                            />
                                        </div>
                                    </button>
                                </div>
                            )) :
                            <div className="font-medium w-full justify-start flex">
                                <h2>There is no such glazing!</h2>
                            </div>}
                        </div>
                        <div className="bg-white absolute top-[-50vh] w-[60vw] flex flex-col items-start rounded-lg p-1" style={{right: `calc(${advancedFilterRightValue})`}}>
                            <div className="flex flex-row justify-around w-full">
                                <div>
                                    <h2>{t('comparePage.filter.filterU')}</h2>
                                    <ThemeProvider theme={customTheme}>
                                        <Box sx={{ width: 200 }}>
                                            <Slider
                                                getAriaLabel={() => 'Minimum distance'}
                                                value={selectedUValueFilter}
                                                onChange={handleUValueFilter}
                                                valueLabelDisplay="auto"
                                                disableSwap
                                                max={1}
                                                step={0.1}
                                            />
                                        </Box>
                                    </ThemeProvider>
                                </div>
                                <div>
                                    <h2>{t('comparePage.filter.filterShgc')}</h2>
                                    <ThemeProvider theme={customTheme}>
                                        <Box sx={{ width: 200 }}>
                                            <Slider
                                                getAriaLabel={() => 'Minimum distance'}
                                                value={selectedShgcValueFilter}
                                                onChange={handleShgcFilter}
                                                valueLabelDisplay="auto"
                                                disableSwap
                                                max={1}
                                                step={0.1}
                                            />
                                        </Box>
                                    </ThemeProvider>
                                </div>
                                <div>
                                    <h2>{t('comparePage.filter.filterVlt')}</h2>
                                    <ThemeProvider theme={customTheme}>
                                        <Box sx={{ width: 200 }}>
                                            <Slider
                                                getAriaLabel={() => 'Minimum distance'}
                                                value={selectedVltValueFilter}
                                                onChange={handleVltFilter}
                                                valueLabelDisplay="auto"
                                                disableSwap
                                                max={1}
                                                step={0.1}
                                            />
                                        </Box>
                                    </ThemeProvider>
                                </div>
                                <div>
                                    <input
                                        type="text"
                                        id="search"
                                        className="mt-1 p-2 border border-gray-300 rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
                                        placeholder="Type to search..."
                                        value={searchValue}
                                        onChange={handleSearchChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    }
);

Dropdown.propTypes = {
    defaultValue: PropTypes.string.isRequired,
    advancedFilterRight: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    isDropdownOpen: PropTypes.bool.isRequired,
}

export default Dropdown;