import PropTypes from 'prop-types';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

const VitroHorizontalBarChart = ({data, dataKey , format}) => {
    const formatter = (value) => `${value}`;
    const maxData = format === "kWh" ? 300 : 50;

    return (
        <ResponsiveContainer width="90%" height="90%">
            <BarChart
                width={500}
                height={500}
                data={data}
                margin={{
                    top: 5,
                    right: 5,
                    left: 5,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="name"/>
                <YAxis tickFormatter={formatter} domain={[0, maxData]}/>
                <Tooltip/>
                <Legend/>
                {dataKey.map((item) => (
                    <Bar dataKey={item.dataKey} name={item.name} fill={item.color}/>
                ))}
            </BarChart>
        </ResponsiveContainer>
    );
}

VitroHorizontalBarChart.propTypes = {
    data: PropTypes.array.isRequired,
    dataKey: PropTypes.array.isRequired,
    format: PropTypes.string.isRequired,
}

export default VitroHorizontalBarChart;