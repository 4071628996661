import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    general: {
        building_type: '',
        first_glazing: null,
        first_glazing_id: -1,
        second_glazing: null,
        second_glazing_id: -1,
        is_new_building: true,
    },
    location: {
        country: 'united states',
        isAmerica: true,
        isMexico: false,
        isCanada: false,
        id: '',
        state: '',
        city: '',
    },
    orientation: {
        value: 0,
    },
    wwr:{
        value: 10,
    },
    shading:{
        none: true,
        moderate: false,
        high: false,
        custom: false,
        north_overhang: 0,
        north_fins: 0,
        north_top: false,
        south_overhang: 0,
        south_fins: 0,
        south_top: false,
        east_overhang: 0,
        east_fins: 0,
        east_top: false,
        west_overhang: 0,
        west_fins: 0,
        west_top: false,
    },
    curtain_wall: {
        low: true,
        moderate: false,
        high: false,
    },
    menu: {
      isBuildingTypeOpen: false,
      isLocationOpen: false,
      isOrientationOpen: false,
      isWindowToWallRatioOpen: false,
      isShadingOpen: false,
      isCurtainWallOpen: false,
      isFirstGlazingOpen: false,
      isSecondGlazingOpen: false,
    },
}

export const subMenuSlice = createSlice({
    name: 'subMenuSlice',
    initialState,
    reducers: {
        assignBuildingType: (state, action) => {
            state.general.building_type = action.payload;
        },
        assignFirstGlazing: (state, action) => {
            state.general.first_glazing = action.payload;
        },
        assignFirstGlazingId: (state, action) => {
            state.general.first_glazing_id = action.payload;
        },
        assignSecondGlazing: (state, action) => {
            state.general.second_glazing = action.payload;
        },
        assignSecondGlazingId: (state, action) => {
            state.general.second_glazing_id = action.payload;
        },
        assignIsNewBuilding: (state, action) => {
            state.general.is_new_building = action.payload;
        },
        assignState: (state, action) => {
            state.location.state = action.payload;
            state.location.city = '';
        },
        assignStateId: (state, action) => {
            state.location.id = action.payload;
        },
        assignCity: (state, action) => {
            state.location.city = action.payload;
        },
        assignCountry: (state, action) => {
            state.location.country = action.payload;
        },
        assignIsAmerica: (state, action) => {
            state.location.isAmerica = action.payload;
        },
        assignIsMexico: (state, action) => {
            state.location.isMexico = action.payload;
        },
        assignIsCanada: (state, action) => {
            state.location.isCanada = action.payload;
        },
        assignOrientation: (state, action) => {
            state.orientation.value = action.payload;
        },
        wwrIncrementBy10: (state) => {
            state.wwr.value += 10;
        },
        wwrDecrementBy10: (state) => {
            state.wwr.value -= 10;
        },
        wwrCustomChange: (state, action) => {
            state.wwr.value = action.payload;
        },
        shadingAssignNone: (state, action) => {
            state.shading.none = action.payload;
        },
        shadingAssignModerate: (state, action) => {
            state.shading.moderate = action.payload;
        },
        shadingAssignHigh: (state, action) => {
            state.shading.high = action.payload;
        },
        shadingAssignCustom: (state, action) => {
            state.shading.custom = action.payload;
        },
        shadingAssignNorthOverhang: (state, action) => {
            state.shading.north_overhang = action.payload;
        },
        shadingAssignNorthFins: (state, action) => {
            state.shading.north_fins = action.payload;
        },
        shadingAssignNorthTop: (state, action) => {
            state.shading.north_top = action.payload;
        },
        shadingAssignSouthOverhang: (state, action) => {
            state.shading.south_overhang = action.payload;
        },
        shadingAssignSouthFins: (state, action) => {
            state.shading.south_fins = action.payload;
        },
        shadingAssignSouthTop: (state, action) => {
            state.shading.south_top = action.payload;
        },
        shadingAssignEastOverhang: (state, action) => {
            state.shading.east_overhang = action.payload;
        },
        shadingAssignEastFins: (state, action) => {
            state.shading.east_fins = action.payload;
        },
        shadingAssignEastTop: (state, action) => {
            state.shading.east_top = action.payload;
        },
        shadingAssignWestOverhang: (state, action) => {
            state.shading.west_overhang = action.payload;
        },
        shadingAssignWestFins: (state, action) => {
            state.shading.west_fins = action.payload;
        },
        shadingAssignWestTop: (state, action) => {
            state.shading.west_top = action.payload;
        },
        curtainWallAssignLow: (state, action) => {
            state.curtain_wall.low = action.payload;
        },
        curtainWallAssignModerate: (state, action) => {
            state.curtain_wall.moderate = action.payload;
        },
        curtainWallAssignHigh: (state, action) => {
            state.curtain_wall.high = action.payload;
        },
        assignIsBuildingTypeOpen: (state, action) => {
            state.menu.isBuildingTypeOpen = action.payload;
        },
        assignIsLocationOpen: (state, action) => {
            state.menu.isLocationOpen = action.payload;
        },
        assignIsOrientationOpen: (state, action) => {
            state.menu.isOrientationOpen = action.payload;
        },
        assignIsWindowToWallRatioOpen: (state, action) => {
            state.menu.isWindowToWallRatioOpen = action.payload;
        },
        assignIsShadingOpen: (state, action) => {
            state.menu.isShadingOpen = action.payload;
        },
        assignIsCurtainWallOpen: (state, action) => {
            state.menu.isCurtainWallOpen = action.payload;
        },
        assignIsFirstGlazingOpen: (state, action) => {
            state.menu.isFirstGlazingOpen = action.payload;
        },
        assignIsSecondGlazingOpen: (state, action) => {
            state.menu.isSecondGlazingOpen = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    assignBuildingType,
    assignFirstGlazing,
    assignFirstGlazingId,
    assignSecondGlazing,
    assignSecondGlazingId,
    assignIsNewBuilding,
    assignState,
    assignCity,
    assignCountry,
    assignIsAmerica,
    assignIsMexico,
    assignIsCanada,
    assignOrientation,
    wwrIncrementBy10,
    wwrDecrementBy10,
    wwrCustomChange,
    shadingAssignNone,
    shadingAssignModerate,
    shadingAssignHigh,
    shadingAssignCustom,
    shadingAssignNorthOverhang,
    shadingAssignNorthFins,
    shadingAssignNorthTop,
    shadingAssignSouthOverhang,
    shadingAssignSouthFins,
    shadingAssignSouthTop,
    shadingAssignEastOverhang,
    shadingAssignEastFins,
    shadingAssignEastTop,
    shadingAssignWestOverhang,
    shadingAssignWestFins,
    shadingAssignWestTop,
    curtainWallAssignLow,
    curtainWallAssignModerate,
    curtainWallAssignHigh,
    assignStateId,
    assignIsBuildingTypeOpen,
    assignIsLocationOpen,
    assignIsOrientationOpen,
    assignIsWindowToWallRatioOpen,
    assignIsShadingOpen,
    assignIsCurtainWallOpen,
    assignIsFirstGlazingOpen,
    assignIsSecondGlazingOpen,
} = subMenuSlice.actions

export default subMenuSlice.reducer