import PropTypes from 'prop-types';

const ProjectInfoField = ({title, value}) => (
    <div className="flex flex-row justify-end pt-2">
        <div className="w-[230px]">
            <h1 className="p-1">{title}</h1>
        </div>
        <div className="ml-4 p-1 bg-white rounded-lg border-blue-400 border-0">
            <h1 className="flex flex-row justify-start w-[230px] pl-4">{value}</h1>
        </div>
    </div>
);

ProjectInfoField.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default ProjectInfoField;