import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { COMPARE_PATH, REPORT_PATH } from '../../routes';
import IconLocation from '../Icon/IconLocation';
import IconMap from '../Icon/IconMap';
import IconWwr from '../Icon/IconWwr';
import IconShading from '../Icon/IconShading';
import IconCurtainWall from '../Icon/IconCurtainWall';
import SelectLocation from '../../containers/SelectLocationSubMenu/SelectLocation';
import SelectOrientation from '../../containers/SelectOrientationSubMenu/SelectOrientation';
import SelectWwr from '../../containers/SelectWwrSubMenu/SelectWwr';
import SelectShading from '../../containers/SelectShadingSubMenu/SelectShading';
import SelectCurtainWall from '../../containers/SelectCurtainWallSubMenu/SelectCurtainWall';
import SelectBuildingType from '../../containers/SelectBuildingTypeSubMenu/SelectBuildingType';
import IconBuildingType from '../Icon/IconBuildingType';
import Calculator from '../Icon/Calculator';
import {
    assignIsBuildingTypeOpen,
    assignIsCurtainWallOpen,
    assignIsFirstGlazingOpen,
    assignIsLocationOpen,
    assignIsOrientationOpen,
    assignIsSecondGlazingOpen,
    assignIsShadingOpen,
    assignIsWindowToWallRatioOpen,
} from '../../redux/slices/subMenuSlice';

const Sidebar = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const selectBuildingSelectionPage = useSelector((state) => state.subMenu.menu.isBuildingTypeOpen);
    const selectLocationPage = useSelector((state) => state.subMenu.menu.isLocationOpen);
    const selectOrientationPage = useSelector((state) => state.subMenu.menu.isOrientationOpen);
    const selectWwrPage = useSelector((state) => state.subMenu.menu.isWindowToWallRatioOpen);
    const selectShadingPage = useSelector((state) => state.subMenu.menu.isShadingOpen);
    const selectCurtainWallPage = useSelector((state) => state.subMenu.menu.isCurtainWallOpen);
    const buildingType = useSelector((state) => state.subMenu.general.building_type);
    const isNewBuilding = useSelector((state) => state.subMenu.general.is_new_building);
    const stateId = useSelector((state) => state.subMenu.location.id);
    const city = useSelector((state) => state.subMenu.location.city);
    const country = useSelector((state) => state.subMenu.location.country);
    const stateName = useSelector((state) => state.subMenu.location.state);
    const orientation = useSelector((state) => state.subMenu.orientation.value);
    const glazingPercentage = useSelector((state) => state.subMenu.wwr.value);
    const southHorizontalFinDepth = useSelector((state) => state.subMenu.shading.south_overhang);
    const westHorizontalFinDepth = useSelector((state) => state.subMenu.shading.west_overhang);
    const eastHorizontalFinDepth = useSelector((state) => state.subMenu.shading.east_overhang);
    const southVerticalFinDepth = useSelector((state) => state.subMenu.shading.south_fins);
    const westVerticalFinDepth = useSelector((state) => state.subMenu.shading.west_fins);
    const eastVerticalFinDepth = useSelector((state) => state.subMenu.shading.east_fins);
    const firstGlazingId = useSelector((state) => state.subMenu.general.first_glazing_id);
    const secondGlazingId = useSelector((state) => state.subMenu.general.second_glazing_id);
    const curtainWallNone = useSelector((state) => state.subMenu.curtain_wall.low);
    const curtainWallModerate = useSelector((state) => state.subMenu.curtain_wall.moderate);
    const curtainWallHigh = useSelector((state) => state.subMenu.curtain_wall.high);
    const isShadingCustom = useSelector((state) => state.subMenu.shading.custom);
    const shadingNone = useSelector((state) => state.subMenu.shading.none);
    const shadingModerate = useSelector((state) => state.subMenu.shading.moderate);
    const shadingHigh = useSelector((state) => state.subMenu.shading.high);
    const [shadingDetail, setShadingDetail] = useState('Not Selected');
    const [curtainWallDetail, setCurtainWallDetail] = useState('Not Selected');

    useEffect(() => {
        if (shadingNone) {
            setShadingDetail('None');
        } else if (shadingModerate) {
            setShadingDetail('Moderate');
        } else if (shadingHigh) {
            setShadingDetail('High');
        } else if (isShadingCustom) {
            setShadingDetail('Custom');
        } else {
            setShadingDetail(t('comparePage.customMarker.notSelected'));
        }
    }, [shadingNone, shadingModerate, shadingHigh, t]);

    useEffect(() => {
        if (curtainWallNone) {
            setCurtainWallDetail('Low');
        } else if (curtainWallModerate) {
            setCurtainWallDetail('Moderate');
        } else if (curtainWallHigh) {
            setCurtainWallDetail('High');
        } else {
            setCurtainWallDetail(t('comparePage.customMarker.notSelected'));
        }
    }, [curtainWallNone, curtainWallModerate, curtainWallHigh, t]);

    const openSubMenu = (index) => {
        switch (index) {
            case 0:
                dispatch(assignIsBuildingTypeOpen(!selectBuildingSelectionPage));
                dispatch(assignIsLocationOpen(false));
                dispatch(assignIsOrientationOpen(false));
                dispatch(assignIsWindowToWallRatioOpen(false));
                dispatch(assignIsShadingOpen(false));
                dispatch(assignIsCurtainWallOpen(false));
                dispatch(assignIsFirstGlazingOpen(false));
                dispatch(assignIsSecondGlazingOpen(false));
                break;
            case 1:
                dispatch(assignIsBuildingTypeOpen(false));
                dispatch(assignIsLocationOpen(!selectLocationPage));
                dispatch(assignIsOrientationOpen(false));
                dispatch(assignIsWindowToWallRatioOpen(false));
                dispatch(assignIsShadingOpen(false));
                dispatch(assignIsCurtainWallOpen(false));
                dispatch(assignIsFirstGlazingOpen(false));
                dispatch(assignIsSecondGlazingOpen(false));
                break;
            case 2:
                dispatch(assignIsBuildingTypeOpen(false));
                dispatch(assignIsLocationOpen(false));
                dispatch(assignIsOrientationOpen(!selectOrientationPage));
                dispatch(assignIsWindowToWallRatioOpen(false));
                dispatch(assignIsShadingOpen(false));
                dispatch(assignIsCurtainWallOpen(false));
                dispatch(assignIsFirstGlazingOpen(false));
                dispatch(assignIsSecondGlazingOpen(false));
                break;
            case 3:
                dispatch(assignIsBuildingTypeOpen(false));
                dispatch(assignIsLocationOpen(false));
                dispatch(assignIsOrientationOpen(false));
                dispatch(assignIsWindowToWallRatioOpen(!selectWwrPage));
                dispatch(assignIsShadingOpen(false));
                dispatch(assignIsCurtainWallOpen(false));
                dispatch(assignIsFirstGlazingOpen(false));
                dispatch(assignIsSecondGlazingOpen(false));
                break;
            case 4:
                dispatch(assignIsBuildingTypeOpen(false));
                dispatch(assignIsLocationOpen(false));
                dispatch(assignIsOrientationOpen(false));
                dispatch(assignIsWindowToWallRatioOpen(false));
                dispatch(assignIsShadingOpen(!selectShadingPage));
                dispatch(assignIsCurtainWallOpen(false));
                dispatch(assignIsFirstGlazingOpen(false));
                dispatch(assignIsSecondGlazingOpen(false));
                break;
            case 5:
                dispatch(assignIsBuildingTypeOpen(false));
                dispatch(assignIsLocationOpen(false));
                dispatch(assignIsOrientationOpen(false));
                dispatch(assignIsWindowToWallRatioOpen(false));
                dispatch(assignIsShadingOpen(false));
                dispatch(assignIsCurtainWallOpen(!selectCurtainWallPage));
                dispatch(assignIsFirstGlazingOpen(false));
                dispatch(assignIsSecondGlazingOpen(false));
                break;
            case 6:
                if(window.location.pathname === '/compare') {
                    dispatch(assignIsBuildingTypeOpen(false));
                    dispatch(assignIsLocationOpen(false));
                    dispatch(assignIsOrientationOpen(false));
                    dispatch(assignIsWindowToWallRatioOpen(false));
                    dispatch(assignIsShadingOpen(false));
                    dispatch(assignIsCurtainWallOpen(false));
                    dispatch(assignIsFirstGlazingOpen(true));
                    dispatch(assignIsSecondGlazingOpen(true));
                } else {
                    dispatch(assignIsBuildingTypeOpen(false));
                    dispatch(assignIsLocationOpen(false));
                    dispatch(assignIsOrientationOpen(false));
                    dispatch(assignIsWindowToWallRatioOpen(false));
                    dispatch(assignIsShadingOpen(false));
                    dispatch(assignIsCurtainWallOpen(false));
                    dispatch(assignIsFirstGlazingOpen(false));
                    dispatch(assignIsSecondGlazingOpen(false));
                }
                break;
            default:
                dispatch(assignIsBuildingTypeOpen(false));
                dispatch(assignIsLocationOpen(false));
                dispatch(assignIsOrientationOpen(false));
                dispatch(assignIsWindowToWallRatioOpen(false));
                dispatch(assignIsShadingOpen(false));
                dispatch(assignIsCurtainWallOpen(false));
                dispatch(assignIsFirstGlazingOpen(false));
                dispatch(assignIsSecondGlazingOpen(false));
                break;
        }
    }

    function navigateReportPage() {

        if (stateId === '' || city === '') {
            toast.error('Please select state and city from the location tab.');
        } else if (firstGlazingId === -1 || secondGlazingId === -1) {
            toast.error('Please select two glazing to compare.');
            if(window.location.pathname === '/report') {
                navigate(`${COMPARE_PATH}?building_type=${buildingType}`)
            }
        } else {
            dispatch(assignIsBuildingTypeOpen(false));
            dispatch(assignIsLocationOpen(false));
            dispatch(assignIsOrientationOpen(false));
            dispatch(assignIsWindowToWallRatioOpen(false));
            dispatch(assignIsShadingOpen(false));
            dispatch(assignIsCurtainWallOpen(false));
            dispatch(assignIsFirstGlazingOpen(false));
            dispatch(assignIsSecondGlazingOpen(false));
            navigate(
                `${REPORT_PATH}`,
                {
                    state:
                        {
                            buildingType,
                            isNewBuilding,
                            stateId,
                            country,
                            city,
                            stateName,
                            orientation,
                            glazingPercentage,
                            southHorizontalFinDepth,
                            westHorizontalFinDepth,
                            eastHorizontalFinDepth,
                            southVerticalFinDepth,
                            westVerticalFinDepth,
                            eastVerticalFinDepth,
                            firstGlazingId,
                            secondGlazingId,
                            curtainWallNone,
                            curtainWallModerate,
                            curtainWallHigh,
                            curtainWallDetail,
                            shadingNone,
                            shadingModerate,
                            shadingHigh,
                            shadingDetail,
                        },
                },
            );
        }
    }

    return (
        <div>
            <div className="flex flex-col fixed bg-gradient-to-br from-[#00587E] to-[#56C7DA] h-full text-white text-base w-[263px]">
                <nav className="mt-4 flex flex-col px-4 mb-2 font-bold">
                    {t('sidebar.title.select')}
                </nav>
                <button
                    onClick={() => openSubMenu(0)}
                    type="submit"
                >
                    <div className="flex flex-wrap">
                        <div className='mt-4 ml-4'>
                            <IconBuildingType/>
                        </div>
                        <nav className="mt-4 flex flex-col px-4 mb-2">
                            {t('sidebar.title.SelectBuildingType')}
                        </nav>
                    </div>
                </button>
                <button
                    onClick={() => openSubMenu(1)}
                    type="submit"
                >
                <div className="flex flex-wrap">
                    <div className='mt-4 ml-4'>
                        <IconLocation/>
                    </div>
                    <nav className="mt-4 flex flex-col px-4 mb-2">
                    {t('sidebar.title.selectLocation')}
                    </nav>
                </div>
                </button>
                <button
                    onClick={() => openSubMenu(2)}
                    type="submit"
                >
                    <div className="flex flex-wrap">
                        <div className='mt-4 ml-4'>
                            <IconMap />
                        </div>
                        <nav className="mt-4 flex flex-col px-4 mb-2">
                            {t('sidebar.title.selectOrientation')}
                        </nav>
                    </div>
                </button>
                <button
                    onClick={() => openSubMenu(3)}
                    type="submit"
                >
                    <div className="flex flex-wrap">
                        <div className='mt-4 ml-4'>
                            <IconWwr />
                        </div>
                        <nav className="mt-4 flex flex-col px-4 mb-2">
                            {t('sidebar.title.selectWwr')}
                        </nav>
                    </div>
                </button>
                <button
                    onClick={() => openSubMenu(4)}
                    type="submit"
                >
                    <div className="flex flex-wrap">
                        <div className='mt-4 ml-4'>
                            <IconShading />
                        </div>
                        <nav className="mt-4 flex flex-col px-4 mb-2">
                            {t('sidebar.title.selectShading')}
                        </nav>
                    </div>
                </button>
                <button
                    onClick={() => openSubMenu(5)}
                    type="submit"
                >
                    <div className="flex flex-wrap">
                        <div className='mt-4 ml-4'>
                            <IconCurtainWall />
                        </div>
                        <nav className="mt-4 flex flex-col px-4 mb-2">
                            {t('sidebar.title.selectCurtainWall')}
                        </nav>
                    </div>
                </button>
                <button
                    className="bg-[#56C7DA] hover:bg-[#004887] rounded-full m-10"
                    onClick={() => navigateReportPage()}
                    type="submit"
                >
                    <div className="flex flex-row ">
                        <div className='ml-4 mt-2'>
                            <Calculator />
                        </div>
                        <nav className="w-full p-2 ml-4 justify-center text-left">
                            {t('sidebar.title.calculate')}
                        </nav>
                    </div>
                </button>
            </div>
            {selectBuildingSelectionPage && (
                <SelectBuildingType nextFunction={openSubMenu}/>
            )}
            {selectLocationPage && (
                <SelectLocation nextFunction={openSubMenu}/>
            )}
            {selectOrientationPage && (
                <SelectOrientation nextFunction={openSubMenu}/>
            )}
            {selectWwrPage && (
                <SelectWwr nextFunction={openSubMenu}/>
            )}
            {selectShadingPage && (
                <SelectShading nextFunction={openSubMenu}/>
            )}
            {selectCurtainWallPage && (
                <SelectCurtainWall nextFunction={openSubMenu}/>
            )}
        </div>
    )
}

export default Sidebar