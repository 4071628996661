import { useEffect } from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import { t } from 'i18next';
import { Helmet } from 'react-helmet';
import ToastWrapper from './components/ToastWrapper/ToastWrapper';
import './App.css';
import MainLayout from './layouts/MainLayout';
import {applyTheme} from './themes/utils';
import themes from './themes';
import Compare from './containers/Compare/Compare';
import NotFound from './containers/NotFound/NotFound';
import MainPage from './containers/MainPage/MainPage';
import { MAIN_LAYOUT_PATH } from './routes';
import Help from './containers/Help/Help';
import About from './containers/About/About';
import Report from './containers/Report/Report';

const App = () => {
    useEffect(() => {
        applyTheme(themes.base);
    }, []);

    return (
        <>
            <ToastWrapper/>

            <Helmet>
                <title>{t('appTitle')}</title>
            </Helmet>

            <Routes>

            <Route path="/" element={<MainLayout />}>

                <Route
                    path="/"
                    element={<Navigate to={MAIN_LAYOUT_PATH} />}
                />

                <Route path="/compare" element={<Compare />}/>

                <Route path="/report" element={<Report />}/>

                <Route path="/about" element={<About />}/>

                <Route path="/help" element={<Help />}/>

            </Route>

            <Route
                path="*"
                element={<NotFound />}
            />

            <Route
                path="/main"
                element={<MainPage />}
            />

            </Routes>
        </>
    );
};

export default App;
