import { useSelector, useDispatch } from 'react-redux'
import { geoCentroid } from 'd3-geo';
import {
    ComposableMap,
    Geographies,
    Geography,
    Marker,
    Annotation
} from 'react-simple-maps';
import canadaGeoJson from './canada.json';
import {
    assignState,
    assignStateId
} from '../../redux/slices/subMenuSlice';

const geoUrl = canadaGeoJson;

const allStates = [
    {"id":"BC", "val":"02"},
    {"id":"NU", "val":"03"},
    {"id":"NT", "val":"04"},
    {"id":"AB", "val":"05"},
    {"id":"NL", "val":"06"},
    {"id":"SK", "val":"07"},
    {"id":"MB", "val":"08"},
    {"id":"QC", "val":"09"},
    {"id":"ON", "val":"10"},
    {"id":"NB", "val":"11"},
    {"id":"NS", "val":"12"},
    {"id":"PE", "val":"13"},
    {"id":"YT", "val":"14"}
];

const offsets = {
    NL: [50, -100],
    NB: [30, 100],
    PE: [50, -80],
    NS: [50, -20],
};

const MapChartMexico = () => {
    const dispatch = useDispatch();
    const selectedState = useSelector((state) => state.subMenu.location.state);

    const handleClick = geo => () => {
        const state = allStates.find(s => s.val === geo.properties.sta_code[0]);
        dispatch(assignState(geo.properties.sta_name));
        dispatch(assignStateId(state.id));
    };
    return (
        <ComposableMap
            projection="geoAlbers"
            width={600}
            height={600}
            projectionConfig={{
                scale: 550,
                center: [-2, 125],
            }}
        >
            <Geographies geography={geoUrl} >
                {({geographies}) => (
                    <>
                        {geographies.map(geo => {
                                const isClicked = selectedState === geo.properties.sta_name;
                                return (
                                    <Geography
                                        key={geo.rsmKey}
                                        stroke="#FFF"
                                        geography={geo}
                                        fill={isClicked ? "#4cb1bd" : "#DDD"}
                                        onClick={handleClick(geo)}
                                        style={{
                                            default: {outline: "none"},
                                            hover: {outline: "none"},
                                            pressed: {outline: "none"},
                                        }}
                                    />
                                )
                            }
                        )}
                        {geographies.map(geo => {
                            const centroid = geoCentroid(geo);
                            const cur = allStates.find(s => s.val === geo.properties.sta_code[0]);
                            return (
                                <g key={`${geo.rsmKey}-name`}>
                                    {cur &&
                                        centroid[0] > -160 &&
                                        centroid[0] < -60 &&
                                        (Object.keys(offsets).indexOf(cur.id) === -1 ? (
                                            <Marker coordinates={centroid}>
                                                <text y="2" fontSize={14} textAnchor="middle" onClick={handleClick(geo)} className="cursor-default">
                                                    {cur.id}
                                                </text>
                                            </Marker>
                                        ) : (
                                            <Annotation
                                                subject={centroid}
                                                dx={offsets[cur.id][0]}
                                                dy={offsets[cur.id][1]}
                                            >
                                                <text x={4} fontSize={14} alignmentBaseline="middle" onClick={handleClick(geo)} className="cursor-default">
                                                    {cur.id}
                                                </text>
                                            </Annotation>
                                        ))
                                    }
                                </g>
                            );
                        })}
                    </>
                )}
            </Geographies>
        </ComposableMap>
    );
}

export default MapChartMexico;
