import PropTypes from 'prop-types';
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

const VitroVerticalBarChartPdf = ({data, dataKey , format, min, max}) => {
    const formatter = (value) => `${value}`;

    return (
        <ResponsiveContainer width="90%" height="90%">
            <BarChart
                width={200}
                height={200}
                data={data}
                layout="vertical"
                margin={{ top: 10, right: 0, left: -85, bottom: 10 }}
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis domain={[min, max]} type="number" dy="25" tickFormatter={formatter} tick={{fontSize: 8}} />
                <YAxis type="category" dataKey="name" width={200} tick={{fontSize: 8}}/>
                <Tooltip/>
                <Bar
                    dataKey={dataKey}
                    barSize={10}
                >
                    {data.map((entry, index) => (
                        <Cell name={data[index].name} fill={data[index].color} />
                    ))}
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
}

VitroVerticalBarChartPdf.propTypes = {
    data: PropTypes.array.isRequired,
    dataKey: PropTypes.array.isRequired,
    format: PropTypes.string.isRequired,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
}

export default VitroVerticalBarChartPdf;