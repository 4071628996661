export const translation = {
    appTitle: 'Vitro Energy Calculator',
    agreement: {
        privacyPolicy: 'Privacy Policy',
        acceptAgree: 'I accept and agree to the ',
        termsAndCondition: 'terms and conditions',
        continue: 'Continue',
        entry: 'By clicking submit below, you consent to allow Vitro Architectural Glass to store and process the information submitted above to provide you the content requested.',
        title1: 'The general statement',
        paragraph1: 'Vitro Architectural Glass is committed to protecting and respecting your privacy, and we will only use your personal information to administer your account and provide the products and services you requested from us. Vitro Architectural Glass needs the contact information you provide to contact you about our products and services. You may unsubscribe from these communications at any time. For information on how to unsubscribe, as well as our privacy practices and commitment to protecting your privacy, review our ',
        paragraph2: 'This website stores cookies on your computer. These cookies are used to collect information about how you interact with our website and allow us to improve and customize your browsing experience and for analytics and metrics about our visitors both on this website and other media. To find out more about the cookies we use, see our ',
        title2: 'Basis of calculation and data input sources/disclaimer',
        paragraph3: 'This tool employs energy calculations provided by the United States Department of Energy and data from the National Weather Service. Vitro assumes no responsibility for the accuracy of the information available for simulations in this program.',
        paragraph4: 'While Vitro has made a good faith effort to verify the reliability of the results of this computer-based tool, it may contain unknown programming errors that may result in incorrect results. The user is encouraged to use good judgment and report any questionable results to Vitro for evaluation. The applicability and subsequent results of data simulated by this tool will be compromised if the user fails to input the correct information. Vitro makes no warranty or guarantee as to the results obtained by the user of this tool, and its results are not intended to be used as a formal energy analysis.',
        title3: 'Trademark and intellectual property/ownership',
        paragraph5: 'Atlantica®, Azuria®, Graylite®, Optiblue®, Optigray®, Pacifica®, Solarban®, the Solarban® logo, Solarblue®, Solarbronze®, Solarcool®, Solargray®, Solexia®, Starphire®, the Starphire® logo, Sungate®, Vistacool®, Vitro®, and the Vitro logo are registered trademarks owned by Vitro. Acuity™, Vitro Certified™ and the Vitro Certified™ Network logo are trademarks owned by Vitro.',
        paragraph6: 'Portions of the intellectual property used to power this tool not licensed or provided by others (including the U.S. Department of Energy and the National Weather Service) are owned by Vitro.',
        paragraph7: 'FenestraPro is a trademark of Phison Ltd. T/A FenestraPro.',
    },
    about: {
        title1: 'About Vitro eMissions Carbon & Energy Calculator',
        paragraph1: 'Vitro eMissions is a web application to give Users an understanding of the impact that different glazing design decisions will have on annual operational carbon emissions and energy use.',
        paragraph2: 'Depending on a building’s parameters (for example, its typology, location, orientation), the building envelope can affect more than half of a building’s operational energy use, and related greenhouse gas emissions. In particular, glazing design can have major impacts. Decisions such as window to wall ratio; the glazed unit configuration and associated properties; whether it is shaded or not can hugely affect energy use.',
        paragraph3: 'Using notional building types with predefined parameters, Users can investigate the how envelope design decisions will affect the building’s energy performance.',
        paragraph4: 'Vitro eMissions is a partnership between Vitro Architectural Glass and FenestraPro. The tool employs energy calculations provided by the United States Department of Energy, and data from the National Weather Service. Vitro assumes no responsibility for the accuracy of the information available for simulations in this program.',
        title2: 'How Operational Energy Results are calculated:',
        paragraph5: 'Vitro eMissions uses the DOE-2 software engine, a widely used and accepted freeware building energy analysis program that can predict the energy use and cost for all types of buildings. DOE-2 uses a description of the building layout, constructions,operating schedules, conditioning systems (lighting, HVAC, etc.) and utility rates provided by the user, along with weather data, to perform an hourly simulation of the building and to estimate utility bills.',
        title3: 'How Operational Carbon Emissions Results are calculated:',
        paragraph6: 'Using the operational energy values derived from DOE-2, carbon emissions are calculated The EPA Simplified GHG Emissions Calculator. This calculator is designed as a simplified calculation tool to help organizations estimate and inventory their annual greenhouse gas (GHG) emissions for US-based operations. All methodologies and default values provided are based on the most current Center for Corporate Climate Leadership Greenhouse Gas Inventory Guidance Documents and the Emission Factors Hub. The Calculator quantifies the direct and indirect emissions from different sources.',
        title4: 'Users input following values:',
        buildingType: 'Building Types',
        location: 'Location',
        orientation: 'Orientation',
        windowToWallRatio: 'Window to Wall Ratio',
        shading: 'Shading (Overhangs / Fins)',
        curtainWallPerformance: 'Curtain Wall Performance',
        glazingConfiguration: 'Glazing Configuration (two types, for comparison)',
        title5: 'The following values are predefined, and as a rule will predefine:',
        subTitle1: 'Building Construction and Thermal resistance of opaque elements – defaults in eQuest',
        externalWall: 'External Wall',
        roof: 'Roof',
        floor: 'Floor',
        subTitle2: 'Occupancy Schedules',
        lighting: 'Lighting',
        taskLighting: 'Task Lighting',
        hvac: 'HVAC Equipment (including Boilers, Chillers, Circulation Loops, Water Heating etc.)',
        infiltration: 'Infiltration / Shell Tightness',
        occupancy: 'Occupancy Factors',
        zone: 'Zone Types',
        period: 'Run Period (full year)',
        geometry: 'Building Geometry',
},
    comparePage: {
        glazing: {
            selectFirstGlazing: 'Select First Glazing to Compare',
            selectSecondGlazing: 'Select Second glazing to compare',
        },
        customMarker: {
            location: 'Location',
            buildingType: 'Building Type',
            existingBuilding: 'Renovation',
            newBuilding: 'New Construction',
            orientation: 'Orientation',
            wwr: 'WWR',
            shading: 'Shading',
            curtainWall: 'Curtain Wall',
            notSelected: 'Not Selected',
            none: 'None',
            low: 'Low',
            moderate: 'Moderate',
            high: 'High',
            custom: 'Custom',
        },
        filter: {
            filterShgc: 'Filter by SHGC',
            filterU: 'Filter by U Value',
            filterVlt: 'Filter by VLT',
        },
        tooltip: {
            uValue: 'U Value',
            vlt: 'VLT',
            shgc: 'SHGC',
        }
    },
    sidebar : {
        title: {
            select: 'Select:',
            SelectLanguage: 'Select Language',
            SelectBuildingType: 'Building Type',
            selectLocation: 'Location',
            selectOrientation: 'Orientation',
            selectWwr: 'Window to Wall Ratio',
            selectShading: 'Shading',
            selectCurtainWall: 'Curtain Wall',
            calculate: 'Calculate',
            next: 'Next',
        },
    },
    header: {
        home: 'Home',
        vitro: 'Vitro Architectural Glass',
        construct: 'Construct',
        about: 'About',
        help: 'Contact Us',
    },
    help: {
        firstname: 'First Name',
        lastname: 'Last Name',
        company: 'Company',
        email: 'Email',
        message: 'Message',
        submit: 'Submit',
    },
    buildingSelectionSubMenu: {
        title: {
            university: 'University',
            hospital: 'Hospital',
            office: 'Office',
            multiFamily: 'Multi Family',
            conventionCenter: 'Convention Center',
            existingBuilding: 'Existing Building',
            newBuilding: 'New Building',
        }
    },
    locationSubMeu: {
        title: {
            pleaseSelectState: 'Please Select State',
            pleaseSelectCity: 'Please Select City',
        },
    },
    orientationSubMenu: {
        title: {
            selectOrientation: 'Select Orientation',
        },
    },
    windowToWallRatioSubMenu: {
        title: {
            selectWindowToWallRatio: 'Select Window to Wall Ratio',
        },
    },
    shadingSubMenu : {
        title: {
            selectShading: 'Select Shading',
            none: 'None',
            moderatePerformance: 'Moderate Performance',
            higherPerformance: 'Higher Performance',
            customPerformance: 'Custom Performance',
        },
        customView: {
            overhang: 'Overhang (ft)',
            fins: 'Fins (ft)',
            top: 'Top',
            floor: 'Floor',
            only: 'Only',
            north: 'North',
            south: 'South',
            east: 'East',
            west: 'West',
        },
    },
    curtainWallSubMenu : {
        title: {
            selectCurtainWall: 'Select Curtain Wall',
            lowPerformance: 'Low Performance',
            moderatePerformance: 'Thermally-Broken Moderate Performance',
            highPerformance: 'Thermally-Broken High Performance',
        }
    },
    report : {
        region: 'Region',
        building: 'Building Type',
        location: 'Location',
        orientation: 'Orientation',
        windowToWallRatio: 'Window to Wall Ratio',
        shading: 'Shading',
        curtainWall: 'Curtain Wall',
        curtainWallProduct: 'Curtain Wall Product',
        hvacEfficiency: 'Hvac Efficiency',
        getDetailedReport: 'Get Detailed Report',
        annualCarbonEmissions: 'Annual Operational Carbon Emissions',
        annualCarbonContext: 'Tons CO2 equivalent (T CO2 eq)',
        operationalCarbon25Years: 'Lifetime impact of Operational Carbon (25 yr)',
        embodiedCarbonOfGlazing: 'Embodied Carbon of Glazing',
        offSet: 'CO2 Offset',
        energyConsumption: 'Energy Consumption',
        annualElectric: 'Annual Electric Consumption (kWh x000)',
        annualGas : 'Annual Gas Consumption (mmBtu x00)',
        monthlyElectric: 'Monthly Electric Consumption (kWh x000)',
        monthlyGas: 'Monthly Gas Consumption (mmBtu x00)',
        moreDetails: 'More Details',
        tooltip: {
            title: 'How do we calculate these results?',
            paragraph1: 'Higher performing glazed units (e.g. triple glazing) will have a larger embodied carbon than poorer performing units (single glazing).',
            paragraph2: 'However, using these higher performing glazing can greatly reduce the operational energy use, and associated carbon.',
            paragraph3: 'This value indicates how long it would take to \'save\' the extra embodied carbon in the reduction in operational carbon.',
        },
        months: {
            'jan': 'Jan',
            'feb': 'Feb',
            'mar': 'Mar',
            'apr': 'Apr',
            'may': 'May',
            'jun': 'Jun',
            'jul': 'Jul',
            'aug': 'Aug',
            'sep': 'Sep',
            'oct': 'Oct',
            'nov': 'Nov',
            'dec': 'Dec',
            'total': 'Total',
        },
        curtainWallValues: {
            Low: 'Low',
            Moderate: 'Medium',
            Medium: 'Medium',
            High: 'High',
        },
        shadingValues: {
            None: 'None',
            Moderate: 'Moderate',
            High: 'High',
            Custom: 'Custom',
        },
        buildingTypes: {
            university: 'University',
            hospital: 'Hospital',
            office: 'Office',
            multiFamily: 'Multi Family',
            conventionCenter: 'Convention Center',
        }
    },
    pdf: {
      pleaseWait: 'Please wait while we prepare your report...',
      pleaseWait2: 'Please wait while we are doing the final touches...',
      name: 'Name',
      email: 'Email',
      company: 'Company',
      position: 'Position',
      download: 'Download now!',
    },
    validationErrors: {
        email: 'Email format is invalid',
        required: 'This field is required',
    },
};
