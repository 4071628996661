import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Seo from '../Seo/Seo';
import Dropdown from '../../components/Dropdown/Dropdown';
import { GLAZING_CONSTANT } from '../../components/Dropdown/DropdownConstant';
import { useQuery } from '../../hooks/useQuery';
import {
    assignBuildingType,
    assignFirstGlazing,
    assignFirstGlazingId,
    assignSecondGlazing,
    assignSecondGlazingId,
} from '../../redux/slices/subMenuSlice';

const Compare = () => {
    const query = useQuery();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const firstGlazingID = query.get('first_glazing');
    const secondGlazingID = query.get('second_glazing');
    const buildingType = query.get('building_type');
    dispatch(assignBuildingType(buildingType));
    const isFirstGlazingOpen = useSelector((state) => state.subMenu.menu.isFirstGlazingOpen);
    const isSecondGlazingOpen = useSelector((state) => state.subMenu.menu.isSecondGlazingOpen);
    const building = useSelector((state) => state.subMenu.general.building_type);
    const first = useSelector((state) => state.subMenu.general.first_glazing);
    const second = useSelector((state) => state.subMenu.general.second_glazing);
    const selectedState = useSelector((state) => state.subMenu.location.state);
    const city = useSelector((state) => state.subMenu.location.city);
    const orientation = useSelector((state) => state.subMenu.orientation.value);
    const glazingPercentage = useSelector((state) => state.subMenu.wwr.value);
    const isShadingNone = useSelector((state) => state.subMenu.shading.none);
    const isShadingModerate = useSelector((state) => state.subMenu.shading.moderate);
    const isShadingHigh = useSelector((state) => state.subMenu.shading.high);
    const isShadingCustom = useSelector((state) => state.subMenu.shading.custom);
    const isCurtainWallLow = useSelector((state) => state.subMenu.curtain_wall.low);
    const isCurtainWallModerate = useSelector((state) => state.subMenu.curtain_wall.moderate);
    const isCurtainWallHigh = useSelector((state) => state.subMenu.curtain_wall.high);
    const isNewBuilding = useSelector((state) => state.subMenu.general.is_new_building);
    const [shadingDetail, setShadingDetail] = useState('Not Selected');
    const [curtainWallDetail, setCurtainWallDetail] = useState('Not Selected');

    // http://localhost:3000/compare?building_type=Office&first_glazing=1234&second_glazing=4567

    useEffect(() => {
        if(firstGlazingID === null) {
            dispatch(assignFirstGlazing(t('comparePage.glazing.selectFirstGlazing')));
            dispatch(assignFirstGlazingId(-1));
        } else if (firstGlazingID !== null && first === null){
            if(firstGlazingID < GLAZING_CONSTANT.length) {
                dispatch(assignFirstGlazing(GLAZING_CONSTANT.at(firstGlazingID > 0 ? firstGlazingID-1 : 0).name));
                dispatch(assignFirstGlazingId(GLAZING_CONSTANT.at(firstGlazingID > 0 ? firstGlazingID-1 : 0).key));
            } else {
                dispatch(assignFirstGlazing(t('comparePage.glazing.selectFirstGlazing')));
                dispatch(assignFirstGlazingId(-1));
            }
        }

        if(secondGlazingID === null) {
            dispatch(assignSecondGlazing(t('comparePage.glazing.selectSecondGlazing')));
            dispatch(assignSecondGlazingId(-1));
        } else if(secondGlazingID !== null && second === null) {
            if(secondGlazingID < GLAZING_CONSTANT.length) {
                dispatch(assignSecondGlazing(GLAZING_CONSTANT.at(secondGlazingID > 0 ? secondGlazingID-1 : 0).name));
                dispatch(assignSecondGlazingId(GLAZING_CONSTANT.at(secondGlazingID > 0 ? secondGlazingID-1 : 0).key));
            } else {
                dispatch(assignSecondGlazing(t('comparePage.glazing.selectSecondGlazing')));
                dispatch(assignSecondGlazingId(-1));
            }
        }
    }, [firstGlazingID, secondGlazingID, t ]);

    useEffect(() => {
        if (isShadingNone) {
            setShadingDetail(t('comparePage.customMarker.none'));
        } else if (isShadingModerate) {
            setShadingDetail(t('comparePage.customMarker.moderate'));
        } else if (isShadingHigh) {
            setShadingDetail(t('comparePage.customMarker.high'));
        } else if (isShadingCustom) {
            setShadingDetail(t('comparePage.customMarker.custom'));
        } else {
            setShadingDetail(t('comparePage.customMarker.notSelected'));
        }
    }, [isShadingNone, isShadingModerate, isShadingHigh, t]);

    useEffect(() => {
        if (isCurtainWallLow) {
            setCurtainWallDetail(t('comparePage.customMarker.low'));
        } else if (isCurtainWallModerate) {
            setCurtainWallDetail(t('comparePage.customMarker.moderate'));
        } else if (isCurtainWallHigh) {
            setCurtainWallDetail(t('comparePage.customMarker.high'));
        } else {
            setCurtainWallDetail(t('comparePage.customMarker.notSelected'));
        }
    }, [isCurtainWallLow, isCurtainWallModerate, isCurtainWallHigh, t]);

    function setBackground() {
        switch (building) {
            case 'Hospital':
                return "url('/images/hospital.png')";
            case 'University':
                return "url('/images/university.png')";
            case 'Multi Family':
                return "url('/images/family.png')";
            case 'Office':
                return "url('/images/office.png')";
            case 'Convention Centre':
                return "url('/images/convention.png')";
            default:
                return "url('/images/university.png')";
        }
    }

    function getBuildingName() {
        switch (building) {
            case 'Hospital':
                return t('buildingSelectionSubMenu.title.hospital');
            case 'University':
                return t('buildingSelectionSubMenu.title.university');
            case 'Multi Family':
                return t('buildingSelectionSubMenu.title.multiFamily');
            case 'Office':
                return t('buildingSelectionSubMenu.title.office');
            case 'Convention Centre':
                return t('buildingSelectionSubMenu.title.conventionCenter');
            default:
                return t('buildingSelectionSubMenu.title.hospital');
        }
    }

    return (
        <>
            <Seo title="Compare"/>

            <section className="h-full">
                <div className="bg-cover bg-no-repeat h-[calc(100vh_-_10rem)]" style={{ backgroundImage: setBackground() }}>
                    <div className="flex justify-end">
                        <div className="flex flex-col m-8">
                            <div className="border-solid border-2 border-white flex flex-row h-[30px] w-[400px] text-base text-white">
                                <h2 className="w-[170px] bg-[#56C7DA] pl-2">{t('comparePage.customMarker.location')}</h2>
                                <div className="flex justify-start w-full pl-2">
                                    <h2>{city ?? selectedState !== '' ? `${city}, ${selectedState}` : t('comparePage.customMarker.notSelected')}</h2>
                                </div>
                            </div>
                            <div className="border-solid border-2 border-white flex flex-row h-[30px] w-[400px] text-base text-white mt-1">
                                <h2 className="w-[170px] bg-[#56C7DA] pl-2">{t('comparePage.customMarker.buildingType')}</h2>
                                <div className="flex justify-start w-full pl-2">
                                    <h2>{isNewBuilding ? t('comparePage.customMarker.newBuilding') : t('comparePage.customMarker.existingBuilding')}</h2>
                                </div>
                            </div>
                            <div className="border-solid border-2 border-white flex flex-row h-[30px] w-[400px] text-base text-white mt-1">
                                <h2 className="w-[170px] bg-[#56C7DA] pl-2">{t('comparePage.customMarker.orientation')}</h2>
                                <div className="flex justify-start w-full pl-2">
                                    <h2>{orientation} °</h2>
                                </div>
                            </div>
                            <div className="border-solid border-2 border-white flex flex-row h-[30px] w-[400px] text-base text-white mt-1">
                                <h2 className="w-[170px] bg-[#56C7DA] pl-2">{t('comparePage.customMarker.wwr')}</h2>
                                <div className="flex justify-start w-full pl-2">
                                    <h2>{glazingPercentage} %</h2>
                                </div>
                            </div>
                            <div className="border-solid border-2 border-white flex flex-row h-[30px] w-[400px] text-base text-white mt-1">
                                <h2 className="w-[170px] bg-[#56C7DA] pl-2">{t('comparePage.customMarker.shading')}</h2>
                                <div className="flex justify-start w-full pl-2">
                                    <h2>{shadingDetail}</h2>
                                </div>
                            </div>
                            <div className="border-solid border-2 border-white flex flex-row h-[30px] w-[400px] text-base text-white mt-1">
                                <h2 className="w-[170px] bg-[#56C7DA] pl-2">{t('comparePage.customMarker.curtainWall')}</h2>
                                <div className="flex justify-start w-full pl-2">
                                    <h2>{curtainWallDetail}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="bg-[#56C7DA] rounded-full m-8 h-[130px] w-[130px] flex flex-row justify-center">
                            <div className="flex flex-col justify-center">
                                <span className="text-white text-base text-center font-bold">{getBuildingName()}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-around pt-2">
                    <Dropdown
                        defaultValue={first}
                        advancedFilterRight="-35vw"
                        isDropdownOpen={isFirstGlazingOpen}
                        type="first"
                    />
                    <Dropdown
                        defaultValue={second}
                        advancedFilterRight="5vw"
                        isDropdownOpen={isSecondGlazingOpen}
                        type="second"
                    />
                </div>
            </section>
        </>
    );
}

export default Compare;