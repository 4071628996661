import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import MapChart from '../Compare/MapChart';
import MapChartMexico from '../Compare/MapChartMexico';
import MapChartCanada from '../Compare/MapChartCanada';
import { StateCityConstant } from './StateCityConstant';
import {
    assignCity,
    assignCountry,
    assignFirstGlazing,
    assignFirstGlazingId,
    assignIsAmerica,
    assignIsCanada,
    assignIsMexico,
    assignSecondGlazing,
    assignSecondGlazingId,
    assignState,
    assignStateId
} from '../../redux/slices/subMenuSlice';

const SelectLocation = ({nextFunction}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const selectedState = useSelector((state) => state.subMenu.location.state);
    const selectedCity = useSelector((state) => state.subMenu.location.city);
    const isUsaSelected = useSelector((state) => state.subMenu.location.isAmerica);
    const isMexicoSelected = useSelector((state) => state.subMenu.location.isMexico);
    const isCanadaSelected = useSelector((state) => state.subMenu.location.isCanada);

    const handleChange = (event) => {
        console.log(event.target.value);
        if(event.target.value === 'Not exist') {
            toast.error('There is no available City. Please choice another state!');
            dispatch(assignCity(''));
        } else {
            dispatch(assignCity(event.target.value));
        }
    };

    function assignMexicoMap() {
        dispatch(assignIsMexico(true));
        dispatch(assignIsAmerica(false));
        dispatch(assignIsCanada(false));
        dispatch(assignState(''));
        dispatch(assignStateId(''));
        dispatch(assignCity(''));
        dispatch(assignFirstGlazing(t('comparePage.glazing.selectFirstGlazing')));
        dispatch(assignFirstGlazingId(-1));
        dispatch(assignSecondGlazing(t('comparePage.glazing.selectSecondGlazing')));
        dispatch(assignSecondGlazingId(-1));
        dispatch(assignCountry('mexico'));
    }

    function assignUsaMap() {
        dispatch(assignIsAmerica(true));
        dispatch(assignIsCanada(false));
        dispatch(assignIsMexico(false));
        dispatch(assignState(''));
        dispatch(assignStateId(''));
        dispatch(assignCity(''));
        dispatch(assignFirstGlazing(t('comparePage.glazing.selectFirstGlazing')));
        dispatch(assignFirstGlazingId(-1));
        dispatch(assignSecondGlazing(t('comparePage.glazing.selectSecondGlazing')));
        dispatch(assignSecondGlazingId(-1));
        dispatch(assignCountry('united states'));
    }

    function assignCanadaMap() {
        dispatch(assignIsCanada(true));
        dispatch(assignIsAmerica(false));
        dispatch(assignIsMexico(false));
        dispatch(assignState(''));
        dispatch(assignStateId(''));
        dispatch(assignCity(''));
        dispatch(assignFirstGlazing(t('comparePage.glazing.selectFirstGlazing')));
        dispatch(assignFirstGlazingId(-1));
        dispatch(assignSecondGlazing(t('comparePage.glazing.selectSecondGlazing')));
        dispatch(assignSecondGlazingId(-1));
        dispatch(assignCountry('canada'));
    }

    return (
        <div
            className="absolute ml-[265px] mt-1 bg-zinc-200 bg-opacity-90 flex flex-col w-[650px] h-[600px] p-1 rounded-lg border-white border-4">
            <div className="flex flex-row p-1 justify-between">
                <h1 className="text-[#00587e]">{t('locationSubMeu.title.pleaseSelectState')}</h1>
                <div>
                    <button
                        className="bg-[#00587e]"
                        onClick={() => assignUsaMap()}
                        type="submit"
                    >
                        <img src="/images/usa_flag.svg" alt="usa" className="h-[20px]"/>
                    </button>
                    <button
                        className="bg-[#00587e] ml-2"
                        onClick={() => assignMexicoMap()}
                        type="submit"
                    >
                        <img src="/images/mexico_flag.svg" alt="mexico" className="h-[20px]"/>
                    </button>
                    <button
                        className="bg-[#00587e] ml-2"
                        onClick={() => assignCanadaMap()}
                        type="submit"
                    >
                        <img src="/images/canada_flag.svg" alt="canada" className="h-[20px]"/>
                    </button>
                </div>
                <button
                    className="bg-[#00587e]"
                    onClick={() => nextFunction(2)}
                    type="submit"
                >
                    <div className="flex flex-wrap">
                        <nav className="w-full p-2 flex-row justify-center text-white font-medium">
                            <h1>{t('sidebar.title.next')}</h1>
                        </nav>
                    </div>
                </button>
            </div>
            <div className="h-[470px] w-[600px]">
                {isUsaSelected && <MapChart />}
                {isMexicoSelected && <MapChartMexico />}
                {isCanadaSelected && <MapChartCanada />}
            </div>
            {selectedState !== '' &&
                <Box sx={{ minWidth: 120 }} className="animate-pulse-custom">
                    <FormControl fullWidth>
                        <InputLabel>{t('locationSubMeu.title.pleaseSelectCity')}</InputLabel>
                        <Select
                            value={selectedCity}
                            label="Please Select City"
                            sx={{
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#00587e',
                                    borderWidth: '3px',
                                }
                            }}
                            onChange={handleChange}
                        >
                            {selectedState !== '' && StateCityConstant[selectedState].map((city) => (
                                <MenuItem value={city}>{city}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            }
        </div>
    );
}

SelectLocation.propTypes = {
    nextFunction: PropTypes.func.isRequired,
}

export default SelectLocation;