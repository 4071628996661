import { Toaster } from 'react-hot-toast';

const ToastWrapper = () => (
    <Toaster
        toastOptions={{
            className: 'bg-lowlight-primary text-slate-600',

            success: {
                className: 'bg-green-600 text-white',
            },
            error: {
                className: 'bg-red-600 text-white',
            },
        }}
    />
);

export default ToastWrapper;
