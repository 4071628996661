import { useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import html2canvas from 'html2canvas';
import Seo from '../Seo/Seo';
import ProjectInfoField from './ProjectInfoField';
import GlassNameField from './GlassNameField';
import VitroHorizontalBarChart from './VitroHorizontalBarChart';
import { MONTHS } from './ReportConstant';
import MonthlyTable from '../../components/MonthlyTable/MonthlyTable';
import { Gauge } from '../../components/Gauge/Gauge';
import VitroVerticalBarChart from './VitroVerticalBarChart';
import ArrowUp from '../../components/Icon/ArrowUp';
import ArrowDown from '../../components/Icon/ArrowDown';
import {useGetTestQuery, useSendReportLogMutation} from '../../redux/services/api';
import DetailedReportModal from '../../components/DetailReportModal/DetailReportModal';
import { GLAZING_CONSTANT } from '../../components/Dropdown/DropdownConstant';
import ProjectInfoFieldPdf from '../../components/Pdf/ProjectInfoFieldPdf';
import VitroVerticalBarChartPdf from './VitroVerticalBarChartPdf';
import MonthlyTablePdf from "../../components/MonthlyTable/MonthlyTablePdf";
import VitroHorizontalBarChartPdf from './VitroHorizontalBarChartPdf';
import {findMinMax, findMinMaxThree, getReportData, getReportPdfData} from './ReportData';
import VitroStackBarChartPdf from './VitroStackBarChartPdf';
import CustomLegend from './CustomLegend';
import Loading from './Loading';
import { CustomGauge } from '../../components/Gauge/CustomGauge';
import IconButton from '../../components/IconButton/IconButton';
import Info from '../../components/Icon/Info';
import ReportTooltip from '../../components/Tooltip/ReportTooltip';
import {CustomGaugePdf} from '../../components/Gauge/CustomGaugePdf';

const Report = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const [isMoreDetailOpen, setIsMoreDetailOpen] = useState(false);
    const [isDetailedReportModalOpen, setIsDetailedReportModalOpen] = useState(false);
    const buildingType = location.state?.buildingType;
    const isNewBuilding = location.state?.isNewBuilding;
    const stateId = location.state?.stateId;
    const city = location.state?.city;
    const country = location.state?.country;
    const stateName = location.state?.stateName;
    const orientation = location.state?.orientation;
    const glazingPercentage = location.state?.glazingPercentage;
    const southHorizontalFinDepth = location.state?.southHorizontalFinDepth;
    const westHorizontalFinDepth = location.state?.westHorizontalFinDepth;
    const eastHorizontalFinDepth = location.state?.eastHorizontalFinDepth;
    const southVerticalFinDepth = location.state?.southVerticalFinDepth;
    const westVerticalFinDepth = location.state?.westVerticalFinDepth;
    const eastVerticalFinDepth = location.state?.eastVerticalFinDepth;
    const firstGlazingId = location.state?.firstGlazingId;
    const secondGlazingId = location.state?.secondGlazingId;
    const curtainWallNone = location.state?.curtainWallNone;
    const curtainWallModerate = location.state?.curtainWallModerate;
    const curtainWallHigh = location.state?.curtainWallHigh;
    const curtainWallDetail = location.state?.curtainWallDetail;
    const shadingNone = location.state?.shadingNone;
    const shadingModerate = location.state?.shadingModerate;
    const shadingHigh = location.state?.shadingHigh;
    const shadingDetail = location.state?.shadingDetail;

    const [reportDate, setReportDate] = useState(new Date().toJSON().slice(0,10).split('-').reverse().join('/'));
    const [detailedReportLoading, setDetailedReportLoading] = useState(false);
    const [imageSrc, setImageSrc] = useState('');
    const [imageSrc2, setImageSrc2] = useState('');
    const [imageSrc3, setImageSrc3] = useState('');
    const [imageSrc4, setImageSrc4] = useState('');
    const divRef = useRef(null);
    const divRef2 = useRef(null);
    const divRef3 = useRef(null);
    const divRef4 = useRef(null);

    const handleScreenshot = async () => {
        await html2canvas(divRef.current, {scale: 3 }).then(canvas => {
            setImageSrc(canvas.toDataURL());
        });
    };

    const handleScreenshot2 = async () => {
        await html2canvas(divRef2.current, {scale: 3}).then(canvas => {
            setImageSrc2(canvas.toDataURL());
        });
    };

    const handleScreenshot3 = async () => {
        await html2canvas(divRef3.current, {scale: 3}).then(canvas => {
            setImageSrc3(canvas.toDataURL());
        });
    };

    const handleScreenshot4 = async () => {
        await html2canvas(divRef4.current, {scale: 3}).then(canvas => {
            setImageSrc4(canvas.toDataURL());
        });
    };

    const [sendReportLog] = useSendReportLogMutation();

    useEffect(() => {
        const postReportLog = async () => {
            const data = {
                "state": stateId,
                "city": city,
                "country": country,
                "building_type": buildingType,
                "isNewBuilding": isNewBuilding,
                "orientation_offset": orientation,
                "percentage_glazing": glazingPercentage,
                "shading_specs": {},
                "firstGlazing": GLAZING_CONSTANT.at(firstGlazingId - 1).name,
                "secondGlazing": GLAZING_CONSTANT.at(secondGlazingId - 1).name,
            };

            await sendReportLog(data);
        };

        postReportLog();

    }, [stateId, city, buildingType, orientation, glazingPercentage, firstGlazingId, secondGlazingId, curtainWallNone,curtainWallModerate, curtainWallHigh, shadingNone, shadingModerate, shadingHigh]);

    function setBackground() {
        switch (buildingType) {
            case 'Hospital':
                return "url('/images/hospital.png')";
            case 'University':
                return "url('/images/university.png')";
            case 'Multi Family':
                return "url('/images/family.png')";
            case 'Office':
                return "url('/images/office.png')";
            case 'Convention Centre':
                return "url('/images/convention.png')";
            default:
                return "url('/images/university.png')";
        }
    }

    function setPdfBackground() {
        switch (buildingType) {
            case 'Hospital':
                return "/images/hospital.png";
            case 'University':
                return "/images/university.png";
            case 'Multi Family':
                return "/images/family.png";
            case 'Office':
                return "/images/office.png";
            case 'Convention Centre':
                return "/images/convention.png";
            default:
                return "/images/university.png";
        }
    }

    function getRequestObject(glazingIndex) {

        let building;
        let portNumber = 3000;

        switch (buildingType) {
            case 'Hospital':
                building = 'Hospital';
                break;
            case 'University':
                building = 'University';
                break;
            case 'Multi Family':
                building = 'Multi-Family';
                break;
            case 'Office':
                building = 'Offices';
                break;
            case 'Convention Centre':
                building = 'Convention Center';
                break;
            default:
                building = 'University';
                break;
        }

        let baseGlazing;

        if(isNewBuilding) {
            baseGlazing = GLAZING_CONSTANT.at(0);
        } else {
            baseGlazing = GLAZING_CONSTANT.at(1);
        }

        const firstGlazing = GLAZING_CONSTANT.at(firstGlazingId-1);
        const secondGlazing = GLAZING_CONSTANT.at(secondGlazingId-1);

        const glazing1 = {"name":baseGlazing.name, "shgc":baseGlazing.info.shgc, "vlt":baseGlazing.info.vlt, "uvalue":baseGlazing.info.uval, "isBaseline":false};
        const glazing2 = {"name":firstGlazing.name, "shgc":firstGlazing.info.shgc, "vlt":firstGlazing.info.vlt, "uvalue":firstGlazing.info.uval, "isBaseline":false};
        const glazing3 = {"name":secondGlazing.name, "shgc":secondGlazing.info.shgc, "vlt":secondGlazing.info.vlt, "uvalue":secondGlazing.info.uval, "isBaseline":false};

        let glazing;

        switch (glazingIndex) {
            case 1:
                glazing = glazing1;
                break;
            case 2:
                glazing = glazing2;
                portNumber = 3001;
                break;
            case 3:
                glazing = glazing3;
                break;
            default:
                glazing = glazing1;
                break;
        }

        let southShading;
        let westShading;
        let eastShading;

        if (southHorizontalFinDepth > 0) {
            southShading = {"orientation":"s", "horizontal_fin_depth":southHorizontalFinDepth, "over_all_windows":true};
        } else if (southVerticalFinDepth > 0) {
            southShading = {"orientation":"s", "vertical_fin_depth":southVerticalFinDepth, "over_all_windows":true};
        } else if (shadingNone) {
                southShading = {"orientation":"s", "horizontal_fin_depth":0.1, "over_all_windows":true};
        } else if (shadingModerate) {
            southShading = {"orientation":"s", "horizontal_fin_depth":4, "over_all_windows":true};
        } else if (shadingHigh) {
            southShading = {"orientation":"s", "horizontal_fin_depth":4, "over_all_windows":true};
        } else {
            southShading = {"orientation":"s", "horizontal_fin_depth":0.1, "over_all_windows":true};
        }

        if (westHorizontalFinDepth > 0) {
            westShading = {"orientation":"w", "horizontal_fin_depth":westHorizontalFinDepth, "over_all_windows":true};
        } else if (westVerticalFinDepth > 0) {
            westShading = {"orientation":"w", "vertical_fin_depth":westVerticalFinDepth, "over_all_windows":true};
        } else if (shadingNone) {
            westShading = {"orientation":"w", "horizontal_fin_depth":0.1, "over_all_windows":true};
        } else if (shadingModerate) {
            westShading = {"orientation":"w", "horizontal_fin_depth":0.1, "over_all_windows":true};
        } else if (shadingHigh) {
            westShading = {"orientation":"w", "vertical_fin_depth":4, "over_all_windows":true};
        } else {
            westShading = {"orientation":"w", "horizontal_fin_depth":0.1, "over_all_windows":true};
        }

        if (eastHorizontalFinDepth > 0) {
            eastShading = {"orientation":"e", "horizontal_fin_depth":eastHorizontalFinDepth, "over_all_windows":true};
        } else if (eastVerticalFinDepth > 0) {
            eastShading = {"orientation":"e", "vertical_fin_depth":eastVerticalFinDepth, "over_all_windows":true};
        } else if (shadingNone) {
            eastShading = {"orientation":"e", "horizontal_fin_depth":0.1, "over_all_windows":true};
        } else if (shadingModerate) {
            eastShading = {"orientation":"e", "horizontal_fin_depth":0.1, "over_all_windows":true};
        } else if (shadingHigh) {
            eastShading = {"orientation":"e", "vertical_fin_depth":4, "over_all_windows":true};
        } else {
            eastShading = {"orientation":"e", "horizontal_fin_depth":0.1, "over_all_windows":true};
        }

        let curtainSystemPerformance = "low";

        if (curtainWallNone) {
            curtainSystemPerformance = "low";
        } else if (curtainWallModerate) {
            curtainSystemPerformance = "medium";
        } else if (curtainWallHigh) {
            curtainSystemPerformance = "high";
        }

        return {
            "country":country,
            "state":stateId.toLowerCase(),
            "city":city,
            "buildingType":building,
            "glazing_specs":
                [
                    glazing,
                ],
            "orientation_offset":orientation,
            "percentage_glazing":glazingPercentage,
            "shading_specs":
                [
                    southShading,
                    westShading,
                    eastShading
                ],
            "curtain_system_performance":curtainSystemPerformance,
            "force_calculation":true,
            "port": portNumber,
        }
    }

    const baseGlazing = useGetTestQuery(getRequestObject(1));
    const firstGlazing = useGetTestQuery(getRequestObject(2));
    const secondGlazing = useGetTestQuery(!baseGlazing.isFetching && baseGlazing.isSuccess ? getRequestObject(3) : skipToken);

    const detailedReportModalOpenHandler = async () => {
        await setReportDate(new Date().toLocaleString());
        setDetailedReportLoading(true);
        await handleScreenshot();
        await handleScreenshot2();
        await handleScreenshot3();
        await handleScreenshot4();
        setIsDetailedReportModalOpen(true);
        setDetailedReportLoading(false);
    };

    if (baseGlazing.isFetching || firstGlazing.isFetching || secondGlazing.isFetching){
        let percentage = 0;

        if(firstGlazing.isSuccess && !firstGlazing.isFetching) {
            percentage = '75%';
        } else if (baseGlazing.isSuccess && !baseGlazing.isFetching) {
            percentage = '50%';
        } else {
            percentage = '25%';
        }

        return (
            <div className="rounded p-6 mb-6 flex flex-col text-slate-600 gap-6 ml-[26px] mt-[10]">
                <div className="flex flex-row justify-center bg-loading-background-2">
                    <div className="bg-logo bg-contain bg-no-repeat bg-center w-1/5 m-2"/>
                    <div className="w-4/5 m-2 border-2 border-white animate-pulse">
                        <div
                            className="bg-white text-black p-4 text-center text-xs font-medium leading-none text-primary-100 border-r-2 border-white"
                            style={{width: percentage}}>
                            Calculating... {percentage}
                        </div>
                    </div>
                </div>

                {percentage === '25%' && (
                    <button type="submit" onClick={() => window.open('https://www.vitroglazings.com/solarban')} className="flex flex-row justify-center">
                        <img src="/images/loading_banner.jpg" alt="vitro banner" />
                    </button>
                )}

                {percentage === '50%' && (
                    <button type="submit" onClick={() => window.open('https://www.vitroglazings.com/solarban')}  className="flex flex-row justify-center">
                        <img src="/images/loading_banner.jpg" alt="vitro banner" />
                    </button>
                )}

                {percentage === '75%' && (
                    <button type="submit" onClick={() => window.open('https://www.vitroglazings.com/solarban')}  className="flex flex-row justify-center">
                        <img src="/images/loading_banner.jpg" alt="vitro banner" />
                    </button>
                )}
            </div>
        );
    }

   if (baseGlazing.isSuccess && firstGlazing.isSuccess && secondGlazing.isSuccess) {

        const reportData = getReportData(baseGlazing, firstGlazing, secondGlazing, buildingType, city, stateName, glazingPercentage, orientation, shadingDetail, curtainWallDetail);
        const pdfReportData = getReportPdfData(baseGlazing, firstGlazing, secondGlazing, buildingType, city, stateName, glazingPercentage, firstGlazingId, secondGlazingId, isNewBuilding);

        return (
            <>
                <Seo title="Report"/>
                <section className="h-full bg-slate-200">

                    {!detailedReportLoading &&
                    <>
                        <div className="flex flex-row">
                            <div className="basis-2/5 pt-2">
                                <ProjectInfoField
                                    title={t('report.building')}
                                    value={`${reportData.general.building_type}, ${isNewBuilding ? t('comparePage.customMarker.newBuilding') : t('comparePage.customMarker.existingBuilding')}`}
                                />
                                <ProjectInfoField
                                    title={t('report.location')}
                                    value={reportData.general.location}
                                />
                                <ProjectInfoField
                                    title={t('report.orientation')}
                                    value={reportData.general.orientation}
                                />
                                <ProjectInfoField
                                    title={t('report.windowToWallRatio')}
                                    value={reportData.general.wwr}
                                />
                                <ProjectInfoField
                                    title={t('report.shading')}
                                    value={reportData.general.shadingDetail}
                                />
                                <ProjectInfoField
                                    title={t('report.curtainWall')}
                                    value={reportData.general.curtainWallDetail}
                                />
                                <div className="flex justify-end">
                                    <button
                                        onClick={detailedReportModalOpenHandler}
                                        className="bg-[#56C7DA] p-2 rounded-lg text-white font-bold mt-4 mb-4"
                                        type="submit"
                                    >
                                        {t('report.getDetailedReport')}
                                    </button>
                                </div>
                                <DetailedReportModal
                                    open={isDetailedReportModalOpen}
                                    onClose={() => setIsDetailedReportModalOpen(false)}
                                    imageSrc={imageSrc}
                                    imageSrc2={imageSrc2}
                                    imageSrc3={imageSrc3}
                                    imageSrc4={imageSrc4}
                                    logData={{
                                        "state": stateId,
                                        "city": city,
                                        "country": country,
                                        "building_type": buildingType,
                                        "isNewBuilding": isNewBuilding,
                                        "orientation_offset": orientation,
                                        "percentage_glazing": glazingPercentage,
                                        "shading_specs": {},
                                        "firstGlazing": GLAZING_CONSTANT.at(firstGlazingId - 1).name,
                                        "secondGlazing": GLAZING_CONSTANT.at(secondGlazingId - 1).name,
                                    }}
                                />
                                <div className="flex flex-col items-end">
                                    {reportData.glass_data.map((item) => (
                                        <GlassNameField
                                            color={item.color}
                                            name={item.name}
                                            link={item.link}
                                        />
                                    ))}
                                </div>
                            </div>
                            <div className="basis-3/5 bg-contain bg-no-repeat bg-right h-[450px]" style={{backgroundImage: setBackground()}} />
                        </div>
                        <div className="p-16">
                            <div className="flex flex-row bg-white rounded pl-2 pr-2">
                                <div className="basis-2/5 m-1 p-2 flex flex-col items-start">
                                    <h1 className="text-3xl font-bold italic text-[#414B5B] mb-4">{t('report.annualCarbonEmissions')}</h1>
                                    <h1 className="text-2xl font-medium italic text-[#414B5B] mb-4">{t('report.annualCarbonContext')}</h1>
                                </div>
                                <div className="bg-[#9ca3af] rounded basis-1/5 m-1 p-2 flex flex-col items-center">
                                    <h1 className="text-center text-white font-medium text-xs">{reportData.glass_data[0].name}</h1>
                                    <hr className="h-px my-2 bg-white border-0 dark:bg-white w-full" />
                                    <div className="pb-2">
                                        <CustomGauge
                                            value={reportData.annual_gauge_data[0].value}
                                            percent={reportData.annual_gauge_data[0].percent}
                                            backgroundColor="#FFFFFF"
                                            arrowColor="#FFFFFF"
                                            baseColor="#CFD0D1"
                                            progressColor="#FFFFFF"
                                            textColor="#000000"
                                        />
                                    </div>
                                </div>
                                <div className="bg-[#00587e] rounded basis-1/5 m-1 p-2 flex flex-col items-center">
                                    <h1 className="text-center text-white font-medium text-xs">{reportData.glass_data[1].name}</h1>
                                    <hr className="h-px my-2 bg-white border-0 dark:bg-white w-full" />
                                    <div className="pb-2">
                                        <CustomGauge
                                            value={reportData.annual_gauge_data[1].value}
                                            percent={reportData.annual_gauge_data[1].percent}
                                            backgroundColor="#F59320"
                                            arrowColor="#F59320"
                                            baseColor="#ADD8E6"
                                            progressColor="#FFFFFF"
                                            textColor="#FFFFFF"
                                        />
                                    </div>
                                </div>
                                <div className="bg-[#0090a1] rounded basis-1/5 m-1 p-2 flex flex-col items-center">
                                    <h1 className="text-center text-white font-medium text-xs">{reportData.glass_data[2].name}</h1>
                                    <hr className="h-px my-2 bg-white border-0 dark:bg-white w-full" />
                                    <div className="pb-2">
                                        <CustomGauge
                                            value={reportData.annual_gauge_data[2].value}
                                            percent={reportData.annual_gauge_data[2].percent}
                                            backgroundColor="#82C341"
                                            arrowColor="#82C341"
                                            baseColor="#ADD8E6"
                                            progressColor="#FFFFFF"
                                            textColor="#FFFFFF"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-row bg-white rounded pl-2 pr-2">
                                <div className="basis-2/5 m-1 p-2 flex flex-col items-start">
                                    <h1 className="text-xl font-light italic text-[#414B5B] mb-4">{t('report.operationalCarbon25Years')}</h1>
                                </div>
                                <div className="bg-[#F7F7F7] rounded basis-1/5 m-1 p-4 flex flex-col items-center">
                                    <h1 className="text-center text-[#999999] font-medium">{reportData.annual_gauge_data[0].lifeValue} TCO2e</h1>
                                </div>
                                <div className="bg-[#F7F7F7] rounded basis-1/5 m-1 p-4 flex flex-col items-center">
                                    <h1 className="text-center text-[#999999] font-medium">{reportData.annual_gauge_data[1].lifeValue} TCO2e</h1>
                                </div>
                                <div className="bg-[#F7F7F7] rounded basis-1/5 m-1 p-4 flex flex-col items-center">
                                    <h1 className="text-center text-[#999999] font-medium">{reportData.annual_gauge_data[2].lifeValue} TCO2e</h1>
                                </div>
                            </div>
                            <div className="flex flex-row bg-white rounded pl-2 pr-2">
                                <div className="basis-2/5 m-1 p-2 flex flex-col items-start">
                                    <h1 className="text-2xl font-medium italic text-[#414B5B] mb-4">{t('report.embodiedCarbonOfGlazing')}</h1>
                                </div>
                                <div className="bg-[#F7F7F7] rounded basis-1/5 m-1 p-2 flex flex-col items-center">
                                    <h1 className="text-center text-black"><span className="text-3xl font-medium text-[#00587e]">{isNewBuilding ? ((baseGlazing.data.totalWindowArea * GLAZING_CONSTANT.at(0).carbon)/1000).toFixed(0) : ((baseGlazing.data.totalWindowArea * GLAZING_CONSTANT.at(1).carbon)/1000).toFixed(0)}</span> TCO2e</h1>
                                </div>
                                <div className="bg-[#F7F7F7] rounded basis-1/5 m-1 p-2 flex flex-col items-center">
                                    <h1 className="text-center text-black">
                                      <span className="text-3xl font-medium text-[#00587e]">
                                        {firstGlazingId === 3 ? 'NA' : ((firstGlazing.data.totalWindowArea * GLAZING_CONSTANT.at(firstGlazingId - 1).carbon) / 1000).toFixed(0)}
                                      </span>
                                        {firstGlazingId !== 3 && ' TCO2e'}
                                    </h1>
                                </div>
                                <div className="bg-[#F7F7F7] rounded basis-1/5 m-1 p-2 flex flex-col items-center">
                                    <h1 className="text-center text-black">
                                      <span className="text-3xl font-medium text-[#00587e]">
                                        {secondGlazingId === 3 ? 'NA' : ((secondGlazing.data.totalWindowArea * GLAZING_CONSTANT.at(secondGlazingId - 1).carbon) / 1000).toFixed(0)}
                                      </span>
                                        {secondGlazingId !== 3 && ' TCO2e'}
                                    </h1>
                                </div>
                            </div>
                            <div className="flex flex-row bg-white rounded pl-2 pr-2">
                                <div className="basis-2/5 m-1 p-2 flex items-start">
                                    <h1 className="text-2xl font-medium italic text-[#414B5B] mb-4 pr-2">{t('report.offSet')}</h1>
                                    <ReportTooltip
                                        title={t('report.tooltip.title')}
                                        paragraph1={t('report.tooltip.paragraph1')}
                                        paragraph2={t('report.tooltip.paragraph2')}
                                        paragraph3={t('report.tooltip.paragraph3')}
                                    >
                                        <IconButton
                                            icon={<Info/>}
                                            onClick={() => null}
                                        />
                                    </ReportTooltip>
                                </div>
                                <div className="bg-[#F7F7F7] rounded basis-1/5 m-1 p-2 flex flex-col items-center" />
                                <div className="bg-[#F7F7F7] rounded basis-1/5 m-1 p-2 flex flex-col items-center">
                                    <h1 className="text-center text-black"><span className="text-3xl font-medium text-[#00587e]">
                                        { firstGlazingId === 3 ? 'NA' :
                                            Math.abs(((((((firstGlazing.data.totalWindowArea * GLAZING_CONSTANT.at(firstGlazingId - 1).carbon)/1000).toFixed(0))
                                                        -
                                                        (isNewBuilding ? ((baseGlazing.data.totalWindowArea * GLAZING_CONSTANT.at(0).carbon)/1000).toFixed(0) : ((baseGlazing.data.totalWindowArea * GLAZING_CONSTANT.at(1).carbon)/1000).toFixed(0)))
                                                    /
                                                    Math.abs((reportData.annual_gauge_data[0].value - reportData.annual_gauge_data[1].value)))
                                                * 12).toFixed(0))
                                        }
                                    </span>{firstGlazingId !== 3 && ' Months'}</h1>
                                </div>
                                <div className="bg-[#F7F7F7] rounded basis-1/5 m-1 p-2 flex flex-col items-center">
                                    <h1 className="text-center text-black"><span className="text-3xl font-medium text-[#00587e]">
                                        {secondGlazingId === 3 ? 'NA' :
                                            Math.abs(((((((secondGlazing.data.totalWindowArea * GLAZING_CONSTANT.at(secondGlazingId - 1).carbon)/1000).toFixed(0))
                                                        -
                                                        (isNewBuilding ? ((baseGlazing.data.totalWindowArea * GLAZING_CONSTANT.at(0).carbon)/1000).toFixed(0) : ((baseGlazing.data.totalWindowArea * GLAZING_CONSTANT.at(1).carbon)/1000).toFixed(0)))
                                                    /
                                                    Math.abs((reportData.annual_gauge_data[0].value - reportData.annual_gauge_data[2].value)))
                                                * 12).toFixed(0))
                                        }
                                    </span>{secondGlazingId !== 3 && ' Months'}</h1>
                                </div>
                            </div>
                            <div className="w-full bg-[#00587e] mb-4 mt-4 rounded-lg">
                                <button
                                    onClick={() => setIsMoreDetailOpen(prev => !prev)}
                                    className="bg-[#00587e] p-2 w-full flex items items-center justify-between font-bold text-l rounded-lg border-4 border-transparent active:border-white duration-300 text-white"
                                    type="submit"
                                >
                                    <h2>{t('report.moreDetails')}</h2>
                                    {isMoreDetailOpen ? (
                                        <ArrowUp />
                                    ) : (
                                        <ArrowDown />
                                    )
                                    }
                                </button>
                            </div>
                            {isMoreDetailOpen ? (
                                <>
                                    <h1 className="text-4xl font-medium italic text-[#00587e] mb-4 mt-4">{t('report.energyConsumption')}</h1>
                                    <div className="flex flex-row space-x-10">
                                        <div className="bg-white basis-1/2 p-2 h-[250px]">
                                            <h1 className="text-xl font-medium text-[#00587e] mb-4">{t('report.annualElectric')}</h1>
                                            <VitroVerticalBarChart
                                                dataKey="value"
                                                data={reportData.annual_electric_consumption}
                                                format="kWh"
                                                min={findMinMaxThree(reportData.annual_electric_consumption[0].value, reportData.annual_electric_consumption[1].value, reportData.annual_electric_consumption[2].value).min - 30}
                                                max={findMinMaxThree(reportData.annual_electric_consumption[0].value, reportData.annual_electric_consumption[1].value, reportData.annual_electric_consumption[2].value).max + 30}
                                            />
                                        </div>
                                        <div className="bg-white basis-1/2 p-2 h-[250px]">
                                            <h1 className="text-xl font-medium text-[#00587e] mb-4">{t('report.annualGas')}</h1>
                                            <VitroVerticalBarChart
                                                dataKey="value"
                                                data={reportData.annual_gas_consumption}
                                                format="Btu"
                                                min={findMinMaxThree(reportData.annual_gas_consumption[0].value, reportData.annual_gas_consumption[1].value, reportData.annual_gas_consumption[2].value).min - 3}
                                                max={findMinMaxThree(reportData.annual_gas_consumption[0].value, reportData.annual_gas_consumption[1].value, reportData.annual_gas_consumption[2].value).max + 3}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-row space-x-10 mt-4">
                                        <div className="bg-white basis-1/2 p-2 h-[500px]">
                                            <h1 className="text-xl font-medium text-[#00587e] mb-4">{t('report.monthlyElectric')}</h1>
                                            <VitroHorizontalBarChart
                                                dataKey={reportData.monthly_consumption.dataKey}
                                                data={reportData.monthly_consumption.electricData}
                                                format="kWh"
                                            />
                                        </div>
                                        <div className="bg-white basis-1/2 p-2 h-[500px]">
                                            <h1 className="text-xl font-medium text-[#00587e] mb-4">{t('report.monthlyGas')}</h1>
                                            <VitroHorizontalBarChart
                                                dataKey={reportData.monthly_consumption.dataKey}
                                                data={reportData.monthly_consumption.gasData}
                                                format="Btu"
                                            />
                                        </div>
                                    </div>
                                    <div className="bg-white mt-4 p-2">
                                        <h1 className="text-xl font-medium text-[#00587e]">{t('report.monthlyElectric')}</h1>
                                        <MonthlyTable
                                            header={[
                                                t('report.months.jan'),
                                                t('report.months.feb'),
                                                t('report.months.mar'),
                                                t('report.months.apr'),
                                                t('report.months.may'),
                                                t('report.months.jun'),
                                                t('report.months.jul'),
                                                t('report.months.aug'),
                                                t('report.months.sep'),
                                                t('report.months.oct'),
                                                t('report.months.nov'),
                                                t('report.months.dec'),
                                                t('report.months.total')
                                            ]}
                                            data={reportData.electricTable.data}
                                        />
                                    </div>
                                    <div className="bg-white mt-4 p-2">
                                        <h1 className="text-xl font-medium text-[#00587e]">{t('report.monthlyGas')}</h1>
                                        <MonthlyTable
                                            header={[
                                                t('report.months.jan'),
                                                t('report.months.feb'),
                                                t('report.months.mar'),
                                                t('report.months.apr'),
                                                t('report.months.may'),
                                                t('report.months.jun'),
                                                t('report.months.jul'),
                                                t('report.months.aug'),
                                                t('report.months.sep'),
                                                t('report.months.oct'),
                                                t('report.months.nov'),
                                                t('report.months.dec'),
                                                t('report.months.total')
                                            ]}
                                            data={reportData.gasTable.data}
                                        />
                                    </div>
                                </>
                            ) : (
                                <div />
                            )}
                        </div>
                    </>
                    }

                    {detailedReportLoading && <Loading/>}

                    {/* 1st PDF start */}
                    <div className="w-[793px] h-[1122px] relative" ref={divRef} style={{ position: "fixed", left: "-9999px"}}>
                        <img src="/images/vitro_pdf-7.svg" alt="pdf" className="w-[793px] h-[1122px]"/>
                        <div className="absolute w-[780px] h-[1122px] pt-20 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 pl-6 pr-6">
                            {/* Project info and building image start */}
                            <div className="flex flex-row justify-between">
                                <div className="flex flex-col justify-around w-[360px] mr-2 bg-white pl-4 pr-3 pt-3 pb-3 rounded">
                                    <ProjectInfoFieldPdf title="Location" value={pdfReportData.general.location} />
                                    <ProjectInfoFieldPdf title="Building" value={pdfReportData.general.building_type} />
                                    <ProjectInfoFieldPdf title="Executed" value={reportDate} />
                                    <ProjectInfoFieldPdf title="Window to Wall Ratio" value={pdfReportData.general.wwr} />
                                    <ProjectInfoFieldPdf title="Glazing Type A" value={firstGlazing.data.glassSpec.name} />
                                    <ProjectInfoFieldPdf title="Glazing Type B" value={secondGlazing.data.glassSpec.name} />
                                </div>
                                <img src={setPdfBackground()} alt="pdf" className="w-[360px] h-[150px]"/>
                            </div>
                            {/* Project info and building image end */}

                            {/* Annual Carbon Emission Table and Gauge start */}
                            <div className="text-sm mt-2 text-[12px]">
                                <div className="flex flex-row bg-white rounded">
                                    <div className="basis-2/5 m-1 p-2 flex flex-col items-start">
                                        <h1 className="font-bold italic text-[#00587e] mb-1">Annual Operational Carbon Emissions</h1>
                                        <h1 className="font-medium italic text-[#414B5B] mb-1">Tons CO2 equivalent (T CO2 eq)</h1>
                                    </div>
                                    <div className="bg-[#00587e] rounded basis-2/5 m-1 flex flex-col items-center">
                                        <h1 className="text-center text-white font-medium text-xs">{reportData.glass_data[1].name}</h1>
                                        <hr className="h-px my-2 bg-white border-0 dark:bg-white w-full" />
                                        <div className="pb-2">
                                            <CustomGaugePdf
                                                value={reportData.annual_gauge_data[1].value}
                                                percent={reportData.annual_gauge_data[1].percent}
                                                backgroundColor="#F59320"
                                                arrowColor="#F59320"
                                                baseColor="#ADD8E6"
                                                progressColor="#FFFFFF"
                                                textColor="#FFFFFF"
                                            />
                                        </div>
                                    </div>
                                    <div className="bg-[#0090a1] rounded basis-2/5 m-1 flex flex-col items-center">
                                        <h1 className="text-center text-white font-medium text-xs">{reportData.glass_data[2].name}</h1>
                                        <hr className="h-px my-2 bg-white border-0 dark:bg-white w-full" />
                                        <div className="pb-2">
                                            <CustomGaugePdf
                                                value={reportData.annual_gauge_data[2].value}
                                                percent={reportData.annual_gauge_data[2].percent}
                                                backgroundColor="#82C341"
                                                arrowColor="#82C341"
                                                baseColor="#ADD8E6"
                                                progressColor="#FFFFFF"
                                                textColor="#FFFFFF"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-row bg-white rounded">
                                    <div className="basis-2/5 m-1 flex flex-col items-start">
                                        <h1 className="font-light italic text-[#414B5B]">Operational Carbon - 25 Year Life Cycle</h1>
                                    </div>
                                    <div className="bg-[#F7F7F7] rounded basis-2/5 m-1 p-1 flex flex-col items-center">
                                        <h1 className="text-center text-[#999999] font-medium">{ firstGlazingId === 3 ? 'NA' :  `${reportData.annual_gauge_data[1].lifeValue} TCO2e`}</h1>
                                    </div>
                                    <div className="bg-[#F7F7F7] rounded basis-2/5 m-1 p-1 flex flex-col items-center">
                                        <h1 className="text-center text-[#999999] font-medium">{ secondGlazingId === 3 ? 'NA' :  `${reportData.annual_gauge_data[2].lifeValue} TCO2e`}</h1>
                                    </div>
                                </div>
                                <div className="flex flex-row bg-white rounded">
                                    <div className="basis-2/5 m-1 p-1 flex flex-col items-start">
                                        <h1 className="font-medium italic text-[#414B5B]">Embodied Carbon of Glazing</h1>
                                    </div>
                                    <div className="bg-[#F7F7F7] rounded basis-2/5 m-1 p-1 flex flex-col items-center">
                                        <h1 className="text-center text-black"><span className="font-medium text-[#00587e]">{firstGlazingId === 3 ? 'NA' : ((firstGlazing.data.totalWindowArea * GLAZING_CONSTANT.at(firstGlazingId - 1).carbon) / 1000).toFixed(0)}</span> {firstGlazingId !== 3 && ' TCO2e'}</h1>
                                    </div>
                                    <div className="bg-[#F7F7F7] rounded basis-2/5 m-1 p-1 flex flex-col items-center">
                                        <h1 className="text-center text-black"><span className="font-medium text-[#00587e]">{secondGlazingId === 3 ? 'NA' : ((secondGlazing.data.totalWindowArea * GLAZING_CONSTANT.at(secondGlazingId - 1).carbon) / 1000).toFixed(0)}</span> {secondGlazingId !== 3 && ' TCO2e'}</h1>
                                    </div>
                                </div>
                                <div className="flex flex-row bg-white rounded">
                                    <div className="basis-2/5 m-1 p-1 flex items-start">
                                        <h1 className="font-medium italic text-[#414B5B] pr-2">CO2 Offset</h1>
                                    </div>
                                    <div className="bg-[#F7F7F7] rounded basis-2/5 m-1 p-1 flex flex-col items-center">
                                        <h1 className="text-center text-black"><span className="font-medium text-[#00587e]">
                                        {firstGlazingId === 3 ? 'NA' :
                                            Math.abs((((((firstGlazing.data.totalWindowArea * GLAZING_CONSTANT.at(firstGlazingId - 1).carbon)/1000).toFixed(0))
                                                        -
                                                        (isNewBuilding ? ((baseGlazing.data.totalWindowArea * GLAZING_CONSTANT.at(0).carbon)/1000).toFixed(0) : ((baseGlazing.data.totalWindowArea * GLAZING_CONSTANT.at(1).carbon)/1000).toFixed(0)))
                                                    /
                                                    Math.abs((reportData.annual_gauge_data[0].value - reportData.annual_gauge_data[1].value)))
                                                * 12).toFixed(0)
                                        }
                                    </span> {firstGlazingId !== 3 && ' Months'}</h1>
                                    </div>
                                    <div className="bg-[#F7F7F7] rounded basis-2/5 m-1 p-1 flex flex-col items-center">
                                        <h1 className="text-center text-black"><span className="font-medium text-[#00587e]">
                                        {secondGlazingId === 3 ? 'NA' :
                                            Math.abs((((((secondGlazing.data.totalWindowArea * GLAZING_CONSTANT.at(secondGlazingId - 1).carbon)/1000).toFixed(0))
                                                        -
                                                        (isNewBuilding ? ((baseGlazing.data.totalWindowArea * GLAZING_CONSTANT.at(0).carbon)/1000).toFixed(0) : ((baseGlazing.data.totalWindowArea * GLAZING_CONSTANT.at(1).carbon)/1000).toFixed(0)))
                                                    /
                                                    Math.abs((reportData.annual_gauge_data[0].value - reportData.annual_gauge_data[2].value)))
                                                * 12).toFixed(0)
                                        }
                                    </span> {secondGlazingId !== 3 && ' Months'}</h1>
                                    </div>
                                </div>
                            </div>
                            {/* Annual Carbon Emission Table and Gauge end */}

                            {/* Energy consumption table start */}
                            <div className="flex flex-row justify-start mt-2">
                                <div className="flex flex-col justify-center">
                                    <h1 className="text-sm font-bold italic text-[#00587e]">Energy Consumption</h1>
                                </div>
                            </div>
                            <div className="flex flex-row">
                                <div className="bg-white p-2 h-[150px] w-[400px] mt-2 rounded">
                                    <h1 className="text-sm font-medium text-[#00587e]">Annual Electric Consumption (kWh x000)</h1>
                                    <VitroVerticalBarChartPdf
                                        dataKey="value"
                                        data={pdfReportData.annual_electric_consumption}
                                        format="kWh"
                                        min={findMinMax(pdfReportData.annual_electric_consumption[0].value, pdfReportData.annual_electric_consumption[1].value).min - 30}
                                        max={findMinMax(pdfReportData.annual_electric_consumption[0].value, pdfReportData.annual_electric_consumption[1].value).max + 30}
                                    />
                                </div>
                                <div className="bg-white p-2 h-[150px] w-[400px] mt-2 ml-2 rounded">
                                    <h1 className="text-sm font-medium text-[#00587e]">Annual Gas Consumption (mmBtu x00)</h1>
                                    <VitroVerticalBarChartPdf
                                        dataKey="value"
                                        data={pdfReportData.annual_gas_consumption}
                                        format="Btu"
                                        min={findMinMax(pdfReportData.annual_gas_consumption[0].value, pdfReportData.annual_gas_consumption[1].value).min - 3}
                                        max={findMinMax(pdfReportData.annual_gas_consumption[0].value, pdfReportData.annual_gas_consumption[1].value).max + 3}
                                    />
                                </div>
                            </div>
                            {/* Energy consumption table end */}

                            {/* Horizontal Bar Chart Start */}
                            <div className="flex flex-row mt-2">
                                <div className="bg-white p-2 h-[200px] w-[400px] rounded">
                                    <h1 className="text-sm font-medium text-[#00587e]">Monthly Electric Consumption (kWh x000)</h1>
                                    <VitroHorizontalBarChartPdf
                                        dataKey={pdfReportData.monthly_consumption.dataKey}
                                        data={pdfReportData.monthly_consumption.electricData}
                                        format="kWh"
                                        maxData={300}
                                    />
                                </div>
                                <div className="bg-white p-2 h-[200px] w-[400px] ml-2 rounded">
                                    <h1 className="text-sm font-medium text-[#00587e]">Monthly Gas Consumption (mmBtu x00)</h1>
                                    <VitroHorizontalBarChartPdf
                                        dataKey={pdfReportData.monthly_consumption.dataKey}
                                        data={pdfReportData.monthly_consumption.gasData}
                                        format="Btu"
                                        maxData={50}
                                    />
                                </div>
                            </div>
                            {/* Horizontal Bar Chart End */}

                            {/* Monthly Table Start */}
                            <div className="bg-white mt-2 p-1 rounded">
                                <h1 className="text-sm font-medium text-[#00587e] pb-1">Monthly Electric Consumption (kWh x000)</h1>
                                <MonthlyTablePdf
                                    header={MONTHS}
                                    data={pdfReportData.electricTable.data}
                                />
                            </div>
                            <div className="bg-white mt-1 p-1 rounded">
                                <h1 className="text-sm font-medium text-[#00587e] pb-1">Monthly Gas Consumption (mmBtu x00)</h1>
                                <MonthlyTablePdf
                                    header={MONTHS}
                                    data={pdfReportData.gasTable.data}
                                />
                            </div>
                            {/* Monthly Table End */}
                        </div>
                    </div>
                    {/* 1st PDF end */}

                    {/* 2nd PDF start */}
                    <div className="w-[793px] h-[1122px] relative" ref={divRef2} style={{ position: "fixed", left: "-9999px"}}>
                        <img src="/images/vitro_pdf-7.svg" alt="pdf" className="w-[793px] h-[1122px]"/>
                        <div className="absolute w-[780px] h-[1122px] pt-20 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 pl-6 pr-6">
                            {/* Project info and building image start */}
                            <div className="flex flex-row mt-2">
                                <div className="flex flex-col justify-around h-[130px] w-[400px] bg-white pr-10 pt-3 pb-3 rounded">
                                    <ProjectInfoFieldPdf title="Location" value={pdfReportData.general.location} />
                                    <ProjectInfoFieldPdf title="Building" value={pdfReportData.general.building_type} />
                                    <ProjectInfoFieldPdf title="Executed" value={reportDate} />
                                    <ProjectInfoFieldPdf title="Glazing Type A" value={firstGlazing.data.glassSpec.name} />
                                </div>
                                <div className="bg-white p-2 h-[130px] w-[400px] ml-2 rounded">
                                    <div className="flex flex-row p-2 bg-white">
                                        <div className="flex flex-col w-[100px] items-center">
                                            <Gauge
                                                value={pdfReportData.first_glazing_annual_gauge_data.stationaryCombustion}
                                                color="#DCDDDE"
                                                radiusSize={40}
                                                valueSize={12}
                                                contextSize={6}
                                            />
                                            <h2 className="text-[8px] text-center">Stationary Combustion</h2>
                                            <h2 className="text-[8px] text-center">(Annual)</h2>
                                        </div>
                                        <div className="flex flex-col w-[100px] items-center">
                                            <Gauge
                                                value={pdfReportData.first_glazing_annual_gauge_data.electricEmissions}
                                                color="#DCDDDE"
                                                radiusSize={40}
                                                valueSize={12}
                                                contextSize={6}
                                            />
                                            <h2 className="text-[8px] text-center">Electric Emissions</h2>
                                            <h2 className="text-[8px] text-center">(Annual)</h2>
                                        </div>
                                        <div className="flex flex-col w-[100px] items-center">
                                            <Gauge
                                                value={pdfReportData.first_glazing_annual_gauge_data.total}
                                                color="#00587e"
                                                radiusSize={40}
                                                valueSize={12}
                                                contextSize={6}
                                            />
                                            <h2 className="text-[8px] text-center">Total</h2>
                                            <h2 className="text-[8px] text-center">(Annual)</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Project info and building image end */}

                            {/* Carbon Emissions and Energy Consumption start */}
                            <div className="flex flex-row mt-2">
                                <div className="bg-white p-2 h-[180px] w-[400px] rounded">
                                    <h1 className="text-sm font-medium text-[#00587e]">Monthly Electric Consumption (kWh x000)</h1>
                                    <VitroStackBarChartPdf
                                        data={pdfReportData.first_chart_monthly_electric_consumption}
                                        format="kWh"
                                    />
                                </div>
                                <div className="bg-white p-2 h-[180px] w-[400px] ml-2 rounded">
                                    <h1 className="text-sm font-medium text-[#00587e]">Monthly Gas Consumption (mmBtu x00)</h1>
                                    <VitroStackBarChartPdf
                                        data={pdfReportData.first_chart_monthly_gas_consumption}
                                        format="Btu"
                                    />
                                </div>
                            </div>
                            {/* Carbon Emissions and Energy Consumption end */}

                            <div className="w-full bg-white mt-2 p-2 flex flex-col rounded">
                                <div className="flex flex-row justify-between">
                                    <CustomLegend color="#FF4136" name="Space Cooling" />
                                    <CustomLegend color="#FFA700" name="Heat Rejection" />
                                    <CustomLegend color="#FFDC00" name="Refridgeration" />
                                    <CustomLegend color="#00FF00" name="Space Heat" />
                                    <CustomLegend color="#0074D9" name="Hp Supp." />
                                    <CustomLegend color="#B10DC9" name="Hot Water" />
                                </div>
                                <div className="flex flex-row justify-between mt-1">
                                    <CustomLegend color="#FF007D" name="Vent. Fans" />
                                    <CustomLegend color="#00BFFF" name="Pumps and Aux" />
                                    <CustomLegend color="#FF005E" name="Ext. Usage" />
                                    <CustomLegend color="#FF7F00" name="Misc. Equip." />
                                    <CustomLegend color="#008000" name="Task Lights" />
                                    <CustomLegend color="#800080" name="Area Lights" />
                                </div>
                            </div>

                            {/* Monthly Table Start */}
                            <div className="bg-white mt-2 p-1 rounded">
                                <h1 className="text-sm font-medium text-[#00587e] pb-1">Monthly Electric Consumption (kWh x000)</h1>
                                <MonthlyTablePdf
                                    header={MONTHS}
                                    data={pdfReportData.firstElectricTableSummary.data}
                                />
                            </div>
                            <div className="bg-white mt-2 p-1 rounded">
                                <h1 className="text-sm font-medium text-[#00587e] pb-1">Monthly Gas Consumption (mmBtu x00)</h1>
                                <MonthlyTablePdf
                                    header={MONTHS}
                                    data={pdfReportData.firstGasTableSummary.data}
                                />
                            </div>
                            {/* Monthly Table End */}
                        </div>
                    </div>
                    {/* 2nd PDF end */}

                    {/* 3rd PDF start */}
                    <div className="w-[793px] h-[1122px] relative" ref={divRef3} style={{ position: "fixed", left: "-9999px"}}>
                        <img src="/images/vitro_pdf-7.svg" alt="pdf" className="w-[793px] h-[1122px]"/>
                        <div className="absolute w-[780px] h-[1122px] pt-20 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 pl-6 pr-6">
                            {/* Project info and building image start */}
                            <div className="flex flex-row mt-2">
                                <div className="flex flex-col justify-around h-[130px] w-[400px] bg-white pr-10 pt-3 pb-3 rounded">
                                    <ProjectInfoFieldPdf title="Location" value={pdfReportData.general.location} />
                                    <ProjectInfoFieldPdf title="Building" value={pdfReportData.general.building_type} />
                                    <ProjectInfoFieldPdf title="Executed" value={reportDate} />
                                    <ProjectInfoFieldPdf title="Glazing Type B" value={secondGlazing.data.glassSpec.name} />
                                </div>
                                <div className="bg-white p-2 h-[130px] w-[400px] ml-2 rounded">
                                    <div className="flex flex-row p-2 bg-white">
                                        <div className="flex flex-col w-[100px] items-center">
                                            <Gauge
                                                value={pdfReportData.second_glazing_annual_gauge_data.stationaryCombustion}
                                                color="#DCDDDE"
                                                radiusSize={40}
                                                valueSize={12}
                                                contextSize={6}
                                            />
                                            <h2 className="text-[8px] text-center">Stationary Combustion</h2>
                                            <h2 className="text-[8px] text-center">(Annual)</h2>
                                        </div>
                                        <div className="flex flex-col w-[100px] items-center">
                                            <Gauge
                                                value={pdfReportData.second_glazing_annual_gauge_data.electricEmissions}
                                                color="#DCDDDE"
                                                radiusSize={40}
                                                valueSize={12}
                                                contextSize={6}
                                            />
                                            <h2 className="text-[8px] text-center">Electric Emissions</h2>
                                            <h2 className="text-[8px] text-center">(Annual)</h2>
                                        </div>
                                        <div className="flex flex-col w-[100px] items-center">
                                            <Gauge
                                                value={pdfReportData.second_glazing_annual_gauge_data.total}
                                                color="#00587e"
                                                radiusSize={40}
                                                valueSize={12}
                                                contextSize={6}
                                            />
                                            <h2 className="text-[8px] text-center">Total</h2>
                                            <h2 className="text-[8px] text-center">(Annual)</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Project info and building image end */}

                            {/* Carbon Emissions and Energy Consumption start */}
                            <div className="flex flex-row mt-2">
                                <div className="bg-white p-2 h-[180px] w-[400px] rounded">
                                    <h1 className="text-sm font-medium text-[#00587e]">Monthly Electric Consumption (kWh x000)</h1>
                                    <VitroStackBarChartPdf
                                        data={pdfReportData.second_chart_monthly_electric_consumption}
                                        format="kWh"
                                    />
                                </div>
                                <div className="bg-white p-2 h-[180px] w-[400px] ml-2 rounded">
                                    <h1 className="text-sm font-medium text-[#00587e]">Monthly Gas Consumption (mmBtu x00)</h1>
                                    <VitroStackBarChartPdf
                                        data={pdfReportData.second_chart_monthly_gas_consumption}
                                        format="Btu"
                                    />
                                </div>
                            </div>
                            {/* Carbon Emissions and Energy Consumption end */}

                            <div className="w-full bg-white mt-2 p-2 flex flex-col rounded">
                                <div className="flex flex-row justify-between">
                                    <CustomLegend color="#FF4136" name="Space Cooling" />
                                    <CustomLegend color="#FFA700" name="Heat Rejection" />
                                    <CustomLegend color="#FFDC00" name="Refridgeration" />
                                    <CustomLegend color="#00FF00" name="Space Heat" />
                                    <CustomLegend color="#0074D9" name="Hp Supp." />
                                    <CustomLegend color="#B10DC9" name="Hot Water" />
                                </div>
                                <div className="flex flex-row justify-between mt-1">
                                    <CustomLegend color="#FF007D" name="Vent. Fans" />
                                    <CustomLegend color="#00BFFF" name="Pumps and Aux" />
                                    <CustomLegend color="#FF005E" name="Ext. Usage" />
                                    <CustomLegend color="#FF7F00" name="Misc. Equip." />
                                    <CustomLegend color="#008000" name="Task Lights" />
                                    <CustomLegend color="#800080" name="Area Lights" />
                                </div>
                            </div>

                            {/* Monthly Table Start */}
                            <div className="bg-white mt-2 p-1 rounded">
                                <h1 className="text-sm font-medium text-[#00587e] pb-1">Monthly Electric Consumption (kWh x000)</h1>
                                <MonthlyTablePdf
                                    header={MONTHS}
                                    data={pdfReportData.secondElectricTableSummary.data}
                                />
                            </div>
                            <div className="bg-white mt-2 p-1 rounded">
                                <h1 className="text-sm font-medium text-[#00587e] pb-1">Monthly Gas Consumption (mmBtu x00)</h1>
                                <MonthlyTablePdf
                                    header={MONTHS}
                                    data={pdfReportData.secondGasTableSummary.data}
                                />
                            </div>
                            {/* Monthly Table End */}
                        </div>
                    </div>
                    {/* 3rd PDF end */}

                    {/* 4th PDF start */}
                    <div className="w-[793px] h-[1122px] relative" ref={divRef4} style={{ position: "fixed", left: "-9999px"}}>
                        <img src="/images/vitro_pdf-7.svg" alt="pdf" className="w-[793px] h-[1122px]"/>
                        <div className="absolute w-[780px] h-[1122px] pt-20 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 pl-6 pr-6">
                            {/* 1st part start */}
                            <div>
                                <div className="bg-[#00587E] p-1 w-full rounded">
                                    <h2 className="text-base font-medium mb-1 text-white">About Vitro eMissions Carbon & Energy Calculator</h2>
                                </div>
                                <div className="text-[10px] pl-2 bg-white p-1 mt-1 rounded">
                                    <p className="mb-1">Vitro eMissions is a web application to give Users an understanding of the impact that different glazing design decisions will have on annual operational carbon emissions and energy use. </p>
                                    <p className="mb-1">Depending on a building&rsquo;s parameters (for example, it&rsquo;s typology, location, orientation), the building envelope can affect more than half of a building&rsquo;s operational energy use, and related greenhouse gas emissions. In particular, glazing design can have major impacts. Decisions such as window to wall ratio; the glazed unit configuration and associated properties; whether it is shaded or not can hugely affect energy use. </p>
                                    <p className="mb-1">Using notional building types with predefined parameters, Users can investigate the how envelope design decisions will affect the building&rsquo;s energy performance. </p>
                                    <p className="mb-1">Vitro eMissions is a partnership between Vitro Architectural Glass and FenestraPro. The tool employs energy calculations provided by the United States Department of Energy, and data from the National Weather Service. Vitro assumes no responsibility for the accuracy of the information available for simulations in this program. </p>
                                </div>
                            </div>
                            {/* 1st part end */}
                            {/* 2nd part start */}
                            <div className="mt-2">
                                <div className="bg-[#0090A1] p-1 w-full rounded">
                                    <h2 className="text-base font-medium mb-1 text-white">How Operational Energy Results are calculated</h2>
                                </div>
                                <div className="text-[10px] pl-2 bg-white p-1 mt-1 rounded">
                                    <p className="mb-1">Vitro eMissions uses the DOE-2 software engine, a widely used and accepted freeware building energy analysis program that can predict the energy use and cost for all types of buildings. DOE-2 uses a description of the building layout, constructions, operating schedules, conditioning systems (lighting, HVAC, etc.) and utility rates provided by the user, along with weather data, to perform an hourly simulation of the building and to estimate utility bills. </p>
                                    <p className="mb-1 mt-1">Users input following values:</p>
                                    <ul className="list-disc mt-1 pl-8">
                                        <li>Building Types </li>
                                        <li>Location </li>
                                        <li>Orientation </li>
                                        <li>Window to Wall Ratio </li>
                                        <li>Shading (Overhangs / Fins) </li>
                                        <li>Curtain Wall Performance </li>
                                        <li>Glazing Configuration (two types, for comparison).  </li>
                                    </ul>
                                    <p className="mb-1 mt-1">The following values are predefined, and as a rule will predefine: </p>
                                    <ul className="list-disc mt-1 pl-8">
                                        <li>
                                            Building Construction and Thermal resistance of opaque elements – defaults in eQuest
                                            <ol className="pl-4 mt-1 space-y-1 list-decimal list-inside">
                                                <li>External Wall</li>
                                                <li>Roof</li>
                                                <li>Floor</li>
                                            </ol>
                                        </li>
                                        <li>
                                            Occupancy Schedules
                                            <ul className="pl-4 mt-1 space-y-1 list-decimal list-inside">
                                                <li>Lighting</li>
                                                <li>Task Lighting</li>
                                                <li>HVAC Equipment (including Boilers, Chillers, Circulation Loops, Water Heating etc.)</li>
                                            </ul>
                                        </li>
                                        <li>
                                            Infiltration / Shell Tightness
                                        </li>
                                        <li>
                                            Occupancy Factors
                                        </li>
                                        <li>
                                            Zone Types
                                        </li>
                                        <li>
                                            Run Period (full year)
                                        </li>
                                        <li>
                                            Building Geometry
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* 2nd part end */}
                            {/* 3rd part start */}
                            <div className="mt-2">
                                <div className="bg-[#57C7D9] p-1 w-full rounded">
                                    <h2 className="text-base font-medium mb-1 text-white">How Operational Carbon Emissions Results are calculated</h2>
                                </div>
                                <div className="text-[10px] pl-2 bg-white p-1 mt-1 rounded">
                                    <p className="mb-1">Using the operational energy values derived from DOE-2, carbon emissions are calculated The EPA Simplified GHG Emissions Calculator. This calculator is designed as a simplified calculation tool to help organizations estimate and inventory their annual greenhouse gas (GHG) emissions for US-based operations. All methodologies and default values provided are based on the most current Center for Corporate Climate Leadership Greenhouse Gas Inventory Guidance Documents and the Emission Factors Hub. The Calculator quantifies the direct and indirect emissions from different sources.  </p>
                                    <p className="mb-1">All values calculated and returned are Scope 1 Emissions, i.e. emissions from sources that the organization owns or controls, like natural gas-fired boilers These are also called direct emissions. </p>
                                    <p className="mb-1">While Vitro has made a good faith effort to verify the reliability of the results of this computer-based tool, it may contain unknown programming errors that may result in incorrect results. The user is encouraged to use good judgment and report any questionable results to Vitro for evaluation. The applicability and subsequent results of data simulated by this tool will be compromised if the user fails to input the correct information. Vitro makes no warranty or guarantee as to the results obtained by the user of this tool, and its results are not intended to be used as a formal energy analysis. </p>
                                </div>
                            </div>
                            {/* 3rd part end */}
                            {/* 4th part start */}
                            <div className="mt-1">
                                <div className="flex flex-row justify-center p-1 w-full">
                                    <h2 className="text-base font-medium mb-1 text-[#00587e]">For more information on embodied carbon and Vitro’s commitment to sustainability, visit:</h2>
                                </div>
                                <div className="flex flex-row justify-center p-1 w-full">
                                    <button
                                        type="button"
                                        onClick={() => window.open("vitroglazings.com/sustainability")}
                                        className="p-2 w-[280px] rounded text-xl font-bold text-[#00587e] mb-1"
                                    >
                                        vitroglazings.com/sustainability
                                    </button>
                                </div>
                            </div>
                            {/* 4th part end */}
                        </div>
                    </div>
                    {/* 4th PDF end */}

                </section>
            </>
        );
    }
}

export default Report;