import { useRef } from 'react';
import PropTypes from 'prop-types';

const ReportTooltip = ({ children, title, paragraph1, paragraph2, paragraph3 }) => {
    const tooltipRef = useRef();
    const container = useRef();

    return (
        <div
            ref={container}
            onMouseEnter={({ clientX, clientY }) => {
                if (!tooltipRef.current || !container.current) return;
                const { left } = container.current.getBoundingClientRect();

                tooltipRef.current.style.left = `${clientX - left}px`;
            }}
            className="group relative inline-block"
        >
            {children}
            {title ? (
                <div className="z-50 invisible group-hover:visible opacity-0 group-hover:opacity-100 transition bg-[#00587e] text-white p-1 rounded absolute top-full mt-2 whitespace-nowrap text-xs">
                    <div className="p-4">
                        <h2 className="font-bold text-xl">{title}</h2>
                        <p className="text-base pt-1">{paragraph1}</p>
                        <p className="text-base pt-1">{paragraph2}</p>
                        <p className="text-base pt-1">{paragraph3}</p>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

ReportTooltip.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    paragraph1: PropTypes.string.isRequired,
    paragraph2: PropTypes.string.isRequired,
    paragraph3: PropTypes.string.isRequired,
}

export default ReportTooltip;