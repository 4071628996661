import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { WWR_SLIDER_MARKS } from './SelectWwrConstant';
import VitroSlider from '../../components/VitroSlider/VitroSlider';
import { wwrCustomChange } from '../../redux/slices/subMenuSlice';

const SelectWwr = ({nextFunction}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const marks = WWR_SLIDER_MARKS;
    const building = useSelector((state) => state.subMenu.general.building_type);
    const wwr = useSelector((state) => state.subMenu.wwr.value);

    const handleSliderChange = (event, newValue) => {
        dispatch(wwrCustomChange(newValue));
    };

    function setBackground() {
        let prefix;
        let suffix;
        switch (building) {
            case 'Hospital':
                prefix = "/images/wwr/hospital/hospital_";
                suffix = `${wwr}.png`
                return `url('${prefix + suffix}')`;
            case 'University':
                prefix = "/images/wwr/university/university_";
                suffix = `${wwr}.png`
                return `url('${prefix + suffix}')`;
            case 'Multi Family':
                prefix = "/images/wwr/family/family_";
                suffix = `${wwr}.png`
                return `url('${prefix + suffix}')`;
            case 'Office':
                prefix = "/images/wwr/office/office_";
                suffix = `${wwr}.png`
                return `url('${prefix + suffix}')`;
            case 'Convention Centre':
                prefix = "/images/wwr/convention/convention_";
                suffix = `${wwr}.png`
                return `url('${prefix + suffix}')`;
            default:
                prefix = "/images/wwr/university/university_";
                suffix = `${wwr}.png`
                return `url('${prefix + suffix}')`;
        }
    }

    return (
        <div
            className="absolute ml-[265px] mt-1 bg-zinc-200 bg-opacity-90 flex flex-col w-[350px] h-[400px] p-1 rounded-lg border-white border-4">
            <div className="flex flex-row p-1 justify-between">
                <h1 className="text-[#00587e]">{t('windowToWallRatioSubMenu.title.selectWindowToWallRatio')}</h1>
                <button
                    className="bg-[#00587e]"
                    onClick={() => nextFunction(4)}
                    type="submit"
                >
                    <div className="flex flex-wrap">
                        <nav className="w-full p-2 flex-row justify-center text-white font-medium">
                            <h1>{t('sidebar.title.next')}</h1>
                        </nav>
                    </div>
                </button>
            </div>
            <div className="flex flex-row justify-center">
                <div className="p-4 mt-4 w-[300px] h-[200px] bg-cover" style={{backgroundImage: setBackground()}} />
            </div>
            <div className="p-4 pt-10">
                <VitroSlider
                    width={300}
                    currentValue={wwr}
                    onChangeEvent={handleSliderChange}
                    incremental={10}
                    marks={marks}
                    max={70}
                    min={10}
                />
            </div>
        </div>
    );
};

SelectWwr.propTypes = {
    nextFunction: PropTypes.func.isRequired,
}

export default SelectWwr;