import { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const ToolTip = ({ children, tooltip }) => {
    const tooltipRef = useRef();
    const container = useRef();
    const { t } = useTranslation();

    return (
        <div
            ref={container}
            onMouseEnter={({ clientX }) => {
                if (!tooltipRef.current || !container.current) return;
                const { left } = container.current.getBoundingClientRect();

                tooltipRef.current.style.left = `${clientX - left}px`;
            }}
            className="group relative inline-block"
        >
            {children}
            {tooltip ? (
                <div className="z-50 invisible group-hover:visible opacity-0 group-hover:opacity-100 transition bg-[#00587e] text-white p-1 rounded absolute top-full mt-2 whitespace-nowrap text-xs">
                    <h2>{t('comparePage.tooltip.vlt')}: {tooltip.vlt*100}% </h2>
                    <h2>{t('comparePage.tooltip.shgc')}: {tooltip.shgc} </h2>
                    <h2>{t('comparePage.tooltip.uValue')}: {tooltip.uval}</h2>
                </div>
            ) : null}
        </div>
    );
};

ToolTip.propTypes = {
    children: PropTypes.node.isRequired,
    tooltip: PropTypes.object.isRequired,
}

export default ToolTip;