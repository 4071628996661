import { useSelector, useDispatch } from 'react-redux'
import { geoCentroid } from 'd3-geo';
import {
    ComposableMap,
    Geographies,
    Geography,
    Marker,
    Annotation
} from 'react-simple-maps';
import mexicoGeoJson from './mexico.json';
import {
    assignState,
    assignStateId
} from '../../redux/slices/subMenuSlice';

const geoUrl = mexicoGeoJson;

const allStates = [
    { "id": "AG", "val": "01" },
    { "id": "BC", "val": "02" },
    { "id": "BS", "val": "03" },
    { "id": "CM", "val": "04" },
    { "id": "CO", "val": "05" },
    { "id": "CL", "val": "06" },
    { "id": "CS", "val": "07" },
    { "id": "CH", "val": "08" },
    { "id": "DF", "val": "09" },
    { "id": "DG", "val": "10" },
    { "id": "GT", "val": "11" },
    { "id": "GR", "val": "12" },
    { "id": "HG", "val": "13" },
    { "id": "JA", "val": "14" },
    { "id": "EM", "val": "15" },
    { "id": "MI", "val": "16" },
    { "id": "MO", "val": "17" },
    { "id": "NA", "val": "18" },
    { "id": "NL", "val": "19" },
    { "id": "OA", "val": "20" },
    { "id": "PU", "val": "21" },
    { "id": "QT", "val": "22" },
    { "id": "QR", "val": "23" },
    { "id": "SL", "val": "24" },
    { "id": "SI", "val": "25" },
    { "id": "SO", "val": "26" },
    { "id": "TB", "val": "27" },
    { "id": "TM", "val": "28" },
    { "id": "TL", "val": "29" },
    { "id": "VE", "val": "30" },
    { "id": "YU", "val": "31" },
    { "id": "ZA", "val": "32" },
];

const offsets = {
    TB: [50, -120],
    PU: [50, -100],
    TL: [50, -120],
    QT: [50, -120],
    AG: [50, -120],
    NA: [-120, 90],
    CL: [-120, 90],
    DF: [-120, 90],
    MO: [-120, 130],
};

const MapChartMexico = () => {
    const dispatch = useDispatch();
    const selectedState = useSelector((state) => state.subMenu.location.state);

    const handleClick = geo => () => {
        const state = allStates.find(s => s.val === geo.properties.sta_code[0]);
        // console.log(geo.properties.sta_code[0]);
        // console.log(geo.properties.sta_name);
        // console.log(state.id);
        dispatch(assignState(geo.properties.sta_name));
        dispatch(assignStateId(state.id));
    };
    return (
        <ComposableMap
            projection="geoAlbers"
            width={600}
            height={600}
            projectionConfig={{
                center: [-5, 20],
            }}
        >
            <Geographies geography={geoUrl} >
                {({geographies}) => (
                    <>
                        {geographies.map(geo => {
                            const isClicked = selectedState === geo.properties.sta_name;
                            return (
                                <Geography
                                    key={geo.rsmKey}
                                    stroke="#FFF"
                                    geography={geo}
                                    fill={isClicked ? "#4cb1bd" : "#DDD"}
                                    onClick={handleClick(geo)}
                                    style={{
                                        default: {outline: "none"},
                                        hover: {outline: "none"},
                                        pressed: {outline: "none"},
                                    }}
                                />
                            )
                            }
                        )}
                        {geographies.map(geo => {
                            const centroid = geoCentroid(geo);
                            const cur = allStates.find(s => s.val === geo.properties.sta_code[0]);
                            return (
                                <g key={`${geo.rsmKey}-name`}>
                                    {cur &&
                                        centroid[0] > -160 &&
                                        centroid[0] < -67 &&
                                        (Object.keys(offsets).indexOf(cur.id) === -1 ? (
                                            <Marker coordinates={centroid}>
                                                <text y="2" fontSize={14} textAnchor="middle" onClick={handleClick(geo)} className="cursor-default">
                                                    {cur.id}
                                                </text>
                                            </Marker>
                                        ) : (
                                            <Annotation
                                                subject={centroid}
                                                dx={offsets[cur.id][0]}
                                                dy={offsets[cur.id][1]}
                                            >
                                                <text x={4} fontSize={14} alignmentBaseline="middle" onClick={handleClick(geo)} className="cursor-default">
                                                    {cur.id}
                                                </text>
                                            </Annotation>
                                        ))
                                    }
                                </g>
                            );
                        })}
                    </>
                )}
            </Geographies>
        </ComposableMap>
    );
}

export default MapChartMexico;
