import PropTypes from 'prop-types';
import { ErrorMessage, Field } from 'formik';

const FormTextInput = ({ name, label }) => (
    <div>
        <label htmlFor={name} className="text-sm font-medium text-gray-700 flex justify-between pb-1">
            <span className="text-black">{label}</span>
            <span className="text-red-600"><ErrorMessage name={name} /></span>
        </label>

        <div className="mt-1">
            <Field
                id={name}
                name={name}
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-highlight-primary focus:border-highlight-primary sm:text-sm"
            />
        </div>
    </div>
);

FormTextInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

export default FormTextInput;
