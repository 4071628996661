const IconShading = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path className="cls-1" d="M4.24,14.03c-1.92,0-3.49,1.58-3.49,3.53s1.58,3.53,3.53,3.53h13.39c2.44,0,4.42-1.98,4.42-4.42s-1.98-4.42-4.42-4.42c-.82,0-1.59,.22-2.31,.67l-.04,.02-.02-.04c-.94-2.02-2.99-3.33-5.21-3.33-2.69,0-4.99,1.83-5.6,4.45v.03s-.22-.01-.22-.01h-.02Z"/>
        <path className="cls-1" d="M19.61,12.7c.44-.75,.69-1.63,.69-2.56,0-2.86-2.32-5.18-5.18-5.18-2.67,0-4.86,2.02-5.14,4.61"/>
        <line className="cls-1" x1="15.12" y1=".75" x2="15.12" y2="2.15"/>
        <line className="cls-1" x1="8.48" y1="3.5" x2="9.47" y2="4.49"/>
        <line className="cls-1" x1="24.52" y1="10.14" x2="23.12" y2="10.14"/>
        <line className="cls-1" x1="21.76" y1="3.5" x2="20.78" y2="4.49"/>
    </svg>
);

export default IconShading;

