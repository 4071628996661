import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';

const FormLabel = ({ name, label }) => (
    <label htmlFor={name} className="text-sm font-medium text-gray-700 flex justify-between pb-1">
        <span className="text-black">{label}</span>
        <span className="text-red-600"><ErrorMessage name={name} /></span>
    </label>

);

FormLabel.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

export default FormLabel;
