import { Tab } from '@headlessui/react';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import FormTextInput from '../../components/Form/FormTextInput';
import FormTextArea from '../../components/Form/FormTextArea';
import Seo from '../Seo/Seo';
import { useSendContactUsLogMutation } from '../../redux/services/api';

const Help = () => {
    const [formikInitialValues, setFormikInitialValues] = useState({
        firstname: '',
        lastname: '',
        email: '',
        company: '',
        query: '',
    });

    const { t } = useTranslation();
    const [sendContactUsLog] = useSendContactUsLogMutation();

    const validationSchema = Yup.object().shape({
        firstname: Yup.string().required(t('validationErrors.required')),
        lastname: Yup.string().required(t('validationErrors.required')),
        email: Yup.string().email(t('validationErrors.email')).required(t('validationErrors.required')),
        company: Yup.string().required(t('validationErrors.required')),
        query: Yup.string().required(t('validationErrors.required')),
    });

    const onSubmitHandler = async (formData) => {
        try {
            await sendContactUsLog(formData);
            toast.success('Success');
        } catch {
            toast.error('Something went wrong. Please try again');
        }
    };

    return (
        <>
            <Seo title="Help"/>
            <section className="h-full">
                <div className="flex flex-row justify-center">
                    <h2 className="text-3xl font-bold pt-4">{t('header.help')}</h2>
                </div>
                <div className="flex flex-row justify-center p-4">
                    <div className="px-2 w-[400px]">
                        <Tab.Group>
                            <Tab.Panels>
                                <Formik
                                    enableReinitialize
                                    initialValues={formikInitialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={onSubmitHandler}
                                >
                                    {({errors}) => (
                                        <Form className="flex flex-col gap-2 py-4">
                                            <FormTextInput name="firstname" label={t('help.firstname')}/>
                                            <FormTextInput name="lastname" label={t('help.lastname')}/>
                                            <FormTextInput name="company" label={t('help.company')}/>
                                            <FormTextInput name="email" label={t('help.email')}/>
                                            <FormTextArea name="query" label={t('help.message')}/>

                                            <div className="flex flex-row justify-center">
                                                <button
                                                    type="submit"
                                                    className="bg-[#38bdf8] p-2 font-bold text-white"
                                                >
                                                    {t('help.submit')}
                                                </button>
                                            </div>
                                        </Form>

                                    )}
                                </Formik>
                            </Tab.Panels>
                        </Tab.Group>
                    </div>
                </div>
            </section>
        </>
    )
};

export default Help;