import {fetchBaseQuery} from "@reduxjs/toolkit/query";

export const serviceUrl = {
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    test: 'test',
};

const baseQuery = fetchBaseQuery({
    baseUrl: serviceUrl.baseUrl,
});

export const baseQueryWithErrorHandling = async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);

    return result;
};