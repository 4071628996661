import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';
import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';
import {
    curtainWallAssignHigh,
    curtainWallAssignLow,
    curtainWallAssignModerate
} from '../../redux/slices/subMenuSlice';

const SelectCurtainWall = ({nextFunction}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const label = { inputProps: { 'aria-label': 'Checkbox' } };

    const checkboxLow = useSelector((state) => state.subMenu.curtain_wall.low);
    const checkboxModerate = useSelector((state) => state.subMenu.curtain_wall.moderate);
    const checkboxHigh = useSelector((state) => state.subMenu.curtain_wall.high);

    const selectLowCurtainWall = () => {
        dispatch(curtainWallAssignLow(!checkboxLow));
        if (checkboxLow === false ){
            dispatch(curtainWallAssignModerate(false));
            dispatch(curtainWallAssignHigh(false));
        }
    }

    const selectModerateCurtainWall = () => {
        dispatch(curtainWallAssignModerate(!checkboxModerate));
        if (checkboxModerate === false ){
            dispatch(curtainWallAssignLow(false));
            dispatch(curtainWallAssignHigh(false));
        }
    }

    const selectHighCurtainWall = () => {
       dispatch(curtainWallAssignHigh(!checkboxHigh));
        if (checkboxHigh === false ){
            dispatch(curtainWallAssignLow(false));
            dispatch(curtainWallAssignModerate(false));
        }
    }

    return (
        <div
            className="absolute ml-[265px] mt-1 bg-zinc-200 bg-opacity-90 flex flex-col w-[350px] h-[275px] p-1 rounded-lg border-white border-4">
            <div className="flex flex-row p-1 justify-between">
                <h1 className="text-[#00587e]">{t('curtainWallSubMenu.title.selectCurtainWall')}</h1>
                <button
                    className="bg-[#00587e]"
                    onClick={() => nextFunction(6)}
                    type="submit"
                >
                    <div className="flex flex-wrap">
                        <nav className="w-full p-2 flex-row justify-center text-white font-medium">
                            <h1>{t('sidebar.title.next')}</h1>
                        </nav>
                    </div>
                </button>
            </div>
            <div>
                <div className="flex flex-row justify-around w-full">
                    <h2 className="w-[250px]">{t('curtainWallSubMenu.title.lowPerformance')}</h2>
                    <Checkbox
                        {...label}
                        defaultChecked size="small"
                        checked={checkboxLow}
                        onClick={selectLowCurtainWall}
                    />
                </div>
                <div className="flex flex-row justify-around w-full pt-2">
                    <h2 className="w-[250px]">{t('curtainWallSubMenu.title.moderatePerformance')}</h2>
                    <Checkbox
                        {...label}
                        defaultChecked size="small"
                        checked={checkboxModerate}
                        onClick={selectModerateCurtainWall}
                    />
                </div>
                <div className="flex flex-row justify-around w-full pt-2">
                    <h2 className="w-[250px]">{t('curtainWallSubMenu.title.highPerformance')}</h2>
                    <Checkbox
                        {...label}
                        defaultChecked size="small"
                        checked={checkboxHigh}
                        onClick={selectHighCurtainWall}
                    />
                </div>
            </div>
        </div>
    );
}

SelectCurtainWall.propTypes = {
    nextFunction: PropTypes.func.isRequired,
}

export default SelectCurtainWall;