import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { MenuIcon } from '@heroicons/react/outline';
import Header from '../components/Header/Header';
import Sidebar from '../components/Sidebar/Sidebar';

const MainLayout = () => {
    const [sidebarOpen, setSidebarOpen] = useState(true);

    return (
        <div className="relative">
            <Header/>
            <div className="mt-24">
                <Sidebar
                sidebarOpen={sidebarOpen}
                setSidebarOpen={(state) => setSidebarOpen(state)}
                />

                <div className="pl-[263px] flex flex-col flex-1 bg-lowlight-secondary overflow-y-auto">
                    <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-lowlight-primary">
                        <button
                            type="button"
                            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-base-secondary hover:text-base-primary focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                            onClick={() => setSidebarOpen(true)}
                        >
                            <span className="sr-only">Open sidebar</span>
                            <MenuIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                    </div>
                    <main className="flex-1">
                        <div>
                            <Outlet />
                        </div>
                    </main>
                </div>
            </div>
        </div>
    );
};

export default MainLayout;