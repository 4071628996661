import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ABOUT, HELP, MAIN_LAYOUT_PATH } from '../../routes';

const Header = () => {
    const { i18n } = useTranslation();
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <header
            className="bg-gradient-to-r from-highlight-primary via-highlight-primary to-highlight-secondary w-full h-24 top-0 left-0 fixed z-10">
            <div className="flex flex-row justify-between bg-gradient-to-l md:bg-banner p-3 h-[98px]">
                <Link to={MAIN_LAYOUT_PATH}>
                    <img src="/logos/vitro2.png" alt="vitro" className="z-1 cursor-auto"/>
                </Link>
                <div className="flex flex-col justify-center pr-10">
                    <div className="flex flex-row">
                        <button
                            className="bg-transparent"
                            onClick={() => navigate(`${MAIN_LAYOUT_PATH}`)}
                            type="submit"
                        >
                            <div className="flex flex-wrap">
                                <nav className="w-full p-3 flex-row justify-center text-white text-base">
                                    {t('header.home')}
                                </nav>
                            </div>
                        </button>
                        <button
                            className="bg-transparent"
                            onClick={() => i18n.language === 'en' ? window.open("https://www.vitroglazings.com/", "_blank") : window.open("https://www.vitroglazings.com/es", "_blank")}
                            type="submit"
                        >
                            <div className="flex flex-wrap">
                                <nav className="w-full p-3 flex-row justify-center text-white text-base">
                                    {t('header.vitro')}
                                </nav>
                            </div>
                        </button>
                        <button
                            className="bg-transparent"
                            onClick={() => window.open("http://construct.vitroglazings.com/", "_blank")}
                            type="submit"
                        >
                            <div className="flex flex-wrap">
                                <nav className="w-full p-3 flex-row justify-center text-white text-base">
                                    {t('header.construct')}
                                </nav>
                            </div>
                        </button>
                        <button
                            className="bg-transparent"
                            onClick={() => navigate(`${ABOUT}`)}
                            type="submit"
                        >
                            <div className="flex flex-wrap">
                                <nav className="w-full p-3 flex-row justify-center text-white text-base">
                                    {t('header.about')}
                                </nav>
                            </div>
                        </button>
                        <button
                            className="bg-transparent"
                            onClick={() => navigate(`${HELP}`)}
                            type="submit"
                        >
                            <div className="flex flex-wrap">
                                <nav className="w-full p-3 flex-row justify-center text-white text-base">
                                    {t('header.help')}
                                </nav>
                            </div>
                        </button>
                        <button
                            className="bg-transparent"
                            onClick={() => i18n.changeLanguage(i18n.language === 'en' ? 'spn' : 'en')}
                            type="submit"
                        >
                            <div className="flex flex-wrap">
                                <nav className="w-full p-3 flex-row justify-center text-white text-base">
                                    EN / ES
                                </nav>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;