import PropTypes from 'prop-types';

const IconButton = ({ icon, onClick }) => (
    <button
        className="p-1 rounded-full cursor-pointer text-base-secondary hover:bg-[#0090a1] hover:text-base-primary active:bg-gray-100 active:text-highlight-primary"
        type="button"
        onClick={onClick}
    >
        {icon}
    </button>
);

IconButton.propTypes = {
    icon: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default IconButton;
