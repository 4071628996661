import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Seo from '../Seo/Seo';

const About = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    return (
        <>
            <Seo title="About"/>
            <section className="h-full bg-white p-8 rounded shadow">
                <div className="bg-gradient-to-br from-[#0092D5] to-[#56C7DA] flex rounded">
                    <div className="flex flex-col w-1/2 justify-center">
                        <p className="text-lg tracking-widest text-white font-bold mt-2 ml-4">{t('about.title1')}</p>
                        <div className="text-sm p-4 text-white">
                            <p className="mb-2">{t('about.paragraph1')}</p>
                            <p className="mb-2">{t('about.paragraph2')}</p>
                            <p className="mb-2">{t('about.paragraph3')}</p>
                            <p>{t('about.paragraph4')}</p>
                        </div>
                    </div>
                    <div className="flex flex-col w-1/2 justify-center">
                        <img src="/images/macbook_view.png" alt="macbook" className="h-[420px]"/>
                    </div>
                </div>
                <div className="mt-4 h-1/2 flex">
                    <div className="flex flex-col w-2/5 justify-center">
                        <p className="text-base font-bold mt-2 ml-2">{t('about.title2')}</p>
                        <p className="text-sm p-2">{t('about.paragraph5')}</p>
                        <p className="text-base font-bold mt-2 ml-2">{t('about.title3')}</p>
                        <p className="text-sm p-2">{t('about.paragraph6')}</p>
                    </div>
                    <div className="flex flex-row w-3/5 bg-slate-200 rounded justify-center p-4">
                        <div className="flex flex-col w-1/3">
                            <div>
                                <p className="text-base font-bold mt-2">{t('about.title4')}</p>
                            </div>
                            <div className="text-sm">
                                <ul className="list-disc mt-2 pl-8">
                                    <li>{t('about.buildingType')}</li>
                                    <li>{t('about.location')}</li>
                                    <li>{t('about.orientation')}</li>
                                    <li>{t('about.windowToWallRatio')}</li>
                                    <li>{t('about.shading')}</li>
                                    <li>{t('about.curtainWallPerformance')}</li>
                                    <li>{t('about.glazingConfiguration')}</li>
                                </ul>
                            </div>
                        </div>
                        <div className="flex flex-col w-2/3">
                            <div>
                                <p className="text-base font-bold mt-2">{t('about.title5')}</p>
                            </div>
                            <div className="text-sm">
                                <ul className="list-disc mt-2 pl-8">
                                    <li>
                                        {t('about.subTitle1')}
                                        <ol className="pl-8 mt-2 space-y-1 list-decimal list-inside">
                                            <li>{t('about.externalWall')}</li>
                                            <li>{t('about.roof')}</li>
                                            <li>{t('about.floor')}</li>
                                        </ol>
                                    </li>
                                    <li>
                                        {t('about.subTitle2')}
                                        <ul className="pl-8 mt-2 space-y-1 list-decimal list-inside">
                                            <li>{t('about.lighting')}</li>
                                            <li>{t('about.taskLighting')}</li>
                                            <li>{t('about.hvac')}</li>
                                        </ul>
                                    </li>
                                    <li>
                                        {t('about.infiltration')}
                                    </li>
                                    <li>
                                        {t('about.occupancy')}
                                    </li>
                                    <li>
                                        {t('about.zone')}
                                    </li>
                                    <li>
                                        {t('about.period')}
                                    </li>
                                    <li>
                                        {t('about.geometry')}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row justify-end">
                    <button
                        className="bg-slate-300 p-4 mt-2"
                        onClick={() => navigate(-1)}
                        type="submit"
                    >
                        <div className="font-bold">
                            Go Back
                        </div>
                    </button>
                </div>
            </section>
        </>
    )
};

export default About;