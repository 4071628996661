import PropTypes from 'prop-types';

const CustomLegend = ({ name, color }) => (
    <div className="flex flex-col justify-center w-[100px]">
        <div className="flex flex-row justify-start">
            <div className="h-[17px] w-[17px] bg-[#8884d8]" style={{backgroundColor: color}} />
            <h2 className="pl-1 text-[9px] inline-block align-top">{name}</h2>
        </div>
    </div>
);

CustomLegend.propTypes = {
    name: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
};

export default CustomLegend;
