import { createApi } from '@reduxjs/toolkit/query/react';
import {
    baseQueryWithErrorHandling,
    serviceUrl,
} from './settings';

export const apiSlice = createApi({
        reducerPath: 'api',
        baseQuery: baseQueryWithErrorHandling,
        endpoints: (builder) => ({

            getTest: builder.query({
                query: ( data ) => ({
                    url: `${serviceUrl.baseUrl}/test`,
                    method: 'POST',
                    body: data,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                }),
            }),

            sendReportLog: builder.mutation({
                query: ( data ) => ({
                    url: `${serviceUrl.baseUrl}/createReportLog`,
                    method: 'POST',
                    body: data,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                }),
            }),

            sendPdfReportLog: builder.mutation({
                query: ( data ) => ({
                    url: `${serviceUrl.baseUrl}/createPdfReportLog`,
                    method: 'POST',
                    body: data,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                }),
            }),

            sendContactUsLog: builder.mutation({
                query: ( data ) => ({
                    url: `${serviceUrl.baseUrl}/createContactUsLog`,
                    method: 'POST',
                    body: data,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                }),
            }),

    }),
});

export const {
    useGetTestQuery,
    useSendReportLogMutation,
    useSendPdfReportLogMutation,
    useSendContactUsLogMutation,
} = apiSlice;